import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import { Avatar, Col, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Gnosis from "yieldster-abi/contracts/YieldsterVault.json";
import TagContainer from "../TagContainer/TagContainer";
import Toaster from "../../components/Toaster/Toaster";
import WrapperFunction from "../../components/Global-Functions/WrapperFunction";
import { TransactionInProgress } from "../../redux/actions/reload-state";
import { TransactionDetails, Vaults } from "../../services/api";
import { message } from "../../utils/message";
import "./Style/index.css";
import { TransactionActionInstance } from "../../redux/actions/DepositWithdrawAction";
import useInterval from "../Polling/useInterval";
import Currency from "../../services/api/currency";

function CardItem(props) {
  const [viewToaster, setViewToaster] = useState(false);
  const [enable, setEnable] = useState(true);
  const [assetList, setAssetList] = useState([]);
  const [protocolList, setProtocolList] = useState([]);
  const [vaultList, setVaultList] = useState([]);
  const [webAssets, setWebAssets] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState({
    vaultAsset: "empty",
    baseCurrency: "empty",
  });
  const [transactionHash, setTransactionHash] = useState();
  const [currencyList, setCurrencyList] = useState([]);
  const [executeWeb3, setExecuteWeb3] = useState();
  const vaultDetails = props?.vault;
  const assetItems = props?.fields;
  const web3 = props?.web3;
  const vaultAddress = vaultDetails?.vault?.vaultAddress;
  const userAddress = useSelector((state) => state.get_user_address.address);
  const transaction = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.transactionHash
  );

  const dispatch = useDispatch();

  const { Option } = Select;

  const [i, drop] = useDrop({
    accept: "asset",
    drop: () => ({ target: "base" }),
    canDrop: (item) => {
      return item.source !== "base";
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });
  const [j, drop1] = useDrop({
    accept: "vault",
    drop: () => ({ target: "vault" }),
    canDrop: (item) => {
      return item.source !== "vault";
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });
  const [k, drop2] = useDrop({
    accept: "protocol",
    drop: () => ({ target: "protocol" }),
    canDrop: (item) => {
      return item.source !== "protocol";
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });

  const displayData = (value, type) => {
    switch (type) {
      case "asset":
        return value[0]?.assetSymbol;
      case "vault":
        return value[0]?.vaultName;
      case "protocol":
        return value[0]?.protocolName;
      default:
        return " ";
    }
  };

  const displayImage = (value, type) => {
    switch (type) {
      case "asset":
        return value[0]?.assetImageURL ? value[0]?.assetImageURL : undefined;
      case "vault":
        return `data:image/jpeg;base64,${value[0]?.logoImg?.data}`;
      case "protocol":
        return value[0]?.protocolIcon
          ? `data:image/jpeg;base64,${value[0]?.protocolIcon}`
          : undefined;
      default:
        return " ";
    }
  };

  const removeItem = (address, type) => {
    props.removeItemsFromCart(address, type);
  };

  const getDisabledAssetsVaults = () => {
    let initialAssetVaultList = [];
    initialAssetVaultList = vaultDetails?.vault?.supportedAssets.concat(
      vaultDetails?.vault?.supportedVaults
    );
    let difference = initialAssetVaultList.filter(
      (x) => !webAssets.includes(x)
    );
    return difference;
  };

  const update = () => {
    const protocolArray = assetItems.protocol.map((item) => ({
      name: item[0].protocolName,
      address: item[0].protocolAddress,
    }));
    if (
      JSON.stringify(initialValues.assets.protocol) !==
      JSON.stringify(assetItems.protocol)
    )
      Vaults.changeSupportedProtocol(vaultAddress, protocolArray)
        .then((response) => {
          if (response.data.status) {
            message.success(response.data.message);
            setInitialValues((prev) => ({
              ...prev,
              assets: {
                ...prev.assets,
                protocol: assetItems.protocol,
              },
            }));
          }
        })
        .catch((e) => console.log("Change Supported Protocol Error", e));
    if (formValues.vaultAsset !== initialValues.vaultAsset)
      Vaults.updateVaultAssets(vaultAddress, formValues.vaultAsset)
        .then((res) => {
          if (res.data.status) {
            message.success("Base Asset updated");
            setInitialValues((prev) => ({
              ...prev,
              vaultAsset: formValues.vaultAsset,
            }));
          }
        })
        .catch((err) => console.log(err));
    if (formValues.baseCurrency !== initialValues.baseCurrency)
      Vaults.updateBaseCurrency(vaultAddress, formValues.baseCurrency)
        .then((res) => {
          if (res.data.status) {
            message.success("Display Currency updated");
            setInitialValues((prev) => ({
              ...prev,
              baseCurrency: formValues.baseCurrency,
            }));
          }
        })
        .catch((err) => console.log(err));
    return true;
  };

  const web3Transactions = async (assetVaultList) => {
    const disabledAssetsVaults = getDisabledAssetsVaults();
    dispatch(TransactionInProgress(true));
    try {
      const getGnosisSafeInstanceAt = (safeAddress) => {
        return new web3.eth.Contract(Gnosis.abi, safeAddress);
      };
      const safeInstance = await getGnosisSafeInstanceAt(vaultAddress);
      const resultNew = await safeInstance.methods
        .setVaultAssets(
          assetVaultList,
          assetVaultList,
          disabledAssetsVaults,
          disabledAssetsVaults
        )
        .encodeABI();
      const transactionDataNewer = {
        contractAddress: vaultAddress,
        txType: "SET_VAULT_ASSETS",
        signer: userAddress,
        txData: resultNew,
        nonce: 0,
        parameters: {
          vaultAddress: vaultAddress,
          supportedAssets: assetList,
          supportedVaults: vaultList,
        },
        editCard: "assets",
      };

      dispatch(WrapperFunction(transactionDataNewer, "POST"));
    } catch (error) {
      console.log("error :>> ", error);
      message.error(error.message);
      props.dispatch(TransactionInProgress(false));
    }
  };

  // poll API based on if transaction hash present in state array transactionHashList
  const pollingApi = () => {
    if (transactionHash) {
      TransactionDetails.getTransactionDetails(transactionHash)
        .then((res) => {
          if (res.data.data?.txnStatus === "SUCCESS") {
            setTransactionHash("");
            dispatch(TransactionActionInstance());
            props.reloadValues().then((response) => {
              props.setVaultDetails(response.data.data);
              let base = response.data.data.assets,
                protocol = response.data.data.protocols,
                vault = response.data.data.supportedVaults;
              let obj = assetItems;
              if (base.length) {
                base.map((value) => {
                  if (
                    !obj?.base?.find((baseItem) => {
                      return (
                        baseItem?.[0]?.assetAddress === value?.assetAddress
                      );
                    })
                  )
                    obj = { ...obj, base: [...obj.base, [value]] };
                });
              }
              if (protocol.length) {
                protocol.map((value) => {
                  if (
                    !obj?.protocol?.find((protocolItem) => {
                      return (
                        protocolItem?.[0]?.assetAddress === value?.assetAddress
                      );
                    })
                  )
                    obj = { ...obj, protocol: [...obj.protocol, [value]] };
                });
              }
              if (vault !== null && vault.length) {
                vault.map((value) => {
                  if (
                    !obj?.vault?.find((vaultItem) => {
                      return (
                        vaultItem?.[0]?.assetAddress === value?.assetAddress
                      );
                    })
                  )
                    obj = { ...obj, vault: [...obj.vault, [value]] };
                });
              }
              props.setAssetItems(obj);
            });
          } else if (res.data.data === "Error") {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    }
  };

  const buttonEnable = (passedAssets) => {
    let assets = [],
      al = [],
      pl = [],
      vl = [];
    passedAssets?.base?.map((items) => {
      al.push(
        items[0]?.assetAddress ? items[0]?.assetAddress : items?.assetAddress
      );
      assets.push(
        items[0]?.assetAddress ? items[0]?.assetAddress : items?.assetAddress
      );
    });
    passedAssets?.protocol?.map((items) =>
      pl.push(
        items[0]?.protocolAddress
          ? items[0]?.protocolAddress
          : items.protocolAddress
      )
    );
    passedAssets?.vault?.map((items) => {
      vl.push(
        items[0]?.vaultAddress ? items[0]?.vaultAddress : items?.vaultAddress
      );
      assets.push(
        items[0]?.vaultAddress ? items[0]?.vaultAddress : items?.vaultAddress
      );
    });
    setAssetList([...new Set(al)]);
    setProtocolList([...new Set(pl)]);
    setVaultList([...new Set(vl)]);
    let initial = vaultDetails?.vault?.supportedAssets;
    if (![...new Set(al)].includes(formValues?.vaultAsset))
      setFormValues((prev) => ({
        ...prev,
        vaultAsset: undefined,
      }));
    setWebAssets(assets);
    if (initial?.length !== 0 && assets?.length !== 0) {
      let temp =
        initial.sort().join(",") === al.sort().join(",") ? false : true;
      let temp2 =
        vaultDetails?.vault?.supportedProtocols?.sort().join(",") ===
        pl.sort().join(",")
          ? false
          : true;
      let temp3 =
        vaultDetails?.vault?.supportedVaults?.sort().join(",") ===
        vl.sort().join(",")
          ? false
          : true;

      if (
        JSON.stringify(initialValues.assets) !== JSON.stringify(assetItems) ||
        passedAssets?.base?.length
      )
        setEnable(temp || temp2 || temp3);
      else setEnable(false);

      setExecuteWeb3(temp || temp3);
    }
  };

  const handleValidation = () => {
    let obj = { ...validated };
    Object.keys(obj).forEach((r) => {
      if (r === "vaultAsset") {
        const valid = formValues[r] ? "valid" : "invalid";
        obj = { ...obj, vaultAsset: valid };
      } else if (r === "baseCurrency") {
        const valid = formValues[r] ? "valid" : "invalid";
        obj = { ...obj, baseCurrency: valid };
      }
    });
    setValidated(obj);
    let v = Object.keys(obj).every((key) => obj[key] === "valid");
    return v;
  };

  const handleViewToaster = () => {
    setViewToaster(false);
  };

  const handleAssetSave = () => {
    if (handleValidation()) {
      let proceed = update();
      if (proceed) {
        if (
          JSON.stringify(initialValues.assets.base) !==
            JSON.stringify(assetItems.base) ||
          JSON.stringify(initialValues.assets.vault) !==
            JSON.stringify(assetItems.vault)
        )
          web3Transactions(webAssets);
      }
    }
  };

  const handleAssetDiscard = () => {
    let base = vaultDetails?.assets,
      protocol = vaultDetails?.protocols,
      vault = vaultDetails?.supportedVaults;
    let obj = { base: [], vault: [], protocol: [] };
    if (base.length) {
      base.map((value) => {
        obj = { ...obj, base: [...obj.base, [value]] };
      });
    }
    if (protocol.length) {
      protocol.map((value) => {
        obj = { ...obj, protocol: [...obj.protocol, [value]] };
      });
    }
    if (vault !== null && vault.length) {
      vault.map((value) => {
        obj = { ...obj, vault: [...obj.vault, [value]] };
      });
    }
    props.setIsValidated({
      ...props.isValidated,
      assetCard: {
        base: "valid",
      },
    });
    props.setAssetItems(obj);
    setFormValues((prev) => ({
      ...prev,
      vaultAsset: initialValues.vaultAsset,
      baseCurrency: initialValues.baseCurrency,
    }));
  };

  const handleChange = (value, name) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setValidated((prev) => ({ ...prev, [name]: value ? "valid" : "invalid" }));
    if (value !== initialValues.name) {
      setEnable(true);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [formValues]);

  useEffect(() => {
    assetItems?.base?.length + assetItems?.vault?.length >= 200 &&
      setViewToaster(true);
    buttonEnable(assetItems);
  }, [assetItems]);

  useEffect(() => {
    if (initialLoad && vaultDetails?.vault && props?.fields?.base?.length) {
      setInitialLoad(false);
      let vaultAsset = vaultDetails.vault?.vaultAsset;
      if (
        !props.fields.base.find(
          (item) => item[0].assetAddress === vaultDetails.vault?.vaultAsset
        )
      )
        vaultAsset = undefined;
      setInitialValues({
        assets: props.fields,
        vaultAsset: vaultAsset,
        baseCurrency: vaultDetails?.vault?.baseCurrency,
      });
      setFormValues({
        assets: props.fields,
        vaultAsset: vaultAsset,
        baseCurrency: vaultDetails?.vault?.baseCurrency,
      });
    }
  }, [props.fields, initialLoad, vaultDetails]);

  useEffect(() => {
    if (transaction?.assets) {
      setTransactionHash(transaction.assets);
    }
  }, [transaction]);

  useInterval(async () => {
    pollingApi();
  }, 5000);

  useEffect(() => {
    Currency.getCurrency().then((res) => {
      setCurrencyList(res.data?.data);
    });
  }, []);

  return (
    <>
      <div className="content-items" ref={drop} id="base">
        <div className="add">
          {assetItems?.base?.length ? (
            assetItems?.base?.map((field) => (
              <TagContainer
                key={field[0]?.assetAddress}
                data={displayData(field, "asset")}
                address={field[0]?.assetAddress}
                type="asset"
                removeItems={removeItem}
                tagIcon={displayImage(field, "asset")}
                tagSymbol={field?.assetSymbol?.slice(0, 3)}
              />
            ))
          ) : (
            <p className="tag-placeholder">{`Drag one or more assets here`}</p>
          )}
        </div>
        {props.isValidated.assetCard.base === "invalid" && (
          <span className="validation-required">*This field is required</span>
        )}
        <div className="text">Vault Assets</div>
      </div>
      <div className="content-items" ref={drop2} id="protocol">
        <div className="add">
          {assetItems?.protocol?.length ? (
            assetItems?.protocol?.map((field) => (
              <TagContainer
                key={field[0]?.protocolAddress}
                data={displayData(field, "protocol")}
                address={field[0]?.protocolAddress}
                type="protocol"
                removeItems={removeItem}
                tagIcon={displayImage(field, "protocol")}
                tagSymbol={field?.protocolName?.slice(0, 2)}
              />
            ))
          ) : (
            <p className="tag-placeholder">{`Drag one or more protocols here`}</p>
          )}
        </div>
        <div className="text">
          Protocols <span className="text-optional"> (Optional)</span>
        </div>
      </div>
      <div className="content-items" ref={drop1} id="vault">
        <div className="add">
          {assetItems?.vault?.length ? (
            assetItems?.vault?.map((field) => (
              <TagContainer
                key={field[0]?.vaultAddress}
                data={displayData(field, "vault")}
                address={field[0]?.vaultAddress}
                type="vault"
                removeItems={removeItem}
                tagIcon={displayImage(field, "vault")}
                tagSymbol={field?.vaultName?.slice(0, 2)}
              />
            ))
          ) : (
            <p className="tag-placeholder">{`Drag one or more vaults here`}</p>
          )}
        </div>
        <div className="text">
          Vaults <span className="text-optional"> (Optional) </span>
        </div>
      </div>
      <Toaster
        viewToaster={viewToaster}
        handleViewToaster={handleViewToaster}
        message="Sum of Base assets and vaults should not exceed 200"
      />
      <Row
        justify="space-between"
        gutter={30}
        span={24}
        className="custom-select-field content-items"
      >
        <Col span={12}>
          <Select
            size="large"
            onChange={(value) => {
              handleChange(value, "vaultAsset");
            }}
            allowClear
            value={formValues?.vaultAsset}
            style={{
              display: "flex",
            }}
          >
            {assetItems?.base.map((asset) => {
              return (
                <Option value={asset[0]?.assetAddress} key={asset[0]?.id}>
                  <Avatar
                    size="small"
                    src={displayImage(asset, "asset")}
                    icon={
                      asset[0]?.assetSymbol && (
                        <div>{asset[0]?.assetSymbol}</div>
                      )
                    }
                  />
                  &nbsp;
                  {asset[0]?.assetName}
                </Option>
              );
            })}
          </Select>
          {validated.vaultAsset === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          <div className="text">Base Asset</div>
        </Col>
        <Col span={12}>
          <Select
            size="large"
            onChange={(value) => {
              handleChange(value, "baseCurrency");
            }}
            allowClear
            value={formValues?.baseCurrency}
            style={{
              display: "flex",
            }}
          >
            {currencyList.map((currency) => {
              return (
                <Option key={currency?.id} value={currency?.currencySymbol}>
                  {currency?.currencySymbol}
                </Option>
              );
            })}
          </Select>
          {validated.baseCurrency === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          <div className="text">Display Currency</div>
        </Col>
      </Row>
      <Row justify="end" span={12}>
        <Col className="save-discard">
          <button className="discard" onClick={() => handleAssetDiscard()}>
            Discard
          </button>
          <button
            className="save"
            disabled={!enable}
            onClick={() => handleAssetSave()}
          >
            Save Changes
          </button>
        </Col>
      </Row>
    </>
  );
}

export default CardItem;
