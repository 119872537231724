export const setConnectToggle = (val) => async(dispatch) => {
  //For knowing about create screen or explore
  //If create => Create vault screen is displayed
  //If explore => Explore screen is displayed
  //If admin=> Admin page is displayed
  
    dispatch({
      type: "SET_TOGGLE",
      payload: {
        toggle: val
      }
    })
  }