import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { ReactComponent as YieldCubeLogo } from "../../../src/assets/yc-logo-wide-white.svg";
import { setCurrencyDetails } from "../../redux/actions/asset-details";
import { User } from "../../services/api";
import { handleWalletSelection } from "../Blocknative/walletSelectionConfig";
import ConnectWallet from "./ConnectToWallet/ConnectWallet";
import WalletConnected from "./WalletConnected/WalletConnected";
import "./index.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "60px",
    flexWrap: " nowrap",
    boxShadow: " 0 2px 4px 0 rgba(212, 212, 211, 0.59)",
    justifyContent: "center",
    backgroundColor: " white",
  },
  exploreButton: {
    fontSize: 14,
    whiteSpace: "nowrap",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    color: "#164F58",
    textTransform: "uppercase",
    marginRight: "12px",
    "&:hover": {
      color: "#22C55E",
      backgroundColor: "#ffffff",
      borderBottom: "2px solid #22C55E",
      borderRadius: "0px",
      transition: "borderBottom 0.3s ease,color 0.3s ease",
    },
    [theme.breakpoints.down("1640")]: {
      fontSize: 10,
      marginRight: 2,
    },
  },
  buttonText: {
    [theme.breakpoints.down("1640")]: {
      padding: "6px",
    },
  },
  navbarContent: {
    display: "grid",
    gridTemplateColumns: "auto",
  },
}));

export default function Navbar({ onClick }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [totalBalance, setTotalBalance] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [currency, setCurrency] = useState("");
  const [displayLogo, setDisplayLogo] = useState(true);

  const userAddress = useSelector((state) => state.get_user_address.address);
  const depositWithdrawRandom = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.randomNumber
  );
  const wallet = useSelector((state) => state.get_wallet_state.wallet);

  const exploreClickHandler = () => {
    history.push("");
  };

  //Handler to route to create safe
  const addSafeHandler = async () => {
    const ready = await handleWalletSelection(userAddress);
    if (!ready) return;
    else history.push("/disclaimer");
  };

  const helpCentereClickHandler = () => {
    history.push("/helpcenter");
  };

  const getOverallStats = () => {
    User.getUserStats(userAddress).then((response) => {
      if (
        response.data &&
        response.data.data &&
        response.data.data.length !== 0
      ) {
        setTotalBalance(response.data.data.totalBalance);
        setTotalReturn(response.data.data.totalGain);
        setCurrency(response.data.data.unit ? response.data.data.unit : "USD");
      } else if (
        response.data &&
        response.data.data &&
        response.data.data.length === 0
      ) {
        setTotalBalance(0);
        setTotalReturn(0);
        setCurrency("USD");
      } else if (response.data && response.data.status === 0) {
        console.log("Network Error");
      }
    });
  };

  useEffect(() => {
    if (userAddress) {
      setTimeout(getOverallStats(), 3000);
    } else {
      setTotalBalance(0);
      setTotalReturn(0);
      setCurrency("USD");
    }
  }, [userAddress, depositWithdrawRandom]);

  useEffect(() => {
    dispatch(setCurrencyDetails("usd"));
    let path = location.pathname.split("/");
    if (path.includes("vault")) {
      setDisplayLogo(true);
    } else setDisplayLogo(false);
  }, []);

  return (
    <>
      <AppBar classes={{ root: classes.root }}>
        {displayLogo ? (
          <Hidden mdDown>
            <div className="akkrueLogo">
              <YieldCubeLogo id="logo" />
            </div>
          </Hidden>
        ) : (
          <div className="akkrueLogoRest">
            <YieldCubeLogo id="logo" />
          </div>
        )}

        <Toolbar className={classes.navbarContent} disableGutters={true}>
          {displayLogo ? (
            <Hidden lgUp>
              <span
                class="material-icons"
                onClick={onClick}
                style={{
                  color: "#164F58",
                  width: "45px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#164F58",
                }}
              >
                menu
              </span>
            </Hidden>
          ) : (
            <div className="akkrueLogoRest">
              <YieldCubeLogo id="logo" />
            </div>
          )}
          <div
            className={
              displayLogo ? "titleExploreWrapVault" : "titleExploreWrap"
            }
          >
            <Button
              classes={{ text: classes.buttonText }}
              className={classes.exploreButton}
              onClick={exploreClickHandler}
            >
              Explore Vaults
            </Button>

            <Button
              classes={{ text: classes.buttonText }}
              className={classes.exploreButton}
              onClick={addSafeHandler}
            >
              Create A Vault
            </Button>
            <Button
              classes={{ text: classes.buttonText }}
              className={classes.exploreButton}
              onClick={helpCentereClickHandler}
            >
              Help Center
            </Button>
          </div>
          <div className="total-and-wallet">
            <div className="total">
              <span className="total-balance">
                Total Balance:{" "}
                <span className="total-balance-value">
                  {totalBalance && !isNaN(totalBalance)
                    ? totalBalance?.toFixed(2)
                    : "0.00"}{" "}
                </span>
                <span className="currency">
                  {currency && currency?.toUpperCase()}
                </span>{" "}
              </span>
              {/*<span className="totalBalanceTExt">
               Total Return:{" "}
              <span className="totalBalanceValue">
                {totalReturn && !isNaN(totalReturn)
                  ? totalReturn?.toFixed(2)
                  : "0.00"}{" "}
                <span className="currency"> % </span>
              </span> 
            </span>*/}
            </div>
            <div className="wallet">
              {userAddress && wallet && wallet?.name ? (
                <WalletConnected />
              ) : userAddress && wallet && wallet?.name ? (
                <ConnectButton />
              ) : (
                <ConnectWallet />
                // <ConnectButton />
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
