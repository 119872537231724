const initState = {
    tokenValue: "",
    navValue: ""
  
};

const setTokenValue = (state=initState,action) => {
    switch(action.type) {
        case "SET_TOKEN_VALUE":
            return { 
                ...state,
                tokenValue: action.payload.setTokenValue,
                navValue: action.payload.setNavValue
            }

        default: 
            return state;
    }
}

export default setTokenValue;