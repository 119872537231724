import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "12.5px",
      paddingBottom: "12.5px",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 125,
    marginTop: "0px",
    "& .MuiOutlinedInput-input": {
      paddingTop: "12.5px",
      paddingBottom: "12.5px",
    },

    "& .MuiFilledInput-input": {
      color: "#1B3E69",
      fontSize: "14px",
      fontFamily: "Open Sans",
      [theme.breakpoints.down('1641')]:{
        
        fontSize: "13px",
      },
    },

    "& .MuiInputLabel-filled": {
      marginTop: "-18px",
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  select: {
    "&:before": {
      borderColor: "#6D7681",
      marginLeft: "12px",
      // width: "70px",
    },
    "&:after": {
      borderColor: "#6D7681",
      marginLeft: "12px",
      // width: "70px",
    },
    backgroundColor: "white",
    '& .MuiSelect-outlined':{
      maxWidth:"80px"
    },
    "& .MuiOutlinedInput-input":{
      // fontWeight: "bold",
      fontSize: "14px",
      // color: "#1B3E69",
      [theme.breakpoints.down('1641')]:{
        
        fontSize: "13px",
      },
    }
  },
  headingStyle: {
    color: "#6D7681",
    fontSize: "10px",
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  downArrow: {
    marginTop: "5px",
    marginRight: "2px",
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const { id, dataValue, userAddress } = props;

  const handleChange = (event) => {
    if (id == "1") {
      props.handleGroupIDAdd(event.target.value);
    } else if (id == "2") {
      props.handleGroupIDRemove(event.target.value);
    } else if (id == "3") {
      props.handleManagerChange(event.target.value);
    }
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        {/* <InputLabel className={classes.headingStyle}>GROUP NAME</InputLabel> */}
        <Select
          onChange={handleChange}
          error={false}
          className={classes.select}
          classes={{ iconFilled: classes.downArrow }}
        >
          {dataValue.length > 0 && !(dataValue === undefined) ? (
            dataValue.map((res) => {
            
              return res.admin.map((data)=>{
                if (data.toUpperCase() == userAddress.toUpperCase()) {
                  return (
                    <MenuItem
                      className={classes.select}
                      classes={{ iconFilled: classes.downArrow }}
                      value={res.whiteListId}
                    >
                      {res.groupName}
                    </MenuItem>
                  );
                }
              })
            
            })
          ) : (
            <></>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
