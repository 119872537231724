import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class Deposit {
  static getFee() {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `FeeStrategy/v2.0/yieldster/getFee`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }
}
