import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Web3 from "web3";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox } from "@mui/material";
import { Col, Divider, message, Row, Tooltip } from "antd";
import { getGnosisSafeInstanceAt } from "../../components/Asset-Details/TableFunctions";
import { Support } from "../../components/Card";
import WrapperFunction from "../../components/Global-Functions/WrapperFunction";
import useInterval from "../../components/Polling/useInterval";
import { TransactionActionInstance } from "../../redux/actions/DepositWithdrawAction";
import { setVaultDetails } from "../../redux/actions/vaultDetails";
import { FeeStrategy, TransactionDetails } from "../../services/api";

const SupportCard = ({
  removeItemsFromCart,
  supportItems,
  depositStrategy,
  withdrawalStrategy,
  managementFeeDetails,
  draggableItems,
  reloadValues,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const web3 = useSelector((state) => state.get_web3.web3);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const transactionHash = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.transactionHash
  );

  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;

  const [initialValues, setInitialValues] = useState({
    deposit: [],
    withdraw: [],
    managementFee: null,
    managementBeneficiary: null,
    performanceFee: null,
    performanceBeneficiary: null,
    noOfDays: undefined,
    timeLock: false,
  });
  const [isValidated, setIsValidated] = useState({
    supportCard: {
      deposit: "empty",
      withdraw: "empty",
      managementFee: "empty",
      managementBeneficiary: "empty",
      performanceFee: "empty",
      performanceBeneficiary: "empty",
      noOfDays: "valid",
    },
  });
  const [formValues, setFormValues] = useState({});
  const [feeDetails, setFeeDetails] = useState({});
  const [enableSave, setEnableSave] = useState(false);
  const [transactionHashList, setTransactionHashList] = useState([]);
  const [timeLocked, setTimeLocked] = useState(false);

  const handleInputChange = (data) => {
    const result = validate(data);
    setIsValidated({
      supportCard: {
        ...isValidated.supportCard,
        [data.id]: result,
      },
    });
    setFormValues({ ...formValues, [data.id]: data.value });
  };

  const validate = (data) => {
    let validation;
    switch (data.id) {
      case "deposit":
        return formValues.deposit.length ? "valid" : "invalid";
      case "withdraw":
        return formValues.withdraw.length ? "valid" : "invalid";
      case "managementFee":
        return data.value || !isNaN(formValues.managementFee)
          ? "valid"
          : "invalid";
      case "managementBeneficiary":
        if (data.value) {
          validation = "valid";
          try {
            web3.utils.toChecksumAddress(data.value);
          } catch {
            validation = "invalid-address";
          }
        } else {
          validation = "invalid";
        }
        return validation;
      case "performanceFee":
        return data.value || !isNaN(formValues.performanceFee)
          ? "valid"
          : "invalid";
      case "performanceBeneficiary":
        if (data.value) {
          validation = "valid";
          try {
            web3.utils.toChecksumAddress(data.value);
          } catch {
            validation = "invalid-address";
          }
        } else {
          validation = "invalid";
        }
        return validation;
      case "noOfDays":
        data.value || !formValues.timeLock
          ? (validation = "valid")
          : (validation = "invalid");
        return validation;
      case "timeLock":
        return "valid";
      default:
        break;
    }
  };

  const handleValidation = () => {
    let obj = { ...isValidated };
    Object.keys(formValues).map((r) => {
      const result = validate({ id: r, value: formValues[r] });
      obj.supportCard = {
        ...obj.supportCard,
        [r]: result,
      };
    });
    setIsValidated(obj);
    let v = Object.keys(initialValues).every(
      (key) => obj.supportCard[key] === "valid"
    );
    return v;
  };

  const handleSubmit = async () => {
    if (handleValidation(formValues) && !enableSave) {
      setEnableSave(true);
      const managementFeeAddress = feeDetails.find(
        (item) => item.name === "Performance Management Fee"
      )?.strategyAddress;
      const performanceFeeAddress = feeDetails.find(
        (item) => item.name === "Management Fee"
      )?.strategyAddress;
      const deposit = formValues.depositStrategy
        ? formValues.depositStrategy
        : formValues.deposit[0][0].strategyAddress;
      const withdraw = formValues.withdrawStrategy
        ? formValues.withdrawStrategy
        : formValues.withdraw[0][0].strategyAddress;
      setEnableSave(true);
      const safeInstance = await getGnosisSafeInstanceAt(vaultAddress, web3);
      Object.keys(formValues).forEach((item) => {
        let result;
        if (item === "deposit" && depositStrategy !== deposit) {
          result = safeInstance.methods
            .setVaultSmartStrategy(deposit, 1)
            .encodeABI();
          try {
            const transactionData = {
              contractAddress: vaultAddress,
              txType: "CHANGE_DEPOSIT_STRATEGY",
              signer: userAddress,
              txData: result,
              parameters: {
                vaultAddress: vaultAddress,
                depositStrategy: deposit,
              },
              editCard: "support",
            };
            dispatch(WrapperFunction(transactionData, "POST"));
          } catch (err) {
            console.log(err);
            message.error(err.message);
          }
        } else if (item === "withdraw" && withdrawalStrategy !== withdraw) {
          result = safeInstance.methods
            .setVaultSmartStrategy(withdraw, 2)
            .encodeABI();
          try {
            const transactionData = {
              contractAddress: vaultAddress,
              txType: "CHANGE_WITHDRAW_STRATEGY",
              signer: userAddress,
              txData: result,
              parameters: {
                vaultAddress: vaultAddress,
                withdrawalStrategy: withdraw,
              },
              editCard: "support",
            };
            dispatch(WrapperFunction(transactionData, "POST"));
          } catch (err) {
            console.log(err);
            message.error(err.message);
          }
        } else if (
          item === "performanceFee" &&
          (formValues.performanceFee !== initialValues.performanceFee ||
            formValues.performanceBeneficiary !==
              initialValues.performanceBeneficiary)
        ) {
          const weiValue = Web3.utils.toWei(
            `${formValues.performanceFee}`,
            "ether"
          );
          result = safeInstance.methods
            .setBeneficiaryAndPercentage(
              formValues.performanceBeneficiary,
              weiValue
            )
            .encodeABI();
          try {
            const transactionData = {
              contractAddress: vaultAddress,
              txType: "SET_BENEFICIARY_AND_PERCENTAGE",
              signer: userAddress,
              txData: result,
              parameters: {
                vaultAddress: vaultAddress,
                feeAddress: performanceFeeAddress,
                beneficiaryAddress: formValues.performanceBeneficiary,
                percentage: parseFloat(formValues.performanceFee),
              },
              editCard: "support",
            };
            dispatch(WrapperFunction(transactionData, "POST"));
          } catch (err) {
            console.log(err);
            message.error(err.message);
          }
        } else if (
          item === "managementFee" &&
          (formValues.managementFee !== initialValues.managementFee ||
            formValues.managementBeneficiary !==
              initialValues.managementBeneficiary)
        ) {
          const weiValue = Web3.utils.toWei(
            `${formValues.managementFee}`,
            "ether"
          );
          result = safeInstance.methods
            .setManagementFeeBeneficiaryAndPercentage(
              formValues.managementBeneficiary,
              weiValue
            )
            .encodeABI();
          try {
            const transactionData = {
              contractAddress: vaultAddress,
              txType: "SET_MANAGEMENT_FEE_BENEFICIARY_AND_PERCENTAGE",
              signer: userAddress,
              txData: result,
              parameters: {
                vaultAddress: vaultAddress,
                feeAddress: managementFeeAddress,
                beneficiaryAddress: formValues.managementBeneficiary,
                percentage: parseFloat(formValues.managementFee),
              },
              editCard: "support",
            };
            dispatch(WrapperFunction(transactionData, "POST"));
          } catch (err) {
            console.log(err);
            message.error(err.message);
          }
        } else if (item === "noOfDays") {
          if (
            formValues.noOfDays !== initialValues.noOfDays ||
            formValues.timeLock !== initialValues.timeLock
          ) {
            let days = parseInt(formValues.noOfDays)
              ? parseInt(formValues.noOfDays)
              : 0;
            let block = 6500 * days;
            // console.log({ formValues }, { days }, { block });
            let timeLockEncoded = safeInstance.methods
              .setWithdrawLockPeriod(block, formValues.timeLock)
              .encodeABI();
            try {
              const transactionData = {
                contractAddress: vaultAddress,
                txType: "SET_TIME_LOCKED_WITHDRAW",
                signer: userAddress,
                txData: timeLockEncoded,
                parameters: {
                  vaultAddress: vaultAddress,
                  isTimeLocked: formValues.timeLock,
                  noOfDays: days,
                },
                editCard: "support",
              };
              dispatch(WrapperFunction(transactionData, "POST"));
            } catch (err) {
              console.log(err);
              message.error(err.message);
            }
          }
        }
      });
    }
  };

  const handleReset = () => {
    setFormValues({ ...initialValues });
    setTimeLocked(initialValues?.timeLock);
  };

  // poll API based on if transaction hash present in state array transactionHashList
  const pollingApi = () => {
    const pollArray = transactionHashList.slice();
    if (!pollArray.length) {
      // setEnableSave(true);
    } else {
      Object.values(pollArray).forEach((hash) => {
        TransactionDetails.getTransactionDetails(hash)
          .then((res) => {
            if (res.data.data?.txnStatus === "SUCCESS") {
              const index = transactionHashList.indexOf(hash);
              if (index > -1) {
                if (pollArray.length === 1) {
                  setTransactionHashList([]);
                  reloadValues().then((res) => {
                    dispatch(setVaultDetails(res.data.data));
                    dispatch(TransactionActionInstance());
                  });
                } else {
                  setTransactionHashList((prev) => {
                    const array = prev.filter((item) => item !== hash);
                    return array;
                  });
                }
              }
            } else if (res.data.data === "Error") {
              message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
          });
      });
    }
  };

  useEffect(() => {
    // to update deposit field
    const depositStrategyAddress =
      depositStrategy &&
      draggableItems.deposit.length &&
      draggableItems.deposit.find(
        (item) => item.strategyAddress === depositStrategy
      );
    const deposit = depositStrategyAddress
      ? [[{ ...depositStrategyAddress, type: "deposit" }]]
      : [];
    // to update withdraw field
    const withdrawStrategyAddress =
      withdrawalStrategy &&
      draggableItems.withdraw.length &&
      draggableItems.withdraw.find(
        (item) => item.strategyAddress === withdrawalStrategy
      );
    const withdraw = withdrawStrategyAddress
      ? [[{ ...withdrawStrategyAddress, type: "withdraw" }]]
      : [];

    // to update fees
    let obj = {};
    FeeStrategy.getFee().then((res) => {
      setFeeDetails(res.data.data);
      if (managementFeeDetails) {
        res.data.data.forEach((item) => {
          Object.keys(managementFeeDetails).forEach((v) => {
            if (item.strategyAddress === v) {
              if (item.name === "Performance Management Fee") {
                obj = {
                  ...obj,
                  managementFee: managementFeeDetails[v].percentage,
                  managementBeneficiary: managementFeeDetails[v].beneficiary,
                };
              } else if (item.name === "Management Fee") {
                obj = {
                  ...obj,
                  performanceFee: managementFeeDetails[v].percentage,
                  performanceBeneficiary: managementFeeDetails[v].beneficiary,
                };
              }
            }
          });
        });
      }
      setInitialValues((initial) => ({
        ...initial,
        deposit,
        withdraw,
        ...obj,
        noOfDays: supportItems?.noOfDays,
        timeLock: supportItems?.timeLock,
      }));
    });
  }, [
    depositStrategy,
    withdrawalStrategy,
    managementFeeDetails,
    draggableItems.withdraw,
    draggableItems.deposit,
  ]);

  useEffect(() => {
    setFormValues({ ...initialValues });
  }, [initialValues]);

  useEffect(() => {
    // to update form values when deposit or withdraw strategy changed
    setTimeLocked(supportItems?.timeLock ? true : false);
    setFormValues((fValues) => {
      return {
        ...fValues,
        ...supportItems,
      };
    });
  }, [supportItems]);

  useEffect(() => {
    if (
      transactionHash?.support &&
      !transactionHashList?.includes(transactionHash.support)
    ) {
      setTransactionHashList((prev) =>
        prev?.length
          ? [...prev, transactionHash.support]
          : [transactionHash.support]
      );
    }
  }, [transactionHash, transactionHashList]);

  useInterval(async () => {
    pollingApi();
  }, 5000);
  const editHandler = () => {
    if (
      formValues?.timeLock == initialValues?.timeLock &&
      formValues?.noOfDays == initialValues?.noOfDays &&
      formValues?.managementFee == initialValues?.managementFee &&
      formValues?.managementBeneficiary?.toLowerCase() ===
        initialValues?.managementBeneficiary?.toLowerCase() &&
      formValues?.performanceFee == initialValues?.performanceFee &&
      formValues?.performanceBeneficiary?.toLowerCase() ===
        initialValues?.performanceBeneficiary?.toLowerCase()
    )
      setEnableSave(true);
    else setEnableSave(false);
  };
  useEffect(() => {
    editHandler();
  }, [formValues]);

  return (
    <>
      <Row gutter={10} span={24}>
        <Col className="gutter-row" span={12}>
          <Support
            id="deposit"
            fields={formValues?.deposit}
            accept="deposit"
            text="Deposit"
            removeItemsFromCart={removeItemsFromCart}
            isValidated={isValidated}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <Support
            id="withdraw"
            fields={formValues?.withdraw}
            accept="withdraw"
            text="Withdraw"
            removeItemsFromCart={removeItemsFromCart}
            isValidated={isValidated}
          />
        </Col>
      </Row>
      <Row gutter={10} span={24}>
        <Col className="time-locked" span={12}>
          <Checkbox
            checked={timeLocked}
            // defaultChecked={timeLocked ? true : false}
            onChange={(changedData) => {
              setTimeLocked(changedData?.target?.checked);
              handleInputChange({
                id: "timeLock",
                value: changedData?.target?.checked,
              });
              setFormValues({
                ...formValues,
                timeLock: changedData?.target?.checked ? true : false,
                noOfDays: changedData?.target?.checked
                  ? formValues.noOfDays
                  : 0,
              });
            }}
            sx={{
              color: "rgba(180, 124, 57, 0.58)",
              "&.Mui-checked": {
                color: "rgba(180, 124, 57, 0.58)",
              },
            }}
          />
          Time Locked
        </Col>
        <Col className="" span={12}>
          <Support
            id="noOfDays"
            fields={formValues?.noOfDays}
            setField={(item) => handleInputChange(item)}
            type="number"
            disabled={!formValues.timeLock}
            accept="text"
            text="No. of Days"
            isValidated={isValidated}
            placeholder="No .of Days"
            tooltipText={"Number of Days"}
          />
        </Col>
      </Row>
      <div className="support-divider">
        <Divider style={{ margin: "15px 0" }} />
      </div>
      <p className="sub-title">Fees</p>
      <Row gutter={[10, 20]} span={24}>
        <Col className="text" span={24}>
          Platform Fee
          <Tooltip
            title="The platform usage fees are set by Yieldcube DAO and currently are fixed at 0.5% annually
calculated on the assets under management in the vault. Fees are calculated every time a deposit or
withdrawal transaction is processed and on a regular basis."
          >
            <QuestionCircleOutlined
              style={{
                marginLeft: "5px",
              }}
            />
          </Tooltip>
          <span className="txt-brown">0.5%</span>
        </Col>
        <Col className="gutter-row" span={12}>
          <Support
            id="managementFee"
            fields={formValues?.managementFee}
            setField={(item) => handleInputChange(item)}
            type="number"
            accept="text"
            text="Management Fee (%)"
            isValidated={isValidated}
            placeholder="Management Fee"
            tooltipText="The vault admin can set a management fee percentage between 0% and 10%
                                annually. Management Fees are calculated on the assets under management in the vault. Fees are
                                calculated every time a deposit or withdrawal transaction is processed and on a regular basis. Fees will
                                be transferred to the beneficiary’s address."
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <Support
            id="managementBeneficiary"
            fields={formValues?.managementBeneficiary || ""}
            setField={(item) => handleInputChange(item)}
            accept="text"
            type="text"
            text="Beneficiary"
            isValidated={isValidated}
            placeholder="Beneficiary"
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <Support
            id="performanceFee"
            fields={formValues?.performanceFee}
            setField={(item) => handleInputChange(item)}
            accept="text"
            type="number"
            text="Performance Fee (%)"
            isValidated={isValidated}
            placeholder="Performance Fee"
            tooltipText="The vault admin can set a performance fee percentage between 0% and 50%. Fees are
                                calculated as a percentage of the increase in NAV (value of assets under management). Fees are
                                calculated every time a deposit or withdrawal transaction is processed and on a regular basis. Fees will
                                be transferred to the beneficiary’s address. Performance fees do not take high watermarks into account."
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <Support
            id="performanceBeneficiary"
            fields={formValues?.performanceBeneficiary || ""}
            setField={(item) => handleInputChange(item)}
            accept="text"
            type="text"
            text="Beneficiary"
            isValidated={isValidated}
            placeholder="Beneficiary"
          />
        </Col>
      </Row>
      <Row justify="end" span={12}>
        <Col className="save-discard">
          <button
            className="discard"
            onClick={handleReset}
            disabled={enableSave}
          >
            Discard
          </button>
          <button className="save" onClick={handleSubmit} disabled={enableSave}>
            Save Changes
          </button>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(SupportCard);
