const initState = {
  tokenSymbol: "",
};

const tokenSymbol = (state = initState, action) => {
  switch (action.type) {
    case "SET_TOKEN_SYMBOL":
      return {
        ...state,
        tokenSymbol: action.payload.tokenSymbol,
      };

    default:
      return state;
  }
};

export default tokenSymbol;
