const initialState = {
  vault: {},
};

const vaultDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_VAULT_DETAILS":
      return { ...state, vault: action.payload };
    default:
      return state;
  }
};

export default vaultDetailsReducer;
