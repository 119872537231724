import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import "@rainbow-me/rainbowkit/styles.css";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "rc-notification/assets/index.css";

import allReducers from "./redux/reducers";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import { WagmiConfig } from "wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chains, wagmiConfig } from "./utils/web3/config";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "7b2871bc1b7db4fdb9e1409dc658d22e";

const composeEnchancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  allReducers,
  composeEnchancer(applyMiddleware(thunk))
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
    fourth: {
      main: "#FFFFFF",
    },
    typography: {
      fontFamily: ["Poppins", "Roboto"].join(","),
      fontWeight: 500,
      fontStyle: "normal",
    },
  },
  overrides: {
    MuiChip: {
      deleteIcon: {
        color: "white",
      },
    },
  },
});

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} appInfo={{ appName: "Yieldcube" }}>
        <Provider store={store}>
          <BrowserRouter>
            <MuiThemeProvider theme={theme}>
              <App />
            </MuiThemeProvider>
          </BrowserRouter>
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
