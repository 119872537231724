export const networkID = 1;
export const FORTMATIC_KEY = "pk_test_83C77166A02748E8"; //Rinkeby fortmatic key
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
export const PORTIS_KEY = "ab6225c0-5f1b-40fa-a02a-2aba9a6f2778";
export const PROJECT_ID = "7b2871bc1b7db4fdb9e1409dc658d22e";
export const RPC_URL = `https://mainnet.infura.io/v3/${INFURA_KEY}`; //Rinkeby RPC_URL

export const APP_URL = "https://vaults.akkrue.com/";
export const CONTACT_EMAIL = "abhishek@yieldster.io";
// export const dappId = "12153f55-f29e-4f11-aa07-90f10da5d778";

export const assetReservations = {
  SUBMITTED_FOR_EXECUTION: "Conversion Pending",
  READY_FOR_WITHDRAWAL: "Ready For Withdrawal",
  EXECUTION_IN_PROGRESS: "Withdrawl In Progress",
  EXECUTED: "Withdrawn",
  FAILED: "Conversion Failed",
};

export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
