const initState = {
    totalSupply:0
  
};

const totalSupply = (state=initState,action) => {
    switch(action.type) {
        case "SET_TOTAL_SUPPLY":
            return { 
                ...state,
                totalSupply: action.payload.totalSupply,
            }

        default: 
            return state;
    }
}

export default totalSupply;