import metamaskIcon from "./icon-metamask.png";
import { ReactComponent as WalletConnectIcon } from "../Icons/icon-wallet-connect.svg";
import { ReactComponent as TrezorIcon } from "./icon-trezor.svg";
import { ReactComponent as LedgerIcon } from "./icon-ledger.svg";
import { ReactComponent as TrustIcon } from "./icon-trust.svg";
import { ReactComponent as FortmaticIcon } from "./icon-fortmatic.svg";
import { ReactComponent as PortisIcon } from "./icon-portis.svg";
import authereumIcon from "./icon-authereum.png";
import { ReactComponent as CoinbaseIcon } from "./icon-coinbase.svg";

const style = {
  height: "25px",
  width: "25px",
};
export const icons = [
  {
    name: "metamask",
    icon: <img alt="Metamask_icons" src={metamaskIcon} style={style} />,
  },
  { name: "walletConnect", icon: <WalletConnectIcon style={style} /> },
  { name: "trezor", icon: <TrezorIcon style={style} /> },
  {
    name: "ledger",
    icon: <LedgerIcon style={style} />,
  },
  {
    name: "trust",
    icon: <TrustIcon style={style} />,
  },
  {
    name: "fortmatic",
    icon: <FortmaticIcon style={style} />,
  },
  {
    name: "portis",
    icon: <PortisIcon style={style} />,
  },
  {
    name: "authereum",
    icon: <img alt="authereum_icon" src={authereumIcon} style={style} />,
  },
  {
    name: "coinbase",
    icon: <CoinbaseIcon style={style} />,
  },
];
