import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import StyledSidebar from "../Styled_Sidebar/styledSidebar";
import APS from "./APS";
import God from "./God";
import Safe from "./SafeDetails";
import SidebarDashboard from "../Sidebar";
import Advisor from "./Advisor";
import Edit from "../../pages/Edit";
import Admin from "./Admin";
import "./Style/style.css";

export default function Sidebar() {
  const [god, setGod] = useState(false);
  const [emergencyBreak, setEmergencyBreak] = useState(false);
  const [breakChange, setBreakChange] = useState(true);
  const [manageVaultType, setManageVaultType] = useState();

  const { url } = useRouteMatch();
  const defaultValue = useSelector((state) => state.get_vault_address);
  const userAddress = useSelector((state) => state.get_user_address);
  const vaultData = useSelector((state) => state.get_vault_details.vault);
  const [adminData, setAdminData] = useState(vaultData?.vault);

  const last = useHistory().location.pathname.split("/").pop();
  const vaultAddress = defaultValue.vaultAddress
    ? defaultValue.vaultAddress
    : localStorage.getItem("newSafeAddress");
  const routes2 = [
    { name: "Support", path: "/support" },
    { name: "Asset", path: "/asset" },
    { name: "Core", path: "/core" },
    { name: "Admin", path: "/admin" },
    { name: "God", path: "/god" },
  ];

  const routes3 = [
    { name: "Support", path: "/support" },
    { name: "Asset", path: "/asset" },
    { name: "Core", path: "/core" },
    { name: "Admin", path: "/admin" },
  ];

  return userAddress.address &&
    adminData?.vaultAdmin?.toLowerCase() ===
      userAddress.address?.toLowerCase() ? (
    <div className="transaction-component-aligner">
      {last == "edit" ? (
        <div className="edit-page-render">
          <Route path={`${url}/edit`}>
            <Edit manageVaultType={manageVaultType} />
          </Route>
        </div>
      ) : (
        <>
          <p className="manage-vault-heading">Manage Vault</p>
          <div className="manage-list-main-div">
            {god == true ? (
              <StyledSidebar url={url} routes={routes2} />
            ) : (
              <StyledSidebar url={url} routes={routes3} />
            )}
            <div className="manage-list-content-renderer">
              <Switch>
                <Route path={`${url}/Support`}>
                  <Safe break={emergencyBreak} />
                </Route>
                <Route exact path={`${url}/asset`}>
                  <APS break={emergencyBreak} />
                </Route>
                <Route path={`${url}/core`}>
                  <Advisor setManageVaultType={setManageVaultType} />
                </Route>
                <Route path={`${url}/admin`}>
                  <Admin />
                </Route>
                <Route
                  path={`${url}/god`}
                  component={() => (
                    <God
                      breakChange={breakChange}
                      setBreakChange={setBreakChange}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <Redirect to={`/vault/${vaultAddress}/dashboard`}>
      <SidebarDashboard />
    </Redirect>
  );
}
