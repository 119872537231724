import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";
export default class Advisor {
    static getAllAdvisor() {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `/sdk/v2.0/yieldster/get`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                'Authorization': { Bearer_Token }
            },
        });
    }

    static getAllKeys() {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `Advisor/sdk/v2.0/yieldster/getAllKeys`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                'Authorization': { Bearer_Token }
            },
        });
    }

    static getCurrentAdvisor(vaultId) {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `Advisor/sdk/v2.0/yieldster/currentAdvisor`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                'Authorization': { Bearer_Token }
            },
            params: { vaultId }
        });
    }

    static getCurrentAdvisorWithName(vaultAddress) {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `Advisor/sdk/v2.0/yieldster/currentAdvisorWithName`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                'Authorization': { Bearer_Token }
            },
            params: { vaultAddress }
        });
    }
}