import axios from "axios";
import {
  DepositWithdrawPendingTransaction,
  vaultTransactionComplete,
  SaveTransactionHash,
  UpdateHashandNonce,
  SetTxStatusCorrupted,
  getTransactionfromSafeHash,
} from "../api";
import GnosisSafeSol from "yieldster-abi/contracts/YieldsterVault.json";
import { TransactionInProgress } from "../../redux/actions/reload-state";
import { AddtoQueue } from "../../redux/actions/AddtoQueue";
import {
  TransactionActionInstance,
  DepositWithdrawInstance,
} from "../../redux/actions/DepositWithdrawAction";
import { TransactionDetails } from "../../services/api";
import { TransactionStatus } from "../../constants/transactionStatus";
import {
  sendTransaction,
  prepareSendTransaction,
  getContract,
  getWalletClient,
  prepareWriteContract,
  readContract,
  writeContract,
  erc20ABI,
} from "@wagmi/core";

const Web3SendVaultTransaction =
  (value, OperationType, collectionId, editCard) =>
  async (dispatch, getState) => {
    const {
      contractAddress,
      txType,
      nonce,
      signer,
      txData,
      parameters,
      vaultAddress,
    } = value;
    let transactionHash = "";
    let txnChain = "ETHEREUM";
    const reduxstate = getState();
    let tag = "NORMAL";
    let walletType;
    const wallet = reduxstate.get_wallet_state?.wallet?.name;
    if (wallet === "MetaMask") {
      walletType = "METAMASK";
    } else {
      walletType = "GNOSIS";
      tag = "GNOSIS";
    }
    const web3 = reduxstate.get_web3.web3;
    const currentRoute = reduxstate.get_current_route.currentRoute;
    const deposit_withdraw_parameters =
      reduxstate.get_deposit_withdraw_parameters;
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    const userAddress =
      reduxstate.get_user_address.address ||
      localStorage.getItem("accountAddress");

    try {
      if (OperationType == "POST") {
        if (txType == "UPGRADE_MASTERCOPY") {
          axios
            .post(
              DepositWithdrawPendingTransaction(),
              {
                contractAddress,
                txType,
                parameters,
                nonce,
                signer,
                txData,
                walletType,
                vaultAddress,
              },
              config
            )
            .then((res) => {
              if (currentRoute) {
                if (
                  !(
                    currentRoute[currentRoute.length - 1] == "pending" &&
                    currentRoute[currentRoute.length - 2] == "transactions"
                  )
                ) {
                  setTimeout(() => {
                    dispatch(AddtoQueue(true));
                  }, 5000);
                }
              }
              dispatch(TransactionInProgress(false));
              dispatch(TransactionActionInstance());
              const { txData, signer, contractAddress, _id, txType } =
                res.data.data;
              if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                web3.eth
                  .sendTransaction({
                    from: signer,
                    to: contractAddress,
                    data: txData,
                    nonce: nonce,
                    gas: deposit_withdraw_parameters.gasRequired,
                    gasPrice: deposit_withdraw_parameters.gasPriceRequired,
                  })
                  .on("transactionHash", function (hash) {
                    transactionHash = hash;
                    axios
                      .patch(SaveTransactionHash(_id), {
                        txHash: hash,
                      })
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      });
                  })
                  .on("receipt", () => {
                    if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                      dispatch(DepositWithdrawInstance());
                    }
                    axios
                      .post(vaultTransactionComplete(transactionHash))
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      })
                      .catch((err) => {
                        console.log("post error", err);
                      });
                  })
                  .on("error", (err) => {
                    axios
                      .patch(SetTxStatusCorrupted(_id), {
                        status: "REVERT",
                      })
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      })
                      .catch(() => {});
                  });
              } else {
                web3.eth
                  .sendTransaction({
                    from: signer,
                    to: contractAddress,
                    data: txData,
                  })
                  .on("transactionHash", function (hash) {
                    transactionHash = hash;
                    axios
                      .patch(SaveTransactionHash(_id), {
                        txHash: hash,
                      })
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      });
                  })
                  .on("receipt", () => {
                    if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                      dispatch(DepositWithdrawInstance());
                    }
                    axios
                      .post(vaultTransactionComplete(transactionHash))
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      })
                      .catch((err) => {
                        console.log("post error", err);
                      });
                  })
                  .on("error", (err) => {
                    console.log("Error in else part:", err.message);
                    axios
                      .patch(SetTxStatusCorrupted(_id), {
                        status: "REVERT",
                      })
                      .then(() => {
                        dispatch(TransactionActionInstance());
                      })
                      .catch(() => {});
                  });
              }
            })
            .catch((err) => {
              console.log("Error occured in POST", err);
            });
        } else {
          if (currentRoute) {
            if (
              !(
                currentRoute[currentRoute.length - 1] == "pending" &&
                currentRoute[currentRoute.length - 2] == "transactions"
              )
            ) {
              setTimeout(() => {
                dispatch(AddtoQueue(true));
              }, 5000);
            }
          }
          dispatch(TransactionInProgress(false));
          dispatch(TransactionActionInstance());
          // const { txData, signer, contractAddress, _id, txType } =
          //   res.data.data;
          if (txType == "DEPOSIT" || txType == "WITHDRAW") {
            if (walletType == "METAMASK") {
              web3.eth
                .sendTransaction({
                  from: signer,
                  to: contractAddress,
                  data: txData,
                  nonce: nonce,
                  // gas: deposit_withdraw_parameters.gasRequired,
                  // gasPrice: deposit_withdraw_parameters.gasPriceRequired,
                })
                .on("transactionHash", function (hash) {
                  transactionHash = hash;
                  web3.eth.getBlockNumber().then((res) => {
                    let blockNumber = res;
                    dispatch(DepositWithdrawInstance());
                    TransactionDetails.addTransaction(
                      blockNumber,
                      transactionHash,
                      txType,
                      txnChain,
                      txData,
                      walletType,
                      signer,
                      contractAddress,
                      signer,
                      tag,
                      userAddress,
                      parameters
                    )
                      .then((res) => {
                        dispatch(TransactionActionInstance());
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                })
                .on("receipt", (receipt) => {
                  if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                    dispatch(DepositWithdrawInstance());
                  }
                })
                .on("error", (err) => {
                  console.log({ err });
                });
            } else {
              // walletType is GNOSIS
              try {
                let prepareWriteContractConfig;
                if (txType == "DEPOSIT") {
                  prepareWriteContractConfig = await prepareWriteContract({
                    address: contractAddress,
                    abi: GnosisSafeSol.abi,
                    functionName: "deposit",
                    args: [parameters._tokenAddress, parameters._amount],
                    account: signer,
                  });
                } else {
                  prepareWriteContractConfig = await prepareWriteContract({
                    address: contractAddress,
                    abi: GnosisSafeSol.abi,
                    functionName: "withdraw",
                    args: [parameters._tokenAddress, parameters._shares],
                    account: signer,
                  });
                }
                writeContract(prepareWriteContractConfig)
                  .then(async (safeHash) => {
                    console.log({ safeHash });
                    web3.eth.getBlockNumber().then((res) => {
                      let blockNumber = res;
                      dispatch(DepositWithdrawInstance());
                      TransactionDetails.addTransaction(
                        blockNumber,
                        safeHash.hash,
                        txType,
                        txnChain,
                        txData,
                        walletType,
                        signer,
                        contractAddress,
                        signer,
                        tag,
                        userAddress,
                        parameters
                      )
                        .then((res) => {
                          console.log({ res });
                          dispatch(TransactionActionInstance());
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  })
                  .then((receipt) => {
                    if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                      dispatch(DepositWithdrawInstance());
                    }
                  })
                  .catch((err) => {
                    console.log({ err });
                  });
              } catch (err) {
                console.log({ err });
              }
            }
          } else {
            if (walletType == "METAMASK") {
              web3.eth
                .sendTransaction({
                  from: signer,
                  to: contractAddress,
                  data: txData,
                })
                .on("transactionHash", function (hash) {
                  transactionHash = hash;
                  web3.eth.getBlockNumber().then((res) => {
                    let blockNumber = res;
                    TransactionDetails.addTransaction(
                      blockNumber,
                      transactionHash,
                      txType,
                      txnChain,
                      txData,
                      walletType,
                      signer,
                      contractAddress,
                      signer,
                      tag,
                      userAddress,
                      parameters,
                      TransactionStatus.PENDING
                    )
                      .then((res) => {
                        dispatch(
                          TransactionActionInstance({
                            [editCard]: transactionHash,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                })
                .on("receipt", (receipt) => {
                  console.log({ receipt });
                })
                .on("error", (err) => {
                  console.log({ err });
                });
            } else {
              prepareSendTransaction({
                account: signer,
                to: contractAddress,
                data: txData,
              })
                .then((config) => {
                  sendTransaction(config)
                    .then((res) => {
                      let transactionHash = res.hash;
                      console.log({ transactionHash });
                      web3.eth.getBlockNumber().then((res) => {
                        let blockNumber = res;
                        TransactionDetails.addTransaction(
                          blockNumber,
                          transactionHash,
                          txType,
                          txnChain,
                          txData,
                          walletType,
                          signer,
                          contractAddress,
                          signer,
                          tag,
                          userAddress,
                          parameters,
                          TransactionStatus.PENDING
                        )
                          .then((res) => {
                            dispatch(
                              TransactionActionInstance({
                                [editCard]: transactionHash,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    })
                    .catch((err) => {
                      console.log({ err });
                    });
                })
                .catch((err) => {
                  console.log({ err });
                });
            }
          }
        }
      } else if (OperationType == "PATCH") {
        axios
          .patch(UpdateHashandNonce(), {
            id: collectionId,
            // txHash:value.txHash,
            nonce: value.nonce,
          })
          .then((res) => {
            dispatch(TransactionActionInstance());
            const { signer, contractAddress, txData, _id } = res.data.data;
            web3.eth
              .sendTransaction({
                from: signer,
                to: contractAddress,
                data: txData,
              })
              .on("transactionHash", function (hash) {
                transactionHash = hash;
                axios
                  .patch(SaveTransactionHash(_id), {
                    txHash: hash,
                  })
                  .then((res) => {
                    dispatch(TransactionActionInstance());
                  });
              })
              .on("receipt", () => {
                if (txType == "DEPOSIT" || txType == "WITHDRAW") {
                  setTimeout(() => {
                    dispatch(AddtoQueue(true));
                  }, 5000);
                }
                axios
                  .post(vaultTransactionComplete(transactionHash))
                  .then((res) => {
                    dispatch(TransactionActionInstance());
                  })
                  .catch((err) => {});
              })
              .on("error", (err) => {
                axios
                  .patch(SetTxStatusCorrupted(_id), {
                    status: "REVERT",
                  })
                  .then(() => {
                    dispatch(TransactionActionInstance());
                  })
                  .catch(() => {});
              });
          });
      }
    } catch (err) {
      console.log("Error encountered", err);
    }
  };

export default Web3SendVaultTransaction;
