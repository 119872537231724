import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FilterValue } from "../../../redux/actions/graphfilter";
import { Vaults } from "../../../services/api";
import CustomLoader from "../../Custom_Loader/customLoader";
import NewPriceCard from "../../Dashboard-Version2/Price_Card/NewPriceCard";
import "../DashBoard_Styling/chartStyle.css";
import DropDown from "./DropDown/dropDown";
import CustomTooltip from "./ToolTip/customToolTip";
import { Description } from "../Description";

export default function Dashboard({ isChartLoading }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const userAddress = localStorage.getItem("accountAddress");
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;

  let [data, setData] = useStateWithCallback([]);
  const [loader, setLoader] = useState(true);
  const [userNavDetails, setUserNavDetails] = useState();
  const [userNavDetailsLoading, setUserNavDetailsLoading] = useState(true);
  const [firstAPI, setFirstAPI] = useState(false);
  const [min_value_of_MyBalance, setMinMyBalance] = useState(0);
  const [max_value_of_MyBalance, setMaxMyBalance] = useState(0);
  const [min_value_of_nav_per_token, setMinNavPerToken] = useState(0);
  const [max_value_of_nav_per_token, setMaxNavPerToken] = useState(0);
  const [diff_my_balance, setDiffMyBalance] = useState(0);
  const [diff_nav_per_token, setDiffNavPerToken] = useState(0);
  const [Nav_Mean, set_Nav_Mean] = useState(0);
  const [My_Balance_Mean, set_MyBalance_Mean] = useState(0);
  const [isUserDeposited, setIsUserDeposited] = useState(false);

  const stateChangeReflector = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const depositWithdrawRandom = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.randomNumber
  );
  const blockNumber = useSelector(
    (state) => state.get_current_block_number.blockNumber
  );
  const sinceInceptionAPR = useSelector(
    (state) => state.get_since_inception_apr.sinceInceptionAPR
  );

  const description = useSelector(
    (state) => state.get_vault_details?.vault?.vault?.description
  );

  const filterClickHandler = (data) => {
    setLoader(true);
    dispatch(FilterValue(data));
    if (data === "1D") {
      //1d
      Vaults.stockChartData("daily", vaultAddress, userAddress, blockNumber)
        .then((response) => {
          setData(response.data.data.user_vaults, () => {
            setFirstAPI(true);
          });
        })
        .catch((err) => {
          setFirstAPI(true);
        })
        .finally(() => {
          setFirstAPI(true);
          setLoader(false);
        });
    } else if (data === "1W") {
      Vaults.stockChartData("weekly", vaultAddress, userAddress, blockNumber)
        .then((response) => {
          setData(response.data.data.user_vaults, () => {
            setFirstAPI(true);
          });
        })
        .catch((err) => {
          setFirstAPI(true);
        })
        .finally(() => {
          setFirstAPI(true);
          setLoader(false);
        });
    } else if (data === "1M") {
      //1m
      Vaults.stockChartData("monthly", vaultAddress, userAddress, blockNumber)
        .then((response) => {
          setData(response.data.data.user_vaults, () => {
            setFirstAPI(true);
          });
        })
        .catch((err) => {
          setFirstAPI(true);
        })
        .finally(() => {
          setFirstAPI(true);
          setLoader(false);
        });
    } else if (data === "1Y") {
      //1y
      Vaults.stockChartData("yearly", vaultAddress, userAddress, blockNumber)
        .then((response) => {
          if (response.data.data.user_vaults.length < 2) {
            filterClickHandler("1M");
          } else {
            setData(response.data.data.user_vaults, () => {
              setFirstAPI(true);
            });
          }
        })
        .catch((err) => {
          setFirstAPI(true);
        })
        .finally(() => {
          setFirstAPI(true);
          setLoader(false);
        });
    }
  };

  const formatYLeftAxis = (data) => {
    return data.toFixed(2);
  };

  const formatYRightAxis = (data) => {
    return data.toFixed(2);
  };

  const MeanOfArray = (array) => {
    let i,
      sum = 0;
    for (i = 0; i < array.length; i++) {
      sum = sum + array[i];
    }
    return sum / array.length;
  };

  useEffect(() => {
    if (firstAPI) {
      isChartLoading(true);
    }
  }, [firstAPI]);

  useEffect(() => {
    let navpertoken = [];
    let mybalance = [];
    if (data) {
      data.forEach((res) => {
        navpertoken.push(res.NAVPerToken);
        mybalance.push(res.myBalance);
      });

      Promise.all(navpertoken, mybalance).then(() => {
        set_Nav_Mean(MeanOfArray(navpertoken));
        set_MyBalance_Mean(MeanOfArray(mybalance));
        setMinMyBalance(Math.min(...mybalance));
        setMaxMyBalance(Math.max(...mybalance));
        setMinNavPerToken(Math.min(...navpertoken));
        setMaxNavPerToken(Math.max(...navpertoken));
        setDiffMyBalance(Math.max(...mybalance) - Math.min(...mybalance));
        setDiffNavPerToken(Math.max(...navpertoken) - Math.min(...navpertoken));
      });
    }
  }, [data]);

  const balanceAndNavApi = () => {
    Vaults.userAndNavDetailsOfVaultToken(userAddress, vaultAddress)
      .then((res) => {
        setUserNavDetails(res.data?.data);
      })
      .finally(() => {
        setUserNavDetailsLoading(false);
      });
  };

  useEffect(() => {
    setIsUserDeposited(false);
  }, [vaultAddress, userAddress, stateChangeReflector, depositWithdrawRandom]);

  useEffect(() => {
    balanceAndNavApi();
  }, []);

  useEffect(() => {
    balanceAndNavApi();
  }, [depositWithdrawRandom, stateChangeReflector, isUserDeposited]);

  useEffect(() => {
    if (vaultAddress && blockNumber) filterClickHandler("1W");
  }, [vaultAddress, blockNumber]);

  return (
    <div>
      <div className="chart-top-contents-aligner">
        {userAddress ? (
          <div className="mini-flex" style={{ marginRight: "2vw" }}>
            <NewPriceCard
              title="My Balance"
              value={userNavDetails?.userBalance}
              isChartLoading={userNavDetailsLoading}
              baseCurrency={userNavDetails?.baseCurrency}
            />
          </div>
        ) : null}

        <div className="mini-flex">
          {sinceInceptionAPR && sinceInceptionAPR > 0 ? (
            <NewPriceCard
              isChartLoading={userNavDetailsLoading}
              toggle="grow"
              title="Price per Token"
              value={userNavDetails?.navPerToken}
              percentvalue={sinceInceptionAPR}
              baseCurrency={userNavDetails?.baseCurrency}
            />
          ) : sinceInceptionAPR === 0 ||
            sinceInceptionAPR === undefined ||
            sinceInceptionAPR === "NaN" ? (
            <NewPriceCard
              isChartLoading={userNavDetailsLoading}
              toggle="null"
              title="Price per Token"
              value={userNavDetails?.navPerToken}
              percentvalue={sinceInceptionAPR}
              baseCurrency={userNavDetails?.baseCurrency}
            />
          ) : (
            <NewPriceCard
              isChartLoading={userNavDetailsLoading}
              toggle="drop"
              title="Price per Token"
              value={userNavDetails?.navPerToken}
              percentvalue={sinceInceptionAPR}
              baseCurrency={userNavDetails?.baseCurrency}
            />
          )}
        </div>
        <div className="mini-flex">
          <Description description={description} />
        </div>
      </div>

      <div className="dashboard-chart-resizer">
        <div className="chart-heading-and-dropdown">
          <p className="performance-text">Performance</p>
          <DropDown filterClickHandler={filterClickHandler} />
        </div>
        {loader ? (
          <CustomLoader loaderHeight="450px" />
        ) : (
          <ResponsiveContainer width="98%" height={450}>
            <LineChart
              width={"100%"}
              height={500}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 35,
              }}
            >
              <CartesianGrid stroke="#ebebeb" strokeDasharray="10 10" />
              <XAxis
                dataKey="date"
                paddingBottom="5rem"
                style={{
                  fontFamily: "Catamaran",
                  fontSize: "0.05px",
                  margin: "10px",
                }}
                axisLine={false}
                tickCount={1000}
                minTickGap={150}
                label={<Label position={"bottom"} value={"Date"} />}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tickCount={1000}
                tickLine={true}
                axisLine={false}
                minTickGap={100}
                tickFormatter={formatYLeftAxis}
                style={{ fontFamily: "Catamaran" }}
                domain={[
                  min_value_of_MyBalance -
                    1.5 *
                      (diff_my_balance *
                        (My_Balance_Mean / max_value_of_MyBalance)),
                  max_value_of_MyBalance +
                    1.5 *
                      (diff_my_balance *
                        (My_Balance_Mean / max_value_of_MyBalance)),
                ]}
                label={
                  <Label value={"My Balance"} position="left" angle={270} />
                }
              />

              <YAxis
                yAxisId="left"
                orientation="left"
                tickCount={1000}
                tickLine={true}
                axisLine={false}
                minTickGap={100}
                style={{ fontFamily: "Catamaran" }}
                tickFormatter={formatYRightAxis}
                domain={[
                  min_value_of_nav_per_token -
                    diff_nav_per_token *
                      (Nav_Mean / max_value_of_nav_per_token),
                  max_value_of_nav_per_token +
                    diff_nav_per_token *
                      (Nav_Mean / max_value_of_nav_per_token),
                ]}
                label={
                  <Label value={"NAV per Token"} position="right" angle={90} />
                }
              />
              <Tooltip
                content={<CustomTooltip isUserDeposited={isUserDeposited} />}
                animationDuration={0.5}
              />
              <Legend wrapperStyle={{ position: "relative" }} />

              <Line
                type="monotone"
                dataKey="navPerToken"
                stroke="#164F58"
                yAxisId="left"
                activeDot={{ r: 5 }}
                strokeWidth={2}
                dot={false}
              />
              {isUserDeposited ? (
                <Line
                  type="monotone"
                  dataKey="userBalance"
                  yAxisId="right"
                  stroke="#F3B258"
                  activeDot={{ r: 5 }}
                  strokeWidth={2}
                  dot={false}
                />
              ) : (
                ""
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
