const initState = {
    toggle: null,
  
};
 //For knowing about create screen or explore
  //If createvault => Create vault screen is displayed
  //If explore => Explore screen is displayed
  //If admin=> Admin Page
  
const setToggle = (state=initState,action) => {
    switch(action.type) {
        case "SET_TOGGLE":
            return { 
                ...state,
                toggle: action.payload.toggle,
            }

        default: 
            return state;
    }
}

export default setToggle;