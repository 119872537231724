

  export const setTokenValue = (val,nav) => async(dispatch) => {

  dispatch({
    type: "SET_TOKEN_VALUE",
    payload: {
      setTokenValue: val,
      setNavValue: nav
    }
  })
}

