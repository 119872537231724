import NoDataImg from "../../../src/assets/no-data-vector-image.svg";
import "./Style/style.css";
//component that displays to display no contents.
const NoData = (props) => {
  const { text } = props;
  return (
    <>
      <div className="no-data-component">
        <img src={NoDataImg} alt="" style={{ height: "10rem" }}></img>
        <p
          style={{
            marginTop: "15px",
            color: "#1B3E69",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      </div>
    </>
  );
};
export default NoData;
