const initState = {
    defaultAddress: "",
  
};

const DefaultVaultAddressReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_DEFAULT_ADDRESS":
            return { 
                ...state,
                address: action.payload.defaultAddress,
            }

        default: 
            return state;
    }
}

export default DefaultVaultAddressReducer;