import React from "react";
import { Popover } from "antd";
import { Link, useRouteMatch } from "react-router-dom";

export default function Description(props) {
  const { url } = useRouteMatch();
  const complete = (
    <div className="complete-card">
      <p className="title">Description</p>
      <p className="description-text">{props?.description}</p>
      <span className="break-line" />
      <p className="title">
        Want to know more about this vault? Read it <Link to={`${url}/description`}> <span className="read-more-here">here</span> </Link>
      </p>
    </div>
  );

  return (
    <>
      {props?.description?.length ? (
        <div className="description-card">
          <p className="description-title">Description</p>
          <div className="description-content">
            <div className="icon" />
            <p className="description-text">{props?.description}</p>
            <Popover
              placement="bottomRight"
              content={complete}
              color="#F3F6F9"
              overlayStyle={{ paddingTop: "15px" }}
            >
              <p className="read-more">More</p>
            </Popover>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
