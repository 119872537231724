export const state1 = {
   returnedTransactionHash:false
  };

  const ReturnedHashReducer = (state = state1, action) => {
    switch (action.type) {
      case "RETURNED_TRANSACTION_HASH":
        return {
          ...state,
          returnedTransactionHash: action.payload,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default ReturnedHashReducer;