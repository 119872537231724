import React, { useState, useEffect } from "react";
import Card from "./Card";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { setVault } from "../../api";
import Navbar from "../../Navbar/index";
import "./index.css";
import { toggleVaultVisibility } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomLoader from "../../Custom_Loader/customLoader";
const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 100,
  },
  progressRing: {
    color: "#ffffff",
  },
}));
function AllVaults({ token }) {
  //States
  const classes = useStyles();
  const [allVaults, setAllVaults] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  //variables

  const changeVaultVisibility = async (vaultAddress) => {
    if (token) {
      setBackDropOpen(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.patch(
        toggleVaultVisibility(vaultAddress),
        config
      );
      if (response.data.status) {
        getAllVaults();
      } else {
        setBackDropOpen(false);
      }
    }
  };

  //function to get all vaults from api
  const getAllVaults = () => {
    axios({
      method: "GET",
      url: setVault(),
    })
      .then((response) => {
        if (
          response.data.data &&
          response.data.status == 1 &&
          response.data.data.length != 0
        ) {
          setAllVaults(response.data.data);
        } else setAllVaults([]);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setBackDropOpen(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllVaults();
  }, []);

  const DisplayVaults = () => {
    if (allVaults.length != 0) {
      return (
        <div className="admin-page-card-wrap">
          {allVaults.map((data) => {
            return (
              <Card
                props={data}
                visibility={data.isVisible}
                changeVaultVisibility={changeVaultVisibility}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="noVaultDisplay">No Vaults available</p>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress className={classes.progressRing} />
      </Backdrop>
      <div className="admin-page-wrap">
        <div className="explore-vaults-admin">
          <h1 style={{ color: "#1B3E69" }}> Explore All Vaults</h1>
        </div>
        {loading ? (
          <CustomLoader marginTop={15} />
        ) : (
          <DisplayVaults token={token} />
        )}
      </div>
    </React.Fragment>
  );
}

export default AllVaults;
