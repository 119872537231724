const initState = {
    assets : [],
    withdrawasset: []
};

const assetsPerVaultReducer = (state=initState,action) => {
    switch(action.type) {
        case "DEPOSIT_ASSETS_PER_VAULT":
            return { 
                ...state,
                assets: action.payload.deposit_assets
            }

            case "WITHDRAWABLE_ASSETS_PER_VAULT":
                return { 
                        ...state,
                       withdrawasset : action.payload.withdrawable_assets
                }

        

        default: 
            return state;
    }
}

export default assetsPerVaultReducer;