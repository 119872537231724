import { store } from "../../index.js";
import { userAddress } from "../../redux/actions/UserAddress.js";
import { Provider } from "../../redux/actions/Provider.js";
import { getWeb3 } from "../../redux/actions/getWeb3";
import { onboard } from "./blocknativeConfig.js";

export function removeProvider() {
  console.log("removeProvider");
  store.dispatch(userAddress(null));
  store.dispatch(Provider(null));
  if (onboard?.disconnectWallet)
    onboard?.disconnectWallet({ label: "MetaMask" });
}
