const initState = {
  provider: null,
  // ,web3:null
};
const ProviderReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_PROVIDER":
      return {
        ...state,
        provider: action.payload.setprovider,
      };
    // case "SET_WEB3":
    //     return {
    //         ...state,
    //         web3: action.payload,
    //     }

    default:
      return state;
  }
};
export default ProviderReducer;
