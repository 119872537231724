const initState = {
    myBalance: 0,
  
};

const myBalanceReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_MY_BALANCE":
            return { 
                ...state,
                myBalance: action.payload.myBalance,
            }

        default: 
            return state;
    }
}

export default myBalanceReducer;