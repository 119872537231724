const initState = {
    toggle: null,
  
};
 //For knowing about create screen or explore
  //If true => Create vault screen is displayed
  //If false => Explore screen is displayed
  
const DashBoardLoader = (state=initState,action) => {
    switch(action.type) {
        case "SET_DASHBOARD_LOADER":
            return { 
                ...state,
                toggle: action.payload.toggle,
            }

        default: 
            return state;
    }
}

export default DashBoardLoader;