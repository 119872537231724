import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Assets from "./SupportedAssets";
import "../SafeDetails/WhiteList_Manager/addButton";
import CustomLoader from "../../Custom_Loader/customLoader";
import { Vaults } from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: " 24px",
    color: "#1B3E69",
    fontWeight: "bold",
    marginTop: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  mainHeading: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  mainContent: {
    fontSize: "16px",
    textAlign: "justify",
    marginBottom: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  smallHeadingNew: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  contentSmall: {
    fontSize: "14px",
    textAlign: "justify",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  contentSmallNew: {
    fontSize: "14px",
    textAlign: "justify",
    marginTop: "10px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  smallHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginTop: "40px",
    textTransform: "uppercase",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  godContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  headingFirst: {
    paddingLeft: "20px",
    fontSize: " 20px",
    marginBotoom: "10px",
  },
  width: {
    height: "130px",
  },
  widthSmall: {},
  contentAddress: {
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "5px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  godAddress: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  address: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "36px",
    [theme.breakpoints.down("1641")]: {
      height: "32px",
    },
  },
  assetName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginLeft: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  assetLine: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    [theme.breakpoints.down("1641")]: {
      height: "34px",
    },
  },
  buttonNew: {
    marginRight: "-15px",
    background: "transparent",
    textDecoration: "underline",
    textTransform: "capitalize",
    boxShadow: "none",
    color: "#1B3E69",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      transform: "scale(1.05)",
      background: "transparent",
      textDecoration: "underline",
      textTransform: "capitalize",
      boxShadow: "none",
      color: "#1B3E69",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "'Open Sans', sans-serif",
    },
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  addressFont: {
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
}));

export default function APS(props) {
  const classes = useStyles();
  const [vaultDetailsList, setVaultDetailsList] = React.useState();
  const user = useSelector((state) => state.get_user_address);
  const web3 = useSelector((state) => state.get_web3.web3);
  const [loader, setLoader] = React.useState(true);
  const [strategyChanged, setStrategyChanged] = React.useState(true);
  const data = useSelector((state) => state.get_vault_details.vault);
  const vaultDetails = data && data.vault;
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );

  const getVaultAssets = () => {
    if (vaultAddress != "") {
      Vaults.getSafeDetails(vaultAddress)
        .then((response) => {
          if (response.data.data) setVaultDetailsList(response.data.data);
        })
        .catch(console.log)
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getVaultAssets();
  }, [vaultAddress]);

  return (
    <>
      {loader ? (
        <>
          <div style={{ marginTop: "20vh" }}>
            <CustomLoader />
          </div>
        </>
      ) : (
        <div>
          {vaultDetails ? (
            <>
              <p className="main-heading">Supported Assets</p>
              <p className="description">
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                possimus eaque amet quas ad perspiciatis placeat voluptas
                laboriosam. Libero incidunt iure error, fugit voluptate possimus
                molestias quis id ratione iste. Aspernatur voluptate placeat
                cumque accusamus nobis odio cupiditate necessitatibus adipisci,
                numquam, laborum praesentium quo repudiandae quasi similique
                debitis excepturi ducimus maiores id eveniet sequi incidunt? */}
              </p>
              <div className={classes.widthSmall}>
                <Assets
                  assets={vaultDetailsList?.assets}
                  protocols={vaultDetailsList?.protocols}
                  vaults={vaultDetailsList?.supportedVaults}
                  userAddress={user.address}
                  web3={web3}
                  strategyChanged={strategyChanged}
                  break={props.break}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
