import { createWalletClient, custom, http, webSocket } from "viem";
import { configureChains, createConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { publicProvider } from "@wagmi/core/providers/public";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  walletConnectWallet,
  trustWallet,
  braveWallet,
  ledgerWallet,
  safeWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { web3Config } from "../web3Config";
import { store } from "../..";
import { walletState } from "../../redux/actions/walletState";
import { userAddress } from "../../redux/actions/UserAddress";
import { INFURA_KEY, PROJECT_ID, RPC_URL } from "../consts";
import { getWalletClient } from "@wagmi/core";
import { User } from "../../services/api";

const appName = "Yieldcube";
const projectId = PROJECT_ID;

const { chains, publicClient } = configureChains(
  [mainnet],
  [publicProvider(), infuraProvider({ apiKey: INFURA_KEY })]
);
const transport = http(RPC_URL);
// const address = await client.writeContract();
// console.log({ address });

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [walletConnectWallet({ chains, projectId })],
  },
  {
    groupName: "Popular",
    wallets: [trustWallet({ chains, projectId }), braveWallet({ chains })],
  },
  {
    groupName: "Others",
    wallets: [safeWallet({ chains }), ledgerWallet({ chains, projectId })],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const updateWalletDetails = async (connector, address) => {
  localStorage.setItem("selectedWallet", connector?.name);
  web3Config();
  if (connector?.name) store.dispatch(walletState(connector));
  store.dispatch(userAddress(address));
  localStorage.setItem("accountAddress", address);
  User.addUser(address).catch((error) => console.log(error));
};

const walletClient = await getWalletClient();
export { chains, wagmiConfig, updateWalletDetails, walletClient };
