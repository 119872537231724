import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import Gnosis from "yieldster-abi/contracts/YieldsterVault.json";
import { TransactionInProgress } from "../../../../redux/actions/reload-state";
import { message } from "../../../../utils/message";
import WrapperFunction from "../../../Global-Functions/WrapperFunction";
import LoaderAnimation from "../../../LoaderAnimation/LoaderModal";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "8px 20px",
    borderRadius: "8px",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
      transform: "scale(1.05)",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  buttonContainer: {
    float: "right",
    padding: "0px 40px 30px 0px",
  },
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
}));

export default function AlertDialog(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = useState();
  const web3 = props.web3;
  const [encodeABIData, setEncodeABIData] = React.useState();

  const handleSave = async () => {
    const account = await web3.eth.getAccounts();
    setLoading(true);
    props.dispatch(TransactionInProgress(true));
    const getGnosisSafeInstanceAt = (safeAddress) => {
      return new web3.eth.Contract(Gnosis.abi, safeAddress);
    };
    const newSafe = await getGnosisSafeInstanceAt(props.vaultAddress);

    if (props.type == "break") {
      try {
        await newSafe.methods.toggleEmergencyBreak().send({ from: account[0] });
        const result = await newSafe.methods.toggleEmergencyBreak().encodeABI();
        setEncodeABIData(result);
        const transactionData = {
          contractAddress: props.vaultAddress,
          txType: "TOGGLE_EMERGENCY_BREAK",
          signer: account[0],
          txData: result,
          nonce: 0,
          parameters: {},
        };
        props.dispatch(WrapperFunction(transactionData, "POST"));
        setLoading(false);
        // axios.post(pendingTransaction(), {
        //   contractAddress: "contractAddress",
        //   txType: "TOGGLE_EMERGENCY_BREAK",
        //   parameters: {},
        //   nonce: "",
        //   signer: account[0],
        //   txHash: "",
        //   txData: result,
        //   walletType: "",
        // });

        props.setChange(!props.change);
        // axios.patch(enableBreak(),{vaultAddress:props.vaultAddress}).then((data)=>{
        //   if(data.data.status == "1"){
        //     setLoading(false)
        //     props.setBreakChange(props.breakChange)
        //     props.dispatch(TransactionInProgress(false))
        //     message.success("updated");

        //   }
        // });
      } catch (err) {
        setLoading(false);
        props.setBreakChange(props.breakChange);
        props.dispatch(TransactionInProgress(false));
        message.error(err["message"]);
      }
    } else if (props.type == "exit") {
      try {
        // await newSafe.methods.enableEmergencyExit().send({from:account[0]});
        const newResult = await newSafe.methods
          .enableEmergencyExit()
          .encodeABI();
        setEncodeABIData(newResult);
        const transactionData = {
          contractAddress: props.vaultAddress,
          txType: "ENABLE_EMERGENCY_EXIT",
          signer: account[0],
          txData: newResult,
          nonce: 0,
          parameters: {},
        };
        props.dispatch(WrapperFunction(transactionData, "POST"));
        setLoading(false);
        // axios.post(pendingTransaction(), {
        //   contractAddress: "contractAddress",
        //   txType: "ENABLE_EMERGENCY_EXIT",
        //   parameters: {},
        //   nonce: "",
        //   signer: account[0],
        //   txHash: "",
        //   txData: newResult,
        //   walletType: "",
        // });

        // axios.patch(exits(),{vaultAddress:props.vaultAddress}).then((data)=>{
        //   if(data.data.status == "1"){
        //     setLoading(false)
        //     props.dispatch(TransactionInProgress(false))
        //     message.success("updated");
        //   }
        // });
      } catch (err) {
        setLoading(false);
        props.dispatch(TransactionInProgress(false));
        message.error(err["message"]);
      }
    } else {
      try {
        await newSafe.methods.toggleEmergencyBreak().send({ from: account[0] });
        const resultNew = await newSafe.methods
          .toggleEmergencyBreak()
          .encodeABI();
        setEncodeABIData(resultNew);
        const transactionData = {
          contractAddress: props.vaultAddress,
          txType: "TOGGLE_EMERGENCY_BREAK",
          signer: account[0],
          txData: resultNew,
          nonce: 0,
          parameters: {},
        };
        props.dispatch(WrapperFunction(transactionData, "POST"));
        setLoading(false);
        // axios.post(pendingTransaction(), {
        //   contractAddress: "contractAddress",
        //   txType: "TOGGLE_EMERGENCY_BREAK",
        //   parameters: {},
        //   nonce: "",
        //   signer: account[0],
        //   txHash: "",
        //   txData: resultNew,
        //   walletType: "",
        // });

        props.setChange(!props.change);
        // axios.patch(disableBreak(),{vaultAddress:props.vaultAddress}).then((data)=>{
        //   if(data.data.status == "1"){
        //     setLoading(false)
        //     props.dispatch(TransactionInProgress(false))
        //     message.success("updated");
        //   }
        // });
      } catch (err) {
        setLoading(false);
        props.dispatch(TransactionInProgress(false));
        message.error(err["message"]);
      }
    }

    dialog.close(value);
  };

  return (
    <div>
      {props.vaultAddress != "" ? (
        <>
          <p className={classes.heading}>{props.heading}</p>
          <p className={classes.content}>{props.description}</p>

          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              style={{
                color: "white",
                background: "#C42121",
                marginRight: "15px",
              }}
              variant="contained"
              onClick={handleSave}
            >
              YES
            </Button>
            <Button
              className={classes.button}
              style={{ color: "#6D7681", background: "#DEE5EF" }}
              variant="contained"
              color="primary"
              onClick={() => {
                dialog.close(value);
              }}
            >
              NO
            </Button>
          </div>
        </>
      ) : (
        <p style={{ margin: "20px" }}>Vault has to be selected</p>
      )}
      <LoaderAnimation loading={loading} />
    </div>
  );
}
