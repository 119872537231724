const initState = {
    isLoading: false,
  
};

const loaderAnimationReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_LOADER":
            return { 
                ...state,
                isLoading: action.payload.setLoader,
            }

        default: 
            return state;
    }
}

export default loaderAnimationReducer;