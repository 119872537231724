import React from "react";
import "./Description-Styler/descriptionStyle.css";

export default function Description(props) {
  return (
    <>
      {props?.description?.length ? (
        <div className="long-description">
          <p className="long-description-title">Vault Description</p>
          <div className="long-description-content"><div dangerouslySetInnerHTML={{__html:props?.description}}/></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
