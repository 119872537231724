import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import { store } from "../../index.js";
import { userAddress } from "../../redux/actions/UserAddress.js";
import { Provider } from "../../redux/actions/Provider.js";
import { connectedNetwork } from "../../redux/actions/connectedNetwork.js";
import { networkName } from "../../utils/networkId.js";
import { getWeb3 } from "../../redux/actions/getWeb3";
import Web3 from "web3";
import { web3Config } from "../../utils/web3Config";
import {
  networkID,
  FORTMATIC_KEY,
  INFURA_KEY,
  PORTIS_KEY,
  RPC_URL,
  APP_URL,
  CONTACT_EMAIL,
} from "../../utils/consts";
import { removeProvider } from "./removeProvider.js";
import { walletState } from "../../redux/actions/walletState.js";
import userlogin from "./userlogin.js";
import { getBlockNumber } from "../../utils/getBlockNumber";
import { User } from "../../services/api";
import { ethers } from "ethers";
let web3;
let provider;
let token;

const injected = injectedModule();

export const updateNetwork = (network) => {
  store.dispatch(connectedNetwork(networkName(network)));
  localStorage.setItem("network", networkName(network));
};

export const upateUserAddress = (address) => {
  if (address) {
    store.dispatch(userAddress(address));
    localStorage.setItem("accountAddress", address);
    User.addUser(address).catch((error) => console.log(error));
  } else {
    removeProvider();
    localStorage.removeItem("selectedWallet");
    localStorage.removeItem("network");
    localStorage.removeItem("accountAddress");

    //Creates a local web3 instance in redux to fetch data from chain
    web3Config();
  }
};

export const updateProvider = (wallet) => {
  web3 = new Web3(wallet.provider);
  provider = wallet.provider;
  localStorage.setItem("selectedWallet", wallet.name);
  store.dispatch(getWeb3(web3));
  store.dispatch(Provider(wallet.provider));
  store.dispatch(walletState(wallet));
  getBlockNumber(web3);
};

export const onboard = Onboard({
  wallets: [injected],
  chains: [
    {
      id: networkID,
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl: RPC_URL,
    },
  ],
  connect: {
    autoConnectLastWallet: true,
  },
  appMetadata: {
    name: "Yieldcube",
    icon: "https://vaults.akkrue.com/akkrue-logo.png",
    logo: "https://vaults.akkrue.com/akkrue-logo.png",
    description:
      "Connect your MetaMask wallet seamlessly to our application, enabling secure and decentralized interactions with just a click.",
  },
  accountCenter: {
    desktop: {
      enabled: false,
      // minimal: true,
    },
  },
});

const wallets = onboard.state.select("wallets");
const { unsubscribe } = wallets.subscribe((wallets) => {
  if (wallets?.[0]) {
    const ethersProvider = new ethers.providers.Web3Provider(
      wallets[0].provider,
      networkID
    );
    upateUserAddress(wallets[0]?.accounts?.[0]?.address);
    updateProvider({
      provider: ethersProvider.provider,
      name: wallets?.[0]?.label,
    });
    updateNetwork(networkID);
  }
});
