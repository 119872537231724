import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class Withdraw {
  static getAllStrategy() {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `WithDrawStrategy/v2.0/yieldster/get`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getStrategyById(objectID) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `WithDrawStrategy/v2.0/yieldster/${objectID}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }
}
