const NonceCalculator = (web3, user_address, callback) => {
  if (web3 && user_address) {
    web3.eth.getTransactionCount(user_address, (err, nonce) => {
      console.log(`Nonce for ${user_address}:`, nonce);
      callback(nonce);
    });
  }
  // }
};
export default NonceCalculator;
