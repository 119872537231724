import { Checkbox, Divider, Row, Col } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageLayout from "../PageLayout";
import "./Style/index.css";

export default function Disclaimer() {
  const history = useHistory();
  const [disclaimerRead, setDisclaimerRead] = useState(false);
  const [disclaimerAgree, setDisclaimerAgree] = useState(false);

  const handleSubmit = () => {
    if (disclaimerRead && disclaimerAgree) {
      history.replace("/createvault");
    }
  };
  return (
    <PageLayout>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="disclaimer-wrapper"
      >
        <Col md={16} xl={12}>
          <div className="disclaimer-submit">
            <div>
              <p>
                Please check the boxes below to confirm your agreement to
                the&nbsp;
                <Link to="/terms-of-use">YieldCube App Terms of use</Link>:
              </p>
              <br />
              <Checkbox
                onChange={(e) => setDisclaimerRead(e.target.checked)}
                checked={disclaimerRead}
              >
                You have read and understand, and do hereby agree to be bound by
                these <Link to="/terms-of-use">Terms of Use</Link>, including
                all future amendments thereto. Such agreement is irrevocable and
                will apply to all my uses of the interface without me providing
                confirmation in each specific instance.
              </Checkbox>
              <Checkbox
                onChange={(e) => setDisclaimerAgree(e.target.checked)}
                checked={disclaimerAgree}
              >
                I acknowledge and agree that the Interface provides information
                about data on the Ethereum blockchain. I accept that the
                Interface operators have no custody over my assets, ability or
                duty to transact on my behalf or power to reverse my
                transactions. The Interface operators do not endorse or provide
                any warranty with respect to any tokens or crypto assets.
              </Checkbox>
            </div>
            <br />
            <button
              className="button-content"
              onClick={handleSubmit}
              disabled={!(disclaimerRead && disclaimerAgree)}
            >
              Continue <span>&#9654;</span>
            </button>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
