const initState = {
  onboard: null,
};

const getOnboardReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_ONBOARD":
      return {
        ...state,
        onboard: action.payload.onboard,
      };

    default:
      return state;
  }
};

export default getOnboardReducer;
