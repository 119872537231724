import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination, Table, Tooltip } from "antd";
import { setVaultAddress } from "../../redux/actions/VaultAddress";
import { safeName } from "../../redux/actions/SafeName";
import { vaultIcon } from "../../redux/actions/vaultIcon";
import { tokenSymbol } from "../../redux/actions/tokenSymbol";
import Vaults from "../../services/api/vaults";
import "./Style/index.css";

function VaultTable(props) {
  const [dataBody, setDataBody] = useState(props.data);
  const history = useHistory();
  const dispatch = useDispatch();

  const setClassname = (v) => {
    return Math.sign(parseFloat(v ?? 1)) == 1
      ? "positive"
      : v[0] === "-"
      ? "negative"
      : " ";
  };

  const compareValues = (a, b) => {
    let v1 = parseFloat(a),
      v2 = parseFloat(b);
    if (v1 != NaN && v2 != NaN) {
      return v1 - v2;
    } else {
      return a.localeCompare(b);
    }
  };

  const columnsHead = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <div className="user-data-cell">
          <div className="user-image">
            <img src={`data:image/jpeg;base64,${image}`} />
          </div>
        </div>
      ),
      width: "2%",
    },
    {
      title: "",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "CUR",
      dataIndex: "cur",
      width: "7%",
      sorter: {
        compare: (a, b) => a.cur.localeCompare(b.cur),
      },
    },
    {
      title: "Total NAV",
      dataIndex: "totalnav",
      width: "12%",
      align: "right",
      sorter: {
        compare: (a, b) => compareValues(a.totalnav, b.totalnav),
      },
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.totalnav),
          children: <span>{record.totalnav}</span>,
        };
      },
    },
    {
      title: "NAV per Token",
      dataIndex: "pertoken",
      width: "15%",
      align: "right",
      sorter: {
        compare: (a, b) => compareValues(a.pertoken, b.pertoken),
      },
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.pertoken),
          children: <span>{record.pertoken}</span>,
        };
      },
    },
    {
      title: "1 Day",
      dataIndex: "day",
      width: "10%",
      align: "right",
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.day),
          children: <span>{record.day}</span>,
        };
      },
    },
    {
      title: "1 Week",
      dataIndex: "week",
      width: "10%",
      align: "right",
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.week),
          children: <span>{record.week}</span>,
        };
      },
    },
    {
      title: "1 Month",
      dataIndex: "month",
      width: "12%",
      align: "right",
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.month),
          children: <span>{record.month}</span>,
        };
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "17%",
      align: "right",
      render: (v) => <span className={setClassname(v)}>{v}</span>,
      onCell: (record) => {
        return {
          className: setClassname(record.total),
          children: <span>{record.total}</span>,
        };
      },
      sorter: {
        compare: (a, b) => compareValues(a.total, b.total),
      },
    },
  ];

  const fixed = (v) => {
    if (v !== null && isNaN(v)) {
      let num = parseFloat(v) / Math.pow(10, 18);
      return num.toFixed(2);
    } else return parseFloat(0).toFixed(2);
  };

  async function sort(params, data) {
    Vaults.getSortedVaults(params, data)
      .then((response) => {
        let vault = [];
        response.data.data.map((data, index) => {
          let temp = {};
          temp["key"] = index;
          temp["name"] = data.vault.vaultName;
          temp["image"] = data.vault.logoImg.data;
          temp["avatar"] = data.logoUrl;
          temp["description"] = data.vault.description;
          temp["cur"] = data.vault.baseCurrency.slice(0, 3);
          temp["coins"] = data.vault.supportedAssets;
          temp["day"] = fixed(data.oneDayAPR);
          temp["week"] = fixed(data.oneWeekAPR);
          temp["month"] = fixed(data.oneMonthAPR);
          temp["total"] = fixed(data.totalAPR);
          temp["pertoken"] = fixed(
            data.tokenNAV.data !== null
              ? data.tokenNAV.data.TokenPrice
              : data.tokenNAV.data
          );
          temp["totalnav"] = fixed(
            data.totalNAV.data !== null
              ? data.totalNAV.data.VaultNAV
              : data.totalNAV.data
          );
          temp["tokenSymbol"] = data.vault.tokenSymbol;
          vault.push(temp);
        });
        setDataBody(vault);
      })
      .catch((e) => {
        console.log("Sorted vaults error", e);
      });
  }

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <Tooltip title="Previous page" placement="bottom">
          <button
            className="pagination-button"
            onClick={(e) => {
              e.preventDefault();
              if (props.pageNo != 0) props.setPageNo((prev) => prev - 1);
            }}
          >
            {"<"}
          </button>
        </Tooltip>
      );
    }
    if (type === "next") {
      let noOfItems = props.pageSize * (props.pageNo + 1),
        count = props.total;
      return (
        <Tooltip title="Next page" placement="bottom">
          <button
            className="pagination-button"
            onClick={(e) => {
              e.preventDefault();
              if (noOfItems < count) {
                props.setPageNo((prev) => prev + 1);
              }
            }}
          >
            {">"}
          </button>
        </Tooltip>
      );
    }
    if (type === "page") {
      return (
        <Tooltip title="Current page" placement="bottom">
          <span className="pagination-page-no">{props.pageNo + 1}</span>
        </Tooltip>
      );
    }
    return originalElement;
  }

  function onChange(pagination, filters, sorter, extra) {
    // console.log(
    //   "pagination",
    //   pagination,
    //   "filter",
    //   filters,
    //   "sorter",
    //   sorter,
    //   "extra",
    //   extra
    // );
    let category;
    // if (sorter) {
    //   if (sorter.field === "cur") category = "BaseCurrency";
    //   else if (sorter.field === "totalnav") category = "TotalNAV";
    //   else if (sorter.field === "pertoken") category = "TokenNAV";
    //   else if (sorter.field === "total") category = "TotalAPR";
    //   let param = { category, type: sorter.order };
    //   sort(param, props.all);
    // }
  }

  const handleClick = (data) => {
    let vaultAddress = data.vaultAddress,
      vaultName = data.name,
      icon = data.image,
      symbol = data.tokenSymbol;
    dispatch(setVaultAddress(vaultAddress));
    dispatch(safeName(vaultName));
    dispatch(vaultIcon(icon));
    dispatch(tokenSymbol(symbol));
    localStorage.setItem("newSafeAddress", vaultAddress);
    localStorage.setItem("vaultName", vaultName);
    localStorage.setItem("vaultIcon", icon);
    localStorage.setItem("vaultSymbol", symbol);
    history.push(`/vault/${vaultAddress}/dashboard`);
  };

  // console.log(3,setClassname(3));
  // console.log(-3,setClassname(-3));
  // console.log(0,setClassname(0));

  return (
    <div className="table-data-format">
      {dataBody === "none" ? (
        <div className="no-vaults">
          <span className="no-vaults-text">No Vaults to display</span>
        </div>
      ) : (
        <>
          <Table
            columns={columnsHead}
            dataSource={dataBody}
            onChange={onChange}
            onRow={(row) => {
              return { onClick: () => handleClick(row) };
            }}
            pagination={false}
          />
          <div className="table-data-pagination">
            <span>Rows per page</span>
            <Pagination
              pageSizeOptions={[10, 20, 30, 40, 50]}
              total={props.total}
              showTitle={false}
              current={props.pageNo + 1}
              pageSize={props.pageSize ?? 10}
              onChange={onChange}
              itemRender={itemRender}
              showSizeChanger={true}
              locale={{ items_per_page: "" }}
              showTotal={(total, range) =>
                `Vaults ${range[0]}-${range[1]} of ${total}`
              }
              onShowSizeChange={(current, size) => {
                props.setPageSize(size);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default VaultTable;
