import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Switch } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomLoader from "../../Custom_Loader/customLoader";
import { Advisor } from "../../../services/api";
import { capSpace } from "../../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  description: {
    fontSize: "14px",
    padding: "10px 0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  content: {
    paddingLeft: "5px",
    fontSize: "12px",
    lineHeight: "20px",
    paddingTop: "20px",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gap: "20px",
    padding: "7px 0px 3px",
  },
  key: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "capitalize",
    padding: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  value: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  edit: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "15px",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    float: "right",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  object: { display: "grid", gridAutoFlow: "rows", gap: "5px", padding: "0px" },
  allocationDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  address: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    padding: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  percent: {
    padding: "0px 0px 0px 10px",
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  protocol: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  advisorSetting: { display: "flex", flexDirection: "column" },
  mainText: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "10px 0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  innerSettings: { margin: "2px 0px 2px 15px" },
}));

export default function AdvisorContent({ setManageVaultType }) {
  const classes = useStyles();
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [data, setData] = useState();
  const { url } = useRouteMatch();
  const vaultAddress = localStorage.getItem("newSafeAddress");
  let editURL = url.replace(/\/[^\/]*$/, "/edit");

  const displayData = (passData) => {
    const display = Object.keys(passData).map((key) =>
      key !== "advisorEmail" &&
      key !== "name" &&
      key !== "vaultAdvisorSetting" ? (
        <tr className={classes.row}>
          <td className={classes.key}>{capSpace(key)}</td>
          {typeof passData[key] == "boolean" ? (
            <td className={classes.value}>
              <Switch defaultChecked={passData[key]} disabled={true} />
            </td>
          ) : typeof passData[key] != "object" ? (
            <td className={classes.value}>{passData?.[key]?.toString()}</td>
          ) : (
            <table className={classes.object}>
              {key === "supportedProtocols"
                ? passData[key]?.map((protocols) => (
                    <td className={classes.protocol}>{protocols}</td>
                  ))
                : key === "assetAllocation"
                ? Object.keys(passData[key]).map((asset) => (
                    <tr className={classes.allocationDetails}>
                      <td className={classes.address}>{asset}</td>
                      <td className={classes.percent}>
                        {passData?.[key]?.[asset]}%
                      </td>
                    </tr>
                  ))
                : ""}
            </table>
          )}
        </tr>
      ) : (
        ""
      )
    );
    return display;
  };

  const getAdvisor = async () => {
    if (vaultAddress) {
      Advisor.getCurrentAdvisorWithName(vaultAddress)
        .then((res) => {
          let obj = { name: res.data.data[0] };
          let setting = res.data.data[1];
          obj = { ...obj, ...setting };
          setData(obj);
          setManageVaultType(obj);
          setLoaderStatus(false);
        })
        .catch((e) => {
          setLoaderStatus(false);
        });
    }
  };

  useEffect(() => {
    getAdvisor();
  }, []);

  return (
    <>
      <div>
        <p className="main-heading">Advisor</p>
        <p className="description">
          {/* Lorem ipsum dolor sit amet. Est dolorem culpa et assumenda sunt est
          dignissimos rerum non consectetur dicta ut quia quia est numquam
          deserunt qui debitis saepe! Ea optio praesentium sit perspiciatis
          aperiam aut asperiores similique in nisi nisi sit quas velit ut
          suscipit necessitatibus? Et sint nesciunt sed doloremque quia et sint
          molestias et sunt repellat 33 dolor pariatur quo corporis tenetur eos
          placeat ratione. */}
        </p>
        {loaderStatus == true ? (
          <div style={{ marginTop: "150px" }}>
            <CustomLoader />
          </div>
        ) : (
          <>
            <button className={classes.edit}>
              <Link to={`${editURL}`} className="link">
                Edit
              </Link>
            </button>
            <table className={classes.content}>
              {data ? (
                Object.keys(data).map((key) => {
                  return key === "vaultAdvisorSetting" ? (
                    <tr className={classes.advisorSetting}>
                      <td className={classes.mainText}>
                        Vault Advisor Settings
                      </td>
                      <table className={classes.innerSettings}>
                        {displayData(data?.[key])}
                      </table>
                    </tr>
                  ) : (
                    <tr>
                      {key === "name" ? (
                        <table className={classes.row}>
                          <td className={classes.key}>{capSpace(key)}</td>
                          <td className={classes.value}>
                            {data?.[key]?.toString()}
                          </td>
                        </table>
                      ) : key === "supportedProtocols" ? (
                        data?.[key]?.length ? (
                          <table className={classes.row}>
                            <td className={classes.key}>{capSpace(key)}</td>
                            <td className={classes.object}>
                              {data[key]?.map((protocols) => {
                                return (
                                  <p className={classes.protocol}>
                                    {protocols}
                                  </p>
                                );
                              })}
                            </td>
                          </table>
                        ) : (
                          ""
                        )
                      ) : key === "assetAllocation" ? (
                        <table className={classes.row}>
                          <td className={classes.key}>{capSpace(key)}</td>
                          <td className={classes.object}>
                            {Object.keys(data[key]).map((asset) => {
                              return (
                                <tr className={classes.allocationDetails}>
                                  <td className={classes.address}>{asset}</td>
                                  <td className={classes.percent}>
                                    {data?.[key]?.[asset]}%
                                  </td>
                                </tr>
                              );
                            })}
                          </td>
                        </table>
                      ) : key === "advisorEmail" ? (
                        <table className={classes.row}>
                          <td className={classes.key}>{capSpace(key)}</td>
                          <td className={classes.value}>
                            {data?.[key]?.toString()}
                          </td>
                        </table>
                      ) : (
                        <>
                          {!data.hasOwnProperty("vaultAdvisorSetting") ? (
                            <table className={classes.row}>
                              <td className={classes.key}>{capSpace(key)}</td>
                              <td className={classes.value}>
                                {typeof data?.[key] === "boolean" ? (
                                  <Switch
                                    defaultChecked={data?.[key]}
                                    disabled={true}
                                  />
                                ) : (
                                  data?.[key]?.toString()
                                )}
                              </td>
                            </table>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </table>
          </>
        )}
      </div>
    </>
  );
}
