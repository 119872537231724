import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import {
  getAssetsURL,
  getImage,
  getAssetsPerVault,
  getStrategyAsset,
} from "../../../../api";
import axios from "axios";
import AvatarCharacter from "../../../../SidebarSettings/APS/Avatar";
import Switches from "../../../../VaultCreation/Stepper/AssetsProtocolsStrategies/Switch";
import Gnosis from "yieldster-abi/contracts/YieldsterVault.json";
import { message } from "../../../../../utils/message";
import Skeleton from "@material-ui/lab/Skeleton";
import { TransactionInProgress } from "../../../../../redux/actions/reload-state";
import WrapperFunction from "../../../../Global-Functions/WrapperFunction";

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  mainContent: {
    padding: "20px 30px 5px 30px",
    fontSize: "14px",
    textAlign: "justify",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  button: {
    margin: "10px 30px 30px 0px",
    padding: "8px 20px",
    borderRadius: "8px",
    boxShadow: "none",
    float: "right",
    "&:hover": {
      transform: "scale(1.05)",
      background: "#1B3E69",
      boxShadow: "none",
    },
  },
  content: {
    padding: "20px 30px", 
    maxHeight: "calc(80vh - 270px)",
    // maxHeight:"calc(80vh-270px)",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mapping: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    gap: "20px",
    height: "35px",
  },
  mappingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "42px",
    [theme.breakpoints.down("1641")]: {
      height: "36px",
    },
  },
  modal: {
    //   width:"400px"
  },
  assetName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  switches: {},
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  lastLine: {
    marginLeft: "30px",
    marginTop: "20px",
    fontSize: "13px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
}));

export default function Modal(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const [assets, setAssets] = React.useState([]);
  const [depositableAssets, setDepositableAssets] = React.useState([]);
  const [withdrawableAssets, setWithdrawableAssets] = React.useState([]);
  const [depositableAssetsId, setDepositableAssetsId] = React.useState([]);
  const [withdrawableAssetsId, setWithdrawableAssetsId] = React.useState([]);
  const [dbDepositableassets, setDbdepositableassets] = React.useState([]);
  const [dbWithdrawableAssets, setDbWithdrawableAssets] = React.useState([]);
  const [dependantAssets, setDependantAssets] = React.useState([]);
  const web3 = props.web3;
  const [value, setValue] = useState();

  useEffect(() => {
    axios.get(getAssetsURL()).then((data) => {
      if (data.data.length >= 1) {
        setAssets(data.data);
      } else {
        setAssets([]);
      }
      if (props.vaultAddress != "")
        axios.get(getAssetsPerVault(props.vaultAddress)).then((data) => {
          setDbdepositableassets(data.data.data.depositableAssets);
          setDbWithdrawableAssets(data.data.data.withdrawableAssets);
        });
    });
  }, []);

  useEffect(() => {
    if (dbDepositableassets != undefined && dbWithdrawableAssets != undefined) {
      setDepositableAssets(
        dbDepositableassets.map((assets) => {
          return assets.assetAddress;
        })
      );
      setWithdrawableAssets(
        dbWithdrawableAssets.map((assets) => {
          return assets.assetAddress;
        })
      );
      setDepositableAssetsId(
        dbDepositableassets.map((assets) => {
          return assets._id;
        })
      );
      setWithdrawableAssetsId(
        dbWithdrawableAssets.map((assets) => {
          return assets._id;
        })
      );
    }
  }, [dbDepositableassets, dbWithdrawableAssets]);

  useEffect(() => {
    var strategyDependantAssets = [];
    if (props.vaultAddress != "")
      axios
        .get(getStrategyAsset(), {
          params: {
            vaultAddress: props.vaultAddress,
          },
        })
        .then((data) => {
          data.data.data.forEach((data) => {
            data.enabledProtocols.forEach((data) => {
              data.supportedAssets.forEach((data) => {
                if (!strategyDependantAssets.includes(data.assetAddress)) {
                  strategyDependantAssets.push(data.assetAddress);
                }
              });
            });
          });
        });

    setDependantAssets(strategyDependantAssets);
  }, []);

  const handleSave = async () => {
    props.dispatch(TransactionInProgress(true));
    const account = await web3.eth.getAccounts();
    const getGnosisSafeInstanceAt = (safeAddress) => {
      return new web3.eth.Contract(Gnosis.abi, safeAddress);
    };
    const newSafe = await getGnosisSafeInstanceAt(props.vaultAddress);

    if (props.name == "Deposit") {
      var assetList = [];
      assets.forEach((asset) => assetList.push(asset.assetAddress));
      var disabledAssets = assetList.filter(
        (address) => !depositableAssets.includes(address)
      );
      try {
        const resultNew = await newSafe.methods
        .setVaultAssets(depositableAssets, [], disabledAssets, [])
        .encodeABI()
        
        const transactionDataNewer = {
          contractAddress: props.vaultAddress,
          txType: "SET_VAULT_ASSETS",
          signer: props.userAddress,
          txData: resultNew,
          nonce: 0,
          parameters: {
            _enabledDepositAsset: depositableAssets,
            _enabledWithdrawalAsset: [],
            _disabledDepositAsset:disabledAssets,
            _disabledWithdrawalAsset:[]
          },        };
        props.dispatch(WrapperFunction(transactionDataNewer,"POST"));
      } catch (err) {
        message.error(err["message"]);
        props.dispatch(TransactionInProgress(false));
      }
    } else {
      var assetList = [];
      assets.forEach((asset) => assetList.push(asset.assetAddress));
      var disabledAssets = assetList.filter(
        (address) => !withdrawableAssets.includes(address)
      );
      try {
        const result = await newSafe.methods
        .setVaultAssets([], withdrawableAssets, [], disabledAssets)
        .encodeABI()

        const transactionData = {
          contractAddress: props.vaultAddress,
          txType: "SET_VAULT_ASSETS",
          signer: props.userAddress,
          txData: result,
          nonce: 0,
          parameters: {
            _enabledDepositAsset: [],
            _enabledWithdrawalAsset: withdrawableAssets,
            _disabledDepositAsset:[],
            _disabledWithdrawalAsset:disabledAssets
          },      
         };
        props.dispatch(WrapperFunction(transactionData,"POST"));
      } catch (err) {
        message.error(err["message"]);
        props.dispatch(TransactionInProgress(false));
      }
    }
    dialog.close(value);
  };

  return (
    <div className={classes.modal}>
      <p className={classes.heading}>{props.heading}</p>
     
      {props.name == "Deposit" ? (
        <p className={classes.mainContent}>
          Multiple deposit tokens are available to the end-user. Any number of
          tokens (at least one) can be selected to be deposited into the vault.
          The deposit tokens also give permission to the vault to hold and
          deploy those tokens into different DeFi protocols. Once deposited, the
          tokens will automatically be converted into assets that are needed by
          the strategies.
        </p>
      ) : (
        <p className={classes.mainContent}>
          Multiple withdrawable assets provide convenience to the end-user as
          the vault will automatically manage the conversion of assets in the
          vault to tokens desired by the user. The deposit tokens also give
          permission to the vault to hold the tokens solely for the purpose of
          conversion and withdrawal.
        </p>
      )}

      <div className={classes.content}>
        <div className={classes.assetContainer}>
          {assets.length ? (
            assets.map((asset) => {
              return (
                <>
                  <div className={classes.mappingContainer}>
                    <div className={classes.mapping}>
                      <AvatarCharacter
                        image={getImage(asset.assetIcon)}
                        class="small20"
                      />
                      <p className={classes.assetName}>{asset.assetName}</p>
                    </div>
                    <div className={classes.switches}>
                      {props.name == "Deposit" && depositableAssets.length ? (
                        <Switches
                          value={depositableAssets.some(
                            (address) =>
                              asset.assetAddress.toUpperCase() ==
                              address.toUpperCase()
                          )}
                          depositableAssets={depositableAssets}
                          depositableAssetsId={depositableAssetsId}
                          name="Deposit"
                          setDepositableAssets={setDepositableAssets}
                          address={asset.assetAddress}
                          id={asset._id}
                          setDepositableAssetsId={setDepositableAssetsId}
                          from="change"
                          disabled={dependantAssets.some(
                            (address) =>
                              asset.assetAddress.toUpperCase() ==
                              address.toUpperCase()
                          )}
                          length={dependantAssets.length + 1}
                        ></Switches>
                      ) : props.name == "Withdraw" &&
                        withdrawableAssets.length ? (
                        <Switches
                          value={withdrawableAssets.some(
                            (address) =>
                              asset.assetAddress.toUpperCase() ==
                              address.toUpperCase()
                          )}
                          withdrawableAssets={withdrawableAssets}
                          withdrawableAssetsId={withdrawableAssetsId}
                          name="Withdraw"
                          setWithdrawableAssets={setWithdrawableAssets}
                          address={asset.assetAddress}
                          id={asset._id}
                          setWithdrawableAssetsId={setWithdrawableAssetsId}
                          from="change"
                          disabled={dependantAssets.some(
                            (address) =>
                              asset.assetAddress.toUpperCase() ==
                              address.toUpperCase()
                          )}
                          length={dependantAssets.length + 1}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Divider />
                </>
              );
            })
          ) : (
            <Skeleton
              style={{ marginRight: "20px", overflow: "hidden" }}
              variant="rect"
              width={420}
              height={100}
            />
          )}
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <p className={classes.lastLine}>{""}</p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          className={classes.button}
          disabled={
            props.apsManager?.toUpperCase() !== props.userAddress?.toUpperCase()
          }
        >
          Save
        </Button>
      </div>
      <Divider />
    </div>
  );
}
