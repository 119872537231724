import Web3 from "web3";
import { store } from "..";
import { getWeb3 } from "../redux/actions/getWeb3";
import { RPC_URL } from "./consts";
import { getBlockNumber } from "./getBlockNumber";

export const web3Config = () => {
  //Creates a local web3 instance in redux

  const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));
  store.dispatch(getWeb3(web3));
  getBlockNumber(web3);
};
