import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Style/style.css";

const StyledSidebar = (props) => {
  const { url, routes } = props;
  const history = useHistory();
  const location = useLocation();
  // handleRouteChange

  return (
    <>
      <div className="styled-sidebar-parent-style">
        <div className="styled-sidebar-parent-style-margin"></div>
        <div style={{ height: "2px", backgroundColor: "white" }}> </div>

        {routes.map((route) => {
          return (
            <React.Fragment key={route.name}>
              <button
                className={
                  location.pathname.endsWith(route.path)
                    ? "styled-sidebar-button-styling-selected"
                    : "styled-sidebar-button-styling"
                }
                onClick={() => history.push(`${url}${route.path}`)}
              >
                <p className="styled-sidebar-route-name">{route.name}</p>
              </button>
              <div style={{ height: "2px", backgroundColor: "white" }}> </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default StyledSidebar;
