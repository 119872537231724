
export const IsConnected = (isConnected) => async (dispatch) => {
   
    
    dispatch({
        type: "SET_ISCONNECTED",
        payload: {
            isConnected: isConnected
        }
      })
  };

  

