import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Input, Row } from "antd";
import SearchInput from "../../assets/icons/search.svg";
import {
  getGnosisSafeInstanceAt,
  getWhiteListInstance,
} from "../../components/Asset-Details/TableFunctions";
import { handleWalletSelection } from "../../components/Blocknative/walletSelectionConfig";
import { BasicCard, Core, EditAdmin, EditAsset } from "../../components/Card";
import {
  CollapseGrid,
  CollapseList,
  SearchItems,
} from "../../components/Collapse";
import WrapperFunction from "../../components/Global-Functions/WrapperFunction";
import { Settings } from "../../components/Modal";
import LogoLoader from "../../components/Akkrue-Official-Logo-Loader/LogoLoader";
import { TransactionType } from "../../constants/transactionType";
import { setVaultDetails as setVault } from "../../redux/actions/vaultDetails";
import {
  Assets,
  Deposit,
  Protocol,
  Vaults,
  WithDraw,
} from "../../services/api";
import { message } from "../../utils/message";
import "./Style/index.css";
import SupportCard from "./SupportCard";
function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userAddress = useSelector((state) => state.get_user_address.address);
  const web3 = useSelector((state) => state.get_web3.web3);
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const data = useSelector((state) => state.get_vault_details.vault);
  const adminData = data && data.vault;
  const [vaultIcon, setVaultIcon] = useState();
  const [edited, setEdited] = useState(false);
  const [manageVaultType, setManageVaultType] = useState();
  const [loader, setLoader] = useState(false);
  const [leftItems, setLeftItems] = useState({
    asset: [],
    vault: [],
    protocol: [],
    deposit: [],
    withdraw: [],
    fees: [],
  });
  const [supportItems, setSupportItems] = useState({
    deposit: [],
    withdraw: [],
  });
  const [assetItems, setAssetItems] = useState({
    base: [],
    vault: [],
    protocol: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const [coreItems, setCoreItems] = useState({ advisor: "", protocol: [] });
  const [listView, setListView] = useState(true);
  const [clearSettings, setClearSettings] = useState();
  const [complete, setComplete] = useState({ a: 0, b: 0, c: 0, d: 0 });
  const [formValues, setFormValues] = useState({
    vaultType: "PUBLIC",
    vaultCreationStatus: "VAULT_PROXY_CREATION",
    supportedProtocols: [],
    supportedAssets: [],
    supportedVaults: [],
    accountAddress: userAddress,
    profitType: adminData?.profitType,
    description: adminData?.description,
    longDescription: adminData?.longDescription,
  });
  const [autoComplete, setAutoComplete] = useState([]);
  const [search, setSearch] = useState("");
  const [isValidated, setIsValidated] = useState({
    assetCard: {
      base: "empty",
    },
    coreCard: {
      advisor: "empty",
      settings: "empty",
    },
    adminCard: {
      vaultName: "empty",
      tokenName: "empty",
      tokenSymbol: "empty",
      vaultIcon: "empty",
      emergencyVault: "empty",
      profitType: "empty",
    },
  });
  const [vaultDetails, setVaultDetails] = useState({});
  const [adminEditDisable, setAdminEditDisable] = useState(false);
    const thisVault = localStorage.getItem("newSafeAddress");

  const addItemsToCart = (items, dropResult) => {
    if (items.length && dropResult.target)
      switch (dropResult.target) {
        case "deposit":
          if (
            !supportItems.deposit.some(
              (item) => item[0]?.strategyAddress === items[0].strategyAddress
            )
          ) {
            setSupportItems({
              ...supportItems,
              deposit: [...supportItems.deposit, items],
              depositStrategy: items[0].strategyAddress,
            });
          }
          break;
        case "withdraw":
          if (
            !supportItems.withdraw.some(
              (item) => item[0]?.strategyAddress === items[0].strategyAddress
            )
          ) {
            setSupportItems({
              ...supportItems,
              withdraw: [...supportItems.withdraw, items],
              withdrawStrategy: items[0].strategyAddress,
            });
          }
          break;

        case "base":
          if (
            !assetItems.base.some(
              (item) => item[0]?.assetAddress === items[0].assetAddress
            )
          ) {
            setIsValidated({
              ...isValidated,
              assetCard: {
                base: "valid",
              },
            });
            setComplete({ a: 0, b: 1, c: 0, d: 0 });
            setAssetItems({ ...assetItems, base: [...assetItems.base, items] });
            setFormValues({
              ...formValues,
              supportedAssets: [
                ...formValues.supportedAssets,
                items[0].assetAddress,
              ],
            });
          }
          break;
        case "vault":
          if (
            !assetItems.vault.some(
              (item) => item[0]?.vaultAddress === items[0].vaultAddress
            )
          ) {
            setComplete({ a: 0, b: 1, c: 0, d: 0 });
            setAssetItems({
              ...assetItems,
              vault: [...assetItems.vault, items],
            });
            setFormValues({
              ...formValues,
              supportedVaults: [
                ...formValues.supportedVaults,
                items[0].vaultAddress,
              ],
            });
          }
          break;
        case "protocol":
          if (
            !assetItems.protocol.some(
              (item) => item[0]?.protocolAddress === items[0].protocolAddress
            )
          ) {
            setComplete({ a: 0, b: 1, c: 0, d: 0 });
            setAssetItems({
              ...assetItems,
              protocol: [...assetItems.protocol, items],
            });
          }
          break;

        default:
          console.log("not in target");
      }
  };

  const removeItemsFromCart = (address, type) => {
    if (type) {
      switch (type) {
        case "deposit":
          !supportItems.deposit.filter(
            (item) => item[0].strategyAddress !== address
          ).length &&
            setSupportItems({
              ...supportItems,
              deposit: supportItems.deposit.filter(
                (item) => item[0].strategyAddress !== address
              ),
              depositStrategy: "",
            });
          break;
        case "withdraw":
          !supportItems.withdraw.filter(
            (item) => item[0].strategyAddress !== address
          ).length &&
            setSupportItems({
              ...supportItems,
              withdraw: supportItems.withdraw.filter(
                (item) => item[0].strategyAddress !== address
              ),
              withdrawStrategy: "",
            });

          break;
        case "asset":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          !assetItems.base.filter((item) => item[0]?.assetAddress !== address)
            .length &&
            setIsValidated({
              ...isValidated,
              assetCard: {
                base: "invalid",
              },
            });
          setAssetItems({
            ...assetItems,
            base: assetItems.base.filter(
              (item) => item[0]?.assetAddress !== address
            ),
          });
          setFormValues({
            ...formValues,
            supportedAssets: formValues.supportedAssets.filter(
              (item) => item[0]?.assetAddress !== address
            ),
          });
          break;
        case "vault":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          setAssetItems({
            ...assetItems,
            vault: assetItems.vault.filter(
              (item) => item[0]?.vaultAddress !== address
            ),
          });
          break;
        case "protocol":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          setAssetItems({
            ...assetItems,
            protocol: assetItems.protocol.filter(
              (item) => item[0]?.protocolAddress !== address
            ),
          });
          break;
        default:
          console.log("not able to remove");
      }
    }
  };

  const handleInputChange = (data) => {
    // console.log("data from input", data);
    // on change of these options clear formValues

    if (data.id === "advisor") {
      setClearSettings(!clearSettings);
      setIsValidated({
        ...isValidated,
        coreCard: {
          advisor: "empty",
        },
      });
    }
    switch (data.id) {
      case "vaultName":
        // validation check
        // data.value
        //   ? setIsValidated({
        //       ...isValidated,
        //       adminCard: {
        //         ...isValidated.adminCard,
        //         vaultName: "valid",
        //       },
        //     })
        //   : setIsValidated({
        //       ...isValidated,
        //       adminCard: {
        //         ...isValidated.adminCard,
        //         vaultName: "invalid",
        //       },
        //     });
        setFormValues({
          ...formValues,
          vaultName: data.value,
        });
        break;
      case "tokenName":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenName: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenName: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          tokenName: data.value,
        });
        break;
      case "tokenSymbol":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenSymbol: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenSymbol: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          tokenSymbol: data.value,
        });
        break;
      case "advisor":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              coreCard: {
                ...isValidated.coreCard,
                advisor: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              coreCard: {
                ...isValidated.coreCard,
                advisor: "invalid",
              },
            });

        setCoreItems({ ...coreItems, advisor: data.value ? data : "" });
        break;
      case "settings":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              coreCard: {
                ...isValidated.coreCard,
                settings: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              coreCard: {
                ...isValidated.coreCard,
                settings: "invalid",
              },
            });
        break;
      case "vaultIcon":
        setVaultIcon(data.value);
        // setFormValues({
        //   ...formValues,
        //   vaultIcon: data.value,
        // });
        break;
      case "emergencyVault":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                emergencyVault: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                emergencyVault: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          accountAddress: userAddress,
          emergencyVaultAddress: data.value,
        });
        break;
      case "vaultAdmin":
        setFormValues({
          ...formValues,
          vaultAdmin: data.value,
        });
        break;
      case "visible":
        setFormValues({
          ...formValues,
          isVisible: data.value,
        });
        break;
      case "vaultType":
        if (data.value)
          setFormValues({
            ...formValues,
            vaultType: "PRIVATE",
          });
        else
          setFormValues({
            ...formValues,
            vaultType: "PUBLIC",
          });
        break;
      case "profitType":
        data?.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                profitType: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                profitType: "invalid",
              },
            });
        if (data?.value)
          setFormValues({
            ...formValues,
            profitType: data?.value,
          });

        break;
      case "description":
        setFormValues({
          ...formValues,
          description: data.value,
        });
        break;
      case "longDescription":
        setFormValues({
          ...formValues,
          longDescription: data.value,
        });
        break;
      default:
        break;
    }
  };

  const handleAdvisor = (values) => {
    setFormValues({
      ...formValues,
      advisorEndPoint: values.advisorEndPoint,
      vaultAdvisorSetting: values.vaultAdvisorSetting,
      supportedProtocols: values.supportedProtocols,
      advisorEmail: values.advisorEmail,
      advisorId: coreItems.advisor.value,
    });
    if (values?.advisorEmail)
      handleInputChange({ id: "settings", value: values });
  };

  async function getAssets() {
    setLoader(true);
    const depositResponse = await Deposit.getAllStrategy();
    let deposit = depositResponse.data.data;
    const withdrawResponse = await WithDraw.getAllStrategy();
    let withdraw = withdrawResponse.data.data;
    const assetResponse = await Assets.getAllAssets();
    let asset = assetResponse.data.data;
    const vaultResponse = await Vaults.getVaultByAdmin(userAddress);
    let vault = vaultResponse.data?.data?.data;
    const protocolResponse = await Protocol.getAllProtocols();
    let protocol = protocolResponse.data.data;
    setLeftItems({ asset, vault, protocol, deposit, withdraw });
    loadValues()
      .then((response) => {
        dispatch(setVault(response.data.data));
        setVaultDetails(response.data.data);
        let base = response.data.data.assets,
          protocol = response.data.data.protocols,
          vault = response.data.data.supportedVaults;
        let obj = assetItems;
        if (base.length) {
          base.map((value) => {
            obj = { ...obj, base: [...obj.base, [value]] };
          });
        }
        if (protocol.length) {
          protocol.map((value) => {
            obj = { ...obj, protocol: [...obj.protocol, [value]] };
          });
        }
        if (vault !== null && vault.length) {
          vault.map((value) => {
            obj = { ...obj, vault: [...obj.vault, [value]] };
          });
        }
        setAssetItems(obj);
        setLoader(false);
      })
      .catch((err) => {
        // console.log("err", err);
        setLoader(false);
      });
    setFormValues({
      ...formValues,
      vaultName: adminData?.vaultName,
      emergencyVaultAddress: adminData?.emergencyVaultAddress,
      vaultAdmin: adminData?.vaultAdmin,
      isVisible: adminData?.isVisible ?? adminData?.visible,
      description: adminData?.description,
      longDescription: adminData?.longDescription,
    });
  }

  const loadValues = () => {
    return Vaults.getSafeDetails(thisVault);
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const searchAll = (data) => {
    setAutoComplete([]);
    Vaults.searchItems(data)
      .then((response) => {
        if (response.data.data.Asset)
          setSearch({ filter: response.data.data.Asset, type: "asset" });
        else if (response.data.data.Vault)
          setSearch({ filter: response.data.data.Vault, type: "vault" });
        else if (response.data.data.Protocol)
          setSearch({ filter: response.data.data.Protocol, type: "protocol" });
      })
      .catch((e) => console.log("Search error", e));
  };

  const autocomplete = (data) => {
    setSearchValue(data);
    if (data === "") {
      setAutoComplete([]);
      setSearch("");
    } else
      Vaults.autoCompleteDetails(data)
        .then((response) => {
          // console.log("autocomplete response", response);
          setAutoComplete(response.data.data);
        })
        .catch((e) => {
          setAutoComplete([]);
          // console.log("autocomplete error", e);
        });
  };

  const handleSearch = useCallback(
    debounce((e) => autocomplete(e), 1000),
    []
  );
  const updateEmergencyVaultAddress = async () => {
    let emergencyAddressTransactionData;
    try {
      const GnosisReturned = await getGnosisSafeInstanceAt(vaultAddress, web3);

      //Change the emergency vault address
      const emergencyAddressEncodedData = await GnosisReturned.methods
        .changeEmergencyVault(formValues.emergencyVaultAddress)
        .encodeABI();

      emergencyAddressTransactionData = {
        contractAddress: vaultAddress,
        txType: TransactionType.CHANGE_EMERGENCY_VAULT,
        signer: userAddress,
        txData: emergencyAddressEncodedData,
        parameters: {
          emergencyVaultAddress: formValues.emergencyVaultAddress,
          vaultId: adminData.id,
        },
        editCard: "admin",
      };
      dispatch(WrapperFunction(emergencyAddressTransactionData, "POST"));
    } catch (error) {
      console.log(error);
    }
  };
  const updateVaultAdmin = async () => {
    let adminAddressTransactionData;
    try {
      const GnosisReturned = await getGnosisSafeInstanceAt(vaultAddress, web3);
      const adminChangeEncodedData = await GnosisReturned.methods
        .changeVaultAdmin(formValues.vaultAdmin)
        .encodeABI();
      adminAddressTransactionData = {
        contractAddress: vaultAddress,
        txType: TransactionType.CHANGE_VAULT_ADMIN,
        signer: userAddress,
        txData: adminChangeEncodedData,
        parameters: {
          vaultId: adminData.id,
          currentAdminAddress: adminData.vaultAdmin,
          newAdminAddress: formValues.vaultAdmin,
        },
        editCard: "admin",
      };
      dispatch(WrapperFunction(adminAddressTransactionData, "POST"));
    } catch (error) {
      console.log(error);
    }
  };

  const createWhitelistGroup = async () => {
    let whitelistGroupCreationTransactionData;
    try {
      //Whitelist group creation
      const WhitelistInstance = await getWhiteListInstance(web3);
      const whitelistGroupCreationEncodedData = await WhitelistInstance.methods
        .createGroup(adminData.vaultAdmin)
        .encodeABI();
      whitelistGroupCreationTransactionData = {
        contractAddress: process.env.REACT_APP_NODE_WHITELIST_ADDRESS,
        txType: TransactionType.CREATE_WHITELIST_GROUP,
        signer: userAddress,
        txData: whitelistGroupCreationEncodedData,
        parameters: {},
      };
      dispatch(WrapperFunction(whitelistGroupCreationTransactionData, "POST"));
    } catch (error) {
      console.log(error);
    }
  };

  const addMembersToGroup = async (groupId, whitelistMembers) => {
    try {
      let addWhitelistMembersTransactionData;
      const WhitelistInstance = await getWhiteListInstance(web3);
      const addWhitelistMembersEncodedData = await WhitelistInstance.methods
        .addMembersToGroup(groupId, whitelistMembers)
        .encodeABI();
      addWhitelistMembersTransactionData = {
        contractAddress: process.env.REACT_APP_NODE_WHITELIST_ADDRESS,
        txType: TransactionType.ADD_MEMBERS_TO_GROUP,
        signer: userAddress,
        txData: addWhitelistMembersEncodedData,
        parameters: {},
      };
      dispatch(WrapperFunction(addWhitelistMembersTransactionData, "POST"));
    } catch (error) {
      console.log(error);
    }
  };

  const addGroupToVault = async (userList, admin, groupName, groupId) => {
    try {
      let addWhitelistGroupToVaultTransactionData;
      const GnosisReturned = await getGnosisSafeInstanceAt(
        vaultAddress.vaultAddress,
        web3
      );
      //Add whitelist group to the vault
      const addWhiteListGroupsToVaultEncodedData = await GnosisReturned.methods
        .addWhiteListGroups(groupId)
        .encodeABI();
      addWhitelistGroupToVaultTransactionData = {
        contractAddress: vaultAddress.vaultAddress,
        txType: TransactionType.ADD_GROUP_TO_VAULT,
        signer: userAddress,
        txData: addWhiteListGroupsToVaultEncodedData,
        parameters: {
          userList,
          admin,
          groupName,
          groupId,
          private: true,
        },
      };
      dispatch(
        WrapperFunction(addWhitelistGroupToVaultTransactionData, "POST")
      );
    } catch (error) {
      console.log(error);
    }
  };

  const removeWhitelistGroupFromVault = async (groupId) => {
    try {
      let removeWhitelistGroupFromVaultTransactionData;
      const GnosisReturned = await getGnosisSafeInstanceAt(
        vaultAddress.vaultAddress,
        web3
      );
      //Remove whitelist group from the vault
      const removeWhitelistGroupFromVaultEncodedData =
        await GnosisReturned.methods.addWhiteListGroups(groupId).encodeABI();
      removeWhitelistGroupFromVaultTransactionData = {
        contractAddress: vaultAddress.vaultAddress,
        txType: TransactionType.REMOVE_GROUP_FROM_VAULT,
        signer: userAddress,
        txData: removeWhitelistGroupFromVaultEncodedData,
        parameters: {
          vaultAddress: vaultAddress.vaultAddress,
          accountAddress: userAddress,
        },
      };
      dispatch(
        WrapperFunction(removeWhitelistGroupFromVaultTransactionData, "POST")
      );
    } catch (error) {
      console.log(error);
    }
  };
  const adminEditHandler = () => {
    if (
      formValues?.vaultName === adminData?.vaultName &&
      formValues?.isVisible == adminData?.isVisible &&
      formValues?.emergencyVaultAddress?.toLowerCase() ==
        adminData?.emergencyVaultAddress?.toLowerCase() &&
      formValues?.vaultAdmin?.toLowerCase() ==
        adminData?.vaultAdmin?.toLowerCase() &&
      formValues?.profitType === adminData?.profitType &&
      formValues?.description === adminData?.description &&
      formValues?.longDescription === adminData?.longDescription 
    )
      setAdminEditDisable(true);
    else setAdminEditDisable(false);
  };

  useEffect(() => {
    adminEditHandler();
    // console.log('formValues?.longDescription', formValues?.longDescription)
  }, [
    formValues?.vaultName,
    formValues?.isVisible,
    formValues?.emergencyVaultAddress,
    formValues?.vaultAdmin,
    formValues?.profitType,
    formValues?.description,
    formValues?.longDescription
  ]);

  const handleAdminSave = (e) => {
    e.preventDefault();
    if (formValues.vaultName !== adminData.vaultName) {
      Vaults.updateVaultName(adminData.id, formValues.vaultName)
        .then((name) => {
          if (name.data.status) {
            message.success(name.data.message);
            dispatch(
              setVault({
                ...data,
                vault: { ...adminData, vaultName: formValues.vaultName },
              })
            );
          } else message.error(name.data.message);
        })
        .catch((err) => console.log(err));
    }
    if (
      formValues.isVisible !== adminData.isVisible &&
      formValues.isVisible != undefined
    ) {
      Vaults.updateVaultVisibility(adminData.vaultAddress, formValues.isVisible)
        .then((visible) => {
          if (visible.data.status) {
            message.success(visible.data.message);
            dispatch(
              setVault({
                ...data,
                vault: { ...adminData, isVisible: formValues.isVisible },
              })
            );
          } else message.error(visible.data.message);
        })
        .catch((err) => console.log(err));
    }
    if (formValues.emergencyVaultAddress !== adminData.emergencyVaultAddress) {
      try {
        web3.utils.toChecksumAddress(formValues.emergencyVaultAddress);

        updateEmergencyVaultAddress();
      } catch (err) {
        setIsValidated({
          ...isValidated,
          adminCard: {
            ...isValidated.adminCard,
            emergencyVault: "invalid-address",
          },
        });
      }
    }
    if (
      formValues.vaultAdmin !== adminData.vaultAdmin &&
      adminData.vaultAdmin == userAddress
    ) {
      try {
        web3.utils.toChecksumAddress(formValues.vaultAdmin);
        updateVaultAdmin();
      } catch (err) {
        setIsValidated({
          ...isValidated,
          adminCard: {
            ...isValidated.adminCard,
            accountAddress: "invalid-address",
          },
        });
      }
    }

    if (
      formValues.profitType !== adminData.profitType &&
      formValues.profitType
    ) {
      Vaults.updateVaultType(adminData.vaultAddress, formValues.profitType)
        .then((profitType) => {
          if (profitType.data.status) {
            message.success(profitType.data.message);
            dispatch(
              setVault({
                ...data,
                vault: { ...adminData, profitType: formValues.profitType },
              })
            );
          } else message.error(profitType.data.message);
        })
        .catch((err) => console.log(err));
    }
    if (
      formValues.description !== adminData.description ||
      formValues.longDescription !== adminData.longDescription
    ) {
      Vaults.updateVaultDescription(
        adminData.vaultAddress,
        formValues.description,
        formValues.longDescription
      )
        .then((description) => {
          if (description?.data?.status) {
            message.success(description?.data?.message);
            dispatch(
              setVault({
                ...data,
                vault: {
                  ...adminData,
                  description: formValues.description,
                  longDescription: formValues.longDescription,
                },
              })
            );
          } else message.error(description?.data?.message);
        })
        .catch((descriptionError) => console.log({ descriptionError }));
    }
  };

  const handleAdminDiscard = (e) => {
    e.preventDefault();
    document.getElementById("myForm").reset();
    setFormValues({
      ...formValues,
      vaultName: adminData?.vaultName,
      isVisible: adminData?.isVisible,
      emergencyVaultAddress: adminData?.emergencyVaultAddress,
      vaultAdmin: adminData?.vaultAdmin,
      profitType: adminData?.profitType,
      description: adminData?.description,
      longDescription:adminData?.longDescription
    });
  };

  const walletSelectionCheck = async () => {
    const connect = await handleWalletSelection(null);
    if (!connect) history.push("/");
  };

  const getCurrentAdvisor = () => {
    Vaults.getVaultDetails(vaultAddress)
      .then((res) => {
        if (
          res.data.data &&
          res.data.status == 1 &&
          res.data.data.length != 0
        ) {
          let vaultData = res.data.data.vault;
          let obj = { name: vaultData.advisorId };
          let setting = {
            advisorEndPoint: vaultData?.advisorEndPoint,
            vaultAdvisorSetting: vaultData?.vaultAdvisorSetting,
            supportedProtocols: vaultData?.supportedProtocols,
            advisorEmail: vaultData?.advisorEmail,
          };
          obj = { ...obj, ...setting };
          setManageVaultType(obj);
          if (obj) {
            handleAdvisor({ vaultAdvisorSetting: obj });
          }
          setIsValidated({
            ...isValidated,
            coreCard: {
              advisor: "valid",
            },
          });
          setCoreItems({
            ...coreItems,
            advisor: obj?.name
              ? {
                  id: "advisor",
                  value: obj?.name,
                }
              : "",
          });
        }
      })
      .catch(console.log);
  };

  const coreCardSaveChanges = () => {
    let data = {
      advisorEndPoint: formValues?.advisorEndPoint,
      vaultAdvisorSetting: formValues?.vaultAdvisorSetting,
      supportedProtocols: formValues?.supportedProtocols,
      advisorEmail: formValues?.advisorEmail,
    };
    if (isValidated?.coreCard?.advisor === "valid") {
      Vaults.updateAdvisorConfig(
        vaultAddress,
        coreItems?.advisor?.value,
        data
      ).then((res) => {
        if (res.status === 200) {
          message.success("Updated advisor settings");
          getCurrentAdvisor();
        }
      });
    }
  };

  const coreCardDiscardChanges = () => {
    handleAdvisor({ vaultAdvisorSetting: manageVaultType });
    manageVaultType?.name
      ? setIsValidated({
          ...isValidated,
          coreCard: {
            ...isValidated.coreCard,
            advisor: "valid",
          },
        })
      : setIsValidated({
          ...isValidated,
          coreCard: {
            ...isValidated.coreCard,
            advisor: "invalid",
          },
        });

    setCoreItems({
      ...coreItems,
      advisor: { id: "advisor", value: manageVaultType?.name },
    });
  };

  useEffect(() => {
    if (!userAddress) walletSelectionCheck();
    try {
      getAssets();
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (adminData) {
      const { withdrawalStrategy, depositStrategy } = adminData;
      // to update deposit field
      const depositStrategyAddress =
        depositStrategy &&
        leftItems.deposit.length &&
        leftItems.deposit.find(
          (item) => item.strategyAddress === depositStrategy
        );
      const deposit = depositStrategyAddress
        ? [[{ ...depositStrategyAddress, type: "deposit" }]]
        : [];
      // to update withdraw field
      const withdrawStrategyAddress =
        withdrawalStrategy &&
        leftItems.withdraw.length &&
        leftItems.withdraw.find(
          (item) => item.strategyAddress === withdrawalStrategy
        );
      const withdraw = withdrawStrategyAddress
        ? [[{ ...withdrawStrategyAddress, type: "withdraw" }]]
        : [];
      setSupportItems((supportItems) => ({
        ...supportItems,
        deposit,
        withdraw,
        timeLock: adminData.isTimeLocked,
        noOfDays: adminData.noOfDaysForLockedWithdraw,
      }));
    }
  }, [leftItems, adminData]);

  useEffect(() => {
    if (userAddress)
      setFormValues({ ...formValues, accountAddress: userAddress });
  }, [userAddress]);

  useEffect(() => {
    if (vaultAddress) {
      getCurrentAdvisor();
    }
  }, [vaultAddress]);

  return (
    <div className="edit-page">
      {loader ? (
        <div className="wrapper">
          <LogoLoader />
        </div>
      ) : (
        <div className="main-items">
          <div>
            <div className="left-block-wrap">
              <div className="toggle-view">
                {/* <button
                className={listView ? "grid" : "list"}
                onClick={() => setListView(false)}
              >
                Icon View
              </button> */}
                <button
                  className={listView ? "list" : "grid"}
                  onClick={() => setListView(true)}
                >
                  List View
                </button>
              </div>
              <div className="search">
                <h4>Drag and Drop to configure your vault</h4>
                <Input.Search
                  placeholder="Search here"
                  className="search-input"
                  prefix={<img src={SearchInput} alt="" />}
                  onChange={(e) => handleSearch(e.target.value)}
                  allowClear={true}
                  enterButton={""}
                />
                {/* {autoComplete ? (
                <ul className="autocomplete-data">
                  {autoComplete?.map((v) => (
                    <li key={v} onClick={() => searchAll(v)}>
                      <span>{v}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )} */}
              </div>
              <div>
                {leftItems ? (
                  listView ? (
                    <CollapseList
                      id="left"
                      fields={leftItems}
                      addItemsToCart={addItemsToCart}
                      searchValue={searchValue}
                    />
                  ) : (
                    <CollapseGrid
                      id="left"
                      fields={leftItems}
                      addItemsToCart={addItemsToCart}
                      searchValue={searchValue}
                    />
                  )
                ) : (
                  ""
                )}
                {search ? (
                  <SearchItems
                    id="left"
                    fields={search}
                    addItemsToCart={addItemsToCart}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="right-block-wrap">
            <div className="card-wrap">
              <BasicCard
                color="darkgreen"
                title="Support"
                id="support"
                active={complete.a}
                subtext="The Support section determines the basic functionality of the Vault. Select a deposit/withdrawal
                strategy and set the fees for the vault."
                tick={complete.a}
                onFocus={() => setComplete({ a: 1, b: 0, c: 0, d: 0 })}
              >
                <SupportCard
                  removeItemsFromCart={removeItemsFromCart}
                  supportItems={supportItems}
                  depositStrategy={adminData && adminData.depositStrategy}
                  withdrawalStrategy={adminData && adminData.withdrawalStrategy}
                  managementFeeDetails={
                    adminData && adminData.managementFeeDetails
                  }
                  draggableItems={{
                    deposit: leftItems.deposit,
                    withdraw: leftItems.withdraw,
                  }}
                  reloadValues={loadValues}
                />
              </BasicCard>
              <BasicCard
                color="lightgreen"
                title="Assets"
                id="assets"
                subtext="The Asset section determines which assets, protocols or other yieldcube vaults are allowed in the vault.
                You can only deposit or withdraw assets that are explicitly allowed in the vault. To add assets, protocols
                and vaults simply drag and drop from the selection on the left."
                active={complete.b}
                tick={complete.b}
              >
                <EditAsset
                  fields={assetItems}
                  vault={vaultDetails}
                  web3={web3}
                  removeItemsFromCart={removeItemsFromCart}
                  isValidated={isValidated}
                  setAssetItems={setAssetItems}
                  setVaultDetails={setVaultDetails}
                  reloadValues={loadValues}
                  setIsValidated={setIsValidated}
                />
              </BasicCard>
              <BasicCard
                color="darkgreen"
                title="Core"
                id="core"
                subtext="The Core section determines how your assets are invested. Choose an advisor and complete the
                settings. Advisors are automated strategies running against your assets."
                active={complete.c}
                tick={complete.c}
                onFocus={() => setComplete({ a: 0, b: 0, c: 1, d: 0 })}
              >
                {/* <Core
                          id="protocol"
                          fields={coreItems.protocol}
                          accept="protocol"
                          text="Protocol"
                        /> */}
                <Row justify="space-evenly" gutter={5} span={24}>
                  <Col span={12}>
                    <Core
                      id="advisor"
                      enabled={coreItems.protocol.length ? false : false}
                      fields={coreItems.advisor}
                      accept="text"
                      setField={(item) => handleInputChange(item)}
                      text="Advisor"
                      isValidated={isValidated}
                      setEdited={setEdited}
                    />
                  </Col>
                  <Col span={12} className="content-items">
                    <Settings
                      enabled={coreItems.advisor === ""}
                      title={coreItems.advisor.value}
                      protocol={coreItems.protocol}
                      protocolsList={assetItems?.protocol?.flat(1)}
                      baseAssetsList={assetItems?.base?.flat(1)}
                      vaultsList={assetItems?.vault.flat(1)}
                      setAdvisor={handleAdvisor}
                      formValues={formValues}
                      clearSettings={clearSettings}
                      manageVaultType={manageVaultType}
                      edited={edited}
                      setEdited={setEdited}
                      setField={(item) => handleInputChange(item)}
                      harvestProtocols={leftItems?.protocol?.filter((val) => {
                        if (val.protocolName.toLowerCase().includes("curve.fi")) {
                          return true;
                        } else return false;
                      })}
                    />
                    {isValidated.coreCard.settings === "invalid" ? (
                      <span className="validation-required">
                        *Fill the advisory settings{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row justify="end" span={14}>
                  <Col className="save-discard">
                    <button
                      className="discard"
                      onClick={() => {
                        coreCardDiscardChanges();
                      }}
                    >
                      Discard
                    </button>
                    <button
                      className={
                        !edited || !(isValidated.coreCard.settings === "valid")
                          ? "not-updated"
                          : "save"
                      }
                      disabled={
                        !edited || !(isValidated.coreCard.settings === "valid")
                      }
                      onClick={() => {
                        coreCardSaveChanges();
                      }}
                    >
                      Save Changes
                    </button>
                  </Col>
                </Row>
              </BasicCard>
              <BasicCard
                color="green"
                title="Admin"
                id="admin"
                active={complete.d}
                subtext=" Enter the Vault name, token name, token symbol, wallet address, emergency vault address, and choose the vault icon"
                tick={complete.d}
                onFocus={() => setComplete({ a: 0, b: 0, c: 0, d: 1 })}
              >
                <form id="myForm">
                  <EditAdmin
                    setField={(item) => handleInputChange(item)}
                    handleValidation={setIsValidated}
                    adminDiscard={adminEditDisable}
                    data={adminData}
                    formValues={formValues}
                  />
                  <Row justify="end" span={12}>
                    <Col className="save-discard">
                      <button
                        className="discard"
                        onClick={(e) => handleAdminDiscard(e)}
                        disabled={adminEditDisable}
                      >
                        Discard
                      </button>
                      <button
                        className="save"
                        onClick={(e) => handleAdminSave(e)}
                        disabled={adminEditDisable}
                      >
                        Save Changes
                      </button>
                    </Col>
                  </Row>
                </form>
              </BasicCard>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Edit);
