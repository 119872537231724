const initState = {
    toggleVariable:"",
  
};

const toggleStateReducer = (state=initState,action) => {
    switch(action.type) {
        case "TOGGLE_CURRENT_STATE":
            return { 
                ...state,
                toggleVariable: action.payload.toggleVariable,
            }

        default: 
            return state;
    }
}

export default toggleStateReducer;