const initState = {
    address: null,
  
};

const setAddressReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_ADDRESS":
            return { 
                ...state,
                address: action.payload.address,
            }

        default: 
            return state;
    }
}

export default setAddressReducer;