import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getWhiteListInstanceAt } from "../../../../Asset-Details/TableFunctions";
import { TransactionInProgress } from "../../../../../redux/actions/reload-state";
import WrapperFunction from '../../../../Global-Functions/WrapperFunction'

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down('1641')]:{
        
      fontSize: "14px",
    },
  },
  button: {
    margin: "0px 30px 15px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#DEE5EF",
    color: "#1B3E69",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      background: "#DEE5EF",
      color: "#1B3E69",
      boxShadow: "none",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
  },
  button1: {
    margin: "0px 23px 15px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#C42121",
    color: "white",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#C42121",
    },
  },
  textField1: {
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    margin: "0px 30px 10px 30px ",
  },
  textField: {
    margin: "0px 30px 10px 30px ",
  },
  contentAddress: {
    fontSize: "14px",
    // fontWeight: "bold",
    marginTop: "10px",
    // color: "#1B3E69",
    marginBottom: "5px",
    [theme.breakpoints.down('1641')]:{
        
      fontSize: "12px",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "420px",
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
}));

export default function ChangeManagerModal(props) {
  const { whitelistId, dispatch, WhitelistAdminAddress,abi } = props;
  const classes = useStyles();
  const dialog = useDialog();
  const [value, setValue] = useState();
  const web3 = props.web3;

  const handleManagerChangeSubmitButton =  () => {
  
    try {
      const promise = new Promise(async(resolve,reject)=>{
        const networkId = await web3.eth.net.getId();
        const account = await web3.eth.getAccounts();
        const whitelistInstance = await getWhiteListInstanceAt(web3, networkId);
        const encodedWhitelistRemoval = await whitelistInstance.methods
        .removeWhitelistAdmin(whitelistId, WhitelistAdminAddress)
        .encodeABI();
        if(encodedWhitelistRemoval){
          resolve({encodedWhitelistRemoval,account})
        }
        else{
          reject("Exited removal whitelist code encoder")
        }
      })
    
      promise.then((s)=>{
       return {
          contractAddress:abi.WhitelistAddress,
          txType:"REMOVE_WHITELIST_ADMIN",
          signer:s.account[0],
          txData:s.encodedWhitelistRemoval,
          nonce:0,
          parameters:{_groupId:whitelistId,_whitelistGroupAdmin:WhitelistAdminAddress},
        }
      }).then((res)=>{
        dialog.close(value)
        dispatch(WrapperFunction(res,"POST"))
      }).catch((err)=>{console.log("Error encountered",err)})
    } catch (err) {
      dispatch(TransactionInProgress(false));
    }
  };

  return (
    <React.Fragment>
      <div style={{ height: "0px" }}>
        <p className={classes.heading}>DELETE WHITELIST ADMIN</p>
        <div className={classes.textField1}>
          <p className={classes.contentAddress}>
            Confirm to delete whitelist admin address
          </p>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            dialog.close();
          }}
          className={classes.button}
        >
          EXIT
        </Button>
        {/* <SimpleSelect topic={props.name}/> */}
        <Button
          variant="contained"
          // disabled={!(whitelistId && changeManagerAddress)}
          onClick={() => {
            // Сlose the dialog and return the value
            handleManagerChangeSubmitButton();
            dialog.close(value);
          }}
          className={classes.button1}
        >
          CONFIRM
        </Button>
      </div>
      {/* <LoaderAnimation loading={progress} /> */}
    </React.Fragment>
  );
}
