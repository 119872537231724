import React from "react";
import OfficialLogo from "../../../assets/yc-logo-wide-white.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    mainContainer: {
      backgroundColor: "#F8FAFD",
      padding: "30px",
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height:"100vh",
    },
   
      
   
    connectWallet: {
      fontFamily: "'Catamaran', sans-serif",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#1B3E69",
      [theme.breakpoints.down('1641')]:{
        fontSize: "20px",
      },
      [theme.breakpoints.down('1441')]:{
        fontSize: "20px",
      },
    },
    imageContainer: {
      width: "136px",
      height: "72.55px",
      margin: "40px auto 5px auto",
      [theme.breakpoints.down('1641')]:{
        width:"120px",
        height:"62.55px",
      },
      [theme.breakpoints.down('1441')]:{
        width:"110px",
      height:"56.55px",
      },
      [theme.breakpoints.down('761')]:{
        width:"100px",
        height:"50.55px",
        marginBottom:"5px",
        marginTop:"40px",
       },
    },
    logoHeadingFont: {
      fontFamily: "'Catamaran', sans-serif",
      fontSize: "48px",
      color: "#1B3E69",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.down(1641)]:{
        fontSize:"44px",
        marginTop:"0px",
      },
      [theme.breakpoints.down(1441)]:{
        fontSize:"44px",
        marginTop:"0px",
      },
      [theme.breakpoints.down('761')]:{
          marginTop:"0px",
        fontSize:"26px",
       },
    },
    headingFont: {
      fontFamily: "'Catamaran', sans-serif",
      fontSize: "48px",
      color: "#1B3E69",
      margin: "auto",
      textAlign: "center",
      [theme.breakpoints.down(1641)]:{
        fontSize:"44px",
      },
      [theme.breakpoints.down(1441)]:{
        fontSize:"44px",
      },
      [theme.breakpoints.down('761')]:{
          marginTop:"80px",
        fontSize:"26px",
       },
    },
    headingFontBold: {
      fontWeight: "bold",
  
    },
    contentFont: {
      fontSize: "16px",
      margin: "0px auto 50px auto",
      
      [theme.breakpoints.down('1641')]:{
        fontSize:"14px",
      },
      [theme.breakpoints.down('1441')]:{
        fontSize:"13px",
      },
      [theme.breakpoints.down('761')]:{
          marginTop:"10px",
          textAlign:"center",
          fontSize:"12px",
      },
    }
   
   
  
  }));

export default function MobileView() {
    const classes = useStyles();
  return (
    <>
      <div className={classes.mainContainer}>
        <img className={classes.imageContainer} src={OfficialLogo} />
        <h3 className={classes.logoHeadingFont}>YieldCube</h3>
        <p className={classes.headingFont}>
          Welcome to <span className={classes.headingFontBold}>YieldCube</span>
          <p className={classes.contentFont}>
            We don't support mobile at the moment. Mobile version is coming
            soon.
          </p>
        </p>
        {/* <div style={{ visibility: "hidden" }}>
          <img className={classes.imageContainer} src={OfficialLogo} />
          <h3 className={classes.logoHeadingFont}>Yieldcube</h3>
        </div> */}
      </div>
    </>
  );
}
