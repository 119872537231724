
export const connectedNetwork = (network) => async (dispatch) => {
   
    
    dispatch({
        type: "SET_NETWORK",
        payload: {
          network: network
        }
      })
  };

  

