export const state1 = {
  formVaultName: "",
  formTokenName: "",
  formTokenSymbol: "",
  formDepositAssets: [],
  formWithdrawalAssets: [],
  formProtocols: [],
  formStrategies: [],
  formStrategyManager: [],
  formStrategyManagerId: [],
  formDepositAssetsId: [],
  formWithdrawalAssetsId: [],
  formProtocolsId: [],
  formStrategiesId: [],
  formDepositAssetsAddress: [],
  formWithdrawalAssetsAddress: [],
  formProtocolsAddress: [],
  formStrategiesAddress: [],
  formUserGroups: [],
  formUserGroupsId: [],
  // formUserGroupAddress : [],
  formWhitelistManager:[],
  formAPSManager: "",
  formAPSManagerId: "",
  formFeeCharging: "true",
  formManagementFee: 0.3,
  formFeeChargingInterval: "",
  formBenificiary: "",
  formWhitelist: "",
  formGroupName: "",
  formMembers: [undefined],
  formvaultDescription: "",
  formvaultIcon:"",
  formVaultStrategy:[
  //   {
  //   address:"",
  //   enabledProtocols:[],
  //   disabledProtocols:[],
  //   assets:[]
  // }
],
formVaultStrategyInfo:[],
formVaultType:"private",
formDepositNames:[],
formWithdrawNames:[],
formStrategyNames:[]
};

export const formReducer = (state = state1, action) => {
  switch (action.type) {
    case "FORM_VAULT_NAME":
      return {
        ...state,
        formVaultName: action.payload,
      };
    case "FORM_TOKEN_NAME":
      return {
        ...state,
        formTokenName: action.payload,
      };
    case "FORM_TOKEN_SYMBOL":
      return {
        ...state,
        formTokenSymbol: action.payload,
      };
    case "FORM_DEPOSIT_ASSET_NAME":
      return {
        ...state,
        // formDepositAssets : action.payload,
        formDepositAssetsAddress: [...action.payloadNew],
        formDepositAssetsId: action.payloadId,
      };
    case "FORM_WITHDRAWAL_ASSET_NAME":
      return {
        ...state,
        // formWithdrawalAssets : action.payload,
        formWithdrawalAssetsAddress: action.payloadNew,
        formWithdrawalAssetsId: action.payloadId,
      };
    case "FORM_PROTOCOL_NAME":
      return {
        ...state,
        // formProtocols : action.payload,
        formProtocolsAddress: [...state.action.payload, action.payloadNew],
        formProtocolsId: action.payloadId,
      };
    case "FORM_STRATEGY_NAME":
      return {
        ...state,
        formStrategies: action.payload,
        formStrategiesAddress: action.payloadNew,
        formStrategiesId: action.payloadId,
      };
    case "FORM_STRATEGY_MANAGER":
      return {
        ...state,
        formStrategyManager: action.payload,
        // formStrategyManagerId: action.payloadId,
      };
    case "FORM_USER_GROUPS":
      return {
        ...state,
        formUserGroups: action.payload,
        // formUserGroupAddress:action.payloadNew,
        // formUserGroupsId: action.payloadId,
      };
    case "FORM_APS_MANAGER":
      return {
        ...state,
        formAPSManager: action.payload,
        // formAPSManagerId: action.payloadId,
      };
    case "FORM_FEE_CHARGING":
      return {
        ...state,
        formFeeCharging: action.payload,
      };
    case "FORM_MANAGEMENT_FEE":
      return {
        ...state,
        formManagementFee: action.payload,
      };
    case "FORM_CHARGING_INTERVAL":
      return {
        ...state,
        formFeeChargingInterval: action.payload,
      };
    case "FORM_BENIFICIARY":
      return {
        ...state,
        formBenificiary: action.payload,
      };
    case "FORM_DEPOSIT_ASSET_ADDRESS":
      return {
        ...state,
        formDepositAssetsAddress: action.payload,
      };
    case "FORM_WITHDRAW_ASSET_ADDRESS":
      return {
        ...state,
        formWithdrawalAssetsAddress: action.payload,
      };
    case "FORM_DEPOSIT_ASSET_ID":
      return {
        ...state,
        formDepositAssetsId: action.payload,
      };
    case "FORM_WITHDRAW_ASSET_ID":
      return {
        ...state,
        formWithdrawalAssetsId: action.payload,
      };
    case "FORM_WHITELIST":
      return {
        ...state,
        formWhitelist: action.payload,
      };
    case "FORM_GROUP_NAME":
      return {
        ...state,
        formGroupName: action.payload,
      };
    case "FORM_MEMBERS":
      return {
        ...state,
        formMembers: action.payload,
      };
    case "FORM_VAULT_DESCRIPTION":
      return {
        ...state,
        formvaultDescription: action.payload,
      };
    case "FORM_VAULT_ICON":
      return {
        ...state,
        formvaultIcon: action.payload,
      };
      case "FORM_VAULT_STRATEGY_INFO":
        // window.prompt("info")
      return {
        ...state,
        formVaultStrategyInfo:action.payload
        
      };
      case "FORM_VAULT_WHITELIST_MANAGER":
      return {
        ...state,
        formWhitelistManager:action.payload
        
      };
      case "FORM_VAULT_TYPE":
      return {
        ...state,
        formVaultType:action.payload
        
      };

      case "FORM_DEPOSIT_NAME":
      return {
        ...state,
        formDepositNames:action.payload
        
      };
      case "FORM_WITHDRAW_NAME":
      return {
        ...state,
        formWithdrawNames:action.payload
        
      };
      case "FORM_STRATEGY_NAME":
      return {
        ...state,
        formStrategyNames:action.payload
        
      };
      case "RESET":
       
        return  {
          formVaultName: "",
        formTokenName: "",
        formTokenSymbol: "",
        formDepositAssets: [],
        formWithdrawalAssets: [],
        formProtocols: [],
        formStrategies: [],
        formStrategyManager: [],
        formStrategyManagerId: [],
        formDepositAssetsId: [],
        formWithdrawalAssetsId: [],
        formProtocolsId: [],
        formStrategiesId: [],
        formDepositAssetsAddress: [],
        formWithdrawalAssetsAddress: [],
        formProtocolsAddress: [],
        formStrategiesAddress: [],
        formUserGroups: [],
        formUserGroupsId: [],
        // formUserGroupAddress : [],
        formWhitelistManager:[],
        formAPSManager: "",
        formAPSManagerId: "",
        formFeeCharging: "true",
        formManagementFee: 0.3,
        formFeeChargingInterval: "",
        formBenificiary: "",
        formWhitelist: "",
        formGroupName: "",
        formMembers: [undefined],
        formvaultDescription: "",
        formvaultIcon:"",
        formVaultStrategy:[
        //   {
        //   address:"",
        //   enabledProtocols:[],
        //   disabledProtocols:[],
        //   assets:[]
        // }
      ],
      formVaultStrategyInfo:[],
      formVaultType:"private",
      formDepositNames:[],
      formWithdrawNames:[],
      formStrategyNames:[]
    };
  
    
    default:
      return state;
  }
};
