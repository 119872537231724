import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Copy from "../../../../src/assets/Transaction-Table/copy.svg";
import ReRoute from "../../../../src/assets/Transaction-Table/reroute.svg";
import DownArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-arrow.svg";
import UpArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-uparrow.svg";
import NoData from "../../../components/No-Data-Component/noData";
import { TransactionDetails } from "../../../services/api";
import CustomLoader from "../../Custom_Loader/customLoader";
import useInterval from "../../Polling/useInterval";
import TxTypes from "../Pending-Transaction/Enum/txTypes";
import SuccessOrFailureButton from "../TableStyledButton/successFailureButton";
import "../Transaction-Styling/Info-Table-Styling.css";
import "./Style/style.css";
const EpochConvertor = (value, condition) => {
  var d = new Date(value);
  // d.setUTCSeconds(utcSeconds);
  var date = d.getDate();
  date = date < 10 ? "0" + date : date;
  var month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var year = d.getFullYear();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var final = hours + ":" + minutes + ":" + seconds + " " + ampm;
  var date_and_time =
    date +
    "/" +
    month +
    "/" +
    year +
    "    " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    ampm;
  if (condition == 1) {
    return final;
  } else if (condition == 0) {
    return date_and_time;
  }
};

const useRowStyles = makeStyles((theme) => ({
  rowsPerPageTextStyle: {
    display: "none",
  },
  rowsPerPageTextStyle: {
    color: "#6D7681",
    fontFamily: "Open Sans",
  },
  selectedPage: {
    color: "#6D7681",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  boldWeight: {
    "& .MuiTablePagination-select": {
      fontWeight: "bold",
    },
    "& .MuiTypography-root .MuiTablePagination-caption .MuiTypography-body2 .MuiTypography-colorInherit":
      {
        display: "none",
      },
    "& .MuiTablePagination-actions": {
      margin: "0px",
    },
    "& .MuiTablePagination-selectRoot": {
      marginRight: "5px",
    },
    "& .MuiTypography-body2": {
      [theme.breakpoints.down("1641")]: {
        fontSize: "12px",
      },
    },
  },
}));

function Row(props) {
  const { row } = props;

  const handleCopy = (value) => {
    if (vaultAddress && userAddress) {
      copy(value);
    }
  };

  const linkHandler = (value, type) => {
    if (vaultAddress && userAddress) {
      if (type == 1) {
        window.open(`https://etherscan.io/tx/${value}`);
      } else if (type == 0) {
        window.open(`https://etherscan.io/address/${value}`);
      }
    }
  };

  const vaultAddress = useSelector((state) => state.get_vault_address);
  const userAddress = useSelector((state) => state.get_user_address.address);

  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #DEE5EF",
        }}
      >
        <TableCell component="th" scope="row">
          <p className="history-table-inner-contents">
            {TxTypes(row?.txnType)}
          </p>
        </TableCell>
        {
          <TableCell>
            <p className="history-table-inner-contents">
              {EpochConvertor(row.dateOfTransaction, 0)}
            </p>
          </TableCell>
        }
        <TableCell align="center">
          {row.txnStatus == "SUCCESS" ? (
            <SuccessOrFailureButton success_or_failure={1} />
          ) : (
            <SuccessOrFailureButton success_or_failure={0} />
          )}
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <img
                src={UpArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            ) : (
              <img
                src={DownArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="collapse-overview-table-style">
              {/* <p style={{ fontWeight: "bold",color:"#1B3E69" }} className="history-table-inner-contents">
        {row.send_receive_status === 1
          ? `Send ${(row.value / 10 ** row.tokenDecimal).toFixed(
              6
            )} ${row.tokenSymbol}`
          : `Received ${(
              row.value /
              10 ** row.tokenDecimal
            ).toFixed(6)} ${row.tokenSymbol} `}
      </p> */}
              <div style={{ display: "flex", gap: "8vw" }}>
                <div>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    From
                  </p>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    Hash
                  </p>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    Executed
                  </p>
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <p className="address-hash-in-overview-table">
                      {row.signerAddress}
                    </p>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Copy to clipboard" placement="top">
                        <img
                          src={Copy}
                          onClick={() => handleCopy(row.signerAddress)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                    <button className="transaction-page-button-copy-redirect-styling">
                      {/* <Tooltip title="add" placement="top"> */}
                      <img
                        src={ReRoute}
                        onClick={() => linkHandler(row.signerAddress, 0)}
                        style={{ height: "15px" }}
                      />

                      {/* </Tooltip> */}
                    </button>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className="address-hash-in-overview-table">
                      {row.txnHash}
                    </p>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Copy to clipboard" placement="top">
                        <img
                          src={Copy}
                          onClick={() => handleCopy(row.txnHash)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Open Etherscan" placement="top">
                        <img
                          src={ReRoute}
                          onClick={() => linkHandler(row.txnHash, 1)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                  </div>
                  <p className="history-table-inner-contents">
                    {EpochConvertor(row?.dateOfTransaction, 0)}
                  </p>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function App(props) {
  const [loading, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [limit, setLimit] = useState(10);
  const classes = useRowStyles();
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const TransactionInitiated = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const userAddress = useSelector((state) => state.get_user_address.address);

  const getAllTransactionDataFunction = () => {
    TransactionDetails.overviewTransaction(vaultAddress,userAddress)
      .then((result) => {
        if (result.data) {
          setTotalRecord(result.data.data.length);
          setPageData(result.data.data);
          setTimeout(() => {
            setLoader(false);
          }, 500);
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 500);
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  useEffect(() => {
    getAllTransactionDataFunction();
  }, [TransactionInitiated]);

  useInterval(() => {
    getAllTransactionDataFunction();
  }, 10000);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));
    setPageNo(0);
  };

  return (
    <>
      <p className="history-table-heading">Overview</p>
      <p className="history-table-description">
        All actions regarding the management of the vault by the APS, Strategy,
        and Whitelist Managers are displayed below.
      </p>
      {loading ? (
        <CustomLoader />
      ) : pageData.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            className="history-table-custom-scroll"
          >
            <Table>
              <TableHead
                style={{
                  paddingBottom: "0px",
                  borderBottom: "1px solid #DEE5EF",
                }}
              >
                <TableRow>
                  <TableCell style={{ paddingBottom: "0px" }}>
                    <p className="history-table-row-heading">Type</p>
                  </TableCell>
                  <TableCell style={{ paddingBottom: "0px" }}>
                    <p className="history-table-row-heading">Date</p>
                  </TableCell>
                  <TableCell style={{ paddingBottom: "0px" }}>
                    <p className="history-table-row-heading">Status</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.map((row) => (
                  <Row key={row.txnId} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            classes={{ root: classes.rowsPerPageTextStyle }}
            className={classes.boldWeight}
            count={totalRecord}
            rowsPerPage={limit}
            page={pageNo}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per Page:"
            labelDisplayedRows={() => ""}
            nextIconButtonProps={<img src={UpArrow}></img>}
          />
        </>
      ) : (
        <NoData text="No Transaction Data" />
      )}
      {/* <CustomSkeleton/> */}
    </>
  );
}
