
export const safeName = (safeName) => async (dispatch) => {
    
    dispatch({
        type: "SET_SAFE_NAME",
        payload: {
          safeName: safeName
        }
      })
  };
