import React from 'react';
import not_found from '../../assets/NotFound/not_found.svg';
import './Styling/PageNotFound.css';

export default function NotFound(){

    return(
        <div className="notFoundContainer">
        <img className="imageForPageNotFound" src={not_found}></img>
        <h1 className="pageNotFoundText">404 &nbsp;&nbsp;&nbsp;Page Not Found</h1>
        </div>
    );
}