import { Fragment } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import CustomStrategySection from "../customStrategySection";
import IframeSection from "../IframeScreen/iframeScreen";
import style from "../Style/style.css";
const MainScreen = () => {
  const { url } = useRouteMatch();
  return (
    <Fragment>
      <div className="custom-strategy-page-aligner">
        <div className="custom-strategy-table-first-flex ">
          <p className="titleText">Custom Strategies</p>
          <div className="custom-strategy-display-table-styler">
            <Route
              path={`${url}/custom-interface`}
              render={(props) => (
                <IframeSection {...props} isAuthed={true} />
              )}
            ></Route>
            <Route
              exact
              path={`${url}/`}
              component={CustomStrategySection}
            ></Route>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MainScreen;
