export const currencyList = () => {
    return (
        [
        
        "aud",
        "eur",
        "brl",
        "cad",
        "chf",
        "cny",
        "czk",
        "dkk",
        "gbp",
        "hkd",
        "huf",
        "idr",
        "ils",
        "inr",
        "jpy",
        "krw",
        "mxn",
        "myr",
        "nok",
        "nzd",
        "php",
        "pln",
        "rub",
        "sek",
        "sgd",
        "thb",
        "try",
        "usd",
        "zar",

       ])
      
}



// ["aed",
//         "ars",
//         "aud",
//         "bch",
//         "bdt",
//         "bhd",
//         "bmd",
//         "bnb",
//         "brl",
//         "btc",
//         "cad",
//         "chf",
//         "clp",
//         "cny",
//         "czk",
//         "dkk",
//         "dot",
//         "eos",
//         "eth",
//         "eur",
//         "gbp",
//         "hkd",
//         "huf",
//         "idr",
//         "ils",
//         "inr",
//         "jpy",
//         "krw",
//         "kwd",
//         "lkr",
//         "ltc",
//         "mmk",
//         "mxn",
//         "myr",
//         "ngn",
//         "nok",
//         "nzd",
//         "php",
//         "pkr",
//         "pln",
//         "rub",
//         "sar",
//         "sek",
//         "sgd",
//         "thb",
//         "try",
//         "twd",
//         "uah",
//         "usd",
//         "vef",
//         "vnd",
//         "xag",
//         "xau",
//         "xdr",
//         "xlm",
//         "xrp",
//         "yfi",
//         "zar",
//         "bits",
//         "link",
//         "sats"]
