import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./ConnectToWallet.css";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { store } from "../../..";
import { getBlockNumber } from "../../../utils/getBlockNumber";
import { getWeb3 } from "../../../redux/actions/getWeb3";
import { Provider } from "../../../redux/actions/Provider";
import { walletState } from "../../../redux/actions/walletState";
import walletConnectModule from "@web3-onboard/walletconnect";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { updateWalletDetails } from "../../../utils/web3/config";
import { Dropdown, Space } from "antd";
import MetaMaskIcon from "../../../assets/Logos/metamask-icon.svg";
import WalletConnectIcon from "../../../assets/Logos/WalletConnect-Logo.png";
import { handleWalletSelection } from "../../Blocknative/walletSelectionConfig";

function ConnectWallet() {
  const { openConnectModal } = useConnectModal();
  const { address, connector } = useAccount();
  const userAddress = useSelector((state) => state.get_user_address.address);

  useEffect(() => {
    if (
      address &&
      (connector?.id === "walletConnect" || connector?.id == "injected")
    )
      updateWalletDetails(connector, address);
  }, [address, connector]);

  const items = [
    {
      key: "MetaMask",
      label: (
        <button
          onClick={async () => {
            try {
              const ready = await handleWalletSelection(userAddress, "connect");
              if (!ready) return;
            } catch (err) {
              console.log("connect wallet", { err });
            }
          }}
          className="connect-wallet-options"
        >
          MetaMask
        </button>
      ),
      icon: <img width={25} src={MetaMaskIcon} alt="metamask icon" />,
    },
    {
      key: "WalletConnect",
      label: (
        <button
          onClick={async () => {
            try {
              if (openConnectModal) {
                openConnectModal();
              }
            } catch (err) {
              console.log("connect wallet", { err });
            }
          }}
          className="connect-wallet-options"
        >
          WalletConnect
        </button>
      ),
      icon: (
        <img width={25} src={WalletConnectIcon} alt="wallet connect icon" />
      ),
    },
  ];

  return (
    <div className="connect-wallet-wrapper">
      <Dropdown menu={{ items }} overlayClassName="connect-wallet-dropdown">
        <button
          className="connect-wallet-button"
          onClick={(e) => e.preventDefault()}
        >
          Connect Wallet
        </button>
      </Dropdown>
    </div>
  );
}

export default ConnectWallet;
