import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function V1Version() {
  const history = useHistory();

  useEffect(() => {
    window.open(`https://v1.yieldster.finance/`);
    history.push("/");
  }, []);

  return <div></div>;
}

export default V1Version;
