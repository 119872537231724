import axios from "axios";
import { API_SERVER_ADDRESS } from "../../constants/serverAddress";
import {LocalStorage} from "../../services/localStorage/index";

const AxiosInstance = axios.create({
  baseURL: API_SERVER_ADDRESS,
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
const requestHandler = (request) => {
  // if (isHandlerEnabled(request)) {
    // const token = LocalStorage.getToken();
    // request.headers.Authorization = `Bearer ${token}`;
  // }
  return request;
};
const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
  }
  return response;
};
const errorHandler = (error) => {
  if (error.response?.status === 401) {
    window.location.reload();
  }
  return Promise.reject({ ...error });
};
AxiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
AxiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
export default AxiosInstance;
