
export const userAddress = (address) => async (dispatch) => {
    
    dispatch({
        type: "SET_ADDRESS",
        payload: {
          address: address
        }
      })
  };

  

