import React, { useEffect, useState, useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "./Table/table";
import "./Safe_Details_Style/style.css";
import WhiteList_Manager from "./WhiteList_Manager/manager";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { whitelistDetails } from "../../../redux/actions/whitelist-details";
import CustomLoader from "../../Custom_Loader/customLoader";
import { getBackendData } from "../../Asset-Details/TableFunctions";
import { Vaults } from "../../../services/api";
import { useLocation } from "react-router-dom";
import { setVaultDetails } from "../../../redux/actions/vaultDetails";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Catamaran",
    color: "#1B3E69",

    fontSize: " 24px",

    fontWeight: "bold",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },

  inline: {
    fontSize: "12px",
    color: "#3f51b5",
    paddingLeft: "20px",
    paddingTop: "0px",
  },

  content: {
    paddingLeft: "20px",
    fontSize: "12px",
    lineHeight: "20px",
  },
  description: {
    fontSize: "14px",
    padding: "10px 0px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
}));

export default function Safe() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;

  const userAddress = useSelector((state) => state.get_user_address.address);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [vaultDetailsList, setVaultDetailsList] = useState();

  const getVaultAssets = () => {
    if (vaultAddress != "") {
      Vaults.getSafeDetails(vaultAddress)
        .then((response) => {
          if (response.data.data) {
            setVaultDetailsList(response.data.data);
            dispatch(setVaultDetails(response.data.data));
          }
        })
        .catch(console.log)
        .finally(() => {
          setLoaderStatus(false);
        });
    }
  };
  useEffect(() => {
    getVaultAssets();
  }, []);

  return (
    <>
      <div>
        <p className={classes.heading}>Support</p>
        <p className={classes.description}>
          {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo possimus
          eaque amet quas ad perspiciatis placeat voluptas laboriosam. Libero
          incidunt iure error, fugit voluptate possimus molestias quis id
          ratione iste. Aspernatur voluptate placeat cumque accusamus nobis odio
          cupiditate necessitatibus adipisci, numquam, laborum praesentium quo
          repudiandae quasi similique debitis excepturi ducimus maiores id
          eveniet sequi incidunt? */}
        </p>
        {loaderStatus ? (
          <div style={{ marginTop: "150px" }}>
            <CustomLoader />
          </div>
        ) : (
          <>
            <Table vaultDetails={vaultDetailsList} />
          </>
        )}
      </div>
    </>
  );
}
