import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import "./WalletConnected.css";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { IsConnected } from "../../../redux/actions/IsConnected";
import { useSelector, useDispatch } from "react-redux";
import copy from "copy-to-clipboard";
import { Provider } from "../../../redux/actions/Provider";
import { userAddress } from "../../../redux/actions/UserAddress";
import { useHistory, useLocation } from "react-router-dom";
import addressDisplayConfig from "../../Sidebar/Safedetails/addressDisplayConfig";
import { icons } from "./Icons/icon";
import { web3Config } from "../../../utils/web3Config";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { walletState } from "../../../redux/actions/walletState";
import { disconnect } from "@wagmi/core";
import { onboard } from "../../Blocknative/blocknativeConfig";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#ffffff",
    color: "black",
    pointerEvents: "auto",
    cursor: "pointer",
    display: "flex",
    flex: "1  1  auto",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    height: "47px",
    width: "307px",
    boxShadow: "none",
    [theme.breakpoints.down("1640")]: {
      width: "288px",
    },

    textTransform: "none",
  },
  modalNetworkType: {
    textTransform: "capitalize",
  },
  networkType: {
    backgroundColor: "#164F58",
    color: "#ffffff",
    lineHeight: "normal",
    textTransform: "uppercase",
    height: "24px",
    width: "72px",
    pointerEvents: "none",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    borderRadius: 0,
  },
  buttonFont: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#ffffff",
    padding: "3.5px 0px",
  },
  connectedIcon: {
    color: "#008C73",
  },
  walletIcon: {
    color: "#b2b5b2",
  },
  networkIcon: {
    color: "#e8673c",
  },
  paper: {
    width: "70%",
    boxShadow: "4px 4px 30px rgb(27 62 105 / 16%)",
  },
  primaryTextLabel: {
    fontSize: "16px",
    color: "#6D7681",
    fontWeight: 400,
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "capitalize",
  },
  primaryTextLabelRight: {
    fontSize: "16px",
    color: "#164F58",
    fontWeight: 700,
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "capitalize",
  },
  listItemRoot: {
    textAlign: "right",
  },
  buttonRootDisconnectModal: {
    padding: 0,
    minWidth: "31px",
  },
  buttonText: {
    padding: 0,
  },
}));

const SimplePopover = () => {
  const history = useHistory();
  const location = useLocation();

  //Variables
  const classes = useStyles();

  //States
  const [anchorEl, setAnchorEl] = React.useState(null);

  //Redux dispatch
  const dispatch = useDispatch();

  //Use selector
  const userAccount = useSelector((state) => state.get_user_address);
  const networkType = useSelector((state) => state.get_network_type);
  const wallet = useSelector((state) => state.get_wallet_state.wallet);
  const { openConnectModal } = useConnectModal();
  //Functions
  //Popover open-close
  const handleClick = (event) => {
    if (wallet?.name == "MetaMask") setAnchorEl(event.currentTarget);
    else {
      setAnchorEl(event.currentTarget);
      openConnectModal && openConnectModal();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Disconnect app from wallet
  const disconnectHandler = async () => {
    // web3Modal.clearCachedProvider();
    if (wallet?.name == "MetaMask")
      onboard.disconnectWallet({ label: "MetaMask" });
    else await disconnect();

    dispatch(IsConnected(false));
    dispatch(Provider(null));
    dispatch(userAddress(null));
    dispatch(walletState(null));

    localStorage.removeItem("selectedWallet");
    localStorage.removeItem("network");
    localStorage.removeItem("accountAddress");

    //Creates a local web3 instance to fetch data from chain
    web3Config();

    if (location.pathname.endsWith("/createvault")) history.push("");
    handleClose();
  };

  //Copy handler function to copy address
  const copytextHandler = () => {
    copy(userAccount.address);
  };

  //Appending address to etherscan
  const linkHandler = () => {
    window.open(`https://etherscan.io/address/${userAccount.address}`);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="walletConnectWrapper">
      <Button
        aria-describedby={id}
        color="primary"
        onClick={handleClick}
        className={classes.button}
      >
        {icons.map((data) => {
          if (data?.name?.toLowerCase() === wallet?.name?.toLowerCase())
            return <React.Fragment key={data.icon}>{data.icon}</React.Fragment>;
        })}

        <div className="walletDetails">
          <span className="walletName">{wallet.name}</span>

          <div
            className="userAccountText"
            style={{
              width: "127px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#164F58",
              fontSize: "12px",
              fontFamily: " 'Open Sans', sans-serif",
            }}
          >
            {userAccount.address}
          </div>
        </div>
        <div className={classes.networkType}>
          <p className={classes.buttonFont}>{networkType.network}</p>
        </div>
        <span className="material-icons">arrow_drop_down</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.paper }}
        elevation={0}
        style={{ width: "26%", left: "0%", top: "2%", height: "100%" }}
      >
        <div className="wallet-wrapper">
          <div className="box wallet-connected-header">
            <div
              className="addressText"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {userAccount.address ? (
                addressDisplayConfig(userAccount.address)
              ) : (
                <></>
              )}
            </div>
            <Button
              classes={{
                root: classes.buttonRootDisconnectModal,
                // text: classes.buttonText,
              }}
              style={{ color: "#164F58" }}
            >
              <FileCopyOutlinedIcon
                onClick={copytextHandler}
                classes={{
                  root: classes.buttonRootDisconnectModal,
                  // text: classes.buttonText,
                }}
              />
            </Button>
            <Button
              classes={{
                root: classes.buttonRootDisconnectModal,
                // text: classes.buttonText,
              }}
              style={{ color: "#164F58" }}
            >
              {" "}
              <OpenInNewOutlinedIcon
                onClick={linkHandler}
                classes={{
                  root: classes.buttonRootDisconnectModal,
                  // text: classes.buttonText,
                }}
              />
            </Button>
          </div>

          <div className="box sidebar">
            <List>
              <ListItem>
                <ListItemText
                  primary="Status"
                  classes={{ primary: classes.primaryTextLabel }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Wallet"
                  classes={{ primary: classes.primaryTextLabel }}
                />
              </ListItem>
              <Divider />

              <ListItem>
                <ListItemText
                  primary="Network"
                  classes={{ primary: classes.primaryTextLabel }}
                />
              </ListItem>
              <Divider />
            </List>
          </div>
          <div className="box content">
            <List>
              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary="Connected"
                  classes={{ primary: classes.primaryTextLabelRight }}
                />
                <FiberManualRecordRoundedIcon
                  className={classes.connectedIcon}
                />
              </ListItem>
              <Divider />

              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  style={{ whiteSpace: "nowrap" }}
                  primary={wallet.name}
                  classes={{ primary: classes.primaryTextLabelRight }}
                />
                <AccountBalanceWalletOutlinedIcon
                  className={classes.walletIcon}
                />
              </ListItem>
              <Divider />

              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  className={classes.modalNetworkType}
                  primary={networkType.network}
                  classes={{ primary: classes.primaryTextLabelRight }}
                />
                <RadioButtonCheckedIcon className={classes.networkIcon} />
              </ListItem>
              <Divider />
            </List>
          </div>

          <div className="box footer">
            <Button onClick={disconnectHandler}>Disconnect</Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SimplePopover;
