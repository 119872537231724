import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
  },
  button: {
    margin: "20px 30px 30px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    boxShadow: "none",
    float: "right",
    "&:hover": {
      transform: "scale(1.05)",
      background: "#1B3E69",
      boxShadow: "none",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
  },
  textField: {
    margin: "0px 30px 20px 30px ",
  },
  contentAddress: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "300px",
      paddingTop: "12.5px",
      paddingBottom: "12.5px",
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
    "& .MuiOutlinedInput-input":{
      paddingTop:"0px",
      paddingBottom:"0px",
      fontSize: "14px",
      // color: "#1B3E69",
      fontFamily: "'Open sans', sans-serif",
      [theme.breakpoints.down('1641')]:{
        
        fontSize: "13px",
      },
    },
  },
}));

export default function MultilineTextFields(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [message, setMessage] = useState();
  const { returnCondition } = props;

  useEffect(() => {}, [returnCondition]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (returnCondition == false) {
      setMessage("Invalid Address");
    } else {
      setMessage("");
    }
    if (props.id == 1) {
      props.handleInputChangeAdd(event.target.value);
    } else if (props.id == 2) {
      props.handleInputChangeRemove(event.target.value);
    }
    // else if(props.id == 3){
    //   props.handleInputManagerAddress(event.target.value);
    // }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="standard-multiline-flexible"
          placeholder="Enter Address"
          className={classes.root}
          // multiline
          // message
          error={!returnCondition}
          rowsMax={4}
          value={value}
          onChange={handleChange}
          // helperText={message}
          variant="outlined"
        />
      </div>
    </form>
  );
}
