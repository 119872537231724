import { PendingVaultTransaction } from "../../services/api";

export const web3SendTransaction = async (
  web3,
  from,
  to,
  data,
  vaultId,
  txType,
  walletType,
  hash
) => {
  let walletName;
  if (walletType.toLowerCase() === "walletconnect") walletName = "GNOSIS";
  else walletName = walletType;

  if (walletType === "METAMASK") {
    web3.eth
      .sendTransaction({
        from,
        to,
        data,
      })
      .on("transactionHash", function (hash) {
        console.log({ hash });
        //Add the necessary data to the api
        PendingVaultTransaction.createPendingTransaction(
          "PENDING",
          vaultId,
          hash,
          txType,
          walletName
        )
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        console.log(error);
      });
  } else if (hash && vaultId && txType && walletName)
    PendingVaultTransaction.createPendingTransaction(
      "PENDING",
      vaultId,
      hash,
      txType,
      walletName
    )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
};
