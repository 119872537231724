import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class PendingVault {
  static createPendingVault(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `pendingVault/sdk/v2.0/yieldster/create`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: data,
    });
  }

  static getPendingVaultById(id) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `pendingVault/sdk/v2.0/yieldster/${id}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getPendingVaultQueue(id) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `pendingVault/sdk/v2.0/yieldster/queue?id=${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static uploadVaultIcon(vaultId, file) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/pendingVault/sdk/v2.0/yieldster/setIcon/${vaultId}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: file,
    });
  }
}
