import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import description from "../../assets/icons/description.svg";
import { vaultIcon } from "../../redux/actions/vaultIcon";
import { safeName } from "../../redux/actions/SafeName";
import { tokenSymbol } from "../../redux/actions/tokenSymbol";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setVaultAddress } from "../../redux/actions/VaultAddress";
import { Vaults } from "../../services/api";
import Skeleton from "@material-ui/lab/Skeleton";
import "./Style/index.css";

function VaultCard(props) {

    const { vault } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [vaultYield, setVaultYield] = useState(null);

    const setClassname = (v) => {
    return Math.sign(parseFloat(v)) == 1
      ? "positive"
      : v[0] === "-"
      ? "negative"
      : " ";
  };

    useEffect(() => {
        if (vault?.vaultAddress) {
            setLoader(true);
            Vaults.getVaultYield({ vault: vault.vaultAddress })
                .then((response) => {
                    if (response.data.data !== null)
                        setVaultYield(response.data.data);
                })
                .catch((e) => {
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    }, [vault])

    useEffect(() => { }, [vaultYield])

    //Handler to Route to selected vault
    const handleClick = (e, type, vaultAddress, vaultName, icon, symbol) => {
        dispatch(setVaultAddress(vaultAddress));
        dispatch(safeName(vaultName));
        dispatch(vaultIcon(icon));
        dispatch(tokenSymbol(symbol));
        localStorage.setItem("newSafeAddress", vaultAddress);
        localStorage.setItem("vaultName", vaultName);
        localStorage.setItem("vaultIcon", icon);
        localStorage.setItem("vaultSymbol", symbol);
        e.stopPropagation();
        if (type === "dashboard") history.push(`/vault/${vaultAddress}/dashboard`);
        else history.push(`/vault/${vaultAddress}/dashboard/description`);
    };

    const complete = (data) => {
        return (
            <div className="complete-card">
                <p className="title">Vault Description</p>
                <div className="text-and-link">
                    <p className="description-text">{data.description}</p>
                    <p
                        className="read-more-link"
                        onClick={(e) => {
                            handleClick(
                                e,
                                "description",
                                data.vaultAddress,
                                data.vaultName,
                                data.image,
                                data.tokenSymbol
                            );
                        }}
                    >
                        More
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div
            className="cardview"
            // key={index}
            onClick={(e) =>
                handleClick(
                    e,
                    "dashboard",
                    vault.vaultAddress,
                    vault.vaultName,
                    vault.image,
                    vault.tokenSymbol
                )
            }
        >
            <div className="cardview-head">
                <h6>{vault.name}</h6>
                <div className="card-head-right">
                    <div className="user-image">
                        <img
                            src={`data:image/jpeg;base64,${vault.image}`}
                            alt={vault.name.slice(0, 5)}
                        />
                    </div>
                </div>
            </div>
            {vault?.description?.length ? (
                <div className="cardview-description-content">
                    <div className="description-icon">
                        <img src={description} />
                    </div>
                    <p className="description-text">{vault.description}</p>
                    <Popover
                        placement="bottomRight"
                        content={complete(vault)}
                        color="#F3F6F9"
                        overlayStyle={{ paddingTop: "15px" }}
                    >
                        <p className="read-more">More</p>
                    </Popover>
                </div>
            ) : (
                <div className="cardview-text"></div>
            )}

            <div className="cardview-footer">
                <div className="card-footer">
                    <label>Coins</label>
                    <div className="mulitCoin">
                        {vault.coins?.images?.map((image, index) => {
                            if (index < 2)
                                return <img src={image} alt="" key={index} />;
                            else return null;
                        })}
                        {vault.coins?.count > 2 && (
                            <span>+ {vault.coins?.count - 2}</span>
                        )}
                    </div>
                </div>
                <div className="card-footer">
                    <label>1 Week</label>
                    {
                    loader
                        ? <Skeleton animation="wave" height={30} width="60%" style={{ marginTop: -5 }} />
                        : 
                        <div className="value-footer">
                            <span className={setClassname(vaultYield?.oneWeekAPR?.toString())}>
                                {parseFloat(vaultYield?.oneWeekAPR??0)?.toFixed(2)} %
                            </span>
                        </div>
                     }
                </div>
                <div className="card-footer">
                    <label>Total</label> {loader
                        ? <Skeleton animation="wave" height={30} width="60%" style={{ marginTop: -5 }} />
                        : <div className="value-footer">
                            <span className={setClassname(vaultYield?.totalAPR?.toString())}>
                                {parseFloat(vaultYield?.totalAPR??0).toFixed(3)} %
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default VaultCard;