import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TransactionTable from "./transactionTable";
import axios from "axios";
import { vaultTransactions, getVault } from "../api";
import PendingTransaction from "./Pending-Transaction/PendingTransaction";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
import "./Transaction-Styling/Transaction_Styling.css";
import StyledSidebar from "../Styled_Sidebar/styledSidebar";
import TransactionSummary from "../Transaction-Table/Transaction-Summary/TransactionSummary";
import AllTransactions from "./All-Transactions/allTransactions";
import TransactionDetails from "../../services/api/transactionDetails";
import AdvisorTransaction from "./AdvisorTransactionTable";
import AssetReservations from "./Asset-Reservations/AssetReservations";

const TransactionTableMain = () => {
  const vaultAddressRedux = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const location = useLocation();

  const vaultAddress =
    vaultAddressRedux && vaultAddressRedux !== ""
      ? vaultAddressRedux
      : location.pathname.split("/")[2];
  const [rowData, setRowData] = useState("");
  const [vaultData, setVaultData] = useState("");
  const [advisorTransactionData, setAdvisorTransactionData] = useState();
  const [advisorTransactionloading, setAdvisorTransactionLoading] =
    useState(true);
  const { url } = useRouteMatch();
  const routes = [
    { name: "Overview", path: "/overview" },
    { name: "Token", path: "/history" },
    { name: "Queue", path: "/pending" },
    { name: "Advisor", path: "/advisor" },
    { name: "Withdrawals", path: "/withdrawals" },
    // {name:"Summary",path:"/summary"}
  ];

  // selected_button_style

  const handleRouteChange = () => {};

  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress) {
        let result = await TransactionDetails.getTransactionByType(
          vaultAddress
        );
        // let result = await axios.get(vaultTransactions(vaultAddress));
        if (result?.data?.data) setRowData(result.data.data);
        else setRowData([]);

        // let result2 = await axios.get(getVault(vaultAddress));
        // setVaultData(result2.data.data);

        let advisorTransactions =
          await TransactionDetails.getAdvisorTransactions(vaultAddress);
        if (advisorTransactions?.data?.data) {
          setAdvisorTransactionData(advisorTransactions.data.data);
          setAdvisorTransactionLoading(false);
        } else setAdvisorTransactionData([]);
        // console.log("original time format",result2.data.data)
      }
    };
    asyncFn();
  }, [vaultAddress]);

  return (
    <>
      <div className="transaction-component-aligner">
        <p className="transaction-title-text">Transactions</p>
        <div
          style={{
            display: "flex",
            marginTop: "7px",
            boxShadow: "4px 4px 30px rgba(27, 62, 105, 0.16)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <StyledSidebar
            url={url}
            routes={routes}
            handleRouteChange={handleRouteChange}
          />
          {/* <div className="queue-history-buttons-div">
            <button id={queueStyle} onClick={()=>history.push(`${url}/pending`)}>Queue</button>
            <button id={historyStyle} onClick={()=>history.push(`${url}/history`)}>History</button>
        </div> */}
          <div className="sidebar-content-renderer">
            <Route exact path={`${url}/overview`} component={AllTransactions} />
            <Route
              exact
              path={`${url}/history`}
              component={() => (
                <TransactionTable
                  rowData={rowData}
                  vaultAddress={vaultAddress}
                  // vaultData={vaultData}
                />
              )}
            />
            <Route
              exact
              path={`${url}/pending`}
              component={PendingTransaction}
            />
            <Route
              exact
              path={`${url}/advisor`}
              component={() => (
                <AdvisorTransaction
                  rowData={advisorTransactionData}
                  vaultAddress={vaultAddress}
                  advisorTransactionloading={advisorTransactionloading}
                  // vaultData={vaultData}
                />
              )}
            />
            <Route
              exact
              path={`${url}/withdrawals`}
              component={AssetReservations}
            />
            {/* <Route exact path={`${url}/summary`} component={TransactionSummary} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionTableMain;
