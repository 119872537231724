import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Popover, Radio, Select, Spin, Tabs } from "antd";
import { ReactComponent as ChartIcon } from "../../assets/icons/chartview.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/gridview.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/listview.svg";
import { VaultGrid } from "../../components/Card";
import NavigationBar from "../../components/Navbar";
import { ComingSoon } from "../../components/StableCoins";
import { VaultTable } from "../../components/Table";
import { Vaults } from "../../services/api";
import { currencyData } from "../../constants/currencyList";
import "./Style/index.css";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/index";
import {
  ALL_VAULT,
  MY_VAULT,
  FEATURED_VAULT,
  STABLE_COINS,
} from "../../constants/displayVault";
import { SearchOutlined } from "@ant-design/icons";
import CustomLoader from "../../components/Custom_Loader/customLoader";

const { TabPane } = Tabs;
const { Option } = Select;

const ViewData = [
  {
    label: (
      <div className="grid-view">
        <GridIcon className="home-icon" />
      </div>
    ),
    value: "grid",
  },
  {
    label: (
      <div className="list-view">
        <ListIcon className="home-icon" />
      </div>
    ),
    value: "list",
  },
  {
    label: (
      <div className="chart-view">
        <ChartIcon className="home-icon" />
      </div>
    ),
    value: "chart",
  },
];

function ExploreVault() {
  const userAddress = useSelector((state) => state.get_user_address.address);
  const [view, setView] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [baseCurrency, setBaseCurrency] = useState();
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [searchContent, setSearchContent] = useState(null);
  const [search, setSearch] = useState(null);
  const [filterType, setFilterType] = useState();
  const [displayData, setDisplayData] = useState({
    required: [],
    all: [],
    featured: [],
    stable: [],
    myVaults: [],
    count: 0,
    allCount: 0,
    featuredCount: 0,
    stableCount: 0,
    myVaultsCount: 0,
  });
  const [pageNo, setPageNo] = useState(0);
  const [loader, setLoader] = useState(true);
  const [allLoader, setAllLoader] = useState(true);
  const [featuredLoader, setFeaturedLoader] = useState(true);
  const [stableLoader, setStableLoader] = useState(true);
  const [myVaultsLoader, setMyVaultsLoader] = useState(true);
  const selected = localStorage.getItem("type")
    ? localStorage.getItem("type")
    : "2";

  const account = userAddress
    ? userAddress
    : "0x0000000000000000000000000000000000000000";

  const handleViewChange = (e) => {
    if (e.target.value === "grid") {
      setView(0);
      setPageSize(30);
      setPageNo(0);
    } else if (e.target.value === "list") {
      setView(1);
      setPageSize(30);
      setPageNo(0);
    } else {
      setView(2);
    }
  };

  const fetchMoreData = (filterType) => {
    setPageNo((prev) => {
      switch (filterType) {
        case ALL_VAULT:
          if (
            displayData?.all?.length > 0 &&
            displayData?.allCount !== 0 &&
            (prev + 1) * pageSize < displayData?.allCount
          )
            return prev + 1;
          else return prev;
          break;
        case MY_VAULT:
          if (
            displayData?.myVaults?.length > 0 &&
            displayData?.myVaultsCount !== 0 &&
            (prev + 1) * pageSize < displayData?.myVaultsCount
          )
            return prev + 1;
          else return prev;
          break;
        case FEATURED_VAULT:
          if (
            displayData?.featured?.length > 0 &&
            displayData?.featuredCount !== 0 &&
            (prev + 1) * pageSize < displayData?.featuredCount
          )
            return prev + 1;
          else return prev;
          break;
        case STABLE_COINS:
          if (
            displayData?.stable?.length > 0 &&
            displayData?.stableCount !== 0 &&
            (prev + 1) * pageSize < displayData?.stableCount
          )
            return prev + 1;
          else return prev;
          break;
        default:
          break;
      }
    });
  };

  const fixed = (v) => {
    if (v !== null) {
      let num = parseFloat(v) / Math.pow(10, 18);
      return num.toFixed(2);
    } else return parseFloat(0).toFixed(2);
  };

  const handleResponse = (response) => {
    let array = [];
    response.map((data, index) => {
      // console.log("data in explore",data);
      let temp = {};
      temp["key"] = index;
      temp["name"] = data.vault.vaultName;
      temp["image"] = data.vault.logoImg.data;
      temp["avatar"] = data.logoUrl;
      temp["description"] = data.vault.description;
      temp["cur"] = data.vault.baseCurrency.slice(0, 3);
      temp["coins"] = {
        count: data.vault.supportedAssets.length,
        images: data.assetImgUrl,
      };
      temp["day"] = data?.oneDayAPR?.toFixed(2);
      temp["week"] = data?.oneWeekAPR?.toFixed(2);
      temp["month"] = data?.oneMonthAPR?.toFixed(2);
      temp["total"] = data?.totalAPR?.toFixed(2);
      temp["pertoken"] = fixed(
        data.tokenNAV != null && data.tokenNAV.data !== null
          ? data.tokenNAV.data.TokenPrice
          : null
      );
      temp["totalnav"] = fixed(
        data.totalNAV != null && data.totalNAV.data !== null
          ? data.totalNAV.data.VaultNAV
          : null
      );
      temp["vaultAddress"] = data.vault.vaultAddress;
      temp["vaultName"] = data.vault.vaultName;
      temp["tokenSymbol"] = data.vault.tokenSymbol;
      array.push(temp);
    });
    return array;
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const handleSearch = useCallback(
    debounce((e) => autocomplete(e.target.value), 1000),
    []
  );

  const handleFilterByBaseCurrency = (event) => {
    setLoader(true);
    setBaseCurrency(event);
    noVaults();
  };

  const vaultChange = (no) => {
    switch (no) {
      case "1":
        localStorage.setItem("type", "1");
        setFilterType(MY_VAULT);
        setPageNo(0);
        break;
      case "2":
        localStorage.setItem("type", "2");
        setFilterType(FEATURED_VAULT);
        setPageNo(0);
        break;
      case "3":
        localStorage.setItem("type", "3");
        setFilterType(STABLE_COINS);
        setPageNo(0);
        break;
      case "4":
        localStorage.setItem("type", "4");
        setFilterType(ALL_VAULT);
        setPageNo(0);
        break;
      default:
        break;
    }
  };

  const autocomplete = (data) => {
    if (data === "") {
      setAutoCompleteData([]);
    } else
      Vaults.autoCompleteDetails(data)
        .then((response) => {
          setAutoCompleteData(response.data.data);
        })
        .catch((e) => {
          setAutoCompleteData([]);
        });
  };

  const noVaults = (e) => {
    setDisplayData({
      required: [],
      all: [],
      featured: [],
      stable: [],
      myVaults: [],
      count: 0,
      allCount: 0,
      featuredCount: 0,
      stableCount: 0,
      myVaultsCount: 0,
    });
  };

  const displayVaults = (size = 30, no = 0, data, base) => {
    let count = 0;
    let pass = {
      type: filterType,
      pageNumber: no,
      pageSize: size,
      data: data,
      baseCurrency: base,
      accountAddress: account,
      withYield: true
    };
    if (view === 1)
      switch (filterType) {
        case ALL_VAULT:
          setAllLoader(true);
          // if (
          //   displayData?.all?.length !== 0 &&
          //   view === 0 &&
          //   displayData?.all?.length < displayData?.allCount
          // )
          //   pass.pageNumber = Math.ceil(displayData?.all?.length / pageSize);
          // else if (view !== 0) pass.pageNumber = no;
          // else pass.pageNumber = 0;
          // setPageNo(pass.pageNumber);
          setPageNo(pass.pageNumber);
          Vaults.getFilteredVaults(pass)
            .then((response) => {
              if (response.data.data) {
                if (size === 0 && no === 0)
                  count = response.data.data.count;
                let vault = handleResponse(response.data.data?.vaults);
                if (vault?.length)
                  setDisplayData({
                    ...displayData,
                    all:
                      displayData?.all?.length === 0 || pass.pageNumber === 0
                        ? vault
                        : displayData?.all?.concat(vault),
                    count: response.data.data?.count,
                    allCount: response?.data?.data?.count,
                  });
              }
            })
            .catch((e) => {
              noVaults(e);
              // setLoader(false);
            })
            .finally(() => {
              setLoader(false);
              setAllLoader(false);
            });
          break;
        case MY_VAULT:
          Vaults.getFilteredVaults(pass)
            .then((response) => {
            
              if (response.data.data) {
                if (size === 0 && no === 0)
                  count = response.data.data.count;
                let vault = handleResponse(response.data.data?.vaults);
                console.log("api: ", response);
                if (vault?.length){
                  setDisplayData(prevData => {
                    return {
                      ...prevData,
                         myVaults:
                      displayData?.myVaults?.length === 0 ||
                      pass.pageNumber === 0
                        ? vault
                        : displayData?.myVaults?.concat(vault),
                    count: response.data.data.count,
                    myVaultsCount: response?.data?.data?.count,
                    }
                  })
                }
              }
            })
            .catch((e) => {
              noVaults(e);
            })
            .finally(() => {
              setLoader(false);
              setMyVaultsLoader(false);
            });
          break;
        case FEATURED_VAULT:
          setFeaturedLoader(true);
          Vaults.getFilteredVaults(pass)
            .then((response) => {
              if (response.data.data) {
                if (size === 0 && no === 0)
                  count = response.data.data.count;
                let vault = handleResponse(response.data.data?.vaults);
                setDisplayData(prevData => {
                  return {
                    ...prevData,
                       featured:
                    displayData?.featured?.length === 0 || pass.pageNumber === 0
                      ? vault
                      : displayData?.featured?.concat(vault),
                  count: response.data.data.counts,
                  featuredCount: response?.data?.data?.counts,
                  }
                })
              }
            })
            .catch((e) => {
              noVaults(e);
            })
            .finally(() => {
              setLoader(false);
              setFeaturedLoader(false);
            });
          break;
        case STABLE_COINS:
          setStableLoader(true);
          Vaults.getFilteredVaults(pass)
            .then((response) => {
              if (response.data.data) {
                if (size === 0 && no === 0)
                  count = response.data.data.count;
                let vault = handleResponse(response.data.data?.vaults);
                setDisplayData({
                  ...displayData,
                  stable:
                    displayData?.stable?.length === 0 || pass.pageNumber === 0
                      ? vault
                      : displayData?.stable?.concat(vault),
                  count: response.data.data.count,
                  stableCount: response.data.data.count,
                });
              }
            })
            .catch((e) => {
              noVaults(e);
            })
            .finally(() => {
              setLoader(false);
              setStableLoader(false);
            });
          break;

        default:
          noVaults("Not filter");
          break;
      }
  };

  const ViewVault = ({ v = view }) => {
    const value = displayData;
    if (v === 2) {
      return <ComingSoon />;
    } else if (
      loader ||
      (filterType === MY_VAULT && myVaultsLoader) ||
      (filterType === FEATURED_VAULT && featuredLoader) ||
      (filterType === STABLE_COINS && stableLoader) ||
      (filterType === ALL_VAULT && allLoader)
    ) {
      return <CustomLoader />;
    } else if (v === 1) {
      if (filterType === MY_VAULT)
        return (
          <VaultTable
            data={value.myVaults?.slice(
              pageNo * pageSize,
              (pageNo + 1) * pageSize
            )}
            all={value.all}
            setPageNo={setPageNo}
            pageNo={pageNo}
            setPageSize={(p) => {
              setPageSize(p);
              setPageNo(0);
            }}
            pageSize={pageSize}
            total={value.count}
          />
        );
      else if (filterType === FEATURED_VAULT)
        return (
          <VaultTable
            data={value.featured?.slice(
              pageNo * pageSize,
              (pageNo + 1) * pageSize
            )}
            all={value.all}
            setPageNo={setPageNo}
            pageNo={pageNo}
            setPageSize={(p) => {
              setPageSize(p);
              setPageNo(0);
            }}
            pageSize={pageSize}
            total={value.count}
          />
        );
      else if (filterType === STABLE_COINS)
        return (
          <VaultTable
            data={value.stable?.slice(
              pageNo * pageSize,
              (pageNo + 1) * pageSize
            )}
            all={value.all}
            setPageNo={setPageNo}
            pageNo={pageNo}
            setPageSize={(p) => {
              setPageSize(p);
              setPageNo(0);
            }}
            pageSize={pageSize}
            total={value.count}
          />
        );
      else if (filterType === ALL_VAULT)
        return (
          <VaultTable
            data={value.all?.slice(pageNo * pageSize, (pageNo + 1) * pageSize)}
            all={value.all}
            setPageNo={setPageNo}
            pageNo={pageNo}
            setPageSize={(p) => {
              setPageSize(p);
              setPageNo(0);
            }}
            pageSize={pageSize}
            total={value.count}
          />
        );
      else return <></>;
    } else return <></>;
  };

  useEffect(() => {
    switch (selected) {
      case "1":
        setFilterType(MY_VAULT);
        break;
      case "2":
        setFilterType(FEATURED_VAULT);
        break;
      case "3":
        setFilterType(STABLE_COINS);
        break;
      case "4":
        setFilterType(ALL_VAULT);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (filterType === MY_VAULT && userAddress === null) {
      setFilterType(FEATURED_VAULT);
      localStorage.setItem("type", "2");
    }
  }, [userAddress]);

  useEffect(() => {
    noVaults();
  }, [searchContent]);

  useEffect(() => {
    setLoader(true);
    displayVaults(pageSize, pageNo, searchContent, baseCurrency);
  }, [pageSize, pageNo, filterType, baseCurrency, searchContent, view]);

  return (
    <div className="main-wrapper" id="exploreVault">
      <div className="header">
        <NavigationBar />
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="main">
            <div className="baseHeading">
              <h1>Explore Vaults</h1>
              <p>
                Manage Your Own Vaults Or Explore Vaults Developed By Others
              </p>
            </div>
            <div className="mainDataWrap">
              <div className="tabCardData">
                <div className="ant-tabs ant-tabs-top">
                  <div className="ant-tabs-nav">
                    <div className="ant-tabs-nav-wrap">
                      <div className="ant-tabs-nav-list">
                        {userAddress !== null ? (
                          <div
                            className={`ant-tabs-tab ${
                              selected === "1" ? "ant-tabs-tab-active" : ""
                            }`}
                            onClick={() => vaultChange("1")}
                          >
                            <div className="ant-tabs-tab-btn">My Vaults</div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className={`ant-tabs-tab ${
                            selected === "2" ? "ant-tabs-tab-active" : ""
                          }`}
                          onClick={() => vaultChange("2")}
                        >
                          <div className="ant-tabs-tab-btn">
                            Featured Vaults
                          </div>
                        </div>
                        <div
                          className={`ant-tabs-tab ${
                            selected === "3" ? "ant-tabs-tab-active" : ""
                          }`}
                          onClick={() => vaultChange("3")}
                        >
                          <div className="ant-tabs-tab-btn">Stable Coins</div>
                        </div>
                        <div
                          className={`ant-tabs-tab ${
                            selected === "4" ? "ant-tabs-tab-active" : ""
                          }`}
                          onClick={() => vaultChange("4")}
                        >
                          <div className="ant-tabs-tab-btn">All</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Tabs
                  defaultActiveKey={selected}
                  onChange={(key) => vaultChange(key)}
                > */}
                <div className="searchvault">
                  <div className="leftSearch">
                    <Form name="search-vault" autoComplete="off">
                      <div className="formElementz">
                        <Form.Item
                          label="Filter by Asset, Protocol or Name"
                          name="Filter by name"
                        >
                          <Popover
                            placement="bottom"
                            visible={
                              autoCompleteData && autoCompleteData.length
                            }
                            overlayStyle={{
                              maxHeight: "400px",
                              padding: "0",
                              marginTop: "25px",
                              display:
                                autoCompleteData?.length !== 0
                                  ? "flex"
                                  : "none",
                            }}
                            content={autoCompleteData?.map((v) => (
                              <li
                                key={v}
                                onClick={() => {
                                  setLoader(true);
                                  setSearch(v);
                                  setSearchContent(v);
                                  setAutoCompleteData([]);
                                }}
                              >
                                {v}
                              </li>
                            ))}
                            overlayInnerStyle={{
                              maxHeight: "400px",
                              overflowY: "auto",
                              cursor: "pointer",
                              padding: 0,
                            }}
                          >
                            <Input.Search
                              placeholder="Enter a search term"
                              className="termSearch"
                              prefix={<SearchOutlined />}
                              onChange={(e) => handleSearch(e)}
                              allowClear={true}
                              value={search}
                              enterButton={""}
                              onSearch={(v) => {
                                if (!v) {
                                  setSearch();
                                  setSearchContent();
                                  setAutoCompleteData([]);
                                  noVaults();
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.code === "Backspace") {
                                  setSearch(search.slice(0, -1));
                                  setAutoCompleteData([]);
                                } else {
                                  if (e.key.match(/^[A-Za-z0-9]$/))
                                    setSearch(search ? search + e.key : e.key);
                                }
                              }}
                            />
                          </Popover>
                        </Form.Item>
                      </div>
                      <div className="formElementz">
                        <Form.Item
                          label="Filter by Base Asset"
                          name="Filter by currency"
                        >
                          <Select
                            placeholder="Coming Soon"
                            disabled={true}
                            suffixIcon={<div className="downArrow"></div>}
                            onSelect={(e) => handleFilterByBaseCurrency(e)}
                            allowClear={true}
                            onClear={() => {
                              setPageNo(0);
                              noVaults();
                              setBaseCurrency();
                            }}
                          >
                            {currencyData.map((currency) => (
                              <Option key={currency.toUpperCase()}>
                                {currency.toUpperCase()}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                  <div className="RightSearch">
                    <div className="boxFormType switchRadio">
                      <span>Select a view</span>
                      <Radio.Group
                        options={ViewData}
                        onChange={handleViewChange}
                        value={ViewData.value}
                        optionType="button"
                        buttonStyle="solid"
                        className={
                          view === 0
                            ? "grid-view-active"
                            : view === 1
                            ? "list-view-active"
                            : "chart-view-active"
                        }
                      />
                    </div>
                  </div>
                </div>
                {userAddress !== null ? (
                  filterType === MY_VAULT ? (
                    <>
                      {view === 0 ? (
                        <VaultGrid
                          data={displayData.myVaults}
                          total={displayData.myVaultsCount}
                          setPageNo={setPageNo}
                          loader={myVaultsLoader}
                          filterType={filterType}
                          search={searchContent}
                          baseCurrency={baseCurrency}
                          pageNo={pageNo}
                          fetchMoreData={fetchMoreData}
                          handleResponse={handleResponse}
                        />
                      ) : (
                        <ViewVault v={view} />
                      )}
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {filterType === FEATURED_VAULT ? (
                  <>
                    {view === 0 ? (
                      <VaultGrid
                        data={displayData.featured}
                        total={displayData.featuredCount}
                        setPageNo={setPageNo}
                        loader={featuredLoader}
                        filterType={filterType}
                        search={searchContent}
                        baseCurrency={baseCurrency}
                        pageNo={pageNo}
                        fetchMoreData={fetchMoreData}
                        handleResponse={handleResponse}
                      />
                    ) : (
                      <ViewVault v={view} />
                    )}
                  </>
                ) : (
                  <></>
                )}
                {filterType === STABLE_COINS ? (
                  <>
                    {view === 0 ? (
                      <VaultGrid
                        data={displayData.stable}
                        total={displayData.count}
                        setPageNo={setPageNo}
                        loader={stableLoader}
                        filterType={filterType}
                        search={searchContent}
                        baseCurrency={baseCurrency}
                        pageNo={pageNo}
                        fetchMoreData={fetchMoreData}
                        handleResponse={handleResponse}
                      />
                    ) : (
                      <ViewVault v={view} />
                    )}
                  </>
                ) : (
                  <></>
                )}
                {filterType === ALL_VAULT ? (
                  <>
                    {view === 0 ? (
                      <VaultGrid
                        data={displayData.all}
                        total={displayData.allCount}
                        setPageNo={setPageNo}
                        loader={allLoader}
                        filterType={filterType}
                        search={searchContent}
                        baseCurrency={baseCurrency}
                        pageNo={pageNo}
                        fetchMoreData={fetchMoreData}
                        account={account}
                        handleResponse={handleResponse}
                      />
                    ) : (
                      <ViewVault v={view} />
                    )}
                  </>
                ) : (
                  <></>
                )}
                {/* </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ExploreVault;
