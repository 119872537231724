import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ReactComponent as Draggable } from "../../assets/icons/drag.svg";
import "./Style/index.css";
const { Panel } = Collapse;

function CollapseList(props) {
  const [selectedFields, setSelectedFields] = useState([]);
  const [assetsList, setAssetsList] = useState(props.fields?.asset);
  const [protocolList, setProtocolList] = useState(props.fields?.protocol);
  const [vaultList, setVaultList] = useState(props.fields?.vault);

  const [i, drag, preview] = useDrag({
    type: "asset",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [j, drag2, preview2] = useDrag({
    type: "vault",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [k, drag3, preview3] = useDrag({
    type: "protocol",
    item: () => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [l, drag4, preview4] = useDrag({
    type: "deposit",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [m, drag5, preview5] = useDrag({
    type: "withdraw",
    item: () => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview2(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview3(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview4(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview5(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  const clearItemSelection = () => {
    setSelectedFields([]);
  };

  const handleItemSelection = (index, type) => {
    let newSelectedFields;
    switch (type) {
      case "asset":
        let asset = assetsList[index];
        asset["type"] = type;
        newSelectedFields = [asset];
        // newSelectedFields = [...selectedFields, asset];
        break;
      case "vault":
        let vault = vaultList[index];
        vault["type"] = type;
        newSelectedFields = [vault];
        // newSelectedFields = [...selectedFields, vault];
        break;
      case "protocol":
        let protocol = protocolList[index];
        protocol["type"] = type;
        newSelectedFields = [protocol];
        // newSelectedFields = [...selectedFields, protocol];
        break;
      case "deposit":
        let deposit = props.fields.deposit[index];
        deposit["type"] = type;
        newSelectedFields = [deposit];
        break;
      case "withdraw":
        let withdraw = props.fields.withdraw[index];
        withdraw["type"] = type;
        newSelectedFields = [withdraw];
        break;
      default:
        break;
    }
    setSelectedFields(newSelectedFields);
  };

  useEffect(() => {
    clearItemSelection();
  }, []);
  useEffect(() => {
    setAssetsList(
      props.fields?.asset?.filter((val) => {
        if (props.searchValue == "") {
          return true;
        } else if (
          val.assetName
            .toLowerCase()
            .includes(props.searchValue.toLowerCase()) ||
          val.assetAddress
            .toLowerCase()
            .includes(props.searchValue.toLowerCase()) ||
          val.assetSymbol
            .toLowerCase()
            .includes(props.searchValue.toLowerCase())
        ) {
          return true;
        } else return false;
      })
    );
    setProtocolList(
      props.fields?.protocol?.filter((val) => {
        if (props.searchValue == "") {
          return true;
        } else if (
          val.protocolName
            .toLowerCase()
            .includes(props.searchValue.toLowerCase()) ||
          val.protocolAddress
            .toLowerCase()
            .includes(props.searchValue.toLowerCase()) ||
          val.protocolSymbol
            .toLowerCase()
            .includes(props.searchValue.toLowerCase())
        ) {
          return true;
        } else return false;
      })
    );
    setVaultList(
      props.fields?.vault?.filter((val) => {
        if (props.searchValue == "") {
          return true;
        } else if (
          val.vaultName
            .toLowerCase()
            .includes(props.searchValue.toLowerCase()) ||
          val.vaultAddress
            .toLowerCase()
            .includes(props.searchValue.toLowerCase())
        ) {
          return true;
        } else return false;
      })
    );
  }, [props.searchValue]);

  return (
    <Collapse collapsible="true" ghost className="collapse">
      <Panel header={<div id="head">Assets</div>} key="1" className="panel">
        <div className="list" ref={drag}>
          {assetsList.map((e, i) => (
            <div
              className="list-items"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "asset")}
            >
              <div className="logo">
                {e.assetImageURL ? (
                  <img src={e.assetImageURL} alt={e.assetSymbol.slice(0, 3)} />
                ) : (
                  <span>{e.assetSymbol.slice(0, 3)}</span>
                )}
              </div>
              <div className="list-text">{e.assetName}</div>
              <div className="list-right-icon">
                <Draggable />
              </div>
            </div>
          ))}
        </div>
      </Panel>
      <Panel header={<div id="head">Protocols</div>} key="3" className="panel">
        <div className="list" ref={drag3}>
          {protocolList.map((e, i) => (
            <div
              className="list-items"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "protocol")}
            >
              <div className="logo">{e.protocolSymbol.slice(0, 2)}</div>
              <div className="list-text">{e.protocolName}</div>
              <div className="list-right-icon">
                <Draggable />
              </div>
            </div>
          ))}
        </div>
      </Panel>
      <Panel header={<div id="head">Vaults</div>} key="2" className="panel">
        <div className="list" ref={drag2}>
          {vaultList &&
            vaultList.map((e, i) => (
              <div
                className="list-items"
                key={i}
                onMouseEnter={() => handleItemSelection(i, "vault")}
              >
                <div className="logo">
                  {e.logoImg ? (
                    <img
                      src={`data:image/jpeg;base64,${e.logoImg.data}`}
                      alt=""
                    />
                  ) : (
                    <span>{e.vaultName.slice(0, 3)}</span>
                  )}
                </div>
                <div className="list-text">{e.vaultName}</div>
                <div className="list-right-icon">
                  <Draggable />
                </div>
              </div>
            ))}
        </div>
      </Panel>
      <Panel
        header={<div id="head">Deposit Strategy</div>}
        key="4"
        className="panel"
      >
        <div className="list" ref={drag4}>
          {props.fields?.deposit?.map((e, i) => (
            <div
              className="list-items"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "deposit")}
            >
              <div className="logo">
                {e.strategyImgURL ? (
                  <img src={e.strategyImgURL} alt={e.name} />
                ) : (
                  <span>{e.name}</span>
                )}
              </div>
              <div className="list-text">{e.name}</div>
              <div className="list-right-icon">
                <Draggable />
              </div>
            </div>
          ))}
        </div>
      </Panel>
      <Panel
        header={<div id="head">Withdraw Strategy</div>}
        key="5"
        className="panel"
      >
        <div className="list" ref={drag5}>
          {props.fields?.withdraw?.map((e, i) => (
            <div
              className="list-items"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "withdraw")}
            >
              <div className="logo">
                {e.strategyImgURL ? (
                  <img src={e.strategyImgURL} alt={e.name} />
                ) : (
                  <span>{e.name}</span>
                )}
              </div>
              <div className="list-text">{e.name}</div>
              <div className="list-right-icon">
                <Draggable />
              </div>
            </div>
          ))}
        </div>
      </Panel>
    </Collapse>
  );
}

export default CollapseList;
