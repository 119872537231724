export const footerNameConfig = [
  {
    name: "Terms",
    path: "/terms-of-use",
  },
  {
    name: "Licenses",
    path: "",
  },
  {
    name: "Imprint",
    path: "",
  },
  {
    name: "Cookie Policy",
    path: "",
  },
  {
    name: "Preferences",
    path: "",
  },
  {
    name: process.env.REACT_APP_VERSION,
  },
];
