export const tokenBalance = (value) => async (dispatch) => {
   
        // const valueToFloat = parseFloat(value)
        dispatch({
        type: "GET_TOKEN_BALANCE",
        payload: {
            tokenBalance: value
        }
      })
  };

  
