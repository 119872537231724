import React from "react";
import "./Style/index.css";

function BasicCard(props) {
  const handleClick = () => {
    let click = "";
    if (props.tick) click = "click";
    return click;
  };

  return (
    <div className="boxVault">
      <div
        className={props.active ? "active" : props.color}
        onFocus={props.onFocus}
      >
        <div className="heading">
          <>{props.title ? props.title : "Basic Features"}</>
          <button className={`title-button${handleClick()}`}>✓</button>
        </div>
        <div className="subtext">{props.subtext ? props.subtext : ""}</div>
        <div className="contents">
          {props.children ? (
            <div id={props.id}>{props.children}</div>
          ) : (
            <div>
              {[...Array(props.number)].map((v, i) => (
                <div className="content-items" key={i}>
                  <div className="add">
                    <button>+</button>
                  </div>
                  <span className="line" />
                  <div className="text">{i}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BasicCard;
