import Skeleton from "@material-ui/lab/Skeleton";
import { Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PercentCard from "../Percentage-Card/NewPercentCard";
import "./Card_Styler/PriceCardStyler.css";

function PriceCard(props) {
  let {
    value,
    title,
    vaultAddress,
    isChartLoading,
    percentvalue,
    toggle,
    useCurrency,
    baseCurrency,
  } = props;
  const vaultIcon = useSelector((state) => state.get_vaultIcon.vaultIcon);
  const getCurrency = useSelector((state) => state.get_assets.currency);

  function nFormatter(num, digits = 2) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits) + si[i].symbol;
  }

  // const isChartLoading = true
  return (
    <div className="price-card-styler">
      <div className="new-price-card-styler-second-flex">
        <p className="new-price-card-styler-second-flex-title">{title}</p>
        <div>
          {title == "Price per Token" ? (
            <PercentCard
              value={percentvalue}
              toggle={toggle}
              isChartLoading={isChartLoading}
            />
          ) : (
            <div style={{ height: "17px" }}></div>
          )}
        </div>
        <div className="price-and-usd-texting-styling">
          {isChartLoading ? (
            title == "Price per Token" ? (
              <Skeleton
                animation="wave"
                // variant="circle"
                height={40}
                width="60%"
                style={{ marginBottom: 6, marginLeft: 0, marginTop: "-24px" }}
              />
            ) : (
              <Skeleton
                animation="wave"
                // variant="circle"
                height={40}
                width="60%"
                style={{ marginTop: -5 }}
              />
            )
          ) : (
            <Tooltip
              style={{ color: "black" }}
              title={parseFloat(value)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              // color={'#e5e5e5'}
              key={"amount"}
              placement="left"
            >
              <p
                style={{
                  color: "#164F58",
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginBottom: 0,
                }}
              >
                {nFormatter(parseFloat(value))}
              </p>
            </Tooltip>
          )}
          {isChartLoading ? (
            title == "Price per Token" ? (
              <Skeleton
                animation="wave"
                // variant="circle"
                height={20}
                width="20%"
                style={{ marginBottom: 6, marginLeft: 5, marginTop: -6 }}
              />
            ) : (
              <Skeleton
                animation="wave"
                // variant="circle"
                height={20}
                width="20%"
                style={{ marginBottom: 6, marginLeft: 5, marginTop: 12 }}
              />
            )
          ) : (
            <p
              style={{
                color: "#6D7681",
                fontSize: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                paddingBottom: "3px",
                paddingLeft: "5px",
                marginBottom: 0,
              }}
            >
              {useCurrency == true
                ? getCurrency.toUpperCase()
                : baseCurrency && baseCurrency.toUpperCase()}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PriceCard;
