const initState = {
    isConnected:false,
  
};

const IsConnectedReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_ISCONNECTED":
            return { 
                ...state,
                isConnected: action.payload.isConnected,
            }

        default: 
            return state;
    }
}

export default IsConnectedReducer;