import { Fragment } from "react";
import "./Style/style.css";
import addButtonWhite from "../../../assets/Custom-Strategy/addButtonWhite.svg";
import AddCustomStrategyImage from "../../../assets/Custom-Strategy/AddCustomStrategyMainImage.svg";
import { CustomDialog } from "react-st-modal";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import SDKMOdal from "../../SDKModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
  },
});

const AddCustomStrategy = () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="main-box-container">
        <img
          src={AddCustomStrategyImage}
          alt=""
          className="add-custom-strategy-main-image"
        ></img>
        <button
          className="add-custom-strategy-button"
          onClick={async () => {
            const result = await CustomDialog(
              <MuiThemeProvider theme={theme}>
                <SDKMOdal dispatch={dispatch}/>{" "}
              </MuiThemeProvider>,
              {}
            );
          }}
        >
          <img src={addButtonWhite} alt=""></img>
          <p>Add Strategy</p>
        </button>
      </div>
    </Fragment>
  );
};
export default AddCustomStrategy;
