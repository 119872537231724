import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 110 ? 100 : prevProgress + 1));
      // setProgress((oldProgress) => {
        
        // if (oldProgress === 100) {
        //   return 100;
        // }
        // if(oldProgress == 0)
        //   return 10
        // if(oldProgress == 10)
        //   return 20
        // if(oldProgress == 20)
        //   return 30
        // if(oldProgress == 30)
        //   return 40
        // if(oldProgress == 40)
        //   return 50
        // if(oldProgress == 50)
        //   return 60
        // if(oldProgress == 60)
        //   return 70
        // if(oldProgress == 70)
        //   return 80
        // if(oldProgress == 80)
        //   return 90
        // if(oldProgress == 90)
        //   return 100
        // else
        //   return 0

        // if (oldProgress === 100) {
        //   return 100;
        // }
        // if(oldProgress == 0)
        //   return 20
        // if(oldProgress == 20)
        //   return 40
        // if(oldProgress == 40)
        //   return 60
        // if(oldProgress == 60)
        //   return 80
        // if(oldProgress == 80)
        //   return 100
        // else
        //   return 0
        // const diff = Math.random() * 10;
        // return Math.min(oldProgress + diff, 100);
        // return 100
      // });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(()=>{
    // const timer = setInterval(() => {
    //   props.timeOut()
    // }, 5850);

    // return () => {
    //   clearInterval(timer);
    // };
    if(progress == 110)
    props.timeOut()
    
  },[progress])

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}
