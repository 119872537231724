import axios from 'axios';


//Action creator


  export const setLoaderAnimation = (val) => async(dispatch) => {
      
    dispatch({
      type: "SET_LOADER",
      payload: {
        setLoader: val
      }
    })
  }

