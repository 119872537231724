import React from "react";
import Footer from "../components/Footer/index";
import NavigationBar from "../components/Navbar";

export default function NotFound({ children }) {
  return (
    <div className="mainWrapper">
      <div className="header">
        <NavigationBar />
      </div>
      {children}
      <Footer />
    </div>
  );
}
