import React, { useState } from "react";
import "./Layout.css";
import Sidebar from "../components/Sidebar/index";
import Navbar from "../components/Navbar/index";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import AssetDetailsMain from "../components/Asset-Details/AssetDetailsMain";
import SideBarSettings from "../components/SidebarSettings/Sidebar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import DashBoardV2 from "../components/Dashboard-Version2/Main-Dashboard";
import { useSelector } from "react-redux";
import TransactionTable from "../components/Transaction-Table/Main-Transaction";
import Strategies from "../components/Strategies";
import SafeDetails from "../components/Safe";
import BreadCrumb from "../components/BreadCrumb/breadCrumb";
import CustomStrategySection from "../components/Custom-Strategy-Section/Main-Screen/mainScreen";
import Hidden from "@material-ui/core/Hidden";
import RightSideComponent from "../components/RightSideContainer/rightSideContainer";
import { setVaultAddress } from "../redux/actions/VaultAddress";
import { safeName } from "../redux/actions/SafeName";
import { tokenSymbol } from "../redux/actions/tokenSymbol";
import { vaultIcon } from "../redux/actions/vaultIcon";
import { useDispatch } from "react-redux";
import { Vaults } from "../services/api";
import { setVaultDetails } from "../redux/actions/vaultDetails";
import { setLoaderAnimation } from "../redux/actions/LoaderAction";
import LogoLoader from "../components/Akkrue-Official-Logo-Loader/LogoLoader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    muiroot: {
      zIndex: 5,
    },

    appBar: {
      padding: 0,
      zIndex: 4,
    },
    drawer: {
      width: 230,
      height: "100vh",
      flexShrink: 0,
      [theme.breakpoints.down("1640")]: {
        width: 200,
      },
    },
    drawerPaper: {
      width: 230,
      overflow: "hidden",
      zIndex: 0,
      backgroundColor: "#164F58",
      borderRight: 0,
      [theme.breakpoints.down("1640")]: {
        width: 200,
      },
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: "24px 30px",
      // position: "relative",
      // top: "60px",
    },
  })
);
function Layout() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [networkData, setNetworkData] = useState("main");
  const networkRedux = useSelector((state) => state.get_network_type.network);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const [responsiveOpen, setResponsiveOpen] = useState(false);
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const web3 = useSelector((state) => state.get_web3.web3);
  const loader = useSelector((state) => state.get_loader_animation.isLoading);
  const accountAddress = localStorage.getItem("accountAddress");
  const networkType = localStorage.getItem("network");

  const vaultAddressCheck = async () => {
    let VaultAddress;
    let pathArray = location.pathname.split("/");

    VaultAddress = pathArray[2];

    if (VaultAddress) {
      dispatch(setLoaderAnimation(true));
      Vaults.getVaultDetails(VaultAddress)
        .then((res) => {
          if (
            res.data.data &&
            res.data.status == 1 &&
            res.data.data.length != 0
          ) {
            dispatch(setVaultDetails(res.data.data));
            dispatch(setVaultAddress(VaultAddress));
            dispatch(safeName(res.data.data.vault.vaultName));
            dispatch(tokenSymbol(res.data.data.vault.rewardTokenSymbol));
            dispatch(vaultIcon(res.data.data.vault.logoImg?.data));

            localStorage.setItem("newSafeAddress", VaultAddress);
            localStorage.setItem(
              "vaultSymbol",
              res.data.data.vault.rewardTokenSymbol
            );
            localStorage.setItem(
              "vaultIcon",
              res.data.data.vault.logoImg?.data
            );
            localStorage.setItem("vaultName", res.data.data.vault.vaultName);
            localStorage.setItem("vaultId",res.data.data.vault.id)
          } else {
            console.log("Error 404 !Vault address not found");
            history.push("/404");
          }
          dispatch(setLoaderAnimation(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoaderAnimation(false));
        });
    }
  };

  useEffect(() => {
    if (networkRedux == "") {
      setNetworkData(localStorage.getItem("network"));
    } else {
      setNetworkData(networkRedux);
    }
  }, [localStorage.getItem("network"), networkRedux]);

  useEffect(() => {
    // var emergencyBreak = false;
    // var god = "";
    // if (userAddress) {
    //   axios.get(getConfig()).then((data) => {
    //     if (data.data.data.length > 0) god = data.data.data[0].GODAddress;
    //     axios.get(getVault(vaultAddress)).then((data) => {
    //       emergencyBreak = data.data.data.emergencyBreak;
    //       if (emergencyBreak) {
    //         if (userAddress.toUpperCase() != god.toUpperCase()) {
    //           message.error("Safe disabled ! Only God can access");
    //           history.push("/v1");
    //         }
    //       }
    //     });
    //   });
    // }
    vaultAddressCheck();
  }, []);

  useEffect(() => {
    vaultAddressCheck();
  }, [web3]);
  
  const responsiveHamburgerHandler = () => {
    setResponsiveOpen(true);
  };
  const handleDrawerToggle = () => {
    setResponsiveOpen(false);
  };

  return (
    <>
      {loader ? (
        <LogoLoader />
      ) : (
        <div className={classes.root}>
          <AppBar
            className={classes.appBar}
            classes={{ root: classes.muiroot }}
          >
            <Navbar onClick={responsiveHamburgerHandler} />
          </AppBar>

          <Hidden mdDown>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Sidebar />
            </Drawer>
          </Hidden>
          <Hidden lgUp>
            <Drawer
              className={classes.drawer}
              variant="temporary"
              open={responsiveOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Sidebar />
            </Drawer>
          </Hidden>

          <Switch>
            <main className={classes.content}>
              <CssBaseline />
              <Toolbar />
              <BreadCrumb />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Route
                    path="/vault/:address/dashboard"
                    component={DashBoardV2}
                  />
                  <Route
                    path="/vault/:address/assets"
                    component={AssetDetailsMain}
                  />
                  <Route
                    path="/vault/:address/strategy"
                    component={Strategies}
                  />
                  <Route
                    path="/vault/:address/vault/manage"
                    component={SideBarSettings}
                  />
                  <Route
                    path="/vault/:address/custom-strategies"
                    component={CustomStrategySection}
                  />
                  <Route
                    path="/vault/:address/vault/transactions"
                    component={TransactionTable}
                  />

                  <Route path="/vault/:address/helpcenter" />
                  <Route
                    path="/vault/:address/vault/safe"
                    component={SafeDetails}
                  />
                </div>
                <div style={{ marginTop: "65px" }}>
                  <RightSideComponent />
                </div>
              </div>
            </main>
          </Switch>
        </div>
      )}
    </>
  );
}

export default Layout;
