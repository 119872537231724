import { ArrowBack } from "@material-ui/icons";
import React from "react";
import "./Style/index.css";

function Preview(props) {
  let description = props.value;
  return (
    <div className="preview-wrapper">
      <div className="back-and-preview">
        <div className="back" onClick={() => props.back(false)}>
          <ArrowBack fontSize="small"/>
          <span className="go-back"> GO BACK</span>
        </div>
        <div className="preview">
          <span>PREVIEW</span>
        </div>
      </div>
      <div className="preview-text-wrapper">
        <div className="dangerously-set" dangerouslySetInnerHTML={{ __html: description }}/>
      </div>
      <div className="button-wrap">
        <button className="button-content" onClick={props.handleSubmit}>
          SAVE DESCRIPTION
        </button>
      </div>
    </div>
  );
}

export default Preview;
