import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Vaults, WithdrawQueue } from "../../../services/api";
import CustomLoader from "../../Custom_Loader/customLoader";
import NoData from "../../No-Data-Component/noData";
import ExchangeTransactionLabel from "../TableStyledButton/exchangeTransactionLabel";
import SendAndReceiveButton from "../TableStyledButton/sendReceiveButton";
import SuccessOrFailureButton from "../TableStyledButton/successFailureButton";
import UpArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-uparrow.svg";
import DownArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-arrow.svg";
import Copy from "../../../../src/assets/Transaction-Table/copy.svg";
import ReRoute from "../../../../src/assets/Transaction-Table/reroute.svg";
import { assetReservations } from "../../../utils/consts";

const EpochConvertor = (value, condition) => {
  // var utcSeconds = value;
  // var d = new Date(0);
  // d.setUTCSeconds(utcSeconds);
  var d = new Date(value);

  var date = d.getDate();
  date = date < 10 ? "0" + date : date;
  var month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var year = d.getFullYear();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var final = hours + ":" + minutes + ":" + seconds + " " + ampm;
  var date_and_time =
    date +
    "/" +
    month +
    "/" +
    year +
    "    " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    ampm;
  if (condition == 1) {
    return final;
  } else if (condition == 0) {
    return date_and_time;
  }
};

function Row(props) {
  const { row, statusVariable } = props;
  const handleCopy = (value) => {
    if (vaultAddress && userAddress) {
      copy(value);
    }
  };

  const nFormatter = (n) => {
    try {
      if (n < 1e3) return n?.toFixed(2);
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const userAddress = useSelector((state) => state.get_user_address.address);

  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #DEE5EF",
        }}
      >
        <TableCell component="th" scope="row">
          {/* {<SendAndReceiveButton send_or_receive={row.send_receive_status} />} */}
          <p className="history-table-inner-contents">
            {EpochConvertor(row.timeStamp, 0)}
          </p>
        </TableCell>
        <TableCell>
          <p className="history-table-inner-contents">
            {nFormatter(row.requiredAmount / 10 ** 18)}
          </p>
        </TableCell>
        <TableCell>
          <p className="history-table-inner-contents">
            {assetReservations[row?.status] ?? ""}
          </p>
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <img
                src={UpArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            ) : (
              <img
                src={DownArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="collapse-table-style">
              <div style={{ display: "flex", gap: "8vw", marginTop: "10px" }}>
                <div>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    Asset
                  </p>
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <p className="history-table-inner-contents">
                      {row.toTokenAddress}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const AssetReservations = () => {
  const location = useLocation();
  const [tableContents, setTableContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const userAddress = useSelector((state) => state.get_user_address.address);

  const fetchPendingData = () => {
    WithdrawQueue.getPendingAssetReservation(vaultAddress, userAddress)
      .then((result) => {
        if (result.data) {
          const rowData = result.data?.data;
          if (rowData) {
            setTableContents(rowData);
          } else {
            setTableContents([]);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPendingData();
    setLoading(true);
  }, []);

  return (
    <>
      <p className="queue-heading-styler">Withdrawals</p>
      <p className="whitelist-descriptor">
        All withdrawals are displayed below.
      </p>
      {loading ? (
        <CustomLoader />
      ) : tableContents.length === 0 ? (
        <NoData text="No Withdrawals Transactions" />
      ) : (
        <TableContainer
          component={Paper}
          className="history-table-custom-scroll"
        >
          <Table>
            <TableHead
              style={{
                paddingBottom: "0px",
                borderBottom: "1px solid #DEE5EF",
              }}
            >
              <TableRow>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Date</p>
                </TableCell>
                {/* <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Asset</p>
                </TableCell> */}
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Amount</p>
                </TableCell>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContents.map((row) => {
                return <Row key={row.txnId} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AssetReservations;
