import React, { Component } from 'react';
import "./Styling/TableStyledButton.css"
import exchange_image from './exchange-image.svg'
const ExchangeTransactionLabel = (props) => {
    const { from_address, to_address } = props;
    return (
        <>
            {(from_address == '0xe91d4869e3346824bc1e9a52fb778276b1402b1f' || to_address == '0xe91d4869e3346824bc1e9a52fb778276b1402b1f') ?
            <div  className="tooltip">
                <span class="tooltiptext"><p>Exchange Transaction</p></span>
                   <img style={{height:"16px",width:"16px"}} src={exchange_image}></img>
            </div>
                :
                <></>
            }
        </>
    )

}

export default ExchangeTransactionLabel