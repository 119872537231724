

  export const setVaultAddress = (val) => async(dispatch) => {
    dispatch({
      type: "SET_VAULT_ADDRESS",
      payload: {
        setVaultAddress: val
      }
    })
  }

