import {
  onboard,
  upateUserAddress,
  updateNetwork,
  updateProvider,
} from "./blocknativeConfig.js";
import { store } from "../../index.js";
import { Provider } from "../../redux/actions/Provider.js";
import { getWeb3 } from "../../redux/actions/getWeb3";
import { getAccount } from "@wagmi/core";
import { ethers } from "ethers";
import { networkID } from "../../utils/consts.js";

export const handleWalletSelection = async (address, connect) => {
  if (!address || address == "") {
    if (onboard && connect) {
      // const walletSelected = await onboard.walletSelect();
      const wallets = await onboard.connectWallet();
      if (wallets[0]) {
        // create an ethers provider with the last connected wallet provider
        const ethersProvider = new ethers.providers.Web3Provider(
          wallets[0].provider,
          networkID
        );
        upateUserAddress(wallets[0]?.accounts?.[0]?.address);
        updateProvider({
          provider: ethersProvider.provider,
          name: wallets?.[0]?.label,
        });
        updateNetwork(networkID);
        return true;
      } else return false;
    } else return false;
  } else {
    const previouslySelectedWallet =
      window.localStorage.getItem("selectedWallet");
    if (previouslySelectedWallet) return true;
  }
};
