export const TransactionType = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  PROTOCOL_INTERACTION: "PROTOCOL_INTERACTION",
  ADVISOR_EXECUTION: "ADVISOR_EXECUTION",
  HARVEST: "HARVEST",
  SET_VAULT_ASSETS: "SET_VAULT_ASSETS",
  SET_VAULT_PROTOCOLS: "SET_VAULT_PROTOCOLS",
  CREATE_WHITELIST_GROUP: "CREATE_WHITELIST_GROUP",
  ADD_MEMBERS_TO_GROUP: "ADD_MEMBERS_TO_GROUP",
  REMOVE_MEMBERS_FROM_GROUP: "REMOVE_MEMBERS_FROM_GROUP",
  ADD_GROUP_TO_VAULT: "ADD_GROUP_TO_VAULT",
  REMOVE_GROUP_FROM_VAULT: "REMOVE_GROUP_FROM_VAULT",
  SET_BENEFICIARY_AND_PERCENTAGE: "SET_BENEFICIARY_AND_PERCENTAGE",
  CHANGE_VAULT_ADMIN: "CHANGE_VAULT_ADMIN",
  CHANGE_DEPOSIT_STRATEGY: "CHANGE_DEPOSIT_STRATEGY",
  CHANGE_WITHDRAW_STRATEGY: "CHANGE_WITHDRAW_STRATEGY",
  CHANGE_EMERGENCY_VAULT: "CHANGE_EMERGENCY_VAULT",
  ENABLE_EMERGENCY_EXIT: "ENABLE_EMERGENCY_EXIT",
  TOGGLE_EMERGENCY_BREAK: "TOGGLE_EMERGENCY_BREAK",
  SET_TIME_LOCKED_WITHDRAW:"SET_TIME_LOCKED_WITHDRAW"
};
