import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "5px",
      height: "15px",
      width: "100px",
      color: "#1B3E69",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      fontWeight: "bold",
      fontSize: "14px",
      textAlign: "right",
    },
  },
}));

export default function BasicTextFields(props) {
  const classes = useStyles();
  const { id, type } = props;

  const handleChange = (event) => {
    if (type == "withdraw") {
      props.setGasPriceWithdraw(event.target.value * 10 ** 9);
    } else {
      props.setInputGasPrice(event.target.value * 10 ** 9);
    }
  };

  const handleChangeGasEst = (event) => {
    if (type == "withdraw") {
      props.setEstGasWithdraw(event.target.value);
    } else {
      props.setInputGasEstimate(event.target.value);
    }
  };

  const handleChangeNonce = (event) => {
    if (type == "withdraw") {
      props.setNonceValueWithdraw(event.target.value);
    } else {
      props.setNonce(event.target.value);
    }
  };

  // if (id == 1) {
  //   return (
  //     <div style={{ width: "80%" }} id={props.estGasPrice}>
  //       <TextField
  //         id="outlined-basic"
  //         variant="outlined"
  //         defaultValue={props.estGasPrice}
  //         className={classes.root}
  //         fullWidth={true}
  //         onChange={handleChange}
  //       />
  //     </div>
  //   );
  // } else if (id == 2) {
  //   return (
  //     <div style={{ width: "80%" }} id={props.estGasPrice}>
  //       <TextField
  //         id="outlined-basic"
  //         variant="outlined"
  //         key={props.estGasPrice}
  //         defaultValue={props.estGasPrice}
  //         className={classes.root}
  //         fullWidth={true}
  //         onChange={handleChangeGasEst}
  //       />
  //     </div>
  //   );
  // }
  // else if (id == 3){
  //   return (
  //     <div style={{ width: "80%" }} id={props.estGasPrice}>
  //       <TextField
  //         id="outlined-basic"
  //         variant="outlined"
  //         key={props.estGasPrice}
  //         defaultValue={props.estGasPrice}
  //         className={classes.root}
  //         fullWidth={true}
  //         onChange={handleChangeNonce}
  //       />
  //     </div>
  //   );
  // }
  return (
    <div style={{ width: "80%" }} id={props.estGasPrice}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        key={props.estGasPrice}
        defaultValue={props.estGasPrice}
        className={classes.root}
        fullWidth={true}
        onChange={
          id == 1
            ? handleChange
            : id == 2
            ? handleChangeGasEst
            : id == 3
            ? handleChangeNonce
            : ""
        }
      />
    </div>
  );
}
