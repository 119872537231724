import React,{ useEffect } from "react";
import "./Transaction-Info-Card-Styling/style.css";
import SuccessMark from "../../assets/Transaction-Table/successmark.svg";
import { useSelector, useDispatch } from "react-redux";
import { AddtoQueue } from "../../redux/actions/AddtoQueue";
import LinearDeterminate from "./ProgressBar";
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'
const TransactionInfo = () => {

  const dispatch = useDispatch();
  const queueStatus = useSelector(
    (state) => state.get_queue_status.queueStatus
  );
  const history = useHistory();
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const [pause,setPause] = React.useState(false)
  const [width, setWidth] = React.useState(1);
  const [timer, setTimer] = React.useState();

  useEffect(()=>{
  var elem = document.getElementById("myBar");
    if (width >= 100) {
      timeOut();
    } else {
      if (!pause) {
        elem.style.width = width + 0.25 + "%";
        setWidth(width + 0.25);
      }
    }
  }, [timer, pause]);


  useEffect(() => {
    var i = 0;
    if (i == 0) {
      i = 1;
      var time = 1;
      var id = setInterval(() => {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          time = time + 1;
          setTimer(time);
        }
      }, 10);
    }
  }, []);

  const handleClick = () => {
    dispatch(AddtoQueue(true));
    history.push(`/vault/${vaultAddress}/vault/transactions/pending`);
  };

  const timeOut = () => {
    dispatch(AddtoQueue(false));
  };

  const handlePause = ()=>{
    setPause(true)
  }

  const handleLeave = ()=>{
    setPause(false);
}
 

  return (
   
    <div className="transaction-aligner-component">
      <div
        className="transaction-info-card-main-container"
        onClick={handleClick}
        onMouseEnter={handlePause}
        onMouseLeave={handleLeave}
      >
        <div style={{ display: "flex", height: "90%", width: "100%" }}>
          <div
            style={{
              width: "10%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img src={SuccessMark} style={{ height: "20px" }} alt=""></img>
          </div>
          <div style={{ width: "90%", margin: "auto", textAlign: "center" }}>
            <p className="transaction-info-text-styling">
              Transaction in progress. Navigate to Queue Section.
            </p>
          </div>
        </div>
        <div id="myProgress">
          <div id="myBar"></div>
        </div>
        {/* <LinearDeterminate timeOut={timeOut} /> */}
      </div>
    </div>
  );
};
export default TransactionInfo;
