const initState = {
    network: "",
  
};

const ConnectedNetworkReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_NETWORK":
            return { 
                ...state,
                network: action.payload.network,
            }

        default: 
            return state;
    }
}

export default ConnectedNetworkReducer;