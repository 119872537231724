import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import down_arrow from "../../../assets/home-dashboard/diagonal-arrow-decline.svg";
import up_arrow from "../../../assets/home-dashboard/diagonal-arrow-growth.svg";
import "./Styling/style.css";
import { Tooltip } from "@material-ui/core";
import { VaultType } from "../../../constants/vaultType";

const nFormatter = (n) => {
  try {
    if (n < 1e3) return n?.toFixed(2);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const CustomTable = (props) => {
  const {
    tableId,
    userTokenBalance,
    tokenSymbol,
    value_1,
    value_2,
    value_3,
    value_4,
    tokensOutstanding,
    loadingSkeleton,
    baseCurrency,
  } = props;

  const [APRProfit, setAPRProfit] = useState("Profit");
  const vaultData = useSelector((state) => state.get_vault_details.vault);
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const userAddress = useSelector((state) => state.get_user_address.address);

  //Function to display APR of profit according to vault type
  const displayAPRProfit = (profitType) => {
    switch (profitType) {
      case VaultType.ASSET_VAULT:
        setAPRProfit("Profit");
        break;
      case VaultType.YIELD_VAULT:
        setAPRProfit("APY");
        break;
      default:
        setAPRProfit("Profit");
        break;
    }
  };
  useEffect(() => {
    displayAPRProfit(vaultData?.vault?.profitType);
  }, [vaultAddress, userAddress, vaultData?.vault?.profitType]);

  return (
    <>
      <div className="custom-table-component">
        <p className="custom-table-heading">
          {tableId === 1 ? "My Balance" : "Vault Statistics"}
        </p>

        {tableId === 1 ? (
          <p className="custom-table-balance">
            {" "}
            {loadingSkeleton ? (
              <Skeleton animation="wave" height={27} width="40%" />
            ) : (
              <>
                {userTokenBalance
                  ? parseFloat(userTokenBalance).toFixed(2)
                  : "0.00"}{" "}
                {tokenSymbol}
              </>
            )}
          </p>
        ) : (
          ""
        )}
        <div className="custom-table-contents">
          <p className="custom-table-content-text">
            {tableId === 1 ? `Current Value in ${baseCurrency}` : "Total NAV"}
          </p>
          {loadingSkeleton ? (
            <Skeleton animation="wave" height={20} width="20%" />
          ) : value_1 > 0 || value_1 < 0 ? (
            value_1 < 1e3 ? (
              <p className="custom-table-content-value">
                {value_1 && parseFloat(value_1).toFixed(2).toString()}
              </p>
            ) : (
              <Tooltip
                title={
                  value_1 &&
                  value_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              >
                <p className="custom-table-content-value">
                  {value_1 && nFormatter(parseFloat(value_1))}
                </p>
              </Tooltip>
            )
          ) : (
            <p className="custom-table-content-value">0.00</p>
          )}
        </div>

        {tableId === 2 ? (
          <div className="custom-table-contents">
            <p className="custom-table-content-text">Tokens outstanding</p>
            {loadingSkeleton ? (
              <Skeleton animation="wave" height={20} width="20%" />
            ) : value_1 < 1e3 ? (
              <p className="custom-table-content-value">{tokensOutstanding}</p>
            ) : (
              <Tooltip
                title={tokensOutstanding
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              >
                <p className="custom-table-content-value">
                  {nFormatter(tokensOutstanding)}
                </p>
              </Tooltip>
            )}
          </div>
        ) : (
          ""
        )}

        <div className="custom-table-contents">
          <p className="custom-table-content-text">
            {tableId === 1
              ? `Profit in ${baseCurrency}`
              : `${APRProfit} (Since Inception)`}
          </p>
          {loadingSkeleton ? (
            <Skeleton animation="wave" height={20} width="20%" />
          ) : (
            <>
              {value_2 > 0 ? (
                <p id="growth" className="custom-table-content-value">
                  <img src={up_arrow}></img>
                  {value_2 && nFormatter(parseFloat(value_2))}
                  {tableId === 2 ? " %" : ""}
                </p>
              ) : value_2 < 0 ? (
                <p id="decline" className="custom-table-content-value">
                  <img src={down_arrow}></img>
                  {value_2 && nFormatter(parseFloat(value_2 * -1))}
                </p>
              ) : (
                <p className="custom-table-content-value">0.00</p>
              )}
            </>
          )}
        </div>
        {tableId === 2 ? (
          <div className="custom-table-contents">
            <p className="custom-table-content-text">
              {APRProfit} (last 24 hr)
            </p>
            {loadingSkeleton ? (
              <Skeleton animation="wave" height={20} width="20%" />
            ) : (
              <>
                {value_3 > 0 ? (
                  <p id="growth" className="custom-table-content-value">
                    <img src={up_arrow}></img>
                    {value_3 &&
                      parseFloat(value_3)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    %
                  </p>
                ) : value_3 < 0 ? (
                  <p id="decline" className="custom-table-content-value">
                    <img src={down_arrow}></img>
                    {value_3 &&
                      parseFloat(value_3 * -1)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    %
                  </p>
                ) : (
                  <p className="custom-table-content-value">0.00</p>
                )}
              </>
            )}
          </div>
        ) : (
          ""
        )}

        {tableId === 2 ? (
          <div className="custom-table-contents">
            <p className="custom-table-content-text">
              {tableId === 1 ? "Estimated APY" : `${APRProfit} (last 7 days)`}
            </p>
            {loadingSkeleton ? (
              <Skeleton animation="wave" height={20} width="20%" />
            ) : (
              <>
                {value_4 > 0 ? (
                  <p id="growth" className="custom-table-content-value">
                    <img src={up_arrow}></img>
                    {value_4 && parseFloat(value_4).toFixed(2)} %
                  </p>
                ) : value_4 < 0 ? (
                  <p id="decline" className="custom-table-content-value">
                    <img src={down_arrow}></img>
                    {value_4 && parseFloat(value_4 * -1).toFixed(2)} %
                  </p>
                ) : (
                  <p className="custom-table-content-value">0.00</p>
                )}
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CustomTable;
