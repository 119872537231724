import React, { useEffect, useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InputBox from "./Input_Box/input";
import DropDown from "./Dropdown/dropDown";
import { createTheme } from "@material-ui/core/styles";
import "./Input_Box/Input_Styling/style.css";
import { getWhiteListInstanceAt } from "../../../Asset-Details/TableFunctions";
import { TransactionInProgress } from "../../../../redux/actions/reload-state";
import WrapperFunction from "../../../Global-Functions/WrapperFunction";

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  headingContent: {
    fontSize: "14px",
    textAlign: "justify",
    padding: "20px 20px 20px 20px",
    color: "#6D7681",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  button: {
    margin: "10px 23px 25px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#DEE5EF",
    color: "#1B3E69",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#DEE5EF",
    },
  },
  button1: {
    margin: "10px 23px 25px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#C42121",
    color: "white",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#C42121",
    },
  },
  //#C42121
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
  },
  textField: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    margin: "0px 0px 10px 20px ",
  },
  textField1: {
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    margin: "0px 30px 10px 30px ",
  },
  contentAddress: {
    fontSize: "14px",
    marginTop: "10px",
    color: "#6D7681",
    marginBottom: "5px",

    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "540px",
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
    mainContent: {
      fontSize: "14px",
    },
  },
});

export default function WhiteListModal(props) {
  const {
    type,
    dataValue,
    userAddress,
    whitelistId,
    adminAddress,
    dispatch,
    whitelistAddress,
  } = props;
  const classes = useStyles();
  const dialog = useDialog();
  const web3 = props.web3;
  // const web3 = new Web3(window.ethereum);

  const [value, setValue] = useState();
  const [addAddress, setAddAddress] = useState();
  const [addID, setAddUID] = useState();
  const [returnConditionAdd, setReturnConditionAdd] = useState(true);
  const [encodedData, setEncodedData] = useState("");

  function validateInputAddresses(address) {
    return (
      /^(0x){1}[0-9a-fA-F]{40}$/i.test(address) &&
      web3.utils.isAddress(address) &&
      address != "0x0000000000000000000000000000000000000000"
    );
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (addAddress && addID) {
        const networkId = await web3.eth.net.getId();
        const whitelistInstance = await getWhiteListInstanceAt(web3, networkId);
        const encodedWhitelistAddAddress = await whitelistInstance.methods
          .addMembersToGroup(addID, [addAddress])
          .encodeABI();
        setEncodedData(encodedWhitelistAddAddress);
      }
    };
    asyncFn();
  }, [addAddress, addID]);

  const handleAddWhitelistAddress = async () => {
    const account = await web3.eth.getAccounts();

    try {
      const transactionData = {
        contractAddress: whitelistAddress,
        txType: "ADD_MEMBERS_TO_GROUP",
        signer: account[0],
        txData: encodedData,
        nonce: 0,
        parameters: { _groupId: addID, _memberAddress: [addAddress] },
      };
      dialog.close(value);
      dispatch(WrapperFunction(transactionData, "POST"));
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveWhitelistAddress = async () => {
    const account = await web3.eth.getAccounts();

    try {
      dispatch(TransactionInProgress(true));

      const promise = new Promise(async (resolve, reject) => {
        const networkId = await web3.eth.net.getId();
        const whitelistInstance = await getWhiteListInstanceAt(web3, networkId);
        const encodeWhitelistAddressRemoval = await whitelistInstance.methods
          .removeMembersFromGroup(whitelistId, [adminAddress])
          .encodeABI();
        if (encodeWhitelistAddressRemoval) {
          resolve(encodeWhitelistAddressRemoval);
        } else {
          reject(encodeWhitelistAddressRemoval);
        }
      });
      promise
        .then((s) => {
          return {
            contractAddress: whitelistAddress,
            txType: "REMOVE_MEMBERS_FROM_GROUP",
            signer: account[0],
            txData: s,
            nonce: 0,
            parameters: {
              _groupId: whitelistId,
              _memberAddress: [adminAddress],
            },
          };
        })
        .then((res) => {
          dialog.close(value);
          dispatch(WrapperFunction(res, "POST"));
        });
    } catch (err) {
      dispatch(TransactionInProgress(false));
    }
  };

  const handleInputChangeAdd = (value) => {
    setAddAddress("");
    let conditionCheck = validateInputAddresses(value);

    setReturnConditionAdd(validateInputAddresses(value));
    if (conditionCheck) {
      setAddAddress(value);
    }
  };

  const handleGroupIDAdd = (value) => {
    setAddUID(value);
  };

  const handleAddWhitelistManager = async () => {
    dispatch(TransactionInProgress(true));
    const networkId = await web3.eth.net.getId();
    const account = await web3.eth.getAccounts();
    const whitelistInstance = await getWhiteListInstanceAt(web3, networkId);
    try {
      const encodeAddWhitelistManager = await whitelistInstance.methods
        .addWhitelistAdmin(addID, addAddress)
        .encodeABI();

      const transactionData = {
        contractAddress: props.abi.WhitelistAddress,
        txType: "ADD_WHITELIST_ADMIN",
        signer: account[0],
        txData: encodeAddWhitelistManager,
        nonce: 0,
        parameters: { _groupId: addID, _whitelistGroupAdmin: addAddress },
      };
      dialog.close(value);
      props.dispatch(WrapperFunction(transactionData, "POST"));
    } catch (err) {
      dispatch(TransactionInProgress(false));
    }
  };

  return (
    <React.Fragment>
      {type == "add" ? (
        <div style={{ height: "0px" }}>
          <p className={classes.heading}>ADD WHITELIST ADDRESS</p>
          <p className={classes.headingContent}>
            When adding a new whitelist manager, consider that any whitelist
            manager added to a vault has the ability to add or revoke other
            whitelist managers.
          </p>

          <div className={classes.textField}>
            <InputBox
              handleInputChangeAdd={handleInputChangeAdd}
              returnCondition={returnConditionAdd}
              id="1"
            />

            <DropDown
              handleGroupIDAdd={handleGroupIDAdd}
              dataValue={dataValue}
              id="1"
              userAddress={userAddress}
            />
          </div>
          <div style={{ height: "15px" }}>
            {!returnConditionAdd ? (
              <p
                style={{
                  color: "#C42121",
                  marginLeft: "20px",
                  fontSize: "12px",
                }}
              >
                Invalid Ethereum Address
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* <SimpleSelect topic={props.name}/> */}

          <Button
            variant="contained"
            disabled={!(addID && addAddress)}
            onClick={() => {
              handleAddWhitelistAddress();
            }}
            className={classes.button}
          >
            Add Address
          </Button>
        </div>
      ) : type == "add_multiple_manager" ? (
        <div style={{ height: "0px" }}>
          <p className={classes.heading}>ADD WHITELIST MANAGER</p>
          <p className={classes.headingContent}>
            To update the list of participates, input the Ethereum wallet
            address of desired participants below and designate their whitelist
            grouping. Groups are used to permit or revoke the permission of
            certain user-classes more easily.
          </p>

          <div className={classes.textField}>
            <InputBox
              handleInputChangeAdd={handleInputChangeAdd}
              returnCondition={returnConditionAdd}
              id="1"
            />

            <DropDown
              handleGroupIDAdd={handleGroupIDAdd}
              dataValue={dataValue}
              id="1"
              userAddress={userAddress}
            />
          </div>
          <div style={{ height: "15px" }}>
            {!returnConditionAdd ? (
              <p
                style={{
                  color: "#C42121",
                  marginLeft: "20px",
                  fontSize: "12px",
                }}
              >
                Invalid Ethereum Address
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* <SimpleSelect topic={props.name}/> */}

          <Button
            variant="contained"
            disabled={!(addID && addAddress)}
            onClick={() => {
              // Сlose the dialog and return the value
              handleAddWhitelistManager();
              // dialog.close(value);
            }}
            className={classes.button}
          >
            Add Address
          </Button>
        </div>
      ) : (
        <div>
          <p className={classes.heading}>DELETE WHITELIST ADDRESS</p>
          <div className={classes.textField1}>
            <p className={classes.contentAddress}>
              Confirm to delete whitelist address
            </p>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              dialog.close();
            }}
            className={classes.button}
          >
            EXIT
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              // Сlose the dialog and return the value
              handleRemoveWhitelistAddress();
              dialog.close(value);
            }}
            className={classes.button1}
          >
            CONFIRM
          </Button>
        </div>
      )}
      {/* <LoaderAnimation loading={progressData} /> */}
    </React.Fragment>
  );
}
