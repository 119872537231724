import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { VaultCreationStatus } from "../../constants/vaultCreationStatus";
import { CreationStatus } from "../../redux/actions/vaultCreation";
import usePolling from "../../hooks/usePolling";
import { PendingVault } from "../../services/api";
import { web3SendTransaction } from "../../utils/web3/sendTransaction";
import "./Style/index.css";
import {
  useAccount,
  usePrepareSendTransaction,
  useSendTransaction,
  useWaitForTransaction,
} from "wagmi";
import { message } from "antd";

function QueueVault() {
  let { id } = useParams();
  const { address, connector } = useAccount();
  const userAddress = useSelector((state) => state.get_user_address.address);

  const dispatch = useDispatch();
  const history = useHistory();
  const vaultCreationStatus = useSelector(
    (state) => state.get_vault_creation_status.value
  );
  // const vaultType = useSelector((state) => state.get_vault_type);
  // const [progress, setProgress] = useState(0);
  // const [vaultCurrentStatus, setVaultCurrentStatus] = useState();
  const [configTo, setConfigTo] = useState("");
  const [configData, setConfigData] = useState("");
  const [txHash, setTxHash] = useState("");
  const [sendTransactionLoading, setSendTransactionLoading] = useState(false);
  const [stateNumber, setStateNumber] = useState(0);
  const [vaultType, setVaultType] = useState();
  const [locked, setLocked] = useState();
  const [numberList, setNumberList] = useState([]);
  const [reInitiate, setReInitiate] = useState(false);
  const [pausePlay, setPausePlay] = useState(false);

  const { config } = usePrepareSendTransaction({
    to: configTo,
    data: configData,
  });
  const {
    data,
    sendTransaction,
    error,
    isLoading: sendLoading,
    reset,
  } = useSendTransaction(config);
  const {
    isLoading,
    refetch,
    isSuccess,
    data: waitData,
    error: waitError,
  } = useWaitForTransaction({
    hash: data?.hash,
  });
  if (error || waitError) {
    message.destroy();
    console.log({ waitError }, { error });
    message.error("reload");
  }

  const web3 = useSelector((state) => state.get_web3.web3);
  const wallet =
    useSelector(
      (state) => state.get_wallet_state?.wallet?.name
    )?.toLowerCase() == "metamask"
      ? "METAMASK"
      : "GNOSIS";

  //Poll for the creation status
  const getQueueStatus = async () => {
    try {
      const queue = await PendingVault.getPendingVaultQueue(id);
      if (queue.data.statusCode == 200) {
        //Check the status provided from Backend
        if (
          queue.data?.data?.data?.vaultType === "PRIVATE" ||
          queue.data?.data?.data?.vaultType === "PUBLIC"
        ) {
          let vt = queue.data?.data?.data?.vaultType;
          let tl = queue.data?.data?.data?.timeLock;
          let list = [];
          setVaultType(vt);
          setLocked(tl);
          vt === "PRIVATE"
            ? (list = [2, 3, 4, 5, 6, 7, 8])
            : (list = [2, 5, 6, 7, 8]);
          if (tl === "true") list.push(9);
          setNumberList(list);
        }
        if (vaultCreationStatus != queue.data.data.data.vaultStatus) {
          displayProgress(queue.data.data.data.vaultStatus);
          if (
            queue.data.data.data.vaultStatus === VaultCreationStatus.COMPLETED
          ) {
            displayProgress(VaultCreationStatus.COMPLETED);
            dispatch(CreationStatus(queue.data.data.data.vaultStatus));
          } else {
            setConfigTo(queue.data?.data?.data?.to);
            setConfigData(queue.data?.data?.data?.encoded);
            if (connector?.name) {
              if (
                queue.data?.data?.data?.encoded &&
                queue.data?.data?.data?.from &&
                queue.data?.data?.data?.to &&
                data?.hash
              ) {
                web3SendTransaction(
                  web3,
                  queue.data?.data?.data?.from,
                  queue.data?.data?.data?.to,
                  queue.data?.data?.data?.encoded,
                  id,
                  queue.data?.data?.data?.vaultStatus,
                  wallet,
                  data?.hash
                );
                dispatch(CreationStatus(queue.data?.data?.data?.vaultStatus));
                reset();
              }
            } else if (userAddress && web3) {
              web3SendTransaction(
                web3,
                queue.data.data.data?.from,
                queue.data.data.data?.to,
                queue.data.data.data?.encoded,
                id,
                queue.data.data.data?.vaultStatus,
                "METAMASK"
              );
              // }, 10000);
              dispatch(CreationStatus(queue.data?.data?.data?.vaultStatus));
            }
          }
        }       
      }
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data?.statusCode == 404) history.push("");
    }
  };

  const routeHandler = () => {
    history.push("");
  };

  const displayProgress = (status) => {
    // console.log("status", status);
    switch (status) {
      case VaultCreationStatus.VAULT_PROXY_CREATION:
        setStateNumber(1);
        // setProgress(0); setVaultCurrentStatus("1. Vault Proxy Creation");
        break;
      case VaultCreationStatus.REGISTER_WITH_APS:
        setStateNumber(2);
        // setProgress(20); setVaultCurrentStatus("2. Registering with APS");
        break;
      case VaultCreationStatus.WHITELIST_CREATION:
        setStateNumber(3);
        break;
      case VaultCreationStatus.WHITELIST_GROUP_ADD:
        setStateNumber(4);
        break;
      case VaultCreationStatus.SET_TOKEN_DETAIlS:
        setStateNumber(5);
        //setProgress(40); setVaultCurrentStatus("3. Setting Token Details");
        break;
      case VaultCreationStatus.SET_VAULT_ASSETS:
        setStateNumber(6);
        // setProgress(60);setVaultCurrentStatus("4. Setting Vault Assets");
        break;
      case VaultCreationStatus.SET_VAULT_BENEFICIARY:
        setStateNumber(7);
        //setProgress(80); setVaultCurrentStatus("5. Setting Beneficiary Address");
        break;
      case VaultCreationStatus.SET_MANAGEMENT_FEE_BENEFICIARY_AND_PERCENTAGE:
        setStateNumber(8);
        break;
      case VaultCreationStatus.SET_LOCKED_WITHDRAW:
        setStateNumber(9);
        break;
      case VaultCreationStatus.COMPLETED:
        setStateNumber(10);
        //// setProgress(100); setVaultCurrentStatus(" Vault created successfully");
        const route = setTimeout(routeHandler, 5000);
        break;
    }
  };

  const checkConnection = (num) => {
    if (locked === "true") return num !== 9;
    else return num !== 8;
  };

  usePolling(() => {
    getQueueStatus();
  }, 10000);

  useEffect(() => {
    getQueueStatus();
  }, []);

  useEffect(() => {
    try {
      if (configData && configTo && sendTransaction) {
        sendTransaction?.();
      }
    } catch (err) {
      console.log("sendTransaction error", { err });
      refetch();
    }
  }, [configData, configTo, sendTransaction]);

  return (
    <div className="queue-wrap">
      <Timeline>
        {[1].concat(numberList).map((num, index) => {
          return (
            <TimelineItem key={index}>
              <TimelineSeparator className="separation">
                <Box position="relative" display="inline-flex">
                  {stateNumber != num || stateNumber == 100 ? (
                    <CircularProgress className="loader-false" />
                  ) : (
                    <CircularProgress className="loader" />
                  )}
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="circle-number"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      {stateNumber < num ? (
                        <TimelineDot className="icon-new">
                          &nbsp;&nbsp;
                          <p className="number">{index + 1}</p>
                          &nbsp;&nbsp;
                        </TimelineDot>
                      ) : stateNumber == num || stateNumber == 100 ? (
                        <TimelineDot className="icon-selected">
                          &nbsp;&nbsp;
                          <p className="number">{index + 1}</p>
                          &nbsp;&nbsp;
                        </TimelineDot>
                      ) : (
                        <TimelineDot className="icon">
                          &nbsp;&nbsp;
                          <p className="number">{index + 1}</p>
                          &nbsp;&nbsp;
                        </TimelineDot>
                      )}
                    </Typography>
                  </Box>
                </Box>
                {stateNumber < num}
                {checkConnection(num) ? (
                  stateNumber <= num ? (
                    <TimelineConnector className="neg-connector" />
                  ) : (
                    <TimelineConnector className="connector" />
                  )
                ) : (
                  <></>
                )}
              </TimelineSeparator>
              <TimelineContent>
                <div className="contents">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="create-vault-stages">
                      {num == 1
                        ? "Vault Proxy Creation"
                        : num == 2
                        ? "Register with APS"
                        : num == 3
                        ? "Whitelist Group Creation"
                        : num == 4
                        ? "Add Whitelist Group to Vault"
                        : num == 5
                        ? "Set Token Details"
                        : num == 6
                        ? "Set Vault Assets"
                        : num == 7
                        ? "Set Vault Beneficiary"
                        : num == 8
                        ? "Set Management Fee Beneficiary & Percentage"
                        : num == 9
                        ? "Set Locked Withdraw"
                        : "Vault created successfully"}
                    </p>
                    {stateNumber == 10 && stateNumber == num ? (
                      <div className="progress">
                        <LinearProgress variant="determinate" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
}

export default QueueVault;
