import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";
export default class Config {
  static getConfigData() {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `config/sdk/v2.0/yieldster/getConfigData`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }
}
