import React, { useEffect, useState } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { CurrentRoute } from "../../redux/actions/CurrentRoute";
import "./Styling/style.css";

const BreadCrumb = (props) => {
  const dispatch = useDispatch();
  const vaultname = localStorage.getItem("vaultName");
  const { location } = props;
  const { pathname } = location;
  const [pathnames, setPathNames] = useState("");
  // const pathnames = pathname.split("/").filter((x) => x);

  useEffect(() => {
    setPathNames(pathname.split("/").filter((x) => x));
  }, [pathname]);

  useEffect(() => {
    dispatch(CurrentRoute(pathnames));
  }, [pathnames]);

  return (
    <>
      <div className="breadcrumb-styling">
        <p
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          YOU ARE HERE:{" "}
        </p>
        <div style={{ paddingLeft: "15px" }}>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            <Link className="link-styling" to="">
              <p>Home</p>
            </Link>
            {pathnames
              ? pathnames.map((name, index) => {
                  // const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                  // const vaultRoute = `/${pathnames[0]}/${pathnames[1]}/dashboard`;
                  return name !== "v1" && name !== "vault" ? (
                    name.includes("0x") ? (
                      <Link
                        className="link-styling"
                        to={`/vault/${name}/dashboard`}
                        key={name}
                      >
                        <Typography>{vaultname}</Typography>
                      </Link>
                    ) : name === "manage" ? (
                      <Link
                        className="link-styling"
                        to={`/vault/${pathnames[1]}/vault/${name}/support`}
                        key={name}
                      >
                        <Typography>{name}</Typography>
                      </Link>
                    ) : name === "dashboard" ? (
                      <Link
                        className="link-styling"
                        to={`/vault/${pathnames[1]}/dashboard`}
                        key={name}
                      >
                        <Typography>{name}</Typography>
                      </Link>
                    ) : (
                      <Typography key={name}>{name}</Typography>
                    )
                  ) : null;
                })
              : ""}
          </Breadcrumbs>
        </div>
      </div>
    </>
  );
};

export default withRouter(BreadCrumb);
