import React, { useEffect, useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { changeGod } from "../../../api";
import axios from "axios";
import APContract from "yieldster-abi/contracts/APContract.json";
import LoaderAnimation from "../../../LoaderAnimation/LoaderModal";
import { TransactionInProgress } from "../../../../redux/actions/reload-state";
import { message } from "../../../../utils/message";
import WrapperFunction from "../../../Global-Functions/WrapperFunction";

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  button: {
    margin: "20px 30px 30px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    boxShadow: "none",
    float: "right",
    "&:hover": {
      transform: "scale(1.05)",
      background: "#1B3E69",
      boxShadow: "none",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  textField: {
    margin: "0px 30px 20px 30px ",
  },
  contentAddress: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "415px",
      [theme.breakpoints.down("1241")]: {
        height: "46px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
}));

export default function FormDialog(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const [loading, setLoading] = React.useState(false);
  const [check, setCheck] = React.useState("");
  const [value, setValue] = useState();
  const web3 = props.web3;
  const abiAddress = props.abiAddress;
  const [encodeABIData, setEncodeABIData] = React.useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    // console.log("address is present",abiAddress.APContractAddress)
    if (
      /^(0x){1}[0-9a-fA-F]{40}$/i.test(value) &&
      web3.utils.isAddress(value) &&
      value != "0x0000000000000000000000000000000000000000"
    ) {
      const account = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();
      // await window.ethereum.enable();

      const APData = await APContract.networks[networkId];
      const APDataDetails = await new web3.eth.Contract(
        APContract.abi,
        // APData.address
        abiAddress.APContractAddress
      );
      setLoading(true);
      setCheck("");
      props.dispatch(TransactionInProgress(true));
      try {
        // await APDataDetails.methods
        //   .setYieldsterGOD(value)
        //   .send({ from: account[0] }, (err, txnHash) => {});
        const result = await APDataDetails.methods
          .setYieldsterGOD(value)
          .encodeABI();
        setEncodeABIData(result);

        const transactionData = {
          contractAddress: abiAddress.APContractAddress,
          txType: "SET_YIELDSTER_GOD",
          signer: account[0],
          txData: result,
          nonce: 0,
          parameters: { _yieldsterGOD: value },
        };
        props.dispatch(WrapperFunction(transactionData, "POST"));

        // axios.post(pendingTransaction(), {
        //   contractAddress: "contractAddress",
        //   txType: "SET_YIELDSTER_GOD",
        //   parameters: { _yieldsterGOD: value },
        //   nonce: "",
        //   signer: account[0],
        //   txHash: "",
        //   txData: result,
        //   walletType: "",
        // });
        props.setChange(!props.change);
        setLoading(false);
        // axios
        //   .patch(changeGod(), {
        //     GODAddress: value,
        //   })
        //   .then((data) => {
        //     if (data.data.status == "1") {
        //       setLoading(false);
        //       props.dispatch(TransactionInProgress(false));
        //       message.success("Successfully updated god address");
        //     }
        //   });
      } catch (err) {
        setLoading(false);
        message.error(err["message"]);
        props.dispatch(TransactionInProgress(false));
      }

      dialog.close(value);
    } else {
      setCheck("Enter Valid ethereum address");
    }
  };

  return (
    <div>
      <p className={classes.heading}>{props.heading}</p>

      <p className={classes.content}>
        Only the current God role can set the new God role. Please ensure that
        the new address is correct as Yieldster cannot revert any mistakes.
      </p>

      <div className={classes.textField}>
        <p className={classes.contentAddress}>Address :</p>
        <TextField
          className={classes.root}
          id="outlined-basic"
          placeholder="Enter new address"
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            style: { fontSize: 13, fontFamily: "'Open sans', sans-serif" },
          }}
        />
      </div>
      {check ? (
        <p
          style={{
            color: "#C42121",
            marginLeft: "30px",
            marginTop: "-10px",
            fontSize: "13px",
          }}
        >
          {check}
        </p>
      ) : (
        ""
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleSave();
        }}
        className={classes.button}
      >
        SAVE
      </Button>
      <LoaderAnimation loading={loading} />
    </div>
  );
}
