import axios from "axios";
import {
  DepositWithdrawPendingTransaction,
  vaultTransactionComplete,
  SaveTransactionHash,
  UpdateHashandNonce,
  SetTxStatusCorrupted
} from "../../api";
import {
  TransactionInProgress
} from "../../../redux/actions/reload-state";
import {
  AddtoQueue
} from "../../../redux/actions/AddtoQueue";
import {
  TransactionActionInstance,
} from '../../../redux/actions/DepositWithdrawAction'

const SDKTransaction = (value, OperationType,iFrameObject,url,collectionId) => async (dispatch, getState) => {
  const {
    contractAddress,
    txType,
    nonce,
    signer,
    txData,
    parameters,
    vaultAddress
  } = value;
  let transactionHash = "";
  const reduxstate = getState();
  let walletType;
  if (reduxstate.get_provider.provider.setprovider.wc == undefined) {
    walletType = "normal";
  } else {
    walletType = reduxstate.get_provider.provider.setprovider.wc._peerMeta.name;
  }
  const web3 = reduxstate.get_web3.web3;
  const currentRoute = reduxstate.get_current_route.currentRoute;

  try {
    if (OperationType == "POST") {
      axios
        .post(DepositWithdrawPendingTransaction(), {
          contractAddress,
          txType,
          parameters,
          nonce,
          signer,
          txData,
          walletType,
          vaultAddress
        }).then((res) => {
          if (currentRoute) {
            if (
              !(currentRoute[currentRoute.length - 1] == "pending" &&
                currentRoute[currentRoute.length - 2] == "transactions")
            ) {
              setTimeout(() => {
                dispatch(AddtoQueue(true));
              }, 5000)
            }
          }
          dispatch(TransactionInProgress(false));
          dispatch(TransactionActionInstance())
          const {
            txData,
            signer,
            contractAddress,
            _id,
            txType
          } = res.data.data
          console.log("important information",res.data.data)
            web3.eth
              .sendTransaction({
                from: signer,
                to: contractAddress,
                data: txData,
              })
              .on("transactionHash", function (hash) {
                transactionHash = hash
                let promise = new Promise((resolve,reject)=>{
                    if(hash){
                          resolve( {
                            dataSource: "YIELDSTER_FINANCE",
                            parent_job_id:2,
                            data:{
                              hash
                            },
                            infura_url: process.env.REACT_APP_INFURA_WS
                          })
                    }
                })
                promise.then((message)=>{
                    iFrameObject.contentWindow.postMessage(message,url)
                 
                })
                axios.patch(SaveTransactionHash(_id), {
                  txHash: hash
                }).then(() => {
                  dispatch(TransactionActionInstance())
              })
            })
              .on("receipt", async(receipt) => {
                const message = await {
                    dataSource: "YIELDSTER_FINANCE",
                    parent_job_id:3,
                    data:{
                      receipt
                    },
                    infura_url: process.env.REACT_APP_INFURA_WS
                  }
                  if(message){
                        iFrameObject.contentWindow.postMessage(message,url)
                  }
                axios
                  .post(vaultTransactionComplete(transactionHash))
                  .then(() => {
                    dispatch(TransactionActionInstance())
                  })
                  .catch((err) => {
                    console.log("post error", err);
                  });
              })
              .on("error", (err) => {
                console.log("Error in else part:", err.message)
                axios.patch(SetTxStatusCorrupted(_id), {
                  status: "REVERT"
                }).then(() => {
                  dispatch(TransactionActionInstance())
                }).catch(() => {})
              });
          

        }).catch((err) => {
          console.log("Error occured in POST", err)
        })
        } 
    else if (OperationType == "PATCH") {
      axios.patch(UpdateHashandNonce(), {
        id: collectionId,
        // txHash:value.txHash,
        nonce: value.nonce
      }).then((res) => {
        dispatch(TransactionActionInstance())
        const {
          signer,
          contractAddress,
          txData,
          _id
        } = res.data.data
        web3.eth
          .sendTransaction({
            from: signer,
            to: contractAddress,
            data: txData,
          }).on("transactionHash", function (hash) {
            transactionHash = hash
            axios.patch(SaveTransactionHash(_id), {
              txHash: hash
            }).then((res) => {
              dispatch(TransactionActionInstance())
            })
          }).on("receipt", () => {
            axios
              .post(vaultTransactionComplete(transactionHash))
              .then((res) => {
                dispatch(TransactionActionInstance())
              })
              .catch((err) => {});
          }).on("error", (err) => {
            axios.patch(SetTxStatusCorrupted(_id), {
              status: "REVERT"
            }).then(() => {
              dispatch(TransactionActionInstance())
            }).catch(() => {})

          });
      })
    }
  } catch (err) {
    console.log("Error encountered", err);
  }
};

export default SDKTransaction;