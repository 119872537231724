const txTypes = (txType) => {
  switch (txType) {
    case "DEPOSIT":
      return "Deposit";

    case "WITHDRAW":
      return "Withdraw";

    case "UPGRADE_MASTERCOPY":
      return "Upgrade Master Copy";

    case "SET_APS":
      return "Set APS";

    case "SET_YIELDSTER_GOD":
      return "Set Yieldster GOD";

    case "TOGGLE_EMERGENCY_BREAK":
      return "Toggle Emergency Break";

    case "ENABLE_EMERGENCY_EXIT":
      return "Enable Emergency Exit";

    case "SET_VAULT_SLIPPAGE":
      return "Set Vault Slippage";

    case "SET_VAULT_ASSETS":
      return "Set Vault Assets";

    case "SET_VAULT_STRATEGY_AND_PROTOCOL":
      return "Set Vault Strategy and Protocol";

    case "DISABLE_VAULT_STRATEGY":
      return "Disable Vault Strategy";

    case "SET_VAULT_ACTIVE_STRATEGY":
      return "Set Vault Active Strategy";

    case "DEACTIVATE_VAULT_STRATEGY":
      return "De-Activate Vault Strategy";

    case "SET_VAULT_SMART_STRATEGY":
      return "Set Vault Smart Strategy";

    case "CHANGE_APS_MANAGER":
      return "Change APS Manager";

    case "CHANGE_STRATEGY_MANAGER":
      return "Change Strategy Manager";

    case "ADD_MEMBERS_TO_GROUP":
      return "Add Members to Group";

    case "REMOVE_MEMBERS_FROM_GROUP":
      return "Remove Members to Group";

    case "ADD_WHITELIST_ADMIN":
      return "Add Whitelist Admin";

    case "REMOVE_WHITELIST_ADMIN":
      return "Remove Whitelist Admin";

    case "SET_BENEFICIARY_AND_PERCENTAGE":
      return "Set Beneficiary & Percentage"

    case "CHANGE_VAULT_ADMIN":
      return "Change Vault Admin"
    
    case "CHANGE_EMERGENCY_VAULT":
      return "Change Emergency Vault"
    default:
      return "SDK Transaction"
  }
};
export default txTypes;