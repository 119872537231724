import React,{useEffect} from "react";
import axios from "axios";
import { getImage } from "../../api";
import AvatarCharacter from "../../SidebarSettings/APS/Avatar";
import ShowMoreText from 'react-show-more-text';
import {getStrategyDetails} from '../../api'
import { getTokenDecimalPrecision} from '../../Asset-Details/TableFunctions'
import {useSelector} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CustomLoader from '../../Custom_Loader/customLoader'
import './styling/IndividualStrategy.css'

const useStyles = makeStyles((theme) => ({
 
  showMore: {
    textAlign: "justify",
    fontSize: "14px",
    color: "#6D7681",
    fontFamily: " 'Open Sans', sans-serif",
    marginBottom: "20px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  smallFont:{
    fontSize:"14px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  }
 
}));
export default function IndividualStrategy(props) {
  const web3 = useSelector((state) => state.get_web3.web3);
  const [strategyDetails,setStrategyDetails] = React.useState([])
  const [symbol,setSymbol] = React.useState()
  const classes = useStyles()
  const [loader,setLoader] = React.useState(true)
  
  useEffect(() => {
    const asyncFn = async () => {
      const ERC20Instance = await getTokenDecimalPrecision(
        props.data.strategyAddress,
        web3
      );
      setSymbol(await ERC20Instance.methods.symbol().call());

      axios
        .get(getStrategyDetails(), {
          params: {
            strategyAddress: props.data.strategyAddress,
            vaultAddress: props.vaultAddress,
          },
        })
        .then((data) => {
          if (data.data.data) {
            setStrategyDetails(data.data.data);
            setLoader(false);
          }
        });
    };
    asyncFn();
  }, [props.data.strategyAddress]);


  return (
    <>
    {loader ? <CustomLoader /> :
    <div>
      <div style={{display:"flex",justifyContent:"space-between"}}>
      <div>
        <p className="strategyHeading">{props.data.strategyName}</p>
        
       <p className="strategyDescription"><ShowMoreText
                      lines={1}
                      more="Show more"
                      less="Show less"
                      className={classes.showMore}
                      anchorClass="my-anchor-css-class"
                      expanded={false}
                      width={1000}
                    >
                      {props.data.strategyDescription}
                    </ShowMoreText></p>
       </div>
       <div>
       <AvatarCharacter
              image={getImage(props.data.strategyIcon)}
              class="large"
            />
       </div>
       </div>
      <div className="indivigual-strategy-main-styler">
        <div>
       <div className="indivigual-strategy-indivigual-content">
                  <p className={classes.smallFont} >Strategy Token Price</p>
                  <p className={classes.smallFont} style={{paddingLeft:"5px"}}><span className={classes.smallFont} style={{fontWeight:"bold",color:"#1B3E69"}}>{props.tokenValueInUSD}</span> USD</p>
        </div>
        <div className="indivigual-strategy-indivigual-content">
                  <p className={classes.smallFont} >Strategy NAV</p>
                  <p className={classes.smallFont} ><span style={{fontWeight:"bold",color:"#1B3E69"}}>{props.strategyNavFromChain}</span> USD</p>
        </div>
        </div>

        <div>
       <div className="indivigual-strategy-indivigual-content">
                  <p className={classes.smallFont} >Strategy APR</p>
                  <p className={classes.smallFont} ><span style={{fontWeight:"bold",color:"#1B3E69"}}>{strategyDetails.apr} </span> %</p>
        </div>
        <div className="indivigual-strategy-indivigual-content">
                  <p className={classes.smallFont} >Safe Balance</p>
                  <p className={classes.smallFont} style={{paddingLeft:"5px"}}><span style={{fontWeight:"bold",color:"#1B3E69",marginRight:"5px"}}>{props.balanceOf}</span>{symbol}</p>
        </div>
        </div>
      </div>
    </div>}
    </>
  );
}