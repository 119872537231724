const initState = {
    vaultType: "",
};

const vaultType = (state = initState, action) => {
    switch (action.type) {
        case "SET_VAULT_TYPE":
            return {
                ...state,
                vaultType: action.payload,
            }
        default:
            return state;
    }
}

export default vaultType;