import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import AvatarCharacter from "../../../SidebarSettings/APS/Avatar";
import { getImage, getManagementFee, SmartStrategyOfVault } from "../../../api";
import Aave from "../../../../assets/home-dashboard/aave.png";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  table: {},
  mainContent: {
    fontSize: "16px",
    textAlign: "justify",
    marginBottom: "40px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  rowStart: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  rowEnd: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#6D7681",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  confirm: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginTop: "40px",
    marginBottom: "10px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  mainHeading: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  dotGreen: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    background: "#188038",
    float: "right",
    // marginRight: "15%",
    [theme.breakpoints.down("1641")]: {
      height: "10px",
      width: "10px",
    },
  },
  dotRed: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    background: "#C42121",
    float: "right",
    // marginRight: "15%",
    [theme.breakpoints.down("1641")]: {
      height: "10px",
      width: "10px",
    },
  },
  buttonNew: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1B3E69",
    textDecoration: "underline",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.01)",
    },
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  paddingColumn: {
    padding: "16px",
    [theme.breakpoints.down("1641")]: {
      padding: "6px 0px",
    },
  },
  width: {
    width: "55%",
  },
  widthShare: {
    width: "80px",
  },
}));

export default function BasicTable(props) {
  var members = [];
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const [smartStrategy, setSmartStrategy] = useState([]);
  const [strategyFee, setStrategyFee] = useState([]);
  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress) {
        const result = await axios.get(SmartStrategyOfVault(vaultAddress));
        setSmartStrategy(
          result.data.data.smartStrategyDetails.map((res) => {
            return { ...smartStrategy, ...res };
          })
        );
        const fee = await axios.get(getManagementFee(vaultAddress));
        setStrategyFee(fee.data.data);
      }
    };
    asyncFn();
  }, [vaultAddress]);

  const classes = useStyles();

  return (
    <div>
      {1 > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "2px solid rgba(109, 118, 129,0.16)",
                        }}
                      >
                        <TableCell
                          className={classes.rowEnd}
                          classes={{ root: classes.width }}
                          style={{
                            paddingLeft: "0px",
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                          }}
                        >
                          Support Strategy
                        </TableCell>
                        <TableCell
                          className={classes.rowEnd}
                          classes={{ root: classes.widthShare }}
                          align="right"
                          style={{
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                            paddingRight: "16px",
                          }}
                        >
                          Status
                        </TableCell>
                        {/* <TableCell
                          className={classes.rowEnd}
                          align="right"
                          style={{
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                            paddingRight: "0px",
                          }}
                        >
                          Share
                        </TableCell> */}
                        {/* {props.manager &&!props.break? ( */}
                        {/* <TableCell
                            className={classes.rowEnd}
                            align="right"
                            style={{
                              paddingTop: "-10px",
                              paddingBottom: "5px",
                              paddingRight: "16px",
                            }}
                          >
                            Action
                          </TableCell> */}
                        {/* ) : (
                          ""
                        )} */}
                      </TableRow>
                    </TableHead>
                    {smartStrategy.map((res) => {
                      return (
                        <TableBody>
                          {/* {props.data.map((row, index) => ( */}
                          <>
                            <TableRow
                              style={{
                                borderBottom:
                                  "2px solid rgba(109, 118, 129,0.16)",
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.paddingColumn}
                              >
                                <p className={classes.rowStart}>
                                  <AvatarCharacter
                                    image={getImage(res.smartStrategyIcon)}
                                    class="small20"
                                  />
                                  <span>{res.name}</span>
                                </p>
                              </TableCell>
                              <TableCell
                                align="right"
                                classes={{ root: classes.widthShare }}
                              >
                                {true ? ( //add status
                                  <p className={classes.dotGreen}></p>
                                ) : (
                                  <p className={classes.dotRed}></p>
                                )}
                              </TableCell>
                              {/* <TableCell
                                align="right"
                                className={classes.paddingColumn}
                              >
                                {row.share != "" ? (                                                          //Add chare
                                  <p className={classes.rowEnd}>{row.share} %</p>
                                ) : row.status ? (
                                  <p className={classes.rowEnd}>0 %</p>
                                ) : (
                                  <p></p>
                                )}
                              </TableCell> */}
                              {/* {true ? (                                                                   //props.manager && !props.break
                                <TableCell
                                  align="right"
                                  className={classes.paddingColumn}
                                >
                                  {true ? (
                                    <p
                                      className={classes.buttonNew}
                                      onClick={() => {
                                        props.handleSupportDeactivate(
                                         
                                        );
                                      }}
                                    >
                                      De-Activate
                                    </p>
                                  ) : (
                                    <p
                                      className={classes.buttonNew}
                                      onClick={() => {
                                        props.handleSupportActivate();
                                      }}
                                    >
                                      Activate
                                    </p>
                                  )}
                                </TableCell>
                              ) : (
                                ""
                              )} */}
                            </TableRow>
                          </>
                          {/* ))} */}
                        </TableBody>
                      );
                    })}
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "2px solid rgba(109, 118, 129,0.16)",
                        }}
                      >
                        <TableCell
                          className={classes.rowEnd}
                          classes={{ root: classes.width }}
                          style={{
                            paddingLeft: "0px",
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                          }}
                        >
                          Fees
                        </TableCell>
                        {/* <TableCell
                          className={classes.rowEnd}
                          classes={{ root: classes.widthShare }}
                          align="right"
                          style={{
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                            paddingRight: "16px",
                          }}
                        >
                          
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    {smartStrategy.map((res) => {
                      return (
                        <TableBody>
                          <>
                            {res.name === "Management Fee" ||
                            res.name === "Performance Management Fee" ? (
                              <TableRow
                                style={{
                                  borderBottom:
                                    "2px solid rgba(109, 118, 129,0.16)",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.paddingColumn}
                                >
                                  <p className={classes.rowStart}>
                                    <AvatarCharacter
                                      image={getImage(res.smartStrategyIcon)}
                                      class="small20"
                                    />
                                    <span>{res.name}</span>
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  classes={{ root: classes.widthShare }}
                                >
                                  {res.name === "Management Fee"
                                    ? strategyFee.managementFee
                                    : strategyFee.performanceFee }%
                                </TableCell>
                              </TableRow>
                            ) : (
                              <></>
                            )}
                          </>
                        </TableBody>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
}
