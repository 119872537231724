import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PathLine } from "react-svg-pathline";
import {
  Col,
  Divider,
  Input,
  Progress,
  Row,
  Switch,
  Tooltip,
  Radio,
  Popover,
  Modal,
} from "antd";
import SearchInput from "../../assets/icons/search.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { handleWalletSelection } from "../../components/Blocknative/walletSelectionConfig";
import { Admin, Asset, BasicCard, Core, Support } from "../../components/Card";
import {
  CollapseGrid,
  CollapseList,
  SearchItems,
} from "../../components/Collapse";
import Footer from "../../components/Footer/index";
import { Settings } from "../../components/Modal";
import NavigationBar from "../../components/Navbar";
import Toaster from "../../components/Toaster/Toaster";
import LogoLoader from "../../components/Akkrue-Official-Logo-Loader/LogoLoader";
import { loadCurrency } from "../../redux/actions/currency-details";
import { setVaultType } from "../../redux/actions/SetVaultType";
import {
  Assets,
  Currency,
  Deposit,
  FeeStrategy,
  PendingVault,
  Protocol,
  Vaults,
  WithDraw,
} from "../../services/api";
import { message } from "../../utils/message";
import DropDown from "./Dropdown";
import "./Style/index.css";
import { VaultType, VaultTypeMap } from "../../constants/vaultType";
import vaultType from "../../redux/reducers/SetVaultTypeReducer";
import Config from "../../services/api/config";
import Checkbox from "@mui/material/Checkbox";
import EditLongDescription from "../../components/Editor/DescriptionEditor";

function CreateVault() {
  const history = useHistory();
  const dispatch = useDispatch();

  const userAddress = useSelector((state) => state.get_user_address.address);
  const web3 = useSelector((state) => state.get_web3.web3);

  const [vaultIcon, setVaultIcon] = useState();
  const [loader, setLoader] = useState(false);
  const [leftItems, setLeftItems] = useState({
    asset: [],
    vault: [],
    protocol: [],
    deposit: [],
    withdraw: [],
    fees: [],
  });
  const [supportItems, setSupportItems] = useState({
    deposit: [],
    withdraw: [],
    managementFee: null,
    noOfDaysForLockedWithdraw: null,
    managementBeneficiary: null,
    performanceFee: null,
    performanceBeneficiary: null,
  });
  const [assetItems, setAssetItems] = useState({
    base: [],
    vault: [],
    protocol: [],
  });
  const [coreItems, setCoreItems] = useState({ advisor: "", protocol: [] });
  const [listView, setListView] = useState(true);
  const [clearSettings, setClearSettings] = useState(true);
  const [counter, setCounter] = useState(0);
  const [complete, setComplete] = useState({ a: 0, b: 0, c: 0, d: 0 });
  const [percent, setPercent] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [formValues, setFormValues] = useState({
    vaultType: "PRIVATE",
    vaultCreationStatus: "VAULT_PROXY_CREATION",
    supportedProtocols: [],
    supportedAssets: [],
    supportedVaults: [],
    accountAddress: userAddress,
    isVisible: true,
    advisorEmail: "",
    baseCurrency: "USD",
    profitType: VaultType.ASSET_VAULT,
  });
  const [autoComplete, setAutoComplete] = useState([]);
  const [search, setSearch] = useState("");
  const [viewToaster, setViewToaster] = useState(false);
  const [isValidated, setIsValidated] = useState({
    supportCard: {
      // deposit and withdraw strategies have atleast an item
      deposit: "empty",
      withdraw: "empty",
      noOfDaysForLockedWithdraw: "valid",
      managementFee: "empty",
      managementBeneficiary: "empty",
      performanceFee: "empty",
      performanceBeneficiary: "empty",
    },
    assetCard: {
      base: "empty",
      vault: "empty",
      currency: "valid",
    },
    coreCard: {
      advisor: "empty",
    },
    adminCard: {
      vaultName: "empty",
      tokenName: "empty",
      tokenSymbol: "empty",
      vaultIcon: "empty",
      emergencyVault: "empty",
      profitType: "valid",
    },
  });
  const [supportTick, setSupportTick] = useState(0);
  const [assetTick, setAssetTick] = useState(0);
  const [coreTick, setCoreTick] = useState(0);
  const [adminTick, setAdminTick] = useState(0);
  const [enableVault, setEnableVault] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [vaultAsset, setVaultAsset] = useState("");
  const [configData, setConfigData] = useState(false);
  const [description, setDescription] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleViewToaster = () => {
    setViewToaster(false);
  };

  const addItemsToCart = (items, dropResult) => {
    if (items.length && dropResult.target)
      switch (dropResult.target) {
        case "deposit":
          if (
            !supportItems.deposit.some(
              (item) => item[0]?.strategyAddress === items[0].strategyAddress
            )
          ) {
            setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                deposit: "valid",
              },
            });
            setSupportItems({
              ...supportItems,
              deposit: [...supportItems.deposit, items],
            });
            setFormValues({
              ...formValues,
              depositStrategy: items[0].strategyAddress,
            });
          }
          break;
        case "withdraw":
          if (
            !supportItems.withdraw.some(
              (item) => item[0]?.strategyAddress === items[0].strategyAddress
            )
          ) {
            setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                withdraw: "valid",
              },
            });
            setSupportItems({
              ...supportItems,
              withdraw: [...supportItems.withdraw, items],
            });
            setFormValues({
              ...formValues,
              withdrawalStrategy: items[0].strategyAddress,
            });
          }
          break;
        case "base":
          if (!viewToaster)
            if (
              !assetItems.base.some(
                (item) => item[0]?.assetAddress === items[0].assetAddress
              )
            ) {
              setIsValidated({
                ...isValidated,
                assetCard: { ...isValidated.assetCard, base: "valid" },
              });
              setComplete({ a: 0, b: 1, c: 0, d: 0 });
              setAssetItems({
                ...assetItems,
                base: [...assetItems.base, items],
              });
              setFormValues({
                ...formValues,
                supportedAssets: [
                  ...formValues.supportedAssets,
                  items[0].assetAddress,
                ],
              });
            }
          break;
        case "vault":
          if (!viewToaster)
            if (
              !assetItems.vault.some(
                (item) => item[0]?.vaultAddress === items[0].vaultAddress
              )
            ) {
              setComplete({ a: 0, b: 1, c: 0, d: 0 });
              setAssetItems({
                ...assetItems,
                vault: [...assetItems.vault, items],
              });
              setFormValues({
                ...formValues,
                supportedVaults: [
                  ...formValues.supportedVaults,
                  items[0].vaultAddress,
                ],
              });
            }
          break;
        case "protocol":
          if (
            !assetItems.protocol.some(
              (item) => item[0]?.protocolAddress === items[0].protocolAddress
            )
          ) {
            setComplete({ a: 0, b: 1, c: 0, d: 0 });
            setAssetItems({
              ...assetItems,
              protocol: [...assetItems.protocol, items],
            });
            setFormValues({
              ...formValues,
              supportedProtocols: [
                ...formValues.supportedProtocols,
                items[0].protocolAddress,
              ],
            });
          }
          break;
        default:
          console.log("not in target");
      }
  };

  // Remove tags from deposit, withdraw, asset and vault
  const removeItemsFromCart = (address, type) => {
    if (type) {
      switch (type) {
        case "deposit":
          !supportItems.deposit.filter(
            (item) => item[0].strategyAddress !== address
          ).length &&
            setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                deposit: "invalid",
              },
            });

          setSupportItems({
            ...supportItems,
            deposit: supportItems.deposit.filter(
              (item) => item[0].strategyAddress !== address
            ),
          });

          setFormValues({
            ...formValues,
            depositStrategy: "",
          });
          break;
        case "withdraw":
          !supportItems.withdraw.filter(
            (item) => item[0].strategyAddress !== address
          ).length &&
            setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                withdraw: "invalid",
              },
            });

          setSupportItems({
            ...supportItems,
            withdraw: supportItems.withdraw.filter(
              (item) => item[0].strategyAddress !== address
            ),
          });

          setFormValues({
            ...formValues,
            withdrawStrategy: "",
          });
          break;
        case "asset":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          !assetItems.base.filter((item) => item[0].assetAddress !== address)
            .length &&
            setIsValidated({
              ...isValidated,
              assetCard: { ...isValidated.assetCard, base: "invalid" },
            });
          setAssetItems({
            ...assetItems,
            base: assetItems.base.filter(
              (item) => item[0].assetAddress !== address
            ),
          });
          setFormValues({
            ...formValues,
            supportedAssets: formValues.supportedAssets.filter(
              (item) => item[0].assetAddress !== address
            ),
          });
          break;
        case "vault":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          setAssetItems({
            ...assetItems,
            vault: assetItems.vault.filter(
              (item) => item[0].vaultAddress !== address
            ),
          });
          setFormValues({
            ...formValues,
            supportedVaults: formValues.supportedVaults.filter(
              (item) => item[0].vaultAddress !== address
            ),
          });
          break;
        case "protocol":
          setComplete({ a: 0, b: 1, c: 0, d: 0 });
          setAssetItems({
            ...assetItems,
            protocol: assetItems.protocol.filter(
              (item) => item[0].protocolAddress !== address
            ),
          });
          setFormValues({
            ...formValues,
            supportedProtocols: formValues.supportedProtocols.filter(
              (item) => item[0].protocolAddress !== address
            ),
          });
          break;
        default:
          console.log("not able to remove");
      }
    }
  };

  const handleInputChange = (data) => {
    // console.log("data from input", data);
    // on change of these options clear formValues

    if (data.id === "advisor") {
      setClearSettings(!clearSettings);
      setIsValidated({
        ...isValidated,
        coreCard: {
          advisor: "invalid",
        },
      });
      setFormValues({
        ...formValues,
        advisorEndPoint: undefined,
        vaultAdvisorSetting: undefined,
        advisorEmail: undefined,
      });
    }
    switch (data.id) {
      case "managementFee":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                managementFee: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                managementFee: "invalid",
              },
            });

        setSupportItems({
          ...supportItems,
          managementFee: data.value,
        });

        setFormValues({
          ...formValues,
          managementFee: data.value,
        });
        break;
      case "noOfDaysForLockedWithdraw":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                noOfDaysForLockedWithdraw: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                noOfDaysForLockedWithdraw: "invalid",
              },
            });

        setSupportItems({
          ...supportItems,
          noOfDaysForLockedWithdraw: data.value,
        });

        setFormValues({
          ...formValues,
          noOfDaysForLockedWithdraw: data.value,
        });
        break;
      case "timeLock":
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                noOfDaysForLockedWithdraw: "empty",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                noOfDaysForLockedWithdraw: "valid",
              },
            });
        break;
      case "managementBeneficiary":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                managementBeneficiary: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                managementBeneficiary: "invalid",
              },
            });

        setSupportItems({
          ...supportItems,
          managementBeneficiary: data.value,
        });

        setFormValues({
          ...formValues,
          managementBeneficiary: data.value,
        });
        break;
      case "performanceFee":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                performanceFee: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                performanceFee: "invalid",
              },
            });

        setSupportItems({
          ...supportItems,
          performanceFee: data.value,
        });

        setFormValues({
          ...formValues,
          performanceFee: data.value,
        });
        break;
      case "performanceBeneficiary":
        // Validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                performanceBeneficiary: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              supportCard: {
                ...isValidated.supportCard,
                performanceBeneficiary: "invalid",
              },
            });

        setSupportItems({
          ...supportItems,
          performanceBeneficiary: data.value,
        });

        setFormValues({
          ...formValues,
          performanceBeneficiary: data.value,
        });
        break;
      case "vaultName":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                vaultName: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                vaultName: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          vaultName: data.value,
        });
        break;
      case "tokenName":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenName: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenName: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          tokenName: data.value,
        });
        break;
      case "tokenSymbol":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenSymbol: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                tokenSymbol: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          tokenSymbol: data.value,
        });
        break;
      case "advisor":
        // Validation check
        data.value &&
        formValues.advisorEmail &&
        formValues.vaultAdvisorSetting &&
        data.value === formValues.advisorId
          ? setIsValidated({
              ...isValidated,
              coreCard: {
                advisor: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              coreCard: {
                advisor: "invalid",
              },
            });

        setCoreItems({ ...coreItems, advisor: data.value ? data : "" });
        break;
      case "vaultIcon":
        setVaultIcon(data.value);
        // setFormValues({
        //   ...formValues,
        //   vaultIcon: data.value,
        // });
        break;
      case "emergencyVault":
        // validation check
        data.value
          ? setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                emergencyVault: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              adminCard: {
                ...isValidated.adminCard,
                emergencyVault: "invalid",
              },
            });

        setFormValues({
          ...formValues,
          accountAddress: userAddress,
          emergencyVaultAddress: data.value,
        });
        break;
      case "privateVault":
        setFormValues({
          ...formValues,
          vaultType: data.value ? "PRIVATE" : "PUBLIC",
        });
        break;
      case "visibleVault":
        setFormValues({
          ...formValues,
          isVisible: data.value,
        });
        break;
      case "vaultCurrency":
        setBaseCurrency(data.value);
        data.value
          ? setIsValidated({
              ...isValidated,
              assetCard: {
                ...isValidated.assetCard,
                currency: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              assetCard: {
                ...isValidated.assetCard,
                currency: "invalid",
              },
            });
        setFormValues({ ...formValues, baseCurrency: data.value });
        break;
      case "vaultAsset":
        data.value
          ? setIsValidated({
              ...isValidated,
              assetCard: {
                ...isValidated.assetCard,
                vault: "valid",
              },
            })
          : setIsValidated({
              ...isValidated,
              assetCard: {
                ...isValidated.assetCard,
                vault: "invalid",
              },
            });
        setVaultAsset(data.symbol);
        setFormValues({ ...formValues, vaultAsset: data.value });
        break;
      case "description":
        setFormValues({ ...formValues, description: data.value });
        break;
      case "longDescription":
        setFormValues({ ...formValues, longDescription: data.value });
        break;
      default:
        break;
    }
  };

  const handleValidation = () => {
    let obj = { ...isValidated };
    Object.keys(isValidated).map((r) => {
      Object.keys(isValidated[r]).map((v) => {
        if (obj[r][v] === "empty") {
          obj = {
            ...obj,
            [r]: {
              ...obj[r],
              [v]: "invalid",
            },
          };
        }
      });
    });
    try {
      web3.utils.toChecksumAddress(formValues.managementBeneficiary);
    } catch (err) {
      console.log({ err });
      obj = {
        ...obj,
        supportCard: {
          ...obj.supportCard,
          managementBeneficiary: "invalid-address",
        },
      };
    }
    try {
      web3.utils.toChecksumAddress(formValues.performanceBeneficiary);
    } catch (err) {
      console.log({ err });
      obj = {
        ...obj,
        supportCard: {
          ...obj.supportCard,
          performanceBeneficiary: "invalid-address",
        },
      };
    }
    try {
      web3.utils.toChecksumAddress(formValues.emergencyVaultAddress);
    } catch (err) {
      console.log({ err });
      obj = {
        ...obj,
        adminCard: {
          ...obj.adminCard,
          emergencyVault: "invalid-address",
        },
      };
    }
    setIsValidated(obj);
    return true;
  };

  const finalValidationHandler = () => {
    if (
      isValidated.supportCard.deposit === "valid" &&
      isValidated.supportCard.withdraw === "valid" &&
      isValidated.supportCard.managementBeneficiary === "valid" &&
      isValidated.supportCard.managementFee === "valid" &&
      isValidated.supportCard.performanceBeneficiary === "valid" &&
      isValidated.supportCard.performanceFee === "valid" &&
      isValidated.assetCard.base === "valid" &&
      isValidated.assetCard.vault === "valid" &&
      isValidated.assetCard.currency === "valid" &&
      isValidated.coreCard.advisor === "valid" &&
      isValidated.adminCard.vaultName === "valid" &&
      isValidated.adminCard.tokenName === "valid" &&
      isValidated.adminCard.tokenSymbol === "valid" &&
      isValidated.adminCard.vaultIcon === "valid" &&
      isValidated.adminCard.emergencyVault === "valid" &&
      isValidated.adminCard.profitType === "valid"
    )
      return true;
    else return false;
  };

  const handleSubmit = async () => {
    if (handleValidation()) {
      const feeResponse = await FeeStrategy.getFee();
      const feeDetails = feeResponse.data.data;
      const performanceFeeAddress = feeDetails.find(
        (item) => item.name === "Performance Management Fee"
      ).strategyAddress;
      const managementFeeAddress = feeDetails.find(
        (item) => item.name === "Management Fee"
      ).strategyAddress;
      const feeObj = {
        [managementFeeAddress]: {
          beneficiary: formValues.managementBeneficiary,
          percentage: formValues.managementFee,
        },
        [performanceFeeAddress]: {
          beneficiary: formValues.performanceBeneficiary,
          percentage: formValues.performanceFee,
        },
      };
      let formData = { ...formValues };
      delete formData.performanceFee;
      delete formData.performanceBeneficiary;
      delete formData.managementFee;
      // delete formData.noOfDaysForLockedWithdraw;
      // delete formData.isTimeLocked;
      delete formData.managementBeneficiary;
      formData = { ...formData, managementFeeDetails: feeObj };
      try {
        if (formData.noOfDaysForLockedWithdraw)
          formData.noOfDaysForLockedWithdraw = parseFloat(
            formData.noOfDaysForLockedWithdraw
          );
      } catch (err) {
        console.log({ err });
      }
      if (enableVault && finalValidationHandler()) {
        try {
          const create = await PendingVault.createPendingVault(formData);
          if (create.data.statusCode == 200) {
            dispatch(setVaultType(formValues?.vaultType));
            PendingVault.uploadVaultIcon(create.data.data.id, vaultIcon)
              .then((res) => {
                history.push(`/${create.data.data.id}/queue`);
              })
              .catch((err) => message.error(err.response.data.error));
          }
        } catch (error) {
          message.error(error.response.data.error);
        }
        // console.log("creating vault");
      } else {
        message.error("Please enter the necessary fields");
      }
      // console.log("creating vault");
    } else {
      Object.keys(isValidated.supportCard).every(
        (key) => isValidated.supportCard[key] === "empty"
      );
    }
  };

  const handleAdvisor = (values) => {
    // values &&
    // // values.advisorEndPoint &&
    // values.vaultAdvisorSetting &&
    // values.advisorEmail
    //   ? setIsValidated({
    //       ...isValidated,
    //       coreCard: {
    //         advisor: "valid",
    //       },
    //     })
    //   : setIsValidated({
    //       ...isValidated,
    //       coreCard: {
    //         advisor: "invalid",
    //       },
    //     });

    setFormValues({
      ...formValues,
      advisorEndPoint: values.advisorEndPoint,
      vaultAdvisorSetting: values.vaultAdvisorSetting,
      // supportedProtocols: values.supportedProtocols,
      advisorEmail: values.advisorEmail,
      advisorId: coreItems.advisor.value,
    });
  };

  const populateTags = (deposit, withdraw) => {
    if (deposit?.length === 1 && withdraw?.length === 1) {
      setIsValidated({
        ...isValidated,
        supportCard: {
          ...isValidated.supportCard,
          deposit: "valid",
          withdraw: "valid",
        },
      });
      setSupportItems({
        ...supportItems,
        deposit: Array([{ ...deposit[0], type: "deposit" }]),
        withdraw: Array([{ ...withdraw[0], type: "withdraw" }]),
      });
      setFormValues({
        ...formValues,
        depositStrategy: deposit[0].strategyAddress,
        withdrawalStrategy: withdraw[0].strategyAddress,
      });
    }
  };

  async function getAssets() {
    setLoader(true);
    const depositResponse = await Deposit.getAllStrategy();
    let deposit = depositResponse.data.data;
    const withdrawResponse = await WithDraw.getAllStrategy();
    let withdraw = withdrawResponse.data.data;
    populateTags(deposit, withdraw);
    const assetResponse = await Assets.getAllAssets();
    let asset = assetResponse.data.data;
    const vaultResponse = await Vaults.getVaultByAdmin(userAddress);
    let vault = vaultResponse.data?.data?.data;
    const protocolResponse = await Protocol.getAllProtocols();
    let protocol = protocolResponse.data.data;
    setLeftItems({ asset, vault, protocol, deposit, withdraw });
    setLoader(false);
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const searchAll = (data) => {
    setAutoComplete([]);
    Vaults.searchItems(data)
      .then((response) => {
        if (response.data.data.Asset)
          setSearch({ filter: response.data.data.Asset, type: "asset" });
        else if (response.data.data.Vault)
          setSearch({ filter: response.data.data.Vault, type: "vault" });
        else if (response.data.data.Protocol)
          setSearch({ filter: response.data.data.Protocol, type: "protocol" });
      })
      .catch((e) => console.log("Search error", e));
  };

  const autocomplete = (data) => {
    setSearchValue(data);
    if (data === "") {
      setAutoComplete([]);
      setSearch("");
    } else
      Vaults.autoCompleteDetails(data)
        .then((response) => {
          // console.log("autocomplete response", response);
          setAutoComplete(response.data.data);
        })
        .catch((e) => {
          setAutoComplete([]);
          // console.log("autocomplete error", e);
        });
  };

  const handleSearch = useCallback(
    debounce((e) => autocomplete(e), 1000),
    []
  );

  const walletSelectionCheck = async () => {
    const connect = await handleWalletSelection(null);
    if (!connect) history.push("/");
  };
  const vaultTypeChangeHandler = (event) => {
    event?.target?.value
      ? setIsValidated({
          ...isValidated,
          adminCard: {
            ...isValidated.adminCard,
            profitType: "valid",
          },
        })
      : setIsValidated({
          ...isValidated,
          adminCard: {
            ...isValidated.adminCard,
            profitType: "invalid",
          },
        });
    switch (event.target.value) {
      case "Asset Vault":
        setFormValues({
          ...formValues,
          profitType: VaultType.ASSET_VAULT,
        });
        break;
      case "Yield Vault":
        setFormValues({
          ...formValues,
          profitType: VaultType.YIELD_VAULT,
        });
        break;
      default:
        setFormValues({
          ...formValues,
          profitType: VaultType.ASSET_VAULT,
        });
        break;
    }
  };

  useEffect(() => {
    // console.log({ userAddress });
    if (!userAddress) walletSelectionCheck();
    try {
      getAssets();
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
    Currency.getCurrency().then((res) => {
      setCurrencyList(res.data?.data);
    });
    dispatch(loadCurrency());
    Config.getConfigData().then((res) => {
      let data = [
        res.data.data.dao,
        res.data.data.god,
        ...res.data.data.whiteList,
      ]
        .map((item) => item?.toUpperCase())
        .includes(userAddress?.toUpperCase());
      if (!data)
        message.warning(
          "You should be approved by Yieldcube to create a public vault. Please contact ops@yieldcube.io",
          10
        );
      setConfigData(data);
    });
  }, []);

  useEffect(() => {
    Object.values(isValidated.supportCard).every((value) => value === "valid")
      ? setSupportTick(1)
      : setSupportTick(0);
    Object.values(isValidated.assetCard).every((value) => value === "valid")
      ? setAssetTick(1)
      : setAssetTick(0);
    Object.values(isValidated.adminCard).every((value) => value === "valid")
      ? setAdminTick(1)
      : setAdminTick(0);

    setEnableVault(
      Object.values(isValidated.supportCard).every(
        (value) => value === "valid"
      ) &&
        Object.values(isValidated.assetCard).every(
          (value) => value === "valid"
        ) &&
        Object.values(isValidated.coreCard).every(
          (value) => value === "valid"
        ) &&
        Object.values(isValidated.adminCard).every((value) => value === "valid")
    );
  }, [isValidated]);

  useEffect(() => {
    if (formValues.advisorEmail && coreItems.advisor.value) setCoreTick(1);
    else setCoreTick(0);
  }, [formValues]);

  useEffect(() => {
    let count = supportTick + assetTick + coreTick + adminTick;
    setCounter(count);
    switch (count) {
      case 1:
        setPercent(25);
        break;
      case 2:
        setPercent(50);
        break;
      case 3:
        setPercent(75);
        break;
      case 4:
        setPercent(100);
        break;
      default:
        setPercent(0);
        break;
    }
  }, [supportTick, assetTick, coreTick, adminTick]);

  // To view toaster
  useEffect(() => {
    if (assetItems.base.length + assetItems.vault.length >= 200)
      setViewToaster(true);
    else setViewToaster(false);
  }, [assetItems]);

  useEffect(() => {
    if (userAddress)
      setFormValues({ ...formValues, accountAddress: userAddress });
  }, [userAddress]);

  return (
    <>
      {loader ? (
        <LogoLoader />
      ) : (
        <div className="mainWrapper">
          <div className="header">
            <NavigationBar />
          </div>
          <div className="wrapper">
            <div className="container create-vault-container">
              <div className="main create-vault2">
                <div className="baseHeading">
                  <h1>Create New Vault</h1>
                  <p>
                    Create a private vault to manage your own assets or create a
                    public vault for others to participate in. Choose from
                    standard strategies or create and apply your own.
                  </p>
                </div>
                <div className="main-items">
                  <div>
                    <div className="left-block-wrap">
                      <div className="toggle-view">
                        {/* <button
                  className={listView ? "grid" : "list"}
                  onClick={() => setListView(false)}
                >
                  Icon View
                </button> */}
                        <button
                          className={listView ? "list" : "grid"}
                          onClick={() => setListView(true)}
                        >
                          List View
                        </button>
                      </div>
                      <div className="search">
                        <h4>Drag and Drop to configure your vault</h4>
                        <Input.Search
                          placeholder="Search here"
                          className="search-input"
                          prefix={<img src={SearchInput} alt="" />}
                          onChange={(e) => handleSearch(e.target.value)}
                          allowClear={true}
                          enterButton={""}
                        />
                        {/* {autoComplete ? (
                    <ul className="autocomplete-data">
                      {autoComplete?.map((v) => (
                        <li key={v} onClick={() => searchAll(v)}>
                          <span>{v}</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )} */}
                      </div>
                      <div>
                        {leftItems ? (
                          listView ? (
                            <CollapseList
                              id="left"
                              fields={leftItems}
                              addItemsToCart={addItemsToCart}
                              searchValue={searchValue}
                            />
                          ) : (
                            <CollapseGrid
                              id="left"
                              fields={leftItems}
                              addItemsToCart={addItemsToCart}
                              searchValue={searchValue}
                            />
                          )
                        ) : (
                          ""
                        )}
                        {search ? (
                          <SearchItems
                            id="left"
                            fields={search}
                            addItemsToCart={addItemsToCart}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="right-block-wrap">
                    <div className="vaultWrap">
                      <div className="vaultwarpBox">
                        <BasicCard
                          color="darkgreen"
                          title="Support"
                          id="support"
                          active={complete.a}
                          subtext="The Support section determines the basic functionality of the Vault. Select a deposit/withdrawal
                      strategy and set the fees for the vault."
                          tick={supportTick}
                          onFocus={() =>
                            setComplete({ a: 1, b: 0, c: 0, d: 0 })
                          }
                        >
                          <Row gutter={10} span={24}>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="deposit"
                                fields={supportItems?.deposit}
                                accept="deposit"
                                text="Deposit"
                                removeItemsFromCart={removeItemsFromCart}
                                isValidated={isValidated}
                              />
                            </Col>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="withdraw"
                                fields={supportItems?.withdraw}
                                accept="withdraw"
                                text="Withdraw"
                                removeItemsFromCart={removeItemsFromCart}
                                isValidated={isValidated}
                              />
                            </Col>
                          </Row>
                          <Row gutter={10} span={24}>
                            <Col className="time-locked" span={12}>
                              <Checkbox
                                value={formValues.isTimeLocked}
                                onChange={(changedData) => {
                                  handleInputChange({
                                    id: "timeLock",
                                    value: changedData?.target?.checked,
                                  });
                                  if (changedData?.target?.checked)
                                    setFormValues({
                                      ...formValues,
                                      noOfDaysForLockedWithdraw: undefined,
                                    });
                                  setFormValues({
                                    ...formValues,
                                    isTimeLocked: changedData?.target?.checked
                                      ? true
                                      : false,
                                    noOfDaysForLockedWithdraw: changedData
                                      ?.target?.checked
                                      ? formValues.noOfDaysForLockedWithdraw
                                      : 0,
                                  });
                                }}
                                sx={{
                                  color: "rgba(180, 124, 57, 0.58)",
                                  "&.Mui-checked": {
                                    color: "rgba(180, 124, 57, 0.58)",
                                  },
                                }}
                              />
                              Time Locked
                            </Col>
                            <Col className="" span={12}>
                              <Support
                                id="noOfDaysForLockedWithdraw"
                                fields={formValues?.noOfDaysForLockedWithdraw}
                                setField={(item) => handleInputChange(item)}
                                type="number"
                                disabled={!formValues.isTimeLocked}
                                accept="text"
                                text="No. of Days"
                                isValidated={isValidated}
                                placeholder="No .of Days"
                                tooltipText={"Number of Days"}
                              />
                            </Col>
                          </Row>
                          <Divider style={{ margin: "15px 0" }} />
                          <p className="sub-title">Fees</p>
                          <Row gutter={[10, 20]} span={24}>
                            <Col className="text" span={24}>
                              <div>
                                Platform Fee{" "}
                                <Tooltip
                                  title="The platform usage fees are set by Yieldcube DAO and currently are fixed at 0.5% annually
calculated on the assets under management in the vault. Fees are calculated every time a deposit or
withdrawal transaction is processed and on a regular basis."
                                >
                                  <QuestionCircleOutlined
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                  />
                                </Tooltip>
                                <span className="txt-brown">0.5%</span>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="managementFee"
                                fields={supportItems?.managementFee}
                                setField={(item) => handleInputChange(item)}
                                type="number"
                                accept="text"
                                text="Management Fee (%)"
                                isValidated={isValidated}
                                placeholder="Management Fee"
                                tooltipText="The vault admin can set a management fee percentage between 0% and 10%
                            annually. Management Fees are calculated on the assets under management in the vault. Fees are
                            calculated every time a deposit or withdrawal transaction is processed and on a regular basis. Fees will
                            be transferred to the beneficiary’s address."
                              />
                            </Col>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="managementBeneficiary"
                                fields={supportItems?.managementBeneficiary}
                                setField={(item) => handleInputChange(item)}
                                accept="text"
                                type="text"
                                text="Beneficiary"
                                isValidated={isValidated}
                                placeholder="Beneficiary"
                              />
                            </Col>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="performanceFee"
                                fields={supportItems?.performanceFee}
                                setField={(item) => handleInputChange(item)}
                                accept="text"
                                type="number"
                                text="Performance Fee (%)"
                                isValidated={isValidated}
                                placeholder="Performance Fee"
                                tooltipText="The vault admin can set a performance fee percentage between 0% and 50%. Fees are
                            calculated as a percentage of the increase in NAV (value of assets under management). Fees are
                            calculated every time a deposit or withdrawal transaction is processed and on a regular basis. Fees will
                            be transferred to the beneficiary’s address. Performance fees do not take high watermarks into account."
                              />
                            </Col>
                            <Col className="gutter-row" span={12}>
                              <Support
                                id="performanceBeneficiary"
                                fields={supportItems?.performanceBeneficiary}
                                setField={(item) => handleInputChange(item)}
                                accept="text"
                                type="text"
                                text="Beneficiary"
                                isValidated={isValidated}
                                placeholder="Beneficiary"
                              />
                            </Col>
                          </Row>
                        </BasicCard>
                        <BasicCard
                          color="lightgreen"
                          title="Assets"
                          id="assets"
                          subtext="The Asset section determines which assets, protocols or other Yieldcube vaults are allowed in the vault.
                      You can only deposit or withdraw assets that are explicitly allowed in the vault. To add assets, protocols
                      and vaults simply drag and drop from the selection on the left."
                          active={complete.b}
                          tick={assetTick}
                        >
                          <Asset
                            id="base"
                            fields={assetItems.base}
                            accept="asset"
                            text="Vault Assets"
                            removeItemsFromCart={removeItemsFromCart}
                            isValidated={isValidated}
                          />
                          <Asset
                            id="protocol"
                            fields={assetItems.protocol}
                            accept="protocol"
                            text="Protocols"
                            removeItemsFromCart={removeItemsFromCart}
                            isValidated={isValidated}
                          />
                          <Asset
                            id="vault"
                            fields={assetItems.vault}
                            accept="vault"
                            text="Vaults"
                            removeItemsFromCart={removeItemsFromCart}
                            isValidated={isValidated}
                          />
                          <Row justify="space-between" gutter={30}>
                            <Col span={12}>
                              <DropDown
                                show={baseCurrency}
                                value={currencyList}
                                type="currency"
                                id="vaultCurrency"
                                setValue={(data) => {
                                  handleInputChange({
                                    id: "vaultCurrency",
                                    value: data,
                                  });
                                }}
                                text="Display Currency"
                                isValidated={isValidated}
                              />
                            </Col>
                            <Col span={12}>
                              <DropDown
                                show={vaultAsset}
                                value={assetItems.base}
                                type="asset"
                                id="vaultAsset"
                                setValue={(symbol, address) => {
                                  handleInputChange({
                                    id: "vaultAsset",
                                    value: address,
                                    symbol: symbol,
                                  });
                                }}
                                text="Base Asset"
                                isValidated={isValidated}
                              />
                            </Col>
                          </Row>
                          <Toaster
                            viewToaster={viewToaster}
                            handleViewToaster={handleViewToaster}
                            message="Sum of Base assets and vaults should not exceed 200"
                          />
                        </BasicCard>
                        <BasicCard
                          color="darkgreen"
                          title="Core"
                          id="core"
                          subtext="The Core section determines how your assets are invested. Choose an advisor and complete the
                      settings. Advisors are automated strategies running against your assets."
                          active={complete.c}
                          tick={coreTick}
                          onFocus={() =>
                            setComplete({ a: 0, b: 0, c: 1, d: 0 })
                          }
                        >
                          {/* <Core
                      id="protocol"
                      fields={coreItems.protocol}
                      accept="protocol"
                      text="Protocol"
                    /> */}
                          <Core
                            id="advisor"
                            enabled={coreItems.protocol.length ? false : false}
                            fields={coreItems.advisor}
                            accept="text"
                            setField={(item) => handleInputChange(item)}
                            text="Advisor"
                            isValidated={isValidated}
                          />
                          <Settings
                            enabled={coreItems.advisor === ""}
                            title={coreItems.advisor.value}
                            protocol={coreItems.protocol}
                            protocolsList={assetItems?.protocol.flat(1)}
                            baseAssetsList={assetItems?.base.flat(1)}
                            vaultsList={assetItems?.vault.flat(1)}
                            setAdvisor={handleAdvisor}
                            formValues={formValues}
                            clearSettings={clearSettings}
                            harvestProtocols={leftItems?.protocol?.filter(
                              (val) => {
                                if (
                                  val.protocolName
                                    .toLowerCase()
                                    .includes("curve.fi")
                                ) {
                                  return true;
                                } else return false;
                              }
                            )}
                            isValidated={isValidated}
                            handleValidation={setIsValidated}
                          />
                        </BasicCard>
                        <BasicCard
                          color="lightgreen"
                          title="Admin"
                          id="admin"
                          active={complete.d}
                          subtext=" Enter the Vault name, token name, token symbol, wallet address, emergency vault address, and choose the vault icon"
                          tick={adminTick}
                          onFocus={() =>
                            setComplete({ a: 0, b: 0, c: 0, d: 1 })
                          }
                        >
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "20px" }}
                          >
                            <Col span={12}>
                              <Admin
                                id="vaultName"
                                accept="text"
                                setField={(item) => handleInputChange(item)}
                                text="Vault Name"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                            <Col span={12}>
                              <Admin
                                id="tokenName"
                                accept="text"
                                setField={(item) => handleInputChange(item)}
                                text="Token Name"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "20px" }}
                          >
                            <Col span={12}>
                              <Admin
                                id="tokenSymbol"
                                accept="text"
                                setField={(item) => handleInputChange(item)}
                                text="Token Symbol"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                            <Col span={12}>
                              <div
                                className="content-items"
                                id="accountAddress"
                              >
                                <div className="add">
                                  <input
                                    type="text"
                                    placeholder="Wallet Address"
                                    id="accountAddress"
                                    value={userAddress}
                                    readOnly
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                                <div className="text">Wallet Address</div>
                              </div>
                            </Col>
                          </Row>
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "20px" }}
                          >
                            <Col span={12}>
                              <Admin
                                id="vaultIcon"
                                accept="upload"
                                setField={(item) => handleInputChange(item)}
                                text="Vault Icon"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                            <Col span={12}>
                              <Admin
                                id="emergencyVault"
                                accept="text"
                                setField={(item) => handleInputChange(item)}
                                text="Emergency Vault"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "20px" }}
                          >
                            <Col span={12}>
                              <div className="content-items">
                                <Radio.Group
                                  className="vault-type-group"
                                  onChange={vaultTypeChangeHandler}
                                  defaultValue={VaultTypeMap?.[0]}
                                >
                                  {VaultTypeMap.map((type, index) => {
                                    return (
                                      <Radio
                                        className="vault-type-radio"
                                        key={index}
                                        value={type}
                                      >
                                        {type}
                                      </Radio>
                                    );
                                  })}
                                </Radio.Group>
                                <div className="text">Vault Type </div>
                              </div>
                            </Col>
                          </Row>
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "20px" }}
                          >
                            <Col span={24}>
                              <Admin
                                id="description"
                                accept="textarea"
                                setField={(item) => handleInputChange(item)}
                                text="Short Description"
                                isValidated={isValidated}
                                handleValidation={setIsValidated}
                              />
                            </Col>
                            <Col span={12}>
                              <div className="text">
                                Tell us more about this vault by adding a
                                detailed description
                              </div>
                            </Col>
                            <Col span={12} className="text">
                              <Modal
                                title={
                                  <div className="description-popover-title">
                                    <span>ADD DETAILED DESCRIPTION</span>
                                  </div>
                                }
                                centered
                                visible={modalOpen}
                                maskClosable={true}
                                width="90vw"
                                footer={null}
                                onOk={() => setModalOpen(false)}
                                onCancel={() => setModalOpen(false)}
                                className="modal-description"
                                zIndex="1200"
                              >
                                <div>
                                  <EditLongDescription
                                    type={"create"}
                                    short={formValues?.description}
                                    description={formValues?.longDescription}
                                    handleInputChange={(d) => {
                                      handleInputChange(d);
                                    }}
                                    close={(v) => setModalOpen(v)}
                                  />
                                </div>
                              </Modal>
                              <div
                                className="add-long-button"
                                onClick={() => {
                                  setModalOpen(true);
                                }}
                              >
                                + Add Detailed Description
                              </div>
                              {/* <Popover
                                trigger={"click"}
                                placement="topLeft"
                                overlayClassName="description-popover"
                                title={
                                  <div className="description-popover-title">
                                    <span>ADD DETAILED DESCRIPTION</span>
                                    <span
                                      className="close-button"
                                      onClick={() => setDescription(false)}
                                    >
                                      X
                                    </span>
                                  </div>
                                }
                                // onVisibleChange={(visible) => {
                                //   setDescription(visible);
                                // }}
                                visible={description}
                                content={
                                  <EditLongDescription
                                    type={"create"}
                                    short={formValues?.description}
                                    description={formValues?.longDescription}
                                    handleInputChange={(d) => {
                                      handleInputChange(d);
                                    }}
                                    close={(v) => setDescription(v)}
                                  />
                                }
                              >
                                <div
                                  className="add-long-button"
                                  onClick={() => {
                                    setDescription(true);
                                  }}
                                >
                                  + Add Detailed Description
                                </div>
                              </Popover> */}
                            </Col>
                          </Row>
                          <Row
                            gutter={10}
                            span={24}
                            style={{ paddingBottom: "40px" }}
                          >
                            <Col span={12}>
                              <div className="inline-toggle-item">
                                <div>
                                  <span className="text">Private Vault</span>
                                  <Tooltip
                                    title="Only whitelisted users are allowed to deposit or withdraw assets from the vault. Public
vaults allow anyone to deposit and withdraw."
                                  >
                                    <QuestionCircleOutlined
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <Switch
                                  defaultChecked={true}
                                  disabled={!configData}
                                  onChange={(item) =>
                                    handleInputChange({
                                      id: "privateVault",
                                      value: item,
                                    })
                                  }
                                ></Switch>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="inline-toggle-item">
                                <div>
                                  <span className="text">Visible</span>
                                  <Tooltip
                                    title="Choose visible if you want other people to be able to see your vault even if they are not
                              whitelisted for a vault. If set to not visible only whitelisted users and the vault admin will be able to see
                              the vault."
                                  >
                                    <QuestionCircleOutlined
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <Switch
                                  defaultChecked={true}
                                  onChange={(item) =>
                                    handleInputChange({
                                      id: "visibleVault",
                                      value: item,
                                    })
                                  }
                                ></Switch>
                              </div>
                            </Col>
                          </Row>
                        </BasicCard>
                      </div>
                      <div className="vaultwrapProgress">
                        <div className="vaultcontainer">
                          <div className="vaultConnectors">
                            <span className={complete.a ? "active1" : ""}>
                              <svg>
                                <PathLine
                                  points={[
                                    { x: -1, y: -350 },
                                    { x: 65, y: -350 },
                                    { x: 65, y: 185 },
                                    { x: 83, y: 185 },
                                  ]}
                                  stroke="#164f58"
                                  strokeWidth="3"
                                  fill="none"
                                  r={10}
                                />
                              </svg>
                            </span>
                            <span className={complete.b ? "active2" : ""}>
                              <svg>
                                <PathLine
                                  points={[
                                    { x: -1, y: -20 },
                                    { x: 40, y: -20 },
                                    { x: 40, y: 75 },
                                    { x: 83, y: 75 },
                                  ]}
                                  stroke="#22c55e"
                                  strokeWidth="3"
                                  fill="none"
                                  r={10}
                                />
                              </svg>
                            </span>
                            <span className={complete.c ? "active3" : ""}>
                              <svg>
                                <PathLine
                                  points={[
                                    { x: 0, y: 0 },
                                    { x: 0, y: 45 },
                                    { x: 200, y: 45 },
                                    { x: 200, y: 85 },
                                  ]}
                                  stroke="#164f58"
                                  strokeWidth="3"
                                  fill="none"
                                  r={10}
                                />
                              </svg>
                            </span>
                            <span className={complete.d ? "active4" : ""}>
                              <svg>
                                <PathLine
                                  points={[
                                    { x: 0, y: 0 },
                                    { x: 0, y: 20 },
                                    { x: 500, y: 20 },
                                    { x: 500, y: 85 },
                                  ]}
                                  stroke="#22c55e"
                                  strokeWidth="3"
                                  fill="none"
                                  r={10}
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="vaultGenerator">
                            <div className="vaultData">
                              <h2>Vault Progress</h2>
                              <div className="progressStat">
                                <Progress
                                  type="circle"
                                  percent={percent}
                                  width={94}
                                  strokeColor={"#18ba4a"}
                                />
                              </div>
                              <div className="vaultStage">
                                <p>
                                  <span>{counter} of 4</span> sections complete
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="button-content"
                        onClick={handleSubmit}
                        // disabled={!enableVault}
                        title={
                          !enableVault &&
                          "Please fill the required fields (Not marked as optional)"
                        }
                      >
                        Create your Vault
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default CreateVault;
