import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class User {
  static addUser(accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `user/v2.0/yieldster/addUser`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        accountAddress,
      },
    });
  }

  static getUserStats(accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/user/v2.0/yieldster/getFinancialDetails`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        accountAddress,
      },
    });
  }

  static getUserNonce(accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/user/v2.0/yieldster/nonce/${accountAddress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static validateNonce(accountAddress, signedMessage) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/user/v2.0/yieldster/validate/`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        accountAddress, signedMessage
      },
    });
  }
}
