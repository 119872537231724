import style from "./Style/style.css";
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import backbutton from "../../../assets/buttons/backbutton.svg";
import reloadbutton from "../../../assets/buttons/reloadbutton.svg";
import closebutton from "../../../assets/buttons/closebutton.svg";
import { useHistory } from "react-router-dom";
import SDKTransaction from "../../VaultTransaction/SDKTransaction/SDKSendTransaction";
import NonceCalculator from "../../Global-Functions/NonceCalculator";
import { id } from "date-fns/locale";
const IframeScreen = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [url, setUrl] = useState("");
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const web3 = useSelector((state) => state.get_web3.web3);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const handleGoBack = () => {
    history.goBack();
  };
  const handleReload = () => {
    document.getElementById("iframeid").src += "";
  };
  const FunctionCompiler = (data) => {
    return data;
  };
  const handleClose = () => {
    history.push(`/vault/${vaultAddress}/custom-strategies`);
  };
  useEffect(() => {
    if (location.state != undefined) {
      localStorage.setItem("custom-interface-url", location.state.url);
      setUrl(location.state.url);
    } else {
      let url = localStorage.getItem("custom-interface-url");
      if (url) {
        setUrl(url);
      } else {
        handleClose();
      }
    }
  }, [location]);

  useEffect(() => {
    if (web3 && url && vaultAddress) {
      let iFrameObject = document.getElementById("iframeid");
      window.addEventListener("message", async (e) => {
        if (e.data.event_id == 1 && e.data.job == "EXECUTE_TRANSACTION") {
          const { from, to, encodedData } = e.data.data;
          NonceCalculator(web3, from, (nonceValue) => {
            const transactionData = FunctionCompiler({
              contractAddress: to,
              txType: "SDK_TRANSACTION",
              signer: from,
              txData: encodedData,
              nonce: nonceValue,
              parameters: {},
              vaultAddress: vaultAddress,
            });

            if (transactionData) {
              dispatch(
                SDKTransaction(transactionData, "POST", iFrameObject, url)
              );
            }
          });
        }
      });
    }
  }, [web3, url, vaultAddress]);

  const CustomUICalls = async (url, iFrameObject) => {
    const message = await {
      dataSource: "YIELDSTER_FINANCE",
      parent_job_id: 1,
      data: {
        vaultAddress,
        userAddress,
      },
      infura_url: process.env.REACT_APP_INFURA_WS,
    };
    if (message) {
      iFrameObject.contentWindow.postMessage(message, url);
    }
  };

  useEffect(() => {
    if (url) {
      let iFrameObject = document.getElementById("iframeid");
      iFrameObject.addEventListener("load", async () => {
        CustomUICalls(url, iFrameObject);
      });
      iFrameObject.removeEventListener("load", async () => {
        CustomUICalls(url, iFrameObject);
      });
    }
  }, [url]);

  useEffect(() => {
    let iFrameObject = document.getElementById("iframeid");
    if (url && userAddress && vaultAddress) {
      CustomUICalls(url, iFrameObject);
    }
  }, [url, userAddress, vaultAddress]);

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backbutton}
              alt=""
              style={{ height: "1.6rem" }}
              onClick={handleGoBack}
              className="iframe-button-styler"
            ></img>
            <img
              src={reloadbutton}
              alt=""
              style={{ height: "1.8rem" }}
              onClick={handleReload}
              className="iframe-button-styler"
            ></img>
          </div>
          <img
            src={closebutton}
            alt=""
            style={{ height: "1.6rem" }}
            onClick={handleClose}
            className="iframe-button-styler"
          ></img>
        </div>
        <iframe
          id="iframeid"
          src={url}
          onLoad={(url) =>
            CustomUICalls(url, document.getElementById("iframeid"))
          }
          title="Yieldcube Framework"
          width="100%"
          height="600"
          allowfullscreen
          sandbox
        ></iframe>
      </div>
    </Fragment>
  );
};
export default IframeScreen;
