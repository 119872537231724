import YieldCubeLogo from "../../assets/yc-logo-wide-solid.svg"
import "./Style/style.css"
const LogoLoader = () => {
    return(<>
     <div id="loading">
      <img src={YieldCubeLogo} alt="Yieldcube" id="akkrue-logo" />
    

      <div className="requestProgress">
        <div className="bar">
        </div>
      </div>
    </div>
    </>)
}
export default LogoLoader