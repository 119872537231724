import React, { Component } from 'react';
import './Styling/TableStyledButton.css'
import settings_image from '../../../assets/Transaction/settings.svg'
import Up_Arrow from '../../../assets/Transaction-Table/transaction-table-grow.svg'
import Down_Arrow from '../../../assets/Transaction-Table/transaction-table-drop.svg'
const SendAndReceiveButton = (props) => {

    const {send_or_receive} = props;

    return(
        <>
        {(send_or_receive == 1)?
            <div style={{display:"flex",gap:"15px"}}>
           <img src={Up_Arrow}></img>
            <p style={{fontSize:"14px",fontFamily:"Open Sans",color:"#6D7681"}}>Send</p>
    </div> : (send_or_receive == 0)?
    <div style={{display:"flex",gap:"15px"}}>
           <img src={Down_Arrow}></img>
            <p style={{fontSize:"14px",fontFamily:"Open Sans",color:"#6D7681"}}>Receive</p>
    </div>  :   

        <div style={{display:"flex",gap:"15px"}}>
        <img src={settings_image} style={{marginTop:"6px"}}></img>
        <p style={{display:"flex",alignItems:"flex-end",fontSize:"1.1rem",fontFamily:"Lato, sans-serif"}}>Safe Creation</p>
        </div> 

        }
        </>
    )
}

export default SendAndReceiveButton

//  <div style={{display:"flex",gap:"5px"}}>
// <p style={{color:"green",fontSize:"1.2rem"}}>&#x2199;</p>
// <p style={{display:"flex",alignItems:"flex-end",fontSize:"1.1rem",fontFamily:"Lato, sans-serif"}}>Receive</p>
// </div> 