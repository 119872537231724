import { useEffect, useState } from "react";
import { ReactComponent as DiscordIcon } from "../../../src/assets/icons/Discord-Logo-Black.svg";
import { ReactComponent as TwitterIcon } from "../../../src/assets/icons/Twitter_logo.svg";
import { ReactComponent as LinkedinIcon} from "../../../src/assets/icons/linkedin.svg"
import Breadcrumb from "../BreadCrumb/breadCrumb";
import Navbar from "../Navbar/index";
import FaqAccordion from "./FaqAccordion/FaqAccordion";
import { Faq } from "../../services/api";
import "./index.css";

function HelpCenter() {
  const [faq, setFaq] = useState([]);
  const faqApi = () => {
    Faq.allFaq()
      .then((response) => {
        if (
          response.data.data &&
          response.data.status == 1 &&
          response.data.data.length != 0
        ) {
          setFaq(response.data.data);
        } else if (
          response.data.data.length == 0 &&
          response.data.status == 1
        ) {
          setFaq(null);
        } else if (response.data.status == 0) {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    faqApi();
  }, []);
  
  return (
    <div className="helpCenterWrapper">
      <div className="navbarHelpcenter">
        <Navbar />
      </div>
      {/* main content */}
      <div className="mainContent">
        <div style={{ marginLeft: "-30px" }}>
          <Breadcrumb />
        </div>
        <div className="initialHeading">About Yieldcube</div>
        <div className="primaryContent">
          <p className="paraContent">
            Yieldcube’s vision is to become a recognized, blue-chip financial
            technology company that serves investors in the DeFi sector. It will
            provide a transparent, regulatory compliant, and highly secure
            platform for capital deployment. This technology will maximize
            risk-adjusted returns across leading DeFi protocols with a focus on
            maintaining principal and achieve returns by allocating capital to
            income streams.
          </p>
          <p className="paraContent">
            We built our platform for strategy developers, fund managers, and
            investors to easily design, launch, and invest in DeFi products so
            that investors have an arbiter of trust to evaluate the best
            investment options.
          </p>
          <p className="paraContent">
            To achieve this goal, the Yieldcube platform provides reliable,
            automated infrastructure with timely data and powerful tools. It
            empowers institutional and retail investors to focus on developing
            successful capital deployment strategies rather than facing the
            burden of maintenance and upkeep.
          </p>
          <p className="paraContent">
            Yieldcube's design simplifies the deployment of complex strategies
            to enable greater possibilities with predictable behavior. It
            consists of tools that allow anyone to create vaults, design or
            choose a strategy, apply a strategy to the vault, and decide who can
            deposit into the vault.
          </p>
          {/* <p className="paraContent">
            Read more about Yieldcube on&nbsp;:&nbsp;
            <a href="https://yieldster-framework.github.io/automation-platform-docs/">
              https://yieldster-framework.github.io/automation-platform-docs
            </a>
          </p> */}
          <div className="secondaryHeading">Contact us</div>
          <p className="paraContent">
            Learn more about Yieldcube, whether it’s to get started with
            investing or to ask questions, by contacting us now.
          </p>
        </div>

        <div className="secondaryContent">
          <div className="accordionContent">
            <div className="accordionHeading">FAQ</div>
            {faq == null || faq == [] ? (
              <span className="accordionNoContent"> No Content</span>
            ) : (
              <FaqAccordion faq={faq} />
            )}
          </div>
          


        </div>
      </div>

      {/* footer */}
      <footer className={
              !faq.length? "footerContactUsNoContent"
                : "footerContactUsWrap"
            }>
          <span className="mailId">
                <a
                  href=""
                  className="anchorTagSocialMedia"
                  title="Email"
                >
                  <span className="material-icons" style={{ color: "#6b7075" }}>
                    email
                  </span>
                </a>
                <a href="" title="Twitter">
                  <TwitterIcon style={{ height: "24px", width: "24px" }} />
                </a>
                <a href="" title="LinkedIn">
                  <LinkedinIcon style={{ height: "24px", width: "24px" }} />
                </a>
            </span>
        </footer>
    </div>
  );
}

export default HelpCenter;
