const initState = {
    vaultAddress: "",
  
};

const setVaultAddress = (state=initState,action) => {
    switch(action.type) {
        case "SET_VAULT_ADDRESS":
            return { 
                ...state,
                vaultAddress: action.payload.setVaultAddress,
            }

        default: 
            return state;
    }
}

export default setVaultAddress;