const initState = {
  blockNumber: null,
};

const CurrentBlockNumberReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_BLOCK_NUMBER":
      return {
        ...state,
        blockNumber: action.payload.blockNumber,
      };

    default:
      return state;
  }
};

export default CurrentBlockNumberReducer;
