import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { LongDescription } from "./Description";
import NewReChart from "./NewChart/trialChart";
import "./DashBoard_Styling/dashboardstyle.css";

function DashBoardV2() {
  const { url } = useRouteMatch();
  const [chart, setChart] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [loadingUniverse, handleSetLoadingUniverse] = useState(true);
  const description = useSelector(
    (state) => state.get_vault_details?.vault?.vault?.longDescription ?? state.get_vault_details?.vault?.vault?.description
  );

  useEffect(() => {
    if (chart && description) {
      setTimeout(() => {
        setLoadingSkeleton(false);
      }, 1500);
    } else {
      setLoadingSkeleton(true);
    }
  }, [chart, description]);

  const handleChartLoading = (value) => {
    setChart(value);
  };

  const Dashboard = () => {
    return (<div className="dashboard-style-main-component">
      <div>
        <p className="dashboard-title-styling">
          Dashboard
        </p>
        <NewReChart
          isChartLoading={handleChartLoading}
        />
      </div>
    </div>)
  }

  return (
    <Switch>
      <Route path={`${url}`} exact render={() => <Dashboard />} />
      <Route
        exact
        path={`${url}/description`}
        render={() => <LongDescription description={description} />}
      />
    </Switch>

  );
}

export default DashBoardV2;
