import { VaultType } from "../constants/vaultType";
export const profitTypeDisplay = (type) => {
  switch (type) {
    case VaultType.ASSET_VAULT:
      return "Asset Vault";
    case VaultType.YIELD_VAULT:
      return "Yield Vault";
    default:
      return;
  }
};
