  export const MyBalance = (val) => async(dispatch) => {
      
    dispatch({
      type: "SET_MY_BALANCE",
      payload: {
        myBalance: val
      }
    })
  }

