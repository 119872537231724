const initState = {
    wallet: null,
  
};

const walletStateReducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_WALLET":
            return { 
                ...state,
                wallet: action.wallet,
            }

        default: 
            return state;
    }
}

export default walletStateReducer;