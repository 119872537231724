import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "../Table/Table_Styling/style.css";
import "../WhiteList_Manager/addButton.css";

const useStyles = makeStyles((theme) => ({
  table: {
    boxShadow: 0,
    width: 450,
    overflow: "auto",
  },
  header: {
    position: "sticky",
  },
  rowAddress: {
    paddingRight: "0px",
    paddingLeft: "10px",
  },
  rowGroupName: {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  height: {
    height: "10px",
  },
  width: {
    width: "250px",
  },
  padding: {
    height: "30px",
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("1641")]: {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  whitelistAddress: {
    fontSize: "14px",
    display: "inline",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "none",
    },
  },
  whitelistAddressSmall: {
    fontSize: "14px",
    display: "none",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "inline",
    },
  },
  smallFont: {
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  subTitle: {
    paddingTop: "15px",
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Catamaran",
    fontStyle: "normal",
    color: "#1b3e69",
    textAlign: "left",
  },
  brownText: {
    fontSize: "14px",
    paddingTop: "15px",
    color: "#b47c39",
    fontWeight: "600",
    fontFamily: "'Open Sans', sans-serif",
  },
}));

export default function BasicTable({ vaultDetails }) {
  const classes = useStyles();
  const { url } = useRouteMatch();
  let editURL = url.replace(/\/[^\/]*$/, "/edit");
  const [performanceFee, setPerformanceFee] = useState({});
  const [managementFee, setManagementFee] = useState({});

  useEffect(() => {
    const { feeStrategies, vault } = vaultDetails;
    if (vault.managementFeeDetails) {
      feeStrategies.map((item) => {
        Object.keys(vault.managementFeeDetails).map((v) => {
          if (item.strategyAddress === v) {
            if (item.name === "Performance Management Fee") {
              setPerformanceFee({
                ...vault.managementFeeDetails[v],
              });
              return managementFee;
            } else if (item.name === "Management Fee") {
              setManagementFee({ ...vault.managementFeeDetails[v] });
              return performanceFee;
            }
          }
        });
      });
    }
  }, []);
  
  const DepositWithdrawStrategyRow = (props) => {
    const { strategyHeading, strategyList } = props;
    return (
      <React.Fragment>
        <p className="sub-title">{strategyHeading}</p>
        <div style={{ marginTop: "10px" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead
                classes={{ root: classes.height }}
                style={{ borderBottom: "1px solid #DEE5EF" }}
              >
                <TableRow>
                  <TableCell padding="none" classes={{ root: classes.width }}>
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Icon
                    </p>
                  </TableCell>
                  <TableCell padding="none">
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Name
                    </p>
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Action
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {strategyList?.map((strategy) => (
                  <TableRow
                    key={strategy.id}
                    style={{ borderBottom: "1px solid #DEE5EF" }}
                  >
                    <TableCell
                      scope="row"
                      className={classes.padding}
                      component="th"
                      padding="none"
                      align="left"
                    >
                      <p className={classes.whitelistAddress}>
                        <img
                          src={strategy.strategyImgURL}
                          alt=""
                          style={{ height: "20px" }}
                        />
                      </p>
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.rowGroupName}
                      align="left"
                    >
                      <p className={`${classes.smallFont} txt-primary`}>
                        {strategy.name}
                      </p>
                    </TableCell>
                    <TableCell padding="none" align="right">
                      <Link to={`${editURL}`} className="link">
                        Edit
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
        </div>
      </React.Fragment>
    );
  };

  const LockedWithdraw = (props) => {
    const { heading, name, amount } = props;
    return (
      <React.Fragment>
        <p className="sub-title">{heading}</p>
        <div style={{ marginTop: "10px" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead
                classes={{ root: classes.height }}
                style={{ borderBottom: "1px solid #DEE5EF" }}
              >
                <TableRow>
                  <TableCell padding="none" classes={{ root: classes.width }}>
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Icon
                    </p>
                  </TableCell>
                  <TableCell padding="none">
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Name
                    </p>
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <p
                      className={classes.smallFont}
                      style={{ fontWeight: "bold" }}
                    >
                      Action
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ borderBottom: "1px solid #DEE5EF" }}>
                  <TableCell
                    scope="row"
                    className={classes.padding}
                    component="th"
                    padding="none"
                    align="left"
                  >
                    <p className={`${classes.smallFont} txt-primary`}></p>
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.rowGroupName}
                    align="left"
                  >
                    <p className={`${classes.smallFont} txt-primary`}>{name}</p>
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <Link to={`${editURL}`} className="link">
                      Edit
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableRow>
            <TableCell padding="none" classes={{ root: classes.width }}>
              <p className={classes.subTitle}>Withdraw Time Period</p>
            </TableCell>
            <TableCell padding="none" classes={{ root: classes.width }}>
              <p className={classes.brownText}>
                {amount}
                {amount == 1 ? " Day" : " Days"}
              </p>
            </TableCell>
          </TableRow>
          <br />
        </div>
      </React.Fragment>
    );
  };

  const FeeRow = (props) => {
    const { feeName, beneficiaryAddress, feeValue } = props;
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead
            classes={{ root: classes.height }}
            style={{ borderBottom: "1px solid #DEE5EF" }}
          >
            <TableRow>
              <TableCell padding="none" classes={{ root: classes.width }}>
                <p className={classes.smallFont} style={{ fontWeight: "bold" }}>
                  {feeName}
                </p>
              </TableCell>
              <TableCell padding="none" align="left">
                <p className={classes.smallFont} style={{ fontWeight: "bold" }}>
                  Beneficiary
                </p>
              </TableCell>
              <TableCell padding="none" align="right">
                <p className={classes.smallFont} style={{ fontWeight: "bold" }}>
                  Action
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="1" style={{ borderBottom: "1px solid #DEE5EF" }}>
              <TableCell
                scope="row"
                className={classes.padding}
                component="th"
                padding="none"
                align="left"
              >
                <p className={`${classes.smallFont} txt-primary`}>
                  {feeValue === 0 || feeValue ? feeValue + "%" : ""}
                </p>
              </TableCell>
              <TableCell
                padding="none"
                className={classes.rowGroupName}
                align="left"
              >
                <p className={`${classes.smallFont} txt-primary`}>
                  {beneficiaryAddress}
                </p>
              </TableCell>
              <TableCell padding="none" align="right">
                <Link to={`${editURL}`} className="link">
                  {" "}
                  Edit
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Table style={{ width: "60%" }}>
        <TableRow>
          <td className="sub-title">Platform Fee</td>
          <td className="txt-brown">0.5%</td>
        </TableRow>
      </Table>
      <div style={{ marginTop: "25px" }}>
        <TableContainer>
          <Table aria-label="simple table">
            <FeeRow
              feeName="Management Fee"
              beneficiaryAddress={managementFee.beneficiary}
              feeValue={managementFee.percentage}
            />
            <br />
            <FeeRow
              feeName="Performance Fee"
              beneficiaryAddress={performanceFee.beneficiary}
              feeValue={performanceFee.percentage}
            />
            <br />
          </Table>
        </TableContainer>

        <DepositWithdrawStrategyRow
          strategyHeading="Deposit"
          strategyList={vaultDetails.depositStrategies}
        />

        {vaultDetails?.vault?.isTimeLocked &&
        vaultDetails?.vault?.noOfDaysForLockedWithdraw ? (
          <LockedWithdraw
            heading="Withdraw"
            name="Locked Withdraw"
            amount={vaultDetails?.vault?.noOfDaysForLockedWithdraw ?? 0}
          />
        ) : (
          <DepositWithdrawStrategyRow
            strategyHeading="Withdraw"
            strategyList={vaultDetails.withdrawalStrategies}
          />
        )}
      </div>
    </div>
  );
}
