const initState = {
    currency : [],
};

const currencyReducer = (state=initState,action) => {
    switch(action.type) {
        case "FETCH_CURRENCY":
            return { 
                ...state,
                currency:  action.payload.currencies
            }

        default: 
            return { 
                ...state
            }
    }
}

export default currencyReducer;