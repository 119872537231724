import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { getVault } from "../api";
import CustomLoader from '../Custom_Loader/customLoader'

import IndividualStrategy from "./IndividualStrategy";
import no_data from "../../assets/Table/no-data.svg";
import axios from "axios";
import Gnosis from "yieldster-abi/contracts/YieldsterVault.json";
// import IStrategyContract from "yieldster-abi/contracts/IStrategy.json";
import "./Styling/style.css";
import "../Styled_Sidebar/Style/style.css";
import OverView from "./OverView";


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "36px",
    [theme.breakpoints.down('1641')]: {
      fontSize: "30px",
    },
  },
  margin: {
    marginTop: "30px",
    [theme.breakpoints.down('1641')]: {
      marginTop: "26px",
    },
  },
  dividing: {
    display: "flex",
    padding: "20px",
    textAlign: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dividingNew: {
    display: "flex",
    padding: "20px",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  selectedDividing: {
    display: "flex",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#f7f5f5",
    color: "#1B3E69",
    alignItems: "center",
  },
  paperStyle: {
    backgroundColor: "white",
    height: "60vh",
    marginRight: "10px",
    marginTop: "60px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  inlinPBigOut: {
    fontSize: "20px",
    padding: "5px 5px 5px 0px",
    marginTop: "20px",
    background: "white",
  },
  paperStyleSmall: {
    background: "white",
    height: "60vh",
    marginLeft: "10px",
    marginTop: "60px",
    marginRight: "10px",
  },
  mainContainer: {
    width: "64.3125vw",
    background: "#ffffff",
    boxShadow: "  4px 4px 30px -4px rgba(27, 62, 105, 0.16)",
    padding: "10px 20px 20px 0px",
    display: "flex",
    borderRadius: "8px",
    minHeight: "640px"
  },
  smallFont: {
    fonstSize: "16px",
    [theme.breakpoints.down('1641')]: {
      fontSize: "13px",
    },
  }
}));

export default function Sidebar() {
  const defaultValue = useSelector((state) => state.get_vault_address);
  const classes = useStyles();
  const [click, setClick] = React.useState("strategy");
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const userAddress = useSelector((state) => state.get_user_address);
  const [strategy, setStrategy] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [current, setCurrent] = React.useState();
  let location = useLocation();
  const web3 = useSelector((state) => state.get_web3.web3);
  const [tokenValueInUSD, setTokenValueInUSD] = React.useState();
  const [strategyNavFromChain, setStrategyNavFromChain] = React.useState();
  const [balanceOf, setBalanceOf] = React.useState();
  const [overviewContainer, setOverviewContainer] = React.useState(true);
  const [empty, setEmpty] = React.useState(false);
  let routes = [];

  useEffect(() => {}, [current]);

  useEffect(() => {

    if (
      balanceOf == undefined ||
      strategyNavFromChain == undefined ||
      tokenValueInUSD == undefined
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
    if (empty) {
      setLoader(false);
    }
  }, [tokenValueInUSD, strategyNavFromChain, balanceOf, empty]);

  useEffect(() => {
    const asyncFn = async () => {
      if (defaultValue.vaultAddress != "")
        axios.get(getVault(defaultValue.vaultAddress)).then(async (data) => {
          if (data.data.data != undefined && data.data.data) {
            if (data.data.data.activeStrategyAddress.length == 0) {
              setEmpty(true);
            }
            if (data.data.data.strategies.length > 0) {
              let activeStrategyList = data.data.data.strategies.map(
                (value) => {
                  if (
                    data.data.data.activeStrategyAddress.length > 0 &&
                    data.data.data.activeStrategyAddress != undefined
                  ) {
                    if (
                      data.data.data.activeStrategyAddress.some(
                        (address) =>
                          address.toUpperCase() ==
                          value.strategyAddress.toUpperCase()
                      )
                    ) {
                      return value;
                    }
                  } else {
                  }
                }
              );
              if (
                data.data.data.activeStrategyAddress.length > 0 &&
                data.data.data.activeStrategyAddress != undefined
              ) {
                await Promise.all(activeStrategyList).then((s) => {
                  setStrategy(
                    s.map((data) => {
                      if (data == undefined) return "";
                      else return data;
                    })
                  );
                  activeStrategyList = activeStrategyList.filter(
                    (active) => active != undefined
                  );
                  if (activeStrategyList.length > 0) {
                    setCurrent(activeStrategyList[0]);
                    setClick(activeStrategyList[0].strategyName);
                    history.push(`${url}/overview`);
                  }
                });
              } else {
                history.push(`${url}/overview`);
              }
            } else {
              history.push(`${url}/overview`);
            }
          } else {
            history.push(`${url}/overview`);
          }
        });
    };
    asyncFn();
  }, [userAddress.address, defaultValue.vaultAddress]);

  function handleStrategy(data) {
    setOverviewContainer(false);
    setCurrent(data);
    setClick(data.strategyName);
    history.push(`${url}/${data.strategyName}`);
  }

  return (
    <div className="active-strategy-component-aligner">
      <p
        className={classes.heading}
        style={{ color: "#1B3E69", fontFamily: "Catamaran" }}
      >
        Active Strategies
      </p>
      <div
        style={{
          display: "flex",
          marginTop: "7px",
          boxShadow: "4px 4px 30px rgba(27, 62, 105, 0.16)",
          borderRadius: "8px 8px 8px 8px"
        }}
      >
        {loader ? (
          <div className={classes.mainContainer}>
            <div style={{ margin: "auto" }}>
              <CustomLoader /></div>
          </div>
        ) : true ? (
          <>
            <div className="styled-sidebar-parent-style">
              <div className={classes.margin}></div>
              <div style={{ height: "2px", backgroundColor: "white" }}> </div>
              <button
                className={
                  location.pathname.endsWith("/overview")
                    ? "styled-sidebar-button-styling-selected"
                    : "styled-sidebar-button-styling"
                }
                onClick={() => {
                  setOverviewContainer(true);
                  history.push(`${url}/overview`);
                }}
              >
                <p className={classes.smallFont}>Overview</p>
              </button>
              <div style={{ height: "2px", backgroundColor: "white" }}> </div>
              {strategy.map((data) => {

                return (
                  data != ""?
                    <>
                    
                      <button
                        className={
                          location.pathname.endsWith(data.strategyName)
                            ? "styled-sidebar-button-styling-selected"
                            : "styled-sidebar-button-styling"
                        }
                        onClick={() => {
                          handleStrategy(data);
                        }}
                      >
                        <p className={classes.smallFont}>{data.strategyName}</p>
                      </button>
                      <div style={{ height: "2px", backgroundColor: "white" }}>
                        {" "}
                      </div>
                    </>
                    : ""
                );
              })
              }
            </div>
            <div className="active-strategy-component-renderer">
              {location.pathname.endsWith("overview") ? (
                <Route path={`${url}`} component={OverView} />
              ) : current != undefined ? (
                <IndividualStrategy
                  vaultAddress={defaultValue.vaultAddress}
                  data={current}
                  tokenValueInUSD={tokenValueInUSD}
                  strategyNavFromChain={strategyNavFromChain}
                  balanceOf={balanceOf}
                />
              ) : <></>}{" "}
            </div>

          </>
        ) : (
          <div className="no-data-to-display-in-table-assets-debts">
            <img src={no_data}></img>
            <p>No Active Strategy</p>
          </div>
        )}

      </div>
    </div>
  );
}