import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { makeStyles } from "@material-ui/core/styles";
import { Advisor, Vaults } from "../../services/api";
import { profitTypeDisplay } from "../../utils/profitTypeDisplay";
import CustomLoader from "../Custom_Loader/customLoader";
import AvatarCharacter from "../SidebarSettings/APS/Avatar";
import "./styling/safe.css";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    background: "#ffffff",
    boxShadow: "  4px 4px 30px rgba(27, 62, 105, 0.16)",
    padding: "20px 20px 20px 0px",
    display: "flex",
    borderRadius: "8px",
    minHeight: "638px",
    marginTop: "10px",
  },
  mainHeading: {
    fontWeight: "normal",
    fontSize: "36px",
    color: "#1B3E69",
    marginTop: "12px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "30px",
    },
  },
  imageContainer: {
    width: "20%",
    paddingTop: "20px",
  },
  contentContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    [theme.breakpoints.down("1641")]: {
      width: "70%",
    },

    [theme.breakpoints.down("1516")]: {
      width: "70%",
    },
    [theme.breakpoints.down("1446")]: {
      width: "78%",
    },

    [theme.breakpoints.down("1111")]: {
      width: "85%",
    },
  },
  subHeading: {
    fontSize: "24px",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  showMore: {
    textAlign: "justify",
    fontSize: "14px",
    color: "#1B3E69",
    fontFamily: " 'Open Sans', sans-serif",
    marginBottom: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  vaultDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  whiteListContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  vaultHeadings: {
    width: "200px",
    fontSize: "14px",
    color: "#1B3E69",
    fontFamily: " 'Open Sans', sans-serif",
    fontWeight: "bold",
    textTransform: "capitalize",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  vaultContents: {
    fontSize: "14px",
    color: "#1B3E69",
    fontFamily: " 'Open Sans', sans-serif",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  smallHeadings: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: " 'Open Sans', sans-serif",
    color: "#1B3E69",
    marginTop: "40px",
    marginBottom: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  smallHeadingsNew: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: " 'Open Sans', sans-serif",
    color: "#1B3E69",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
    display: "grid",
  },
  assetContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    align: "center",
    flexWrap: "wrap",
  },
  assets: {
    width: "30%",
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    marginRight: "10px",
  },
  assetDetailsOnly: {
    paddingLeft: "20px",
  },
  assetHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  assetSymbol: {
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  strategyProtocolContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    align: "center",
    flexWrap: "wrap",
  },
  strategyContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  feeContainer: { display: "grid", gridAutoFlow: "rows", rowGap: "20px" },
  feeDetails: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "20px",
  },
  advisorDetails: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: "10px",
  },
  advisorSetting: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: "10px",
    color: "#1B3E69",
  },
  advisorKey: {
    fontSize: "12px",
    color: "#1B3E69",
    textTransform: "capitalize",
    paddingLeft: "10px",
  },
  advisorValue: { fontSize: "11px", color: "#1B3E69" },
  allocation: { paddingTop: "5px", paddingBottom: "5px" },
}));

function SafeDetails() {
  const classes = useStyles();
  const address = useSelector((state) => state.get_vault_address);
  const [vaultDetails, setVaultDetails] = useState({});
  const [advisorDetails, setAdvisorDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const vaultIcon = localStorage.getItem("vaultIcon");
  const vaultName = localStorage.getItem("vaultName");

  const changeDateFormatTo = (date) => {
    const [yy, mm, dd] = date.split(/-/g);
    return `${dd}-${mm}-${yy}`;
  };

  const displaySettings = (value) => {
    return Object.keys(value).map((key) => (
      <div className={classes.advisorDetails}>
        <p className={classes.advisorKey}>{key}</p>
        <p className={classes.advisorValue}>
          {value[key]} {key && value ? "%" : ""}
        </p>
      </div>
    ));
  };

  const getDetails = () => {
    let vault = address.vaultAddress
      ? address.vaultAddress
      : localStorage.getItem("newSafeAddress");
    if (vault) {
      Vaults.getSafeDetails(vault)
        .then((response) => {
          if (response.data.status) {
            // console.log("safe response", response?.data?.data);
            setVaultDetails(response?.data?.data);
          }
        })
        .catch((e) => {
          console.log("safe error", e);
          setLoader(false);
        });
      Advisor.getCurrentAdvisorWithName(vault)
        .then((response) => {
          if (response.data.status) {
            // console.log("safe response", response?.data?.data);
            setAdvisorDetails(response?.data?.data?.[1]);
          }
        })
        .catch((e) => {
          console.log("safe advisor error", e);
          setLoader(false);
        });
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      {loader ? (
        <div className="safe-main-data-holder">
          <h1 className={classes.mainHeading}>Safe</h1>
          <div className={classes.mainContainer}>
            <div style={{ margin: "auto" }}>
              <CustomLoader />
            </div>
          </div>
        </div>
      ) : (
        <div className="safe-main-data-holder">
          {/* {console.log("vaultDetails", vaultDetails)} */}
          {Object.entries(vaultDetails).length ? (
            <div>
              <h1 className={classes.mainHeading}>Safe</h1>
              <div className={classes.mainContainer}>
                <div className={classes.imageContainer}>
                  <AvatarCharacter
                    image={`data:image/jpeg;base64,${vaultIcon}`}
                    class="larger"
                  />
                </div>
                <div className={classes.contentContainer}>
                  <h1 className={classes.subHeading}>
                    {vaultDetails?.vault?.vaultName
                      ? vaultDetails?.vault?.vaultName
                      : vaultName}
                  </h1>
                  <div>
                    <ShowMoreText
                      lines={1}
                      more="Show more"
                      less="Show less"
                      className={classes.showMore}
                      anchorClass="my-anchor-css-class"
                      expanded={false}
                      width={1000}
                    >
                      {vaultDetails?.vault?.vaultDescription}
                    </ShowMoreText>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Vault name</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.vaultName}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Token Name</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.tokenName != null
                        ? vaultDetails?.vault?.tokenName
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Token Symbol</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.tokenSymbol != null
                        ? vaultDetails?.vault?.tokenSymbol
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>
                      Display Currency
                    </div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.baseCurrency != null
                        ? vaultDetails?.vault?.baseCurrency
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>
                      Emergency Vault Address
                    </div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.emergencyVaultAddress != null
                        ? vaultDetails?.vault?.emergencyVaultAddress
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Vault Access</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.vaultType != null
                        ? vaultDetails?.vault?.vaultType
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Vault Type</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.profitType != null &&
                      vaultDetails?.vault?.profitType.length
                        ? profitTypeDisplay(vaultDetails?.vault?.profitType)
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Base Asset</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.vaultAsset != null
                        ? vaultDetails?.vault?.vaultAsset
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Base Asset Name</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.vaultAssetName != null
                        ? vaultDetails?.vault?.vaultAssetName
                        : "Not set"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Inception Date</div>
                    <div className={classes.vaultContents}>
                      {changeDateFormatTo(
                        vaultDetails?.vault?.createdAt
                          ? vaultDetails?.vault?.createdAt?.split("T")[0]
                          : "Date not available"
                      )}
                    </div>
                  </div>
                  {vaultDetails?.vault?.isTimeLocked &&
                  vaultDetails?.vault?.noOfDaysForLockedWithdraw ? (
                    <div className={classes.vaultDetails}>
                      <div className={classes.vaultHeadings}>
                        Withdraw Lock Period
                      </div>
                      <div className={classes.vaultContents}>
                        {vaultDetails?.vault?.noOfDaysForLockedWithdraw}
                        {vaultDetails?.vault?.noOfDaysForLockedWithdraw == 1
                          ? " day"
                          : " days"}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={classes.smallHeadings}>MANAGERS</div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Vault Admin</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.vaultAdmin
                        ? vaultDetails?.vault?.vaultAdmin
                        : ""}
                    </div>
                  </div>
                  <div className={classes.smallHeadingsNew}>ADVISOR</div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Current Advisor</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.advisorId
                        ? vaultDetails?.vault?.advisorId
                        : "Advisor not selected"}
                    </div>
                  </div>
                  <div className={classes.vaultDetails}>
                    <div className={classes.vaultHeadings}>Advisor Email</div>
                    <div className={classes.vaultContents}>
                      {vaultDetails?.vault?.advisorEmail
                        ? vaultDetails?.vault?.advisorEmail
                        : "Advisor email not set"}
                    </div>
                  </div>
                  {vaultDetails?.vault?.advisorId === "Custom" ? (
                    <div className={classes.vaultDetails}>
                      <div className={classes.vaultHeadings}>
                        Advisor Endpoint
                      </div>
                      <div className={classes.vaultContents}>
                        {vaultDetails?.vault?.advisorEndPoint
                          ? vaultDetails?.vault?.advisorEndPoint
                          : "Automatically set"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={classes.smallHeadings}>ADVISOR SETTINGS</div>
                  {advisorDetails ? (
                    Object.keys(advisorDetails).map((key) => {
                      return key === "vaultAdvisorSetting" ||
                        key == "advisorEmail" ? (
                        <></>
                      ) : key === "supportedProtocols" ? (
                        <table className={classes.advisorSetting}>
                          {advisorDetails?.supportedProtocols?.length != 0 ? (
                            <tbody>
                              <tr>
                                <td style={{ verticalAlign: "top" }}>
                                  <p className={classes.vaultHeadings}>{key}</p>
                                </td>
                                <td>
                                  <table>
                                    {vaultDetails?.vault?.vaultAdvisorSetting?.[
                                      key
                                    ]?.map((protocols) => {
                                      return (
                                        <tr>
                                          <td className={classes.vaultContents}>
                                            {protocols}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <></>
                          )}
                        </table>
                      ) : key === "assetAllocation" ? (
                        <>
                          <div className={classes.advisorSetting}>
                            <p className={classes.vaultHeadings}>
                              Asset Allocation
                            </p>
                          </div>
                          <div className={classes.allocation}>
                            {displaySettings(advisorDetails?.[key])}
                          </div>
                        </>
                      ) : (
                        <table className={classes.advisorSetting}>
                          <tbody>
                            <tr>
                              <td>
                                <p className={classes.vaultHeadings}>{key}</p>
                              </td>
                              <td>
                                <p className={classes.vaultContents}>
                                  {advisorDetails?.[key]?.toString()}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <div className={classes.smallHeadings}>ASSETS</div>
                  <div className={classes.assetContainer}>
                    {vaultDetails?.assets?.length ? (
                      vaultDetails?.assets?.map((data) => {
                        return (
                          <div className={classes.assets}>
                            <div className={classes.imageOnly}>
                              <AvatarCharacter
                                image={data.assetImageURL}
                                class="small"
                              />
                            </div>
                            <div className={classes.assetDetailsOnly}>
                              <p className={classes.assetHeading}>
                                {data.assetName}
                              </p>
                              <p className={classes.assetSymbol}>
                                {data.assetSymbol}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ fontSize: "14px" }}>
                        None of the assets are selected
                      </p>
                    )}
                  </div>
                  <div className={classes.smallHeadingsNew}>PROTOCOLS</div>
                  <div className={classes.strategyProtocolContainer}>
                    {vaultDetails?.protocols?.length ? (
                      vaultDetails?.protocols?.map((data) => {
                        return (
                          <div className={classes.strategyContainer}>
                            <div className={classes.imageStrategy}>
                              <AvatarCharacter
                                image={data?.protocolIcon}
                                class="small"
                              />
                            </div>
                            <div className={classes.assetDetailsOnly}>
                              <p className={classes.assetHeading}>
                                {data?.protocolName}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ fontSize: "14px" }}>
                        None of the protocols are selected
                      </p>
                    )}
                  </div>
                  <div className={classes.smallHeadings}>VAULTS</div>
                  <div className={classes.assetContainer}>
                    {vaultDetails?.supportedVaults?.length ? (
                      vaultDetails?.supportedVaults?.map((data) => {
                        return (
                          <div className={classes.assets}>
                            <div className={classes.imageOnly}>
                              <AvatarCharacter
                                image={`data:image/jpeg;base64,${data.logoImg?.data}`}
                                class="small"
                              />
                            </div>
                            <div className={classes.assetDetailsOnly}>
                              <p className={classes.assetHeading}>
                                {data.vaultName}
                              </p>
                              {data.tokenName && data.tokenSymbol ? (
                                <p className={classes.assetSymbol}>
                                  {data.tokenSymbol}
                                </p>
                              ) : (
                                <p className={classes.assetSymbol}></p>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ fontSize: "14px" }}>
                        None of the vaults are selected
                      </p>
                    )}
                  </div>
                  <div className={classes.smallHeadingsNew}>FEE SUMMARY</div>
                  <div className={classes.feeContainer}>
                    <div className={classes.feeDetails}>
                      <div className={classes.imageOnly}>
                        <AvatarCharacter
                          // image={fee.strategyImgURL}
                          class="small"
                        />
                      </div>
                      <div className={classes.vaultDetails}>
                        <div className={classes.vaultHeadings}>
                          Platform Fee
                        </div>
                        <div className={classes.vaultContents}>0.5 &nbsp;%</div>
                      </div>
                    </div>
                    {vaultDetails?.feeStrategies?.length ? (
                      vaultDetails?.feeStrategies?.map((fee) => {
                        return (
                          <div className={classes.feeDetails}>
                            <div className={classes.imageOnly}>
                              <AvatarCharacter
                                image={fee.strategyImgURL}
                                class="small"
                              />
                            </div>
                            <div className={classes.vaultDetails}>
                              <div className={classes.vaultHeadings}>
                                {fee.name}
                              </div>
                              <div className={classes.vaultContents}>
                                {
                                  vaultDetails?.vault?.managementFeeDetails[
                                    fee.strategyAddress
                                  ]?.percentage
                                }
                                &nbsp;%
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ fontSize: "14px" }}>
                        None of the fee values are set
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default SafeDetails;
