import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Vaults } from "../../../../../services/api/index";
import "./DropDown_Style/dropDownStyle.css";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "15px",
    paddingTop: "12.5px",
    paddingBottom: "12.5px",
    display: "flex",
    alignItems: "center",
    color: "#1B3E69",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    "&:hover": {
      borderColor: "none",
    },
  },
  formControl: {
    width: 294,
    height: 55,
    border: "0px solid #DEE5EF",
    borderRadius: "5%",
    backgroundColor: "white",
    color: "#1B3E69",
    "&:hover": {
      borderColor: "none",
    },
    marginTop: "8px",
  },
  select: {
    "&:before": {
      borderColor: "#DEE5EF",
    },
    "&:hover": {
      borderColor: "#DEE5EF",
    },
    "&:after": {
      borderColor: "#DEE5EF",
    },
  },
  whiteColor: {
    color: "#1B3E69",
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setValue(event.target.value);
    dropDownSelect(event.target.value);
  };
  const data = useSelector((state) => state.get_assets_deposit_via_wallet);
  const vaultId = localStorage.getItem("vaultId");

  useEffect(() => {
    props.dropDownSelect("");
  }, []);

  useEffect(() => {
    Vaults.getVaultDepositWithdrawAssets(vaultId)
      .then((res) => {
        dispatch({
          type: "DEPOSIT_ASSETS_PER_VAULT",
          payload: {
            deposit_assets: res.data?.data?.depositableVaults,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [vaultId]);

  useEffect(() => {
    if (data.assets.length > 0) {
      dropDownSelect(data.assets.length ? data.assets[0] : undefined);
      setValue(data.assets.length ? data.assets[0] : undefined);
    }
  }, [data.assets]);

  const dropDownSelect = (res) => {
    props.dropDownSelect(res);
  };
  const imageData = (response) => {
    if (response) {
      if (Object.hasOwn(response, "assetImageURL"))
        return response.assetImageURL;
      else if (Object.hasOwn(response, "vaultImageURL"))
        return `data:image/jpeg;base64,${response.vaultImageURL.data}`;
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-filled-label">
        {value == "" ? "Choose Asset" : ""}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        className={classes.select}
        onChange={handleChange}
        classes={{
          root: classes.root,
          icon: classes.whiteColor,
        }}
      >
        {data?.assets?.map((res) => {
          if (res) {
            return (
              <MenuItem key={res?.assetAddress} value={res}>
                <img
                  className="dropdown-icon-image-resizer"
                  src={imageData(res)}
                ></img>
                {res?.assetName}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
}
