export const state1 = {
    transactionProgress:false
  };

  const TransactionProgressReducer = (state = state1, action) => {
    switch (action.type) {
      case "TRANSACTION_IN_PROGRESS":
        return {
          ...state,
          transactionProgress: action.payload,
        };
      
      default:
        return {
          ...state,
        };
    }
  };
  
  export default TransactionProgressReducer;