import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";
export default class Assets {
    static getAllAssets() {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `asset/v2.0/yieldster/getAssets`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                'Authorization': {Bearer_Token}
            },
        });
    }
}