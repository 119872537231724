import { Fragment } from "react";
import "./Style/style.css";
import Avatar from "@material-ui/core/Avatar";
import { message } from "../../../utils/message";
import { useHistory, useRouteMatch } from "react-router-dom";
const StrategyCard = (props) => {
  const { image, title, description } = props;
  const history = useHistory();
  const { url } = useRouteMatch();
  const handleCustomStrategyClick = () => {
    history.push({
      pathname: `${url}/custom-interface`,
      state: { url: props.url },
    });
  };
  return (
    <Fragment>
      <div
        className="custom-strategy-listing-container"
        onClick={handleCustomStrategyClick}
      >
        <div className="custom-strategy-listing-container-inner-div">
          <Avatar
            alt=""
            src={image}
            className="custom-strategy-listing-table-image"
          />
          <p className="avatar-title">{title}</p>
          <p className="avatar-description">{description}</p>
        </div>
      </div>
    </Fragment>
  );
};
export default StrategyCard;
