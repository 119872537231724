export const state1 = {
    strategyShare
    :[]
};

export  const StrategyShare = (state = state1, action) => {
    switch (action.type) {
      case "STRATEGY_SHARE_VALUE":
        return {
          ...state,
          strategyShare: action.payload,
        };
        default:
            return state;
    }
};
      