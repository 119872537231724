const initState = {
    filter: "",
  
};
  
const setFilterValue = (state=initState,action) => {
    switch(action.type) {
        case "SET_FILTER_VALUE":
            return { 
                ...state,
                filter: action.payload.filter,
            }
        default: 
            return state;
    }
}

export default setFilterValue;