import React from 'react';
import not_authorized from '../../assets/NotAuthorized/not_authorized.svg';
import './Styling/NotAuthorized.css';

export default function NotAuthorized(){

    return(
        <div className="notFoundContainer">
        <img className="imageForPageNotFound" src={not_authorized}></img>
        <p className="pageNotFoundText">Not Authorized </p>
        </div>
    );
}