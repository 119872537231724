import Web3 from "web3";

export const isUpper = (str) => {
  return !/[a-z]/.test(str) && /[A-Z]/.test(str);
};

export const capSpace = (txt) => {
  var arr = Array.from(txt);
  var splitCount = 0; // added a counter
  for (let i = 1; i < txt.length; i++) {
    if (isUpper(txt[i]) === true) {
      // sum it with i
      arr.splice(i + splitCount, 0, " ");
      splitCount++; // increase every time you split
    }
  }
  return arr.join("").toString().toLowerCase();
};

export const convertToWei = (decimals, value) => {
  switch (decimals) {
    case "1":
      return Web3.utils.toWei(value, "wei");

    // case "2":
    //   return (value*(10**2)).toString()

    case "4":
      return Web3.utils.toWei(value, "kwei");

    case "6":
      return Web3.utils.toWei(value, "mwei");

    case "9":
      return Web3.utils.toWei(value, "gwei");

    case "12":
      return Web3.utils.toWei(value, "szabo");

    case "15":
      return Web3.utils.toWei(value, "finney");

    case "18":
      return Web3.utils.toWei(value, "ether");

    case "21":
      return Web3.utils.toWei(value, "kether");

    case "24":
      return Web3.utils.toWei(value, "mether");

    case "27":
      return Web3.utils.toWei(value, "gether");

    case "30":
      return Web3.utils.toWei(value, "tether");

    default:
      return (value * 10 ** parseInt(value)).toString();
  }
};
