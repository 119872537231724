import React, { useState, useEffect } from "react";
import RegistrationLogin from "./RegistrationLogin/index";
import AllVaults from "./AllVaults/index";

//Function to set token in session storage
function setToken(userToken) {
  localStorage.setItem("token", JSON.stringify(userToken));
}

//Function to get token from session storage
function getToken() {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
}
function MasterCopyUpdationScreen() {
  const token = getToken();

  if (!token) {
    return <RegistrationLogin setToken={setToken} />;
  } else return <AllVaults token={token} />;
}
  
export default MasterCopyUpdationScreen;
