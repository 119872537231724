import { React, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import MasterCopyUpgradeScreen from "../src/components/Master-Copy-Updation-Screen/index";
import TransactionInfoCard from "../src/components/Transaction-Info-Card/TransactionInfo";
import "./App.css";
import HelpCenter from "./components/HelpCenter/index";
import NotAuthorized from "./components/NotAuthorized";
import NotFound from "./components/PageNotFound";
import MobileView from "./components/WelcomePage/Mobile";
import CreateVault from "./pages/CreateVault";
import Disclaimer from "./pages/CreateVault/Disclaimer";
import TermsAndConditions from "./pages/CreateVault/TermsAndConditions";
import ExplorePage from "./pages/ExploreVault/ExploreVault";
import QueueVault from "./pages/QueueVault/QueueVault";
import { setConnectToggle } from "./redux/actions/ConnectToggle";
import { safeName } from "./redux/actions/SafeName";
import { userAddress } from "./redux/actions/UserAddress";
import { setVaultAddress } from "./redux/actions/VaultAddress";
import { tokenSymbol } from "./redux/actions/tokenSymbol";
import { setVaultDetails } from "./redux/actions/vaultDetails";
import { vaultIcon } from "./redux/actions/vaultIcon";
import { Vaults } from "./services/api";
import { web3Config } from "./utils/web3Config";
import Layout from "./views/Layout";
import V1Version from "./views/V1Version";
import { walletState } from "./redux/actions/walletState";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { formatGwei } from "viem";
import Web3 from "web3";
import { updateWalletDetails } from "./utils/web3/config";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { address, connector } = useAccount();
  const userAdd = useSelector((state) => state.get_user_address.address);

  const accountAddress = localStorage.getItem("accountAddress") ?? address;
  dispatch(userAddress(accountAddress));

  const progress = useSelector(
    (state) => state.get_transaction_in_progress.transactionProgress
  );
  const queueStatus = useSelector((state) => state.get_queue_status.queueState);
  const [mobile, setMobile] = useState(false);

  const ScreenSizeCalculator = () => {
    const mql = window.matchMedia("(max-width: 1024px)");
    let mobileView = mql.matches;
    if (mobileView) {
      let pathArray = location.pathname.split("/");
      if (pathArray[1] === "") {
        setMobile(true);
      } else {
        setMobile(false);
      }
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    ScreenSizeCalculator();
    window.addEventListener("resize", function (event) {
      ScreenSizeCalculator();
    });
  }, []);

  const beforeUnloadListener = (event) => {
    if (window.onprogress) {
      event.preventDefault();
      event.stopPropagation();
      return (event.returnValue = " ");
    }
  };

  useEffect(() => {
    if (progress) {
      window.onprogress = true;
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    } else {
      window.onprogress = false;
    }
  }, [progress]);

  useEffect(() => {
    const asyncFn = async () => {
      //web3 instance before a user is connected to the Dapp.

      //url parameter to check if url is valid
      let VaultAddress;
      const previouslySelectedWallet =
        window.localStorage.getItem("selectedWallet");
      let pathArray = location.pathname.split("/");

      //user variables from local storage

      dispatch(setConnectToggle(pathArray[1]));
      if (
        accountAddress &&
        accountAddress != null &&
        accountAddress != undefined &&
        accountAddress != ""
      ) {
        if (previouslySelectedWallet && connector?.name && address)
          await updateWalletDetails(connector, address);       

        if (pathArray[2] && pathArray.length <= 4) {
          history.push(location.pathname);
        } else if (pathArray.length > 4) {
          try {
            if (VaultAddress) {
              Vaults.getVaultDetails(VaultAddress)
                .then((res) => {
                  if (
                    res.data.data &&
                    res.data.status === 1 &&
                    res.data.data.length != 0
                  ) {
                    dispatch(setVaultDetails(res.data.data));
                    dispatch(setVaultAddress(VaultAddress));
                    dispatch(safeName(res.data.data.vault?.vaultName));
                    dispatch(tokenSymbol(res.data.data.vault?.tokenSymbol));
                    dispatch(vaultIcon(res.data.data.vault?.logoImg?.data));

                    localStorage.setItem("newSafeAddress", VaultAddress);
                    localStorage.setItem(
                      "vaultSymbol",
                      res.data.data.vault?.tokenSymbol
                    );
                    localStorage.setItem(
                      "vaultIcon",
                      res.data.data.vault?.logoImg?.data
                    );
                    localStorage.setItem(
                      "vaultName",
                      res.data.data.vault?.vaultName
                    );
                    localStorage.setItem("vaultId", res.data.data.vault?.id);
                    history.push(location.pathname);
                  } else {
                    console.log("Error 404 !Vault address not found");
                    history.push("/404");
                  }
                })
                .catch((err) => {
                  console.log(err);
                  history.push("/404");
                });
            }
          } catch (e) {
            console.error("Invalid ethereum address", e.message);

            history.push("/404");
          }
        }
      }
      if (
        !accountAddress ||
        window.localStorage.getItem("selectedWallet") === "WalletConnect"
      ) {
        web3Config();
      }
    };
    asyncFn();
  }, [connector, address]);

  return (
    <>
      {mobile ? (
        <MobileView />
      ) : (
        <>
          {/* {(queueStatus == false)?<TransactionInfoCard />:""}  */}
          <CSSTransition
            in={queueStatus}
            timeout={500}
            classNames="toast-notification"
            unmountOnExit
          >
            <TransactionInfoCard />
          </CSSTransition>
          <DndProvider backend={HTML5Backend}>
            <Switch>
              {/* <Route path="/" exact component={WelcomePage} /> */}
              {/* <Route path="/" exact component={SplashScreen} /> */}
              <Route path="/" exact component={ExplorePage} />

              <Route path="/admin" exact component={MasterCopyUpgradeScreen} />
              <Route path="/mobile" exact component={MobileView} />

              {/* <Route path="/explore" exact component={Explore} /> */}
              <Route path="/disclaimer" exact component={Disclaimer} />
              <Route
                path="/terms-of-use"
                exact
                component={TermsAndConditions}
              />
              <Route path="/createvault" exact component={CreateVault} />
              <Route path="/helpcenter" exact component={HelpCenter} />

              <Route path="/:id/queue" component={QueueVault} />

              <Route path="/vault" component={Layout} />
              <Route path="/v1" exact={true} component={V1Version} />

              <Route path="/not-authorized" exact component={NotAuthorized} />

              <Route path="*" exact={true} component={NotFound} />
              <Route path="/404" component={NotFound} />
              <Redirect from="*" to="/404" />
            </Switch>
          </DndProvider>
        </>
      )}
    </>
  );
}
export default App;
