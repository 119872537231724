const initState = {
    vaultIcon:"",
  
};

const vaultIcon = (state=initState,action) => {
    switch(action.type) {
        case "SET_VAULT_ICON":
            return { 
                ...state,
                vaultIcon: action.payload.vaultIcon,
            }
            

        default: 
            return state;
    }
}

export default vaultIcon;