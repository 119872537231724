
export const config = [
  {
    name: "Dashboard",
    icon: <span className="material-icons">dashboard</span>,
    path: "/dashboard",
    autoFocus: true,
  },
  {
    name: "Assets & Debts",
    icon: <span className="material-icons">swap_horiz</span>,
    path: "/assets",
    autoFocus: false,
  },

  // {
  //   name: "Strategies",
  //   icon: <span className="material-icons">rule</span>,
  //   path: "/strategy",
  //   autoFocus: false,
  // },
  {
    name: "Vault",
    icon: <span className="material-icons">view_in_ar</span>,
    path: "/vault",
    dropdown: <span className="material-icons">arrow_drop_down</span>,
    autoFocus: false,

    sub: [
      {
        name: "Manage",
        path: "/vault/manage/support",
        onlyAdmin: true,
      },
      {
        name: "Safe",
        path: "/vault/safe",
      },
      {
        name: "Transactions",
        path: "/vault/transactions/overview",
      },
    ],
  },

  {
    name: "Help Center",
    icon: <span className="material-icons">help_outline</span>,
    path: "/helpcenter",
    autoFocus: false,
  },
];


export const config1 = [
  {
    name: "Dashboard",
    icon: <span className="material-icons">dashboard</span>,
    path: "/dashboard",
    autoFocus: true,
  },
  {
    name: "Assets & Debts",
    icon: <span className="material-icons">swap_horiz</span>,
    path: "/assets",
    autoFocus: false,
  },

  {
    name: "Strategies",
    icon: <span className="material-icons">rule</span>,
    path: "/strategy",
    autoFocus: false,
  },
  {
    name: "Vault",
    icon: <span className="material-icons">view_in_ar</span>,
    path: "/vault",
    dropdown: <span className="material-icons">arrow_drop_down</span>,
    autoFocus: false,

    sub: [
      {
        name: "Manage",
        path: "/vault/manage/support",
        onlyAdmin: true,
      },
      {
        name: "Safe",
        path: "/vault/safe",
      },
      {
        name: "Transactions",
        path: "/vault/transactions/overview",
      },
    ],
  },

  {
    name: "Help Center",
    icon: <span className="material-icons">help_outline</span>,
    path: "/helpcenter",
    autoFocus: false,
  },
];
