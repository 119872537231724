import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import "./TextBoxStyle/textBox.css";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "294px",
    height: "55px",
    fontSize: "30px",
    color: "white",
    backgroundColor: "white",
    fontWeight: "bold",

    // margin: "0px 0px 0px 20px",
    borderColor: "#DEE5EF",
    "& .MuiOutlinedInput-input": {
      paddingTop: "12.5px",
      paddingBottom: "12.5px",
      color: "#1B3E69",
      fontSize: "24px",
      fontWeight: "bold",
    },
    "& .MuiInputBase-input": {
      textAlign: "right",
    },
  },

  margin: {
    margin: theme.spacing(1),
    // height:100,
  },
}));

export default function CustomizedInputs(props) {
  const classes = useStyles();
  const [amount, setAmount] = React.useState();
  const { shareAmount, valueSizeCheck } = props;

  useEffect(() => {
    if (shareAmount != 0) {
      setAmount(shareAmount);
    }
  }, [shareAmount]);

  const handleChange = (event) => {
    setAmount(event.target.value);
    props.inputChange(event.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.root}
        variant="outlined"
        // defaultValue={amount}
        inputProps={{ "aria-label": "naked" }}
        value={amount}
        placeholder="0"
        onChange={handleChange}
        error={!valueSizeCheck}
        // disabled={true}
        // color="primary"
      />
    </div>
  );
}
