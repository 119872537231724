export const GasRequired = (val) => {
      return {
      type: "SET_GAS_REQUIRED",
      payload: {
        gasRequired: val
      }
    }
  }

  export const GasPriceRequired = (val) => {
    return {
    type: "SET_GAS_PRICE_REQUIRED",
    payload: {
      gasPriceRequired: val
    }
  }
}

export const NonceRequired = (val) => {
    return {
    type: "SET_NONCE_REQUIRED",
    payload: {
      nonceRequired: val
    }
  }
}


