import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    [theme.breakpoints.down("1618")]: {
      width: "33px",
      height: "33px",
    },
  },
  large: {
    width: "67px",
    height: "67px",
  },
  smaller: {
    width: "25px",
    height: "25px",
  },
  larger: {
    padding: "1px",
    borderRadius: "0%",
    width: "170px",
    height: "170px",
    [theme.breakpoints.down("1618")]: {
      width: "150px",
      height: "150px",
    },
    [theme.breakpoints.down("1516")]: {
      width: "137px",
      height: "137px",
    },
    [theme.breakpoints.down("1446")]: {
      width: "117px",
      height: "117px",
    },
    [theme.breakpoints.down("1380")]: {
      width: "94px",
      height: "94px",
    },
    [theme.breakpoints.down("1279")]: {
      width: "87px",
      height: "87px",
    },
    [theme.breakpoints.down("1150px")]: {
      width: "147px",
      height: "147px",
    },
  },
  smaller30: {
    width: "35px",
    height: "35px",
  },
  medium: {
    height: "35px",
    width: "30px",
  },
  small20: {
    height: "20px",
    width: "20px",
  },
}));

export default function ImageAvatars(props) {
  const classes = useStyles();
  const { image } = props;

  return (
    <div className={classes.root}>
      {props.class == "large" ? (
        <Avatar alt="User" src={image} className={classes.large} />
      ) : props.class == "small" ? (
        <Avatar alt="User" src={image} className={classes.small} />
      ) : props.class == "larger" ? (
        <Avatar alt="User" src={image} className={classes.larger} />
      ) : props.class == "smaller" ? (
        <Avatar alt="User" src={image} className={classes.smaller} />
      ) : props.class == "medium" ? (
        <Avatar alt="User" src={image} className={classes.medium} />
      ) : props.class == "small20" ? (
        <Avatar alt="User" src={image} className={classes.small20} />
      ) : (
        <Avatar alt="User" src={image} className={classes.smaller30} />
      )}
    </div>
  );
}
