import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class WithdrawQueue {
  static withdrawCheck(vaultAddress, accountAddress, toToken, amount) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/withdraw`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
        toToken,
        amount,
      },
    });
  }

  static getWithdrawQueueById(hash) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `withdraw-queue/v2.0/yieldster/get-by-id`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        hash,
      },
    });
  }
  static getWithdrawQueueByUser(userAddress, vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `withdraw-queue/v2.0/yieldster/get-by-useraddress`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        userAddress,
        vaultAddress,
      },
    });
  }

  static getPendingAssetReservation(vaultAddress, accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `withdraw-queue/v2.0/yieldster/pending-asset-reservations`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
      },
    });
  }
}
