import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Vaults } from "../../../../../../../../services/api";
import "../../Input_TextBox/DropDown/DropDownStyle/dropDownStyle.css";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "15px",
    paddingTop: "12.5px",
    paddingBottom: "12.5px",
    display: "flex",
    alignItems: "center",
    color: "#1B3E69",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    "&:hover": {
      borderColor: "none",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    // padding:10,
    width: 294,
    height: 55,
    border: "0px solid #DEE5EF",
    borderRadius: "5%",
    marginLeft: "4px",
    backgroundColor: "white",
    color: "#1B3E69",
    "&:hover": {
      borderColor: "none",
    },
    marginTop: "8px",
  },
  select: {
    "&:before": {
      borderColor: "#DEE5EF",
    },
    "&:hover": {
      borderColor: "#DEE5EF",
    },
    "&:after": {
      borderColor: "#DEE5EF",
    },
  },
  whiteColor: {
    color: "#1B3E69",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [withdrawableAssets, setWithdrawableAssets] = useState([]);
  const vaultId = localStorage.getItem("vaultId");

  const handleChange = (event) => {
    if (event.target.value === "NO_PARTICULAR") {
      props.handleWithdrawModeSelect("No");
    } else {
      props.handleWithdrawModeSelect("Yes");
    }
    setValue(event.target.value);
    dropDownSelect(event.target.value);
  };

  const dropDownSelect = (res) => {
    props.dropDownSelect(res);
  };

  useEffect(() => {
    if (vaultId)
      Vaults.getVaultDepositWithdrawAssets(vaultId)
        .then((res) => {
          setWithdrawableAssets(res.data.data?.depositableVaults);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [vaultId]);

  useEffect(() => {
    if (withdrawableAssets?.length > 0) {
      props.handleWithdrawModeSelect("Yes");
      dropDownSelect(
        withdrawableAssets.length ? withdrawableAssets[0] : undefined
      );
      setValue(withdrawableAssets.length ? withdrawableAssets[0] : undefined);
    }
  }, [withdrawableAssets]);
  const imageData = (response) => {
    if (response) {
      if (Object.hasOwn(response, "assetImageURL"))
        return response.assetImageURL;
      else if (Object.hasOwn(response, "vaultImageURL"))
        return `data:image/jpeg;base64,${response.vaultImageURL.data}`;
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.root,
          icon: classes.whiteColor,
        }}
        displayEmpty={true}
        renderValue={value !== "" ? undefined : () => "Choose asset"}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        {withdrawableAssets?.map((res) => {
          if (res)
            return (
              <MenuItem key={res?.assetAddress} value={res}>
                <img
                  className="dropdown-icon-image-resizer-withdraw"
                  src={imageData(res)}
                  alt=""
                ></img>
                {res?.assetName}
              </MenuItem>
            );
        })}
      </Select>
    </FormControl>
  );
}
