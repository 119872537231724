import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "  4px 4px 30px rgba(27, 62, 105, 0.16)",
  },
  heading: {
    fontFamily: "`Open Sans`, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
  },
  questionTypography: {
    fontFamily: "Open Sans, sans-serif",
  },
  ansewerTypography: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
  },
  accordionSummaryRoot: {
    borderBottom: "1px solid #DEE5EF",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  accordionDetailsRoot: {
    backgroundColor: "#DEE5EF",
  },
}));

export default function FaqAccordion(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.faq.map((data, index) => {
        return (
          <Accordion elevation={0} key={data.question}>
            <AccordionSummary
              expandIcon={<span class="material-icons">arrow_drop_down</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ root: classes.accordionSummaryRoot }}
            >
              <Typography
                className={classes.heading}
                classes={{ body1: classes.questionTypography }}
              >
                Question {index + 1} : {data.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <Typography classes={{ body1: classes.ansewerTypography }}>
                <span dangerouslySetInnerHTML={{ __html: `${data.answer}` }} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
