import { Avatar, Tag } from "antd";
import React from "react";
import "./Style/index.css";

const TagContainer = (props) => {
  const handleCloseTag = () => {
    props.removeItems(props.address, props.type);
  };
  return (
    <Tag
      id="tag-item"
      closable
      className={
        props.type === "vault"
          ? "vault-tag"
          : props.type === "protocol"
          ? "protocol-tag"
          : "base-tag"
      }
      onClose={handleCloseTag}
    >
      {(props.tagSymbol || props.tagIcon) &&
        ["asset", "deposit", "withdraw", "vault", "protocol"].includes(
          props.type
        ) && (
          <Avatar
            size="small"
            src={props.tagIcon && props.tagIcon}
            icon={props.tagSymbol && <div>{props.tagSymbol}</div>}
          />
        )}
      {props.data}
    </Tag>
  );
};

export default TagContainer;
