import axios from "axios";
import { getAssetsURL } from "../../components/api";
import { getProtocolURL, getStrategyURL } from "../../components/api";
import uniqueArrayList from "../../components/Global-Functions/uniqueArrayList";
//Action creator

export const loadAssets = () => async (dispatch) => {
  //FETCH AXIOS
  const mainArray = [];
  const strategyArray = [];

  // const assetsData = await axios.get(getAssetsURL());
  // if (assetsData.data.length > 0) {
  //   assetsData.data.map((res) => {
  //     mainArray.push({
  //       _id: res._id,
  //       name: res.assetName,
  //       symbol: res.assetSymbol,
  //       address: res.assetAddress,
  //       balance: "",
  //       value: "",
  //       price: "",
  //       exactBalance: "",
  //       exactPrice: "",
  //       exactValue: "",
  //       icon: res.assetIcon,
  //     });
  //   });
  // }

  const protocolData = await axios.get(getProtocolURL());
  if (protocolData.data.data.length > 0) {
    protocolData.data.data.map((res) => {
      res.supportedAssets.map((protocolAsset) => {
        mainArray.push({
          _id: protocolAsset._id,
          name: protocolAsset.assetName,
          symbol: protocolAsset.assetSymbol,
          address: protocolAsset.assetAddress,
          balance: "",
          value: "",
          price: "",
          exactBalance: "",
          exactPrice: "",
          exactValue: "",
          icon: protocolAsset.assetIcon,
        });
      });
      mainArray.push({
        _id: res._id,
        name: res.protocolName,
        symbol: res.protocolSymbol,
        address: res.protocolAddress,
        balance: "",
        value: "",
        price: "",
        exactBalance: "",
        exactPrice: "",
        exactValue: "",
        icon: res.protocolIcon,
      });
    });
  }

  const strategyData = await axios.get(getStrategyURL());

  if (strategyData.data.data.length > 0) {
    strategyData.data.data.map((res) => {
      strategyArray.push({
        _id: res._id,
        name: res.strategyName,
        // symbol: res.protocolSymbol,
        address: res.strategyAddress,
        balance: "",
        value: "",
        price: "",
        exactBalance: "",
        exactPrice: "",
        exactValue: "",
        icon: res.strategyIcon,
      });
    });
  }

  dispatch({
    type: "FETCH_ASSETS",
    payload: {
      assets: uniqueArrayList(mainArray),
      strategyArray: uniqueArrayList(strategyArray),
      //  protocol: protocolData.data
    },
  });
};

export const setCurrencyDetails = (val) => async (dispatch) => {
  dispatch({
    type: "LOAD_CURRENCY",
    payload: {
      selectedCurrency: val,
    },
  });
};

export const setBaseCurrency = (val) => async (dispatch) => {
  dispatch({
    type: "SET_BASE_CURRENCY",
    payload: {
      previousCurrency: val,
    },
  });
};
