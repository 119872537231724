export const state1 = {
  enabledProtocolList: "",
  disabledProtocolList: "",
};

const protocolReducer = (state = state1, action) => {
  switch (action.type) {
    case "ENABLED_PROTOCOLS":
      return {
        ...state,
        enabledProtocolList: action.payload,
      };
    case "DISABLED_PROTOCOLS":
      return {
        ...state,
        disabledProtocolList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default protocolReducer;
