
function addressDisplayConfig(address) {
  var array = [];
  var string = address;

  for (var i = 0; i < string.length; i++) {
    if (i < 15) {
      array[i] = string[i];
    } else if (i > 20 && i < 24) {
      array[i] = ".";
    } else if (i > 36) {
      array[i] = string[i];
    }
  }
  var newArray = array.join("");
  return newArray;
}

export default addressDisplayConfig;
