import Notification from 'rc-notification';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './Message.css'
let notification=null;

Notification.newInstance({getContainer:()=>document.getElementById("root1")}, n => notification=n);
export const message={
    success:(message,time)=>{

            notification.notice({
              content: <div className="successMessageWrap"><div className="successIcon"><CheckCircleOutlineIcon/></div><div className="successMessage">{message}</div></div>,
              duration:time?time:2

            });
    },
    error:(message,time)=>{
        notification.notice({
            content: <div className="errorMessageWrap"><div className="errorIcon"><CancelIcon/></div><div className="errorMessage">{message}</div></div>,
            duration:time?time:5

          });
    },
    warning:(message,time)=>{
        notification.notice({
            content: <div className="warningMessageWrap"><div className="warningIcon"><ErrorOutlineIcon/></div><div className="warningMessage">{message}</div></div>,
            duration:time?time:2

          });
    },
}