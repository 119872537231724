import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class PendingVaultTransaction {
  static createPendingTransaction(status, vaultId, txHash, txType, walletType) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `pendingVaultTx/v2.0/yieldster/create`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: {
        status,
        vaultId,
        txHash,
        txType,
        walletType,
      },
    });
  }

  static getPendingVaultTransactionStatus(txHash) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `pendingVaultTx/v2.0/yieldster/completedTransaction/${txHash}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }
}
