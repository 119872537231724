import React from "react";
import { Avatar, Select } from "antd";

const { Option } = Select;

function DropDown(props) {
  const dropdownValues = props.value;
  const type = props.type;

  const handleAssetSelect = (symbol, address) => {
    props.setValue(symbol, address);
  };

  const handleCurrencySelect = (value) => {
    props.setValue(value);
  };

  return (
    <div className="assets-card-dropdown">
      <div style={{ width: "100%" }}>
        {type === "asset" ? (
          <Select
            size="large"
            value={props.show}
            onChange={(e, v) => {
              handleAssetSelect(e, v.id);
            }}
            style={{ width: "100%" }}
            
          >
            {dropdownValues?.length ? (
              dropdownValues?.map((value) => {
                return (
                  <Option
                    key={value[0]?.assetAddress}
                    id={value[0]?.assetAddress}
                    value={value[0]?.assetName}
                  >
                    <Avatar
                      size="small"
                      src={value[0]?.assetImageURL}
                      icon={
                        value[0]?.assetSymbol && (
                          <div>{value[0]?.assetSymbol}</div>
                        )
                      }
                    />
                    &nbsp;
                    <span className="option-text">{value[0]?.assetName}</span>
                  </Option>
                );
              })
            ) : (
              <Option>Select atleast one asset</Option>
            )}
          </Select>
        ) : (
          <Select
            size="large"
            value={props.show}
            onChange={(e) => {
              handleCurrencySelect(e);
            }}
            style={{ width: "100%" }}
          >
            {dropdownValues?.map((value) => {
              return (
                <Option
                  key={value?.id}
                  id={value?.id}
                  value={value?.currencySymbol}
                >
                  <span className="option-text">{value?.currencySymbol}</span>
                </Option>
              );
            })}
          </Select>
        )}
      </div>
      <div className="label">
        <div className="text">{props.text} </div>
        <div className="validation">
          {props.isValidated?.assetCard?.vault === "invalid" &&
            props.id === "vaultAsset" && (
              <span className="validation-required">
                *This field is required
              </span>
            )}
        </div>
      </div>
    </div>
  );
}

export default DropDown;
