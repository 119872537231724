import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DownArrow from "../../assets/Transaction-Table/transaction-table-dropdown-arrow.svg";
import UpArrow from "../../assets/Transaction-Table/transaction-table-dropdown-uparrow.svg";
import { iconURL } from "../api";
import CustomLoader from "../Custom_Loader/customLoader";
import NoData from "../No-Data-Component/noData";
import "./Collapse-Table-Styling/style.css";

const useRowStyles = makeStyles({
  cellPadding: {
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "15px",
    width: "42%",
  },
  headerPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  headerTitlePadding: {
    paddingLeft: "15px",
    paddingRight: "0px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  dropDownArrow: {
    width: "7%",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  paddingLeftStyler: {
    paddingRight: "15px",
  },
  rowsPerPageTextStyle: {
    display: "none",
  },
  rowsPerPageTextStyle: {
    color: "#6D7681",
    fontFamily: "Open Sans",
  },
  selectedPage: {
    color: "#6D7681",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  boldWeight: {
    "& .MuiTablePagination-select": {
      fontWeight: "bold",
    },
    "& .MuiTypography-root .MuiTablePagination-caption .MuiTypography-body2 .MuiTypography-colorInherit":
    {
      display: "none",
    },
    "& .MuiTablePagination-actions": {
      margin: "0px",
    },
    "& .MuiTablePagination-selectRoot": {
      marginRight: "5px",
    },
  },
});

const EpochConvertor = (value) => {
  var d = new Date(value);
  var date = d.getDate();
  date = date < 10 ? "0" + date : date;
  var month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var year = d.getFullYear();

  var newDate = year + "-" + month + "-" + date;
  return newDate;
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  // const asset_icon = iconURL(row.assetImage);
  const data = useSelector((state) => state.get_assets);
  const web3 = useSelector((state) => state.get_web3.web3);
  const WeiValueConvertor = (value, precision) => {
    if (web3) {
      switch (precision) {
        case "1":
          return web3.utils.fromWei(value, "wei");

        case "4":
          return web3.utils.fromWei(value, "kwei");

        case "6":
          return web3.utils.fromWei(value, "mwei");

        case "9":
          return web3.utils.fromWei(value, "gwei");

        case "12":
          return web3.utils.fromWei(value, "szabo");

        case "15":
          return web3.utils.fromWei(value, "finney");

        case "18":
          return web3.utils.fromWei(value, "ether");

        case "21":
          return web3.utils.fromWei(value, "kether");

        case "24":
          return web3.utils.fromWei(value, "mether");

        case "27":
          return web3.utils.fromWei(value, "gether");

        case "30":
          return web3.utils.fromWei(value, "tether");

        default:
          return (parseFloat(value) / 10 ** parseInt(precision))
            .toFixed(2)
            .toString();
      }
    }
  };

  const nFormatter = (n) => {
    try {
      if (n < 1e3) return n?.toFixed(2)
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
    } catch (error) {
      console.log(error);
    }
  };

  let balance = 0,
    value = 0,
    price = 0,
    exactBalance = 0,
    exactValue = 0,
    exactPrice = 0;

  exactBalance = parseFloat(row.Balance);
  exactPrice = parseFloat(row.Price);
  exactValue = parseFloat(row?.Value);
  balance = nFormatter(exactBalance);
  price = nFormatter(exactPrice);
  value = nFormatter(exactValue);

  const imageData = (response) => {
    if (response) {
      if (Object.hasOwn(response, "assetImage"))
        return response.assetImage;
      else if (Object.hasOwn(response, "vaultImageURL"))
        return `data:image/jpeg;base64,${response.vaultImageURL.data}`;
    }
  };

  return (
    <React.Fragment>
      <TableRow className="assets-debts-table-contents">
        <TableCell
          component="th"
          scope="row"
          padding="none"
          classes={{ root: classes.cellPadding }}
        >
          <p style={{ color: "#1B3E69" }}>
            <img src={imageData(row)} style={{ marginRight: "15px" }}></img>{" "}
            {row.assetName}
          </p>
        </TableCell>
        <TableCell align="right" padding="none">
          <p>{balance}</p>
        </TableCell>
        <TableCell align="right" padding="none">
          <p>{price}</p>
        </TableCell>
        <TableCell align="right" padding="none">
          <p>{value}</p>
        </TableCell>
        <TableCell
          align="right"
          padding="none"
          classes={{ root: classes.paddingLeftStyler }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <img
                style={{ height: "24px", width: "24px" }}
                src={UpArrow}
              ></img>
            ) : (
              <img
                style={{ height: "24px", width: "24px" }}
                src={DownArrow}
              ></img>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="assets-debts-collapse-style">
              <p
                style={{
                  fontSize: "14px",
                  color: "#1B3E69",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {row.assetName}
              </p>
              <div style={{ display: "flex", gap: "10%" }}>
                <div>
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Exact Balance
                  </p>
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Exact Price
                  </p>
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Exact Value
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: "14px" }}>
                    {exactBalance
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {row?.symbol}
                    {/* {data.currency.toUpperCase()} */}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    {exactPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {row?.baseCurrency}
                    {/* {data.currency.toUpperCase()} */}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    {exactValue
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {row?.baseCurrency}
                    {/* {data.currency.toUpperCase()} */}
                  </p>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const classes = useRowStyles();
  const { id, dataArray, dateTime } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [dataSet, setDataSet] = useState([]);
  var finalRow = [];
  const data = useSelector((state) => state.get_assets);
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  var sum,
    totalAsset = 0,
    vaultNetValue = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setDataSet(dataArray);
  }, [dataArray]);

  useEffect(() => {
    if (vaultAddress && dataSet.length > 0) historyDataFunction(0);
    else setLoader(false);
  }, [data.currency, dataSet]);

  const historyDataFunction = async (currencyRate) => {
    props.setLoaderToggle(true);
    totalAsset = 0;
    vaultNetValue = 0;
    sum = 0;
    setLoader(false);
  };
  return (
    <>
      {props.loader ? (
        <div style={{ marginTop: "100px" }}>
          <CustomLoader />
        </div>
      ) : dataArray?.length ? (
        <div style={{ marginTop: "30px" }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead className="table-header-styling">
                <TableRow>
                  <TableCell classes={{ root: classes.headerTitlePadding }}>
                    <p style={{ fontSize: "16px" }}>
                      {id == 1 ? "ASSETS" : "DEBTS"}
                    </p>
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ root: classes.headerPadding }}
                  >
                    <p>Balance</p>
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ root: classes.headerPadding }}
                  >
                    <p>Price</p>
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ root: classes.headerPadding }}
                  >
                    <p>Value</p>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.dropDownArrow }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return <Row key={row.assetName} row={row} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            classes={{ root: classes.rowsPerPageTextStyle }}
            className={classes.boldWeight}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per Page:"
            labelDisplayedRows={() => ""}
          />
        </div>
      ) : (
        <NoData text="No Data" />
      )}
    </>
  );
}
