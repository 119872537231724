import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "'Catamaran', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.4,
      whiteSpace:"nowrap",
      [breakpoints.down("1640")]: {
        fontSize: 12
      },
    }
  }
})



export default theme