import {
  Modal,
  Button,
  Divider,
  Radio,
  Switch,
  Row,
  Dropdown,
  Menu,
  Col,
  Input,
  Select,
  Form,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./Style/index.css";
import { Advisor } from "../../services/api";

const { Option } = Select;

function SettingsModal(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("instant");
  const [select, setSelect] = useState("One");
  const [form, setForm] = useState({ name: [], value: [], protocol: [] });
  const [customFields, setCustomFields] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [allKeys, setAllKeys] = useState([]);
  const [formValues] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    // console.log("formValues", formValues.getFieldsValue());
    props.setEdited && props.setEdited(true);
    // setLoading(true);
    try {
      formValues
        .validateFields()
        .then((res) => {
          let pass = {},
            protocol = {},
            fieldValues = formValues.getFieldValue();
          Object.keys(fieldValues).map((key) => {
            if (
              key !== "advisorEndPoint" &&
              key !== "protocols" &&
              key !== "email" &&
              key !== "assetAllocation" &&
              typeof fieldValues[key] !== "object"
            )
              pass[key] = fieldValues[key];
            else if (typeof fieldValues[key] === "object") {
              if (fieldValues[key]?.key)
                pass[fieldValues[key].key] = fieldValues[key].value;
            }
          });

          if (fieldValues.forcedRebalance) pass.forcedRebalance = true;
          else pass.forcedRebalance = false;
          // customFields?.map((item) => {
          //   if (item.key && item.value) pass[item.key] = item.value;
          // });
          protocols.map((v) => {
            protocol[v.value] = v.value;
          });
          pass["assetAllocation"] = fieldValues?.assetAllocation;
          let supportedProtocols = [];
          if (fieldValues?.assetAllocation || fieldValues?.protocolSwitch)
            supportedProtocols =
              fieldValues?.assetAllocation &&
              props.title === "Portfolio-Allocator"
                ? Object.keys(fieldValues?.assetAllocation)
                : Object.keys(fieldValues?.protocolSwitch)?.filter(
                    (item) => fieldValues?.protocolSwitch?.[item]
                  );
          props.setAdvisor({
            advisorEndPoint: fieldValues?.advisorEndPoint,
            vaultAdvisorSetting: pass,
            supportedProtocols: supportedProtocols,
            advisorEmail: fieldValues?.email,
          });
          if (
            (props.formValues.advisorEmail || fieldValues?.email) &&
            (props.formValues.vaultAdvisorSetting || pass)
          )
          props?.handleValidation &&
            props.handleValidation({
              ...props.isValidated,
              coreCard: {
                advisor: "valid",
              },
            });
          else
          props?.handleValidation &&
            props.handleValidation({
              ...props.isValidated,
              coreCard: {
                advisor: "invalid",
              },
            });

          setForm({ name: [], value: [], protocol: [] });
          setVisible(false);
          formValues.resetFields();
          setCustomFields([]);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log({ err });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setForm({ name: [], value: [], protocol: [] });
    // formValues.resetFields();
    setCustomFields([]);
  };

  const handleInput = (e) => {
    // console.log(formValues.getFieldValue());
    // let obj = {};
    // obj[e.target.name] = e.target.value;
    // if (e.target.type === "url") {
    //   var res =
    //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    //   let v = res.test(e.target.value);
    //   if (v) {
    //     form.setForm(...form, {
    //       name: [...form.name, e.target.name],
    //       value: [...form.value, e.target.value],
    //     });
    //   }
    // } else
    //   setForm(...form, {
    //     name: [...form.name, e.target.name],
    //     value: [...form.value, e.target.value],
    //   });
  };

  const validateNumberRange = (_, value) => {
    if (value >= 0 && value <= 1) {
      return Promise.resolve();
    }
    return Promise.reject("Number must be between 0 and 1");
  };

  const handleProtocol = (event, value) => {
    setForm({
      ...form,
      protocol: [
        ...form.protocol,
        { address: value, value: event.target.value },
      ],
    });
  };

  const onRadioChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  function onSwitchChange(checked) {
    setForm({
      ...form,
      name: [...form.name, "forcedRebalance"],
      value: [...form.value, checked],
    });
  }

  const onSelect = (e) => {
    setSelect(e.target.value);
    // console.log(e.target.value);
  };

  const addNewField = () => {
    setCustomFields((prev) => {
      if (prev) return [...prev, { id: prev?.length + 1, key: "", value: "" }];
      else return [{ id: prev?.length + 1, key: "", value: "" }];
    });
  };

  const deleteCustomInput = (data) => {
    setCustomFields((prev) => prev.filter((item) => item.id !== data?.id));
    formValues.setFieldsValue({ [data.key]: undefined });
  };

  const customInputChange = (item, e, type) => {
    setCustomFields((prev) => {
      return prev.map((inputValue) => {
        if (item?.id === inputValue?.id) {
          inputValue[type] = e?.target?.value;
          return inputValue;
        } else return inputValue;
      });
    });
  };

  const onProtocolChange = (id, protocol) => {
    setProtocols(protocol);
  };

  const checkSumOfProtocol = (value) => {
    try {
      protocols.map((item) => {
        if (value !== item.protocolAddress) {
          formValues.setFieldsValue({
            assetAllocation: {
              [item.protocolAddress]:
                +formValues.getFieldValue()["assetAllocation"][
                  item.protocolAddress
                ] ?? 0,
            },
          });
        }
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const menu = (
    <Menu onSelect={onSelect}>
      <Menu.Item key={1}>
        <span value="One">One</span>
      </Menu.Item>
      <Menu.Item key={2}>
        <span value="Two">Two</span>
      </Menu.Item>
      <Menu.Item key={3}>
        <span value="Three">Three</span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    Advisor.getAllKeys().then((res) => {
      if (res.status === 200) setAllKeys(res.data?.data);
    });
  }, []);

  useEffect(() => {
    let formPropValues = {};
    if (props && props.manageVaultType) formPropValues = props.manageVaultType;
    else if (props && props.formValues) formPropValues = props.formValues;
    // {
    // formValues.setFieldsValue({
    //   gas: props?.manageVaultType?.gas,
    //   Dinv: props?.manageVaultType?.Dinv,
    //   T: props?.manageVaultType?.T,
    //   Tmin: props?.manageVaultType?.Tmin,
    //   email: props?.manageVaultType?.advisorEmail,
    //   advisorEndPoint: props?.manageVaultType?.advisorEndPoint,
    //   protocols: props?.manageVaultType?.supportedProtocols ?? [],
    // });
    // } else if (props && props.formValues) {
    if (props.title === "Maximize-APR")
      formValues.setFieldsValue({
        // gas: formPropValues?.vaultAdvisorSetting?.gas,
        Dinv: formPropValues?.vaultAdvisorSetting?.Dinv,
        email: formPropValues?.advisorEmail,
        advisorEndPoint: formPropValues?.advisorEndPoint,
        T: formPropValues?.vaultAdvisorSetting?.T,
        Tmin: formPropValues?.vaultAdvisorSetting?.Tmin,
        maxprotocolshare: formPropValues?.vaultAdvisorSetting?.maxprotocolshare,
        maxtvlshare: formPropValues?.vaultAdvisorSetting?.maxtvlshare,
        protocols: formPropValues?.supportedProtocols ?? [],
      });
    else
      formValues.setFieldsValue({
        email: formPropValues?.advisorEmail,
        advisorEndPoint: formPropValues?.advisorEndPoint,
        T: formPropValues?.vaultAdvisorSetting?.T,
        Tmin: formPropValues?.vaultAdvisorSetting?.Tmin,
        gasLimit: formPropValues?.vaultAdvisorSetting?.gasLimit,
        lpToken: formPropValues?.vaultAdvisorSetting?.lpToken,
        protocols: formPropValues?.supportedProtocols ?? [],
      });
    let defaultCustomFields = [];
    let index = 1;
    Object.keys(formPropValues?.vaultAdvisorSetting ?? {})?.map((item) => {
      if (item !== "assetAllocation" && item !== "forcedRebalance") {
        if (!allKeys.find((keyItem) => keyItem.key === item)) {
          formValues.setFieldsValue({
            [index]: {
              value: formPropValues?.vaultAdvisorSetting[item],
              key: item,
            },
          });
          defaultCustomFields = [
            ...defaultCustomFields,
            {
              id: index,
              ...formPropValues?.vaultAdvisorSetting[item],
            },
          ];
        }
        index += 1;
      } else if (item === "assetAllocation") {
        formValues.setFieldsValue({
          assetAllocation:
            formPropValues?.vaultAdvisorSetting?.assetAllocation ?? 0,
        });
      } else if (item === "forcedRebalance") {
        formValues.setFieldsValue({
          forcedRebalance: formPropValues?.vaultAdvisorSetting?.forcedRebalance,
        });
      }
    });
    let protocolSwitch = {};
    formPropValues?.supportedProtocols?.map((item) => {
      protocolSwitch[item] = true;
    });
    formValues.setFieldsValue({ protocolSwitch: protocolSwitch });
    setCustomFields(defaultCustomFields);
    // }
    return () => {
      // console.log("reset 1");
      // formValues.resetFields();
      // setProtocols([]);
      // setCustomFields([]);
    };
  }, [visible, props.manageVaultType]);

  const setDefaultValueOnAssets = () => {
    try {
      protocols.map((item) => {
        formValues.setFieldsValue({
          assetAllocation: {
            [item.protocolAddress]: isNaN(
              formValues.getFieldValue()["assetAllocation"]?.[
                item.protocolAddress
              ]
            )
              ? 0
              : formValues.getFieldValue()["assetAllocation"]?.[
                  item.protocolAddress
                ],
          },
        });
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    setDefaultValueOnAssets();
    return () => {
      // setProtocols([]);
      // console.log("reset 2");
      // formValues.resetFields();
      // setCustomFields([]);
    };
  }, [props.title]);

  useEffect(() => {
    let formattedAssetList = props.baseAssetsList?.map((asset) => {
      return {
        protocolSymbol: asset?.assetSymbol,
        protocolAddress: asset?.assetAddress,
      };
    });
    let formattedVaultList = props.vaultsList?.map((vault) => {
      return {
        protocolSymbol: vault?.vaultName,
        protocolAddress: vault?.vaultAddress,
      };
    });
    setProtocols([
      ...props.protocolsList,
      ...formattedAssetList,
      ...formattedVaultList,
    ]);
    if (
      protocols.length !==
      props.protocolsList?.length +
        formattedAssetList.length +
        formattedVaultList.length
    ) {
      setDefaultValueOnAssets();
    }
  }, [props.protocolsList, props.baseAssetsList, props.vaultsList]);

  useEffect(() => {
    // default value of clear settings is undefined
    if (props.clearSettings != undefined) {
      props.setAdvisor({
        advisorEndPoint: undefined,
        vaultAdvisorSetting: undefined,
        supportedProtocols: [],
        advisorEmail: undefined,
      });
    }
  }, [props.clearSettings]);

  return (
    <>
      <Button
        type="ghost"
        disabled={props.enabled}
        onClick={showModal}
        className="settings-button"
      >
        Settings
      </Button>
      <Modal
        visible={visible}
        title="Settings"
        onOk={handleOk}
        onCancel={handleCancel}
        className="my-modal"
        width={540}
        footer={
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleOk}
            className="button-save"
          >
            Save Settings
          </Button>
        }
      >
        <Form form={formValues} name="core" className="core-settings">
          <Row justify="space-between">
            <h2 className="title">
              {props.title ? props.title : "Maximize-APR"}
              {props.title === "Maximize-APR" ? (
                <Tooltip
                  title={
                    <div>
                      <h3 style={{ color: "white" }}>Maximize-APR</h3>
                      Maximize APR is a simple but powerful strategy that
                      identifies the asset or protocol with the highest APR and
                      automatically moves your investment to that asset or
                      protocol. The strategy takes gas costs and estimated
                      future returns to determine if a switch is profitable.
                      <br />
                      <br />
                      <h3 style={{ color: "white" }}>
                        Important Considerations
                      </h3>
                      Maximize APR should only be used to optimize between
                      protocols and vaults that are denominated in the same base
                      asset. For example only US-$ denominated stable coin
                      protocols, only eth denominated yield bearing eth
                      protocols, and so on. If you mix and match vaults and
                      protocols with different base assets the results will at
                      best be unpredictable and most likely non-sensical.
                      Maximize APR only makes sense for assets, protocols and
                      vaults that actually earn a yield. If an asset or protocol
                      does not return a yield (calculated based on the base
                      asset) the APR will be 0. If you include Yieldcube Vaults in
                      your optimization universe only Yield Vaults should be
                      used with Maximize APR. Asset vaults do not have an APR.
                      Using Maximize APR with asset vaults or assets will lead
                      to non-sensical decisions and will often lead to excessive
                      switching between protocols without adding any value.
                    </div>
                  }
                  overlayInnerStyle={{
                    width: "500px",
                    color: "white",
                    background: "rgba(0, 0, 0, 0.85)",
                    padding: "10px",
                  }}
                >
                  <QuestionCircleOutlined
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              {props.title === "Portfolio-Allocator" ? (
                <Tooltip
                  title={
                    <div>
                      <h3 style={{ color: "white" }}>Portfolio-Allocator</h3>
                      Portfolio Allocator is a simple but powerful strategy that
                      distributes your assets between the assets, protocols and
                      vaults you select.
                      <br />
                      <br />
                      This strategy is helpful to create a balanced and
                      diversified portfolio of assets and strategies to match
                      your risk appetite and desire for return. The strategy
                      either uses probabilistic rebalancing or forced
                      rebalancing depending on your settings.
                    </div>
                  }
                  overlayInnerStyle={{
                    width: "500px",
                    color: "white",
                    background: "rgba(0, 0, 0, 0.85)",
                    padding: "10px",
                  }}
                >
                  <QuestionCircleOutlined
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </h2>
            <div className="logo">{/* <MetamaskLogo /> */}</div>
            <Row className="sub-text">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Present
              mollis lectus a urna. */}
            </Row>
          </Row>
          <Divider horizontal="true" />
          <Row justify="space-between" style={{ paddingTop: "21px" }}>
            <Col span={12}>
              <Form.Item
                label="Email"
                tooltip="Please provide an email address to receive notifications 
                and alerts about your vault. If you do not want to provide an email address
                you can use ops@yieldcube.io. If you choose ops@yieldcube.io our
                operations team will add your vault to our standard monitors. This email is typically 
                only used in case of emergencies or when your strategy was updated."
              />
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                ]}
                name="email"
              >
                <Input
                  className="input-text"
                  name="email"
                  type="email"
                  size="large"
                  onChange={handleInput}
                  placeholder="Enter a valid email id of the advisor"
                />
              </Form.Item>
            </Col>
          </Row>
          {props.title === "Custom" ? (
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item label="End Point" tooltip="Advisor url" />
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: props.title === "Custom" ? true : false,
                      message: "End point is required",
                    },
                    {
                      type: "url",
                      message: "This field must be a valid url.",
                    },
                  ]}
                  name="advisorEndPoint"
                >
                  <Input
                    className="input-text"
                    name="advisorEndPoint"
                    type="url"
                    size="large"
                    required={true}
                    onChange={handleInput}
                    placeholder="Enter the advisor url"
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {props.title === "Maximize-APR" ? (
            <>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Minimum Transaction Size (Tmin)"
                    tooltip="The vault will only transact if the value of available assets for a
                    transaction exceeds the minimum transaction size. This setting is used to reduce the effect gas costs
                    have on profitability of the vault, especially for smaller investment amounts"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Tmin value is required",
                      },
                    ]}
                    name="Tmin"
                  >
                    <Input
                      className="input-text"
                      name="Tmin"
                      min={0}
                      type="number"
                      size="large"
                      onChange={handleInput}
                      required={true}
                      placeholder="Enter the minimum transaction size"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Monitoring Frequency (minutes)"
                    tooltip="Determines how often the advisor will apply the strategy against the
                    assets in the vault. In general daily should be a good value, but for larger vaults shorter timeframes are
                    usually more beneficial."
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "T value is required",
                      },
                    ]}
                    name="T"
                  >
                    <Input
                      className="input-text"
                      name="T"
                      type="number"
                      min={0}
                      size="large"
                      onChange={handleInput}
                      required={true}
                      placeholder="Monitoring Frequency"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Estimated Gas Cost"
                    tooltip="To determine whether switching between protocols is profitable the Maximize APR
                    strategy takes estimated gas costs into account. Higher estimated gas costs will reduce the number of
                    transactions in your vault and will thereby improve profitability."
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Gas is required",
                      },
                    ]}
                    name="gas"
                  >
                    <Input
                      className="input-text"
                      name="gas"
                      type="number"
                      min={0}
                      size="large"
                      onChange={handleInput}
                      required={true}
                      placeholder="Enter the projected gas cost"
                    />
                  </Form.Item>
                </Col>
              </Row> */}
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <label> Look forward period (Days)</label>
                        <label> (for calculating switch)</label>
                      </div>
                    }
                    tooltip="This is the timeframe used to calculate the total return that could be
                    earned during the look forward period. Maximize APR only switches protocols if the expected earnings
                    of the new protocol during the Look Forward Period exceeds estimated gas costs + slippage +
                    Transaction Fees (not gas)"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Dinv value is required",
                      },
                    ]}
                    name="Dinv"
                  >
                    <Input
                      className="input-text"
                      name="Dinv"
                      type="number"
                      min={0}
                      size="large"
                      onChange={handleInput}
                      required={true}
                      placeholder="Look forward period for calculating switch"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/*fields added below for maximaize-APR*/}
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Max Protocol share"
                    tooltip="Determines the maximum portion or percentage of the total vault assets that can be allocated to a specific protocol within the vault's investment strategy. This setting helps manage risk by limiting exposure to any single protocol or strategy within the vault."
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "value is required.",
                      },
                      {
                        validator: validateNumberRange,
                      },
                    ]}
                    name="maxprotocolshare"
                  >
                    <Input
                      className="input-text"
                      name="maxprotocolshare"
                      min={0}
                      max={1}
                      type="number"
                      size="large"
                      step={0.1}
                      onChange={handleInput}
                      required={true}
                      placeholder="Enter the Max Protocol share"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Max TVL share"
                    tooltip="Restricts the percentage of the vault's total value that can be allocated to a single pool within that protocol. This limit helps in diversifying the vault's exposure across different pools within the same protocol."
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Value is required",
                      },
                      {
                        validator: validateNumberRange,
                      },
                    ]}
                    name="maxtvlshare"
                  >
                    <Input
                      className="input-text"
                      name="maxtvlshare"
                      min={0}
                      max={1}
                      type="number"
                      size="large"
                      step={0.1}
                      onChange={handleInput}
                      required={true}
                      placeholder="Enter the Max TVL Share"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <label>Forced Rebalance&nbsp;</label>
                        <label>(enable to override Tmin)</label>
                      </div>
                    }
                    tooltip="If forced rebalance is selected the vault will rebalance your positions even if the
                        minimum transaction size is not reached for any of your positions. This can have a severely negative
                        impact on performance due to gas costs and should only be used for vaults carrying larger balances."
                  />
                </Col>
                <Col span={12} className="input-values">
                  <div>
                    <Form.Item name="forcedRebalance" valuePropName="checked">
                      <Switch
                        title="forcedRebalance"
                        onChange={(e) => onSwitchChange(e)}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {/*fields added above for maximaize-APR*/}
              {props?.protocolsList?.length ? (
                <Row justify="space-between">
                  <Col span={12}>
                    <Form.Item label="Protocols" />
                  </Col>
                  <Col span={12} />
                </Row>
              ) : (
                <></>
              )}
              {protocols?.map((asset, index) => {
                return (
                  <Row justify="space-between" key={index}>
                    <Col span={1} />
                    <Col span={11}>
                      <Form.Item label={asset?.protocolSymbol} />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={["protocolSwitch", asset?.protocolAddress]}
                        valuePropName="checked"
                      >
                        <Switch title={asset?.protocolAddress} />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : props.title === "Portfolio-Allocator" ||
            props.title === "Custom" ? (
            <>
              {props.title !== "Custom" ? (
                <>
                  <Row justify="space-between">
                    <Col span={12}>
                      <Form.Item
                        label="Minimum transaction size (Tmin)"
                        tooltip="The vault will only transact if the value of available assets for a
                        transaction exceeds the minimum transaction size. This setting is used to reduce the effect gas costs
                        have on profitability of the vault, especially for smaller investment amounts"
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Tmin value is required",
                          },
                        ]}
                        name="Tmin"
                      >
                        <Input
                          className="input-text"
                          name="Tmin"
                          type="number"
                          min={0}
                          size="large"
                          placeholder=" Minimum transaction size"
                          onChange={handleInput}
                          required={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={12}>
                      <Form.Item
                        label="Monitoring Frequency (minutes)"
                        tooltip="Determines how often the advisor will apply the strategy against the
                        assets in the vault. In general daily should be a good value, but for larger vaults shorter timeframes are
                        usually more beneficial."
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "T value is required",
                          },
                        ]}
                        name="T"
                      >
                        <Input
                          className="input-text"
                          name="T"
                          type="number"
                          min={0}
                          size="large"
                          placeholder=" Monitoring Frequency"
                          onChange={handleInput}
                          required={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row justify="space-between">
                    <Col span={12}>
                      <Form.Item
                        label="Monitoring Frequency (minutes)"
                        tooltip="Determines how often the advisor will apply the strategy against the
                        assets in the vault. In general daily should be a good value, but for larger vaults shorter timeframes are
                        usually more beneficial."
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "T value is required",
                          },
                        ]}
                        name="T"
                      >
                        <Input
                          className="input-text"
                          name="T"
                          type="number"
                          min={0}
                          size="large"
                          placeholder=" Monitoring Frequency"
                          onChange={handleInput}
                          required={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {props?.protocolsList?.length ? (
                    <Row justify="space-between">
                      <Col span={12}>
                        <Form.Item label="Protocols" />
                      </Col>
                      <Col span={12} />
                    </Row>
                  ) : (
                    <></>
                  )}
                  {protocols?.map((asset, index) => {
                    return (
                      <Row justify="space-between" key={index}>
                        <Col span={1} />
                        <Col span={11}>
                          <Form.Item label={asset?.protocolSymbol} />
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={["protocolSwitch", asset?.protocolAddress]}
                            valuePropName="checked"
                          >
                            <Switch title={asset?.protocolAddress} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
              {props.title === "Portfolio-Allocator" ? (
                <>
                  <Row justify="space-between">
                    <Col span={12}>
                      <Form.Item
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <label>Forced Rebalance&nbsp;</label>
                            <label>(enable to override Tmin)</label>
                          </div>
                        }
                        tooltip="If forced rebalance is selected the vault will rebalance your positions even if the
                        minimum transaction size is not reached for any of your positions. This can have a severely negative
                        impact on performance due to gas costs and should only be used for vaults carrying larger balances."
                      />
                    </Col>
                    <Col span={12} className="input-values">
                      <div>
                        <Form.Item
                          name="forcedRebalance"
                          valuePropName="checked"
                        >
                          <Switch
                            title="forcedRebalance"
                            onChange={(e) => onSwitchChange(e)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  {props?.protocolsList?.length ? (
                    <Row justify="space-between">
                      <Col span={12}>
                        <Form.Item label="Protocol Allocations" />
                      </Col>
                      <Col span={12} />
                    </Row>
                  ) : (
                    <></>
                  )}
                  {protocols.map((protocol, index) => (
                    <Row justify="space-between" key={index}>
                      <Col span={1} />
                      <Col span={11}>
                        <Form.Item label={protocol?.protocolSymbol} />
                      </Col>
                      <Col span={12} className="input-values">
                        <div>
                          <Form.Item
                            name={[
                              "assetAllocation",
                              protocol?.protocolAddress,
                            ]}
                            rules={[
                              {
                                message: "Protocol sum should be 100",
                                validator: () => {
                                  let sumOfProtocols = 0.0;
                                  let completedAllProtocols = false;
                                  try {
                                    protocols.forEach((item) => {
                                      let tempSumOfProtocols = 0.0;
                                      if (
                                        isNaN(
                                          formValues.getFieldValue(
                                            "assetAllocation"
                                          )?.[item?.protocolAddress]
                                        )
                                      ) {
                                        completedAllProtocols = false;
                                        formValues.setFieldsValue({
                                          assetAllocation: {
                                            [item?.protocolAddress]: 0,
                                          },
                                        });
                                      } else completedAllProtocols = true;
                                      tempSumOfProtocols =
                                        sumOfProtocols +
                                          parseFloat(
                                            formValues.getFieldValue(
                                              "assetAllocation"
                                            )?.[item?.protocolAddress]
                                          ) ?? 0;
                                      sumOfProtocols = parseFloat(
                                        tempSumOfProtocols.toFixed(10)
                                      );
                                    });
                                  } catch (err) {
                                    console.log(err);
                                  }
                                  if (parseFloat(sumOfProtocols) === 100) {
                                    return Promise.resolve();
                                  } else if (completedAllProtocols) {
                                    return Promise.reject(
                                      "Protocol sum should be 100"
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              className="input-text"
                              name={protocol?.protocolAddress}
                              onChange={() => {
                                checkSumOfProtocol(protocol?.protocolAddress);
                              }}
                              value={
                                +JSON.stringify(
                                  props.manageVaultType?.vaultAdvisorSetting
                                    ?.assetAllocation?.[
                                    protocol?.protocolAddress
                                  ] ??
                                    props.formValues.vaultAdvisorSetting
                                      ?.assetAllocation?.[
                                      protocol?.protocolAddress
                                    ]
                                )
                              }
                              type="number"
                              size="large"
                              min={0}
                              required={true}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </>
              ) : (
                <></>
              )}
              {/* {props.protocolsList?.map((protocol) => {
                return (
                  <Row justify="space-between">
                    <Col span={1} />
                    <Col span={11}>
                      <Form.Item label={protocol?.protocolSymbol} />
                    </Col>
                    <Col span={12}>
                      <Form.Item name={protocol?.protocolAddress}>
                        <Switch title={protocol?.protocolAddress} />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })} */}
              {/* {props.protocol ? (
                props.protocol.map((v, i) => (
                  <Row justify="space-between" key={i}>
                    <Col span={12}>{v[0].protocolName}</Col>
                    <Col span={12}>
                      <Input
                        className="input-text"
                        id="protocol"
                        name={v[0].protocolName}
                        placeholder="Allocation Percentage"
                        type="number" min={0}
                        size="large"
                        onChange={(event) =>
                          handleProtocol(event, v[0].protocolAddress)
                        }
                        required={true}
                      />
                    </Col>
                  </Row>
                ))
              ) : (
                <Row justify="center">
                  <Col>Please Select Protocols</Col>
                </Row>
              )} */}
              {props.title === "Custom" ? (
                <>
                  {customFields?.map((item, index) => (
                    <Row justify="space-between" key={index}>
                      <Col span={8}>
                        <Form.Item
                          name={[item?.id, "key"]}
                          rules={[
                            {
                              message: "same key already exist",
                              validator: (_, value) => {
                                if (
                                  allKeys.find((item) => item.key === value) ||
                                  customFields.find(
                                    (fields) =>
                                      fields.id !== item.id &&
                                      value === fields.key
                                  )
                                )
                                  return Promise.reject(
                                    "same key already exist"
                                  );
                                else return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            value={item?.key}
                            onChange={(e) => customInputChange(item, e, "key")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} style={{ position: "relative" }}>
                        <Form.Item
                          name={[item?.id, "value"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            value={item?.value}
                            onChange={(e) =>
                              customInputChange(item, e, "value")
                            }
                          />
                        </Form.Item>
                        <Button
                          icon={<DeleteOutlined style={{ fontSize: "12px" }} />}
                          type="default"
                          shape="circle"
                          className="delete-custom-input"
                          onClick={() => {
                            deleteCustomInput(item);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Row justify="space-between">
                    <Col span={24}>
                      <div>
                        <Button
                          icon={<PlusOutlined />}
                          className="add-new-field"
                          type="dashed"
                          onClick={addNewField}
                        >
                          Add new field
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </>
          ) : props.title === "Convex-Auto-Compounder" ? (
            <>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="Monitoring Frequency (minutes)"
                    tooltip="Determines how often the advisor will apply the strategy against the
                    assets in the vault. In general daily should be a good value, but for larger vaults shorter timeframes are
                    usually more beneficial."
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "T value is required",
                      },
                    ]}
                    name="T"
                  >
                    <Input
                      className="input-text"
                      name="T"
                      type="number"
                      min={0}
                      size="large"
                      onChange={handleInput}
                      required={true}
                      placeholder="Monitoring Frequency"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item label="LP Token" tooltip="LP Token" />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "LP Token is required",
                      },
                    ]}
                    name="lpToken"
                  >
                    <Select
                      size="large"
                      className="single-select"
                      // onChange={handleChange}
                      // onClear={() =>
                      //   props.setField({ id: "advisor", value: "" })
                      // }
                      // allowClear
                      // value={props?.fields?.value ?? ""}
                      style={{
                        backgroundColor: "transparent",
                        gap: 0,
                        display: "flex",
                      }}
                    >
                      {props.harvestProtocols?.map((protocol, index) => (
                        <Option value={protocol.protocolAddress} key={index}>
                          {protocol?.protocolSymbol}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item label="Harvest Threshold" tooltip="The harvest threshold, ranging from 0 to 1, determines whether
                   rewards should be harvested. It is determined by comparing the estimated gas cost for collecting the rewards and reinvesting it
                  to the rewards earned, ensuring it is less than the harvest threshold." />
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Harvest Threshold limit is required",
                      },
                    ]}
                    name="harvestThreshold"
                  >
                    <Input
                      className="input-text"
                      name="harvestThreshold"
                      min={0}
                      max={1}
                      type="number"
                      size="large"
                      // onChange={handleInput}
                      required={true}
                      placeholder="Enter the harvest threshold"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item label="Tmin" tooltip="The T_MIN decides whether the minimum value required to make a transaction.
                  Investing, Staking, Reward collection will not run, if the value being transferred is less than T_MIN"/>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Tmin is required",
                      },
                    ]}
                    name="Tmin"
                  >
                    <Input
                      className="input-text"
                      name="Tmin"
                      min={0}
                      type="number"
                      size="large"
                      // onChange={handleInput}
                      required={true}
                      placeholder="Enter the min transaction size"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <div>Radio Field</div>
                </Col>
                <Col span={12} className="input-values">
                  <Radio.Group onChange={onRadioChange} value={value}>
                    <Radio value={"instant"}>Instant</Radio>
                    <Radio value={"delayed"}>Delayed</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <div>Toggles</div>
                </Col>
                <Col span={12} className="input-values">
                  <div>
                    <Switch defaultChecked onChange={onSwitchChange} />
                  </div>
                  <div>
                    <Switch onChange={onSwitchChange} />
                  </div>
                  <div>
                    <Switch onChange={onSwitchChange} />
                  </div>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <div>Dropdown </div>
                  <p>A short description of the setting</p>
                </Col>
                <Col span={12}>
                  <Dropdown trigger={["click"]} overlay={menu}>
                    <Button
                      className="dropdown-button"
                      type="secondary"
                      block
                      onClick={(e) => {
                        e.preventDefault();
                        onSelect(e);
                      }}
                    >
                      <span>{select}</span>
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7FzcqzAB3-Wg4AnZ16NJ-AINDt7nRZEsnQA&usqp=CAU"
                        height={16}
                      />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>Input Field</Col>
                <Col span={12}>
                  <Input
                    className="input-text"
                    placeholder="Placeholder"
                    type="text"
                    size="large"
                  />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>Input Field right</Col>
                <Col span={12}>
                  <Row>
                    <Input
                      className="input-text-right"
                      placeholder="1.25"
                      type="text"
                      size="large"
                    />
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Button className="input-below">25%</Button>
                    <Button className="input-below">50%</Button>
                    <Button className="input-below">75%</Button>
                    <Button className="input-below">100%</Button>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default SettingsModal;
