import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Transaction-Styling/Info-Table-Styling.css";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";
import SuccessOrFailureButton from "./TableStyledButton/successFailureButton";
import SendAndReceiveButton from "./TableStyledButton/sendReceiveButton";
import ExchangeTransactionLabel from "./TableStyledButton/exchangeTransactionLabel";
import DownArrow from "../../../src/assets/Transaction-Table/transaction-table-dropdown-arrow.svg";
import UpArrow from "../../../src/assets/Transaction-Table/transaction-table-dropdown-uparrow.svg";
import Copy from "../../../src/assets/Transaction-Table/copy.svg";
import ReRoute from "../../../src/assets/Transaction-Table/reroute.svg";
import CustomLoader from "../Custom_Loader/customLoader";
import NoData from "../../components/No-Data-Component/noData";
import ExportButton from "./ExportButton";
import { CSVLink } from "react-csv";
import { AdvisorTypeMap } from "../../constants/advisorType";

const EpochConvertor = (value, condition) => {
  // var utcSeconds = value;
  // var d = new Date(0);
  // d.setUTCSeconds(utcSeconds);
  var d = new Date(value);

  var date = d.getDate();
  date = date < 10 ? "0" + date : date;
  var month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var year = d.getFullYear();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var final = hours + ":" + minutes + ":" + seconds + " " + ampm;
  var date_and_time =
    date +
    "/" +
    month +
    "/" +
    year +
    "    " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    ampm;
  if (condition == 1) {
    return final;
  } else if (condition == 0) {
    return date_and_time;
  }
};

function Row(props) {
  const { row, statusVariable } = props;
  const handleCopy = (value) => {
    if (vaultAddress && userAddress) {
      copy(value);
    }
  };

  const linkHandler = (value, type) => {
    if (vaultAddress && userAddress) {
      if (type == 1) {
        window.open(`https://etherscan.io/tx/${value}`);
      } else if (type == 0) {
        window.open(`https://etherscan.io/address/${value}`);
      }
    }
  };

  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const userAddress = useSelector((state) => state.get_user_address.address);

  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #DEE5EF",
        }}
      >
        <TableCell component="th" scope="row">
          {AdvisorTypeMap[row?.advisorType] ?? "-"}
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {<SendAndReceiveButton send_or_receive={row.send_receive_status} />}
        </TableCell> */}
        {statusVariable !== 2 ? (
          <TableCell>
            <p
              style={{ fontWeight: "bold" }}
              className="history-table-inner-contents"
            >
              {parseFloat(row?.amount ?? 0).toFixed(2)}
            </p>
          </TableCell>
        ) : (
          <></>
        )}
        <TableCell>
          <p className="history-table-inner-contents">
            {EpochConvertor(row?.dateOfTransaction, 0)}
          </p>
        </TableCell>
        {/* <TableCell>
          {<SuccessOrFailureButton success_or_failure={1} />}
        </TableCell> */}
        <TableCell>
          {
            <ExchangeTransactionLabel
              to_address={row?.toToken}
              from_address={row?.fromToken}
            />
          }
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <img
                src={UpArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            ) : (
              <img
                src={DownArrow}
                style={{ height: "24px", width: "24px" }}
              ></img>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="collapse-table-style">
              <p
                style={{ fontWeight: "bold", color: "#1B3E69" }}
                className="history-table-inner-contents"
              >
                {row?.transactionMessage}
              </p>
              <div style={{ display: "flex", gap: "8vw", marginTop: "10px" }}>
                <div>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    From
                  </p>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    To
                  </p>

                  <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    Hash
                  </p>
                  {/* <p
                    style={{ fontWeight: "bold" }}
                    className="history-table-inner-contents"
                  >
                    Executed
                  </p> */}
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <p className="address-hash-in-transaction-table">
                      {row?.fromToken ?? "-"}
                    </p>{" "}
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Copy to clipboard" placement="top">
                        <img
                          src={Copy}
                          onClick={() => handleCopy(row?.fromToken)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Open Etherscan" placement="top">
                        <img
                          src={ReRoute}
                          onClick={() => linkHandler(row?.fromToken, 0)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className="address-hash-in-transaction-table">
                      {row?.toToken ?? "-"}
                    </p>{" "}
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Copy to clipboard" placement="top">
                        <img
                          src={Copy}
                          onClick={() => handleCopy(row?.toToken)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Open Etherscan" placement="top">
                        <img
                          src={ReRoute}
                          onClick={() => linkHandler(row?.toToken, 0)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className="address-hash-in-transaction-table">
                      {row?.transactionHash ?? "-"}
                    </p>
                    {/* <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Copy to clipboard" placement="top">
                        <img
                          src={Copy}
                          onClick={() => handleCopy(row?.transactionHash)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button>
                    <button className="transaction-page-button-copy-redirect-styling">
                      <Tooltip title="Open Etherscan" placement="top">
                        <img
                          src={ReRoute}
                          onClick={() => linkHandler(row?.transactionHash, 1)}
                          style={{ height: "15px" }}
                        />
                      </Tooltip>
                    </button> */}
                  </div>
                  {/* <p className="history-table-inner-contents">
                    {EpochConvertor(row.timeStamp, 0)}
                  </p> */}
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function AdvisorTransaction(props) {
  const { rowData, vaultAddress, advisorTransactionloading } = props;

  return (
    <>
      <div className="history-table-heading-export">
        <div>
          <p className="history-table-heading">Advisor Transactions</p>
        </div>
      </div>
      <p className="history-table-description">
        All advisor transactions are displayed below.
      </p>
      {advisorTransactionloading ? (
        <CustomLoader />
      ) : rowData?.length > 0 && rowData !== "Error" ? (
        <TableContainer
          component={Paper}
          className="history-table-custom-scroll"
        >
          <Table>
            <TableHead
              style={{
                paddingBottom: "0px",
                borderBottom: "1px solid #DEE5EF",
              }}
            >
              <TableRow>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="history-table-row-heading">Advisor Id</p>
                </TableCell>
                {/* <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="history-table-row-heading">Type</p>
                </TableCell> */}
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="history-table-row-heading">Amount</p>
                </TableCell>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="history-table-row-heading">Date</p>
                </TableCell>
                {/* <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="history-table-row-heading">Status</p>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.map((row) => (
                <Row key={row?.txnId} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData text="No Transaction Data" />
      )}
      {/* <CustomSkeleton/> */}
    </>
  );
}
