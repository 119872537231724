
function hashDisplayConfig(address) {
    var array = [];
    var string = address;
  
    for(var i = 0; i < string.length; i++) {
      if (i < 15) {
        array[i] = string[i];
      } else if (i > 20 && i < 55) {
          if(i%3== 0){
            array[i] = ".";
          }
      } else if (i > 55) {
        array[i] = string[i];
      }
    }
    var newArray = array.join("");
    return newArray;
  }
  
  export default hashDisplayConfig;
  