import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { CustomDialog } from "react-st-modal";
import { Divider } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import addIcon from "../../../../assets/Manage/Whitelist/AddButton.svg";
import "../../../SidebarSettings/SafeDetails/WhiteList_Manager/addButton";
import Modal from "./Modal";

const useStyles = makeStyles((theme) => ({
  headingContainer: {},
  heading: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    marginRight: "20px",
    borderRadius: "5px",
    paddingLeft: "10px",
    backgroundColor: "#f7f5f5",
    padding: "20px",
  },
  assetLine: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    [theme.breakpoints.down("1641")]: {
      height: "34px",
    },
  },
  assetName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginLeft: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  smallHeadingNew: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  iconContainer: {
    display: "flex",
    [theme.breakpoints.up(900)]: {
      marginLeft: "50px",
      marginRight: "10px",
    },
    [theme.breakpoints.up(1165)]: {
      marginLeft: "100px",
      marginRight: "20px",
    },

    cursor: "pointer",
  },
  card: {
    display: "flex",
    gap: "20px",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
  },
});
export default function Assets(props) {
  const defaultValue = useSelector((state) => state.get_vault_address);
  const classes = useStyles();
  const [change, setChange] = React.useState(true);
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  let editURL = url.replace(/\/[^\/]*$/, "/edit");

  return (
    <div className={classes.headingContainer}>
      <p className={classes.smallHeadingNew}>Assets</p>
      <Divider />
      {props.assets?.length > 0 ? (
        <>
          {props.assets.map((asset) => {
            return (
              <>
                <div className={classes.assetLine}>
                  <div style={{ height: "20px", width: "20px" }}>
                    {asset.assetImageURL ? (
                      <img
                        src={asset.assetImageURL}
                        alt={asset.assetSymbol.slice(0, 2)}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          border: "1px solid grey",
                          borderRadius: "20px",
                        }}
                      >
                        {asset.assetSymbol.slice(0, 2)}
                      </span>
                    )}
                  </div>
                  <p className={classes.assetName}>{asset.assetName}</p>
                </div>
                <Divider />
              </>
            );
          })}
        </>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "40px",
        }}
      >
        {!props.break ? (
          <Link to={editURL}>
            <button
              className="custom-add-button-styling"
              // onClick={async () => {
              //   if (false) {
              //     const result = await CustomDialog(
              //       <MuiThemeProvider theme={theme}>
              //         <Modal
              //           name="Deposit"
              //           vaultAddress={defaultValue.vaultAddress}
              //           apsManager={props.apsManager}
              //           userAddress={props.userAddress}
              //           web3={props.web3}
              //           heading="Add Depositable assets"
              //           change={change}
              //           setChange={setChange}
              //           dispatch={dispatch}
              //         />{" "}
              //       </MuiThemeProvider>,
              //       {}
              //     );
              //   } else {
              //     // message.warning("");
              //   }
              // }}
            >
              <img src={addIcon}></img>
              <p>ADD</p>
            </button>
          </Link>
        ) : (
          ""
        )}
      </div>

      <p className={classes.smallHeadingNew}>Protocols</p>
      <Divider />
      {props.protocols?.length > 0 ? (
        <>
          {props.protocols?.map((protocol) => {
            return (
              <>
                <div className={classes.assetLine}>
                  <div style={{ height: "20px", width: "20px" }}>
                    {protocol.protocolIcon ? (
                      <img
                        src={protocol.protocolIcon}
                        alt={protocol.protocolSymbol.slice(0, 2)}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          border: "1px solid grey",
                          borderRadius: "20px",
                        }}
                      >
                        {protocol.protocolSymbol.slice(0, 2)}
                      </span>
                    )}
                  </div>
                  <p className={classes.assetName}>{protocol.protocolName}</p>
                </div>
                <Divider />
              </>
            );
          })}
        </>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "40px",
        }}
      >
        {!props.break ? (
          <Link to={editURL}>
            <button
              className="custom-add-button-styling"
              onClick={async () => {
                if (false) {
                  const result = await CustomDialog(
                    <MuiThemeProvider theme={theme}>
                      <Modal
                        vaultAddress={defaultValue.vaultAddress}
                        name="Withdraw"
                        apsManager={props.apsManager}
                        userAddress={props.userAddress}
                        web3={props.web3}
                        heading="Add Withdrawable assets"
                        change={change}
                        setChange={setChange}
                        dispatch={dispatch}
                      />{" "}
                    </MuiThemeProvider>,
                    {}
                  );
                } else {
                  // message.warning("");
                }
              }}
            >
              <img src={addIcon}></img>
              <p>ADD</p>
            </button>
          </Link>
        ) : (
          ""
        )}
      </div>

      <p className={classes.smallHeadingNew}>Vaults</p>
      <Divider />
      {props.vaults?.length > 0 ? (
        <>
          {props.vaults?.map((vault) => {
            return (
              <>
                <div className={classes.assetLine}>
                  <div style={{ height: "20px", width: "20px" }}>
                    {vault?.logoImg?.data ? (
                      <img
                        src={`data:image/jpeg;base64,${vault?.logoImg?.data}`}
                        alt={vault?.vaultName?.slice(0, 2)}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          border: "1px solid grey",
                          borderRadius: "20px",
                        }}
                      >
                        {vault?.vaultName?.slice(0, 2)}
                      </span>
                    )}
                  </div>
                  <p className={classes.assetName}>{vault?.vaultName}</p>
                </div>
                <Divider />
              </>
            );
          })}
        </>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "40px",
        }}
      >
        {!props.break ? (
          <button
            className="custom-add-button-styling"
            onClick={async () => {
              if (false) {
                const result = await CustomDialog(
                  <MuiThemeProvider theme={theme}>
                    <Modal
                      vaultAddress={defaultValue.vaultAddress}
                      name="Withdraw"
                      apsManager={props.apsManager}
                      userAddress={props.userAddress}
                      web3={props.web3}
                      heading="Add Withdrawable assets"
                      change={change}
                      setChange={setChange}
                      dispatch={dispatch}
                    />{" "}
                  </MuiThemeProvider>,
                  {}
                );
              } else {
                // message.warning("");
              }
            }}
          >
            <img src={addIcon}></img>
            <p>ADD</p>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
