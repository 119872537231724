import React from "react";
import { useDrop } from "react-dnd";
import TagContainer from "../TagContainer/TagContainer";
import "./Style/index.css";

function CardItem(props) {
  const [i, drop] = useDrop({
    accept: props.accept,
    drop: () => ({ target: props.id }),
    canDrop: (item) => {
      return item.source !== props.id;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });

  const displayData = (value) => {
    switch (value[0]?.type) {
      case "asset":
        return value[0].assetSymbol.slice(0, 3);
      case "vault":
        // Null name ??
        return value[0].vaultName?.slice(0, 3);
      case "protocol":
        return value[0].protocolName?.slice(0, 3);
      default:
        return " ";
    }
  };

  const displayImage = (value) => {
    let p;
    switch (value[0]?.type) {
      case "asset":
        p = value[0].assetImageURL;
        break;
      case "vault":
        p = `data:image/jpeg;base64,${value[0].logoImg.data}`;
        break;
      case "protocol":
        p = value[0]?.protocolIcon
          ? `data:image/jpeg;base64,${value[0]?.protocolIcon}`
          : undefined;
        break;
      default:
        return " ";
    }
    return p;
  };

  const removeItem = (address, type) => {
    props.removeItemsFromCart(address, type);
  };

  return (
    <div className="content-items" ref={drop} id={props.id}>
      <div className="add">
        {props.fields.length ? (
          props.fields.map((field, index) => (
            <TagContainer
              key={field[0]?.id}
              data={displayData(field)}
              address={
                field[0]?.type === "asset"
                  ? field[0]?.assetAddress
                  : field[0]?.type === "protocol"
                  ? field[0]?.protocolAddress
                  : field[0]?.vaultAddress
              }
              type={field[0]?.type}
              removeItems={removeItem}
              tagIcon={displayImage(field)}
              tagSymbol={
                field[0]?.type === "asset"
                  ? field[0].assetSymbol?.slice(0, 3)
                  : field[0]?.type === "protocol"
                  ? field[0].protocolName?.slice(0, 2)
                  : field[0].vaultName?.slice(0, 2)
              }
            />
          ))
        ) : (
          <p className="tag-placeholder">{`Drag one or more ${props.accept}s here`}</p>
        )}
      </div>
      {props.isValidated.assetCard.base === "invalid" &&
        props.id === "base" && (
          <span className="validation-required">*This field is required</span>
        )}
      <div className="text">
        {props.text}&nbsp;
        {(props.id == "vault" || props.id == "protocol") && (
          <span className="text-optional">(Optional)</span>
        )}
      </div>
    </div>
  );
}

export default CardItem;
