const initState = {
  vaultID: "",
  creationStatus: "",
  // groupData:"",
  // whiteListData:"",
  // proxyData:"",
  // registerAPSData:"",
  // vaultAssetData:"",
  // strategyProtocolData:"",
  formdata: "",
  pause_or_resume: "",
  re_initiate: "",
  current_strategy: "",
  total_strategy: "",
};

const PendingVaultCreationReducer = (state = initState, action) => {
  switch (action.type) {
    case "INITIAL_VAULT_ID_IN_PROGRESS":
      return {
        ...state,
        vaultID: action.payload.initial_vault_id,
      };

    case "CURRENT_STATUS_IN_PROGRESS":
      return {
        ...state,
        creationStatus: action.payload.vault_creation_status,
        currentStatus: action.payload.vault_current_status,
      };

    case "META_DATA_FILE":
      return {
        ...state,
        metadata: action.payload.meta_data_file,
      };

    case "PAUSE_OR_PLAY_IN_PROGRESS":
      return {
        ...state,
        pause_or_resume: action.payload.pause_or_resume,
      };

    case "RE_INITIATION_STATUS_IN_PROGRESS":
      return {
        ...state,
        re_initiate: action.payload.vault_re_initiation_status,
      };

    case "VAULT_STRATEGY_DETAILS_IN_PROGRESS":
      return {
        ...state,
        current_strategy: action.payload.current_strategy,
        total_strategy: action.payload.total_strategy,
      };

    // case "CREATE_GROUP_IN_PROGRESS":
    //     return {
    //         ...state,
    //         groupData: action.payload.create_group,
    //     }

    // case "ADD_TO_WHITELIST_IN_PROGRESS":
    //     return {
    //         ...state,
    //         whiteListData: action.payload.add_to_whitelist,
    //     }

    // case "CREATE_PROXY_IN_PROGRESS":
    //     return {
    //         ...state,
    //         proxyData: action.payload.create_proxy,
    //     }

    // case "REGISTER_APS_IN_PROGRESS":
    //     return {
    //         ...state,
    //         registerAPSData: action.payload.register_aps,
    //     }

    // case "SET_VAULT_ASSETS_IN_PROGRESS":
    //     return {
    //         ...state,
    //         vaultAssetData: action.payload.set_vault_assets,
    //     }

    // case "SET_VAULT_STRATEGY_PROTOCOL_IN_PROGRESS":
    //     return {
    //         ...state,
    //         strategyProtocolData: action.payload.set_strategy_protocol,
    //     }

    default:
      return state;
  }
};

export default PendingVaultCreationReducer;
