import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDialog } from "react-st-modal";
const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  headingContent: {
    fontSize: "14px",
    textAlign: "justify",
    color: "#6D7681",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  button: {
    margin: "20px 0px 0px 0px",
    padding: "8px 20px",
    borderRadius: "8px",
    width: "440px",
    background: "#1B3E69",
    color: "white",
    fontFamily: "Catamaran",
    fontWeight: "bold",
    boxShadow: "none",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#1B3E69",
      cursor: "pointer",
    },
  },
}));
const EnableDisableModal = (props) => {
  const { vaultVisibility, changeVaultVisibility, vaultAddress } = props;
  const classes = useStyles();
  const dialog = useDialog();
  return (
    <React.Fragment>
      <div>
        <p className={classes.heading}>CHANGE VAULT VISIBILITY</p>
        <div style={{ padding: "20px" }}>
          <p className={classes.headingContent}>
            Currently the vault visibility is set to{" "}
            <strong>{vaultVisibility ? "visible" : "not-visible"}</strong>.
            Please change the visibility using the options below.
          </p>
          <div>
            <Button
              variant="contained"
              // disabled={!addAddress}
              onClick={() => {
                changeVaultVisibility(vaultAddress);
                dialog.close();
              }}
              className={classes.button}
            >
              MAKE VAULT
              {!vaultVisibility ? " VISIBLE" : " HIDDEN"}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default EnableDisableModal;
