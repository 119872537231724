import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import GnosisSafeSol from "../../../contractABI/GnosisSafe.json";
import { setTokenValue } from "../../../redux/actions/TokenValue";
import useInterval from "../../Polling/useInterval";
import addressDisplayConfig from "./addressDisplayConfig";
import { Vaults } from "../../../services/api";
import "./SafeDetail.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
    backgroundColor: "#DEE5EF",
    width: "100%",
    padding: "20px",

    // height: 235,
    // [theme.breakpoints.down("1640")]: {
    //   height: "166px",
    // },
  },
  vaultName: {
    fontFamily: "'Catamaran', sans-serif!important",
    fontWeight: "400!important",
    color: "#0E3439!important",
    fontSize: "24px!important",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("1640")]: {
      fontSize: "20px!important",
    },
  },
  adddressText: {
    color: "rgb(93 109 116)",
  },
  balanceTextWrap: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
  },

  balanceText: {
    color: "#164F58!important",
    fontSize: " 28px",
    fontWeight: "700",
    fontFamily: "'Open Sans', sans-serif",
    width: "fitContent",
    [theme.breakpoints.down("1640")]: {
      fontSize: "20px!important",
    },
  },
  vaultIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  button: {
    backgroundColor: "#22c55e !Important",
    color: "white!Important",
    whiteSpace: "nowrap",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#22c55e",
    },
  },
}));

function SafeDetails() {
  const classes = useStyles();
  const history = useHistory();

  //Dispatch
  const dispatch = useDispatch();
  let num, navNumber;
  //States
  const [balance, setBalance] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  //Useselector
  const networkType = useSelector((state) => state.get_network_type);
  const userAddress = useSelector((state) => state.get_user_address);
  const vaultAddress = useSelector((state) => state.get_vault_address);
  const safename = useSelector((state) => state.get_safe_name);
  const vaultIcon = localStorage.getItem("vaultIcon");
  const token_balance = useSelector(
    (state) => state.get_token_value.tokenValue
  );
  const nav_balance = useSelector((state) => state.get_token_value.navValue);
  const [currency, setCurrency] = useState("USD");
  const [navPerToken, setNavPerToken] = useState(0);
  const web3 = useSelector((state) => state.get_web3.web3);
  //Functions

  //Vault address copy function
  const copytextHandler = () => {
    copy(vaultAddress.vaultAddress);
  };

  //Function to append vault address to etherscan link
  const linkHandler = () => {
    if (vaultAddress && vaultAddress.vaultAddress)
      window.open(`https://etherscan.io/address/${vaultAddress.vaultAddress}`);
  };

  const getGnosisSafeInstanceAt = (safeAddress, Web3) => {
    const web3 = Web3;

    return new web3.eth.Contract(GnosisSafeSol.abi, safeAddress);
  };

  //Handler to route to create safe
  const addSafeHandler = () => {
    history.push("/createvault");
  };

  useEffect(() => {
    Vaults.userAndNavDetailsOfVaultToken(
      userAddress.address,
      vaultAddress.vaultAddress
    )
      .then((res) => {
        setCurrency(res.data?.data.baseCurrency);
        setNavPerToken(res.data?.data.navPerToken);
      })
      .catch((err) => console.log({ err }));
  }, [userAddress.address, vaultAddress.vaultAddress]);

  useInterval(async () => {
    if (vaultAddress.vaultAddress && web3) {
      const GnosisReturned = await getGnosisSafeInstanceAt(
        vaultAddress.vaultAddress,
        web3
      );

      let val = await GnosisReturned.methods.tokenValueInUSD().call();
      let nav = await GnosisReturned.methods.getVaultNAV().call();

      // num = (val / 10 ** 18).toFixed(3);
      num = web3.utils.fromWei(val, "ether");
      navNumber = (nav / 10 ** 18).toFixed(2);
      setBalance(num);
      dispatch(setTokenValue(num, navNumber));
    } else {
      setBalance(0);
      dispatch(setTokenValue(0, 0));
    }
  }, 5000);

  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress.vaultAddress && web3) {
        const GnosisReturned = await getGnosisSafeInstanceAt(
          vaultAddress.vaultAddress,
          web3
        );

        let val = await GnosisReturned.methods.tokenValueInUSD().call();
        let nav = await GnosisReturned.methods.getVaultNAV().call();

        // num = (val / 10 ** 18).toFixed(3);
        num = web3.utils.fromWei(val, "ether");
        navNumber = (nav / 10 ** 18).toFixed(2);
        setBalance(num);
        dispatch(setTokenValue(num, navNumber));
      } else {
        setBalance(0);
        dispatch(setTokenValue(0, 0));
      }
    };
    asyncFn()
  }, []);

  useEffect(() => {
    setTokenPrice(token_balance);
  }, [userAddress.address, vaultAddress.vaultAddress, token_balance]);

  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress.vaultAddress && web3) {
        const GnosisReturned = await getGnosisSafeInstanceAt(
          vaultAddress.vaultAddress,
          web3
        );

        let val = await GnosisReturned.methods.tokenValueInUSD().call();
        let nav = await GnosisReturned.methods.getVaultNAV().call();

        // num = (val / 10 ** 18).toFixed(3);
        num = web3.utils.fromWei(val, "ether");
        navNumber = (nav / 10 ** 18).toFixed(2);
        setBalance(num);
        dispatch(setTokenValue(num, navNumber));
      } else {
        setBalance(0);
        dispatch(setTokenValue(0, 0));
      }
    };
    asyncFn();
  }, [
    vaultAddress.vaultAddress,
    token_balance,
    nav_balance,
    userAddress.address,
  ]);

  return (
    <React.Fragment>
      <div className="safeWrapper">
        <div className={classes.root}>
          <img
            alt="Icons"
            src={`data:image/jpeg;base64,${vaultIcon}`}
            className={classes.vaultIcon}
          />
          <ListItemText className={classes.vaultName} disableTypography={true}>
            {safename.safeName}
          </ListItemText>
          <p className={classes.balanceTextWrap}>
            <span className={classes.balanceText}>
              {navPerToken?.toFixed(2)}
            </span>
            <span
              style={{
                color: "#6D7681",

                fontWeight: 400,
                fontFamily: " 'Open Sans', sans-serif",
                fontSize: "16px",
              }}
            >
              {currency?.toUpperCase()}
            </span>
          </p>
          <Tooltip title={vaultAddress.vaultAddress} placement="top">
            <div
              style={{
                width: "185px",
                fontSize: 14,
                whiteSpace: "nowrap",
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: "400",
                color: "#1B3E69",
              }}
            >
              {addressDisplayConfig(vaultAddress.vaultAddress)}
            </div>
          </Tooltip>
          <ListItemIcon className="iconWrap">
            <button>
              <Tooltip title="Copy to clipboard" placement="top">
                <span className="copyEtherscanText" onClick={copytextHandler}>
                  COPY
                </span>
              </Tooltip>
            </button>
            <button>
              <Tooltip title="Etherscan" placement="top">
                <span className="copyEtherscanText" onClick={linkHandler}>
                  LINK
                </span>
              </Tooltip>
            </button>
          </ListItemIcon>
        </div>
        <div className="networkInfo">{networkType.network}</div>
      </div>
    </React.Fragment>
  );
}

export default SafeDetails;
