import React, { useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { getVault, getVaultPortfolio } from "../../api";
import { useSelector } from "react-redux";
import BasicTable from "./StrategyListTable";
import CustomLoader from "../../Custom_Loader/customLoader";
import ShowMoreText from "react-show-more-text";
import SupportStrategyTable from "../OverView/SupportStrategy/index";

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#1B3E69",
    [theme.breakpoints.down("1641")]: {
      fontSize: "20px",
    },
  },
  mainContent: {
    fontSize: "14px",
    textAlign: "justify",
    marginBottom: "20px",
    width: "60%",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  label: {
    paddingLeft: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: "0px",
    marginBottom: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginBottom: "20px",
    marginTop: "40px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
  showMore: {
    textAlign: "justify",
    fontSize: "14px",
    color: "#6D7681",
    fontFamily: " 'Open Sans', sans-serif",
    marginBottom: "20px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
}));

export default function OverView() {
  const classes = useStyles();
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const [portfolio, setPortfolioData] = React.useState([]);
  const [present, setPresent] = React.useState(true);
  const [strategies, setStrategies] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [dataNew, setData] = React.useState([]);
  const [color, setColor] = React.useState([
    "#155785",
    "#1071A1",
    "#0A8BBD",
    "#05A5D9",
    "#00BFF5",
  ]);

  const getVaultDetails = () => {
    return axios.get(getVault(vaultAddress));
  };

  const getProftifolioDetails = () => {
    return axios.get(getVaultPortfolio(), {
      params: { vaultAddress: vaultAddress },
    });
  };

  useEffect(() => {
    if (vaultAddress) {
      Promise.all([getVaultDetails(), getProftifolioDetails()]).then((res) => {
        setStrategies(res[0].data.data.strategies);
        if (res[0].data.data.strategies.length > 0) {
          setPresent(true);
        } else {
          setPresent(false);
        }
        if (res[1].data.data.length) {
          setPortfolioData(res[1].data.data);
        }
      });
    }
  }, [vaultAddress]);

  useEffect(() => {}, [rows]);

  useEffect(() => {
    if (present) {
      let data = [];
      let strategy = strategies.map((res, i) => {
        let shareAmount = portfolio.find((share) => {
          return (
            res.strategyAddress.toUpperCase() ===
            share.strategyAddress.toUpperCase()
          );
        });

        if (shareAmount) {
          res.status = true;
          res.share = shareAmount.strategyShare;
          if (shareAmount.strategyShare > 0) {
            data.push({
              name: res.strategyName,
              data: [shareAmount.strategyShare],
            });
            setData(data);
          }
        } else {
          res.status = false;
          res.share = "";
        }
        return res;
      });

      setRows(strategy);
    }
  }, [strategies, present, portfolio]);

  return (
    <>
      {present && strategies.length == 0 ? (
        <CustomLoader />
      ) : (
        <>
          <h1 className={classes.mainHeading}>Strategy Overview</h1>
          <p className={classes.mainContent}>
            <ShowMoreText
              lines={1}
              more="Show more"
              less="Show less"
              className={classes.showMore}
              anchorClass="my-anchor-css-class"
              expanded={false}
              width={1000}
            >
              Strategies are algorithms that send instructions to the vault and
              decide how funds inside the vault are allocated in various DeFi
              protocols. Algorithms can live either on-chain or off-chain in a
              fully automated, secure, backend service provided by Yieldcube.
              This is to ensure that any code on the Yieldcube platform has been
              audited.
            </ShowMoreText>
          </p>
          {dataNew.length > 0 ? (
            <>
              <p className={classes.title}>STRATEGY SHARE</p>

              <div
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                {dataNew.map((value, i) => {
                  return (
                    <div
                      className={classes.label}
                      style={{
                        width: `${value.data[0]}%`,
                        backgroundColor: `${color[i]}`,
                        color: "white",
                        height: "30px",
                        padding: "6px 10px",
                        fontWeight: "bold",
                        fontFamily: "'Open Sans', sans-serif",
                        borderRadius:
                          dataNew.length == i + 1 ? "0px 8px 8px 0px" : "0px",
                        display:
                          value.data[0] == "" || value.data[0] == 0
                            ? "none"
                            : "inline",
                      }}
                    >
                      {value.data} %
                    </div>
                  );
                })}
              </div>

              <div style={{ display: "flex" }}>
                {dataNew.map((value, i) => {
                  return (
                    <div
                      className={classes.label}
                      style={{
                        width: value.data[0] != "" ? `${value.data[0]}%` : "0%",
                        color: `${color[i]}`,
                        marginTop: "10px",
                      }}
                    >
                      {value.name}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
          <p className={classes.title} style={{ marginBottom: "0px" }}>
            STRATEGY LIST{" "}
          </p>

          {rows.length > 0 ? (
            <>
              <BasicTable data={rows} manager={false} />
              <SupportStrategyTable />
            </>
          ) : !present ? (
            <p
              style={{
                fontSize: "14px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              No strategy present
            </p>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
