const initState = {
  gasRequired: 0,
  gasPriceRequired: 0,
  nonceRequired: 0,
};

const ParameterArrayReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_GAS_REQUIRED":
      return {
        ...state,
        gasRequired: action.payload.gasRequired,
      };

    case "SET_GAS_PRICE_REQUIRED":
      return {
        ...state,
        gasPriceRequired: action.payload.gasPriceRequired,
      };

    case "SET_NONCE_REQUIRED":
      return {
        ...state,
        nonceRequired: action.payload.nonceRequired,
      };
    default:
      return state;
  }
};

export default ParameterArrayReducer;
