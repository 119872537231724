import { Switch } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import CustomLoader from "../../Custom_Loader/customLoader";
import { makeStyles } from "@material-ui/core/styles";
import { profitTypeDisplay } from "../../../utils/profitTypeDisplay";

const useStyles = makeStyles((theme) => ({
  key: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "capitalize",
    padding: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  value: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  edit: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#1B3E69",
    fontSize: "15px",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    float: "right",
    [theme.breakpoints.down("1641")]: {
      fontSize: "13px",
    },
  },
}));

const Admin = () => {
  const loader = useSelector((state) => state.get_loader_animation.isLoading);
  const data = useSelector((state) => state.get_vault_details.vault);
  const adminData = data && data.vault;
  const whiteList = data && data.whiteList;
  const walletAddress = localStorage.getItem("accountAddress");
  const { url } = useRouteMatch();
  const classes = useStyles();
  let editURL = url.replace(/\/[^\/]*$/, "/edit");
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div>
          <p className="main-heading">Admin</p>
          <p className="description">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
            possimus eaque amet quas ad perspiciatis placeat voluptas
            laboriosam. Libero incidunt iure error, fugit voluptate possimus
            molestias quis id ratione iste. Aspernatur voluptate placeat cumque
            accusamus nobis odio cupiditate necessitatibus adipisci, numquam,
            laborum praesentium quo repudiandae quasi similique debitis
            excepturi ducimus maiores id eveniet sequi incidunt? */}
          </p>
          {adminData && (
            <>
              <table width={"100%"} className="admin-table">
                <tbody>
                  <tr>
                    <td width={250} className={classes.key}>
                      Name
                    </td>
                    <td className={classes.value}>
                      {adminData.vaultName}
                      <button className={classes.edit}>
                        <Link to={`${editURL}`} className="link">
                          Edit
                        </Link>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Token Name
                    </td>
                    <td className={classes.value}>{adminData.tokenName}</td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Token Symbol
                    </td>
                    <td className={classes.value}>{adminData.tokenSymbol}</td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Wallet Address
                    </td>
                    <td className={classes.value}>
                      {adminData.walletAddress || walletAddress}
                    </td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Vault Icon
                    </td>
                    <td>
                      <img
                        src={`data:image/jpeg;base64,${adminData.logoImg.data}`}
                        alt=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Emergency Vault
                    </td>
                    <td className={classes.value}>
                      {adminData.emergencyVaultAddress}
                    </td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Vault Admin
                    </td>
                    <td className={classes.value}>{adminData.vaultAdmin}</td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Visible
                    </td>
                    <td>
                      <Switch
                        disabled
                        defaultChecked={
                          adminData.isVisible
                            ? adminData.isVisible
                            : adminData.visible
                        }
                      ></Switch>
                    </td>
                  </tr>
                  <tr>
                    <td width={250} className={classes.key}>
                      Private
                    </td>
                    <td>
                      <Switch
                        disabled
                        defaultChecked={adminData.vaultType === "PRIVATE"}
                      ></Switch>
                    </td>
                  </tr>
                  {adminData?.profitType?.length ? (
                    <tr>
                      <td width={250} className={classes.key}>
                        Vault Type
                      </td>
                      <td className={classes.value}>
                        {profitTypeDisplay(adminData?.profitType)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {adminData.vaultType === "PRIVATE" && (
                <>
                  <div className="table-subheading">Whitelist Groups</div>
                  <table width={"100%"} className="whitelist-table">
                    <thead style={{ borderBottom: "1px solid #DEE5EF" }}>
                      <tr>
                        <th width="250px" align="left" className={classes.key}>
                          Group Name
                        </th>
                        <th align="left" className={classes.key}>
                          Whitelist Members
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {whiteList?.length &&
                        whiteList.map((list) => (
                          <tr
                            key={list.id}
                            style={{ borderBottom: "1px solid #DEE5EF" }}
                          >
                            <td align="left" className="txt-primary">
                              {list.groupName}
                            </td>
                            <td align="left" className="txt-primary">
                              {list?.userList?.map((group) => (
                                <>
                                  {group}
                                  <br />
                                </>
                              ))}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default React.memo(Admin);
