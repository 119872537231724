import NonceCalculator from "./NonceCalculator";
import Web3SendVaultTransaction from "../../components/VaultTransaction/Web3SendVaultTransaction";
import {
  GasRequired,
  GasPriceRequired,
  NonceRequired,
} from "../../redux/actions/ParameterArray";
const WrapperFunction =
  (data, OperationType, vaultAddressParam) => async (dispatch, getState) => {
    const reduxstate = getState();
    const web3 = reduxstate.get_web3.web3;
    const vaultAddress = () => {
      if (vaultAddressParam) {
        return vaultAddressParam;
      } else {
        return reduxstate.get_vault_address.vaultAddress;
      }
    };
    if (!(data.txType == "DEPOSIT" || data.txType == "WITHDRAW")) {
      dispatch(GasRequired(""));
      dispatch(GasPriceRequired(""));
      dispatch(NonceRequired(""));
    }
    var promise = new Promise(function (resolve, reject) {
      NonceCalculator(web3, data.signer, (nonce) => {
        if (nonce >= 0) {
          resolve(nonce);
        } else {
          reject();
        }
      });
    });

    promise
      .then((s) => {
        return {
          contractAddress: data.contractAddress,
          txType: data.txType,
          signer: data.signer,
          nonce: s,
          txData: data.txData,
          parameters: data.parameters,
          vaultAddress: vaultAddress(),
        };
      })
      .then((res) => {
        dispatch(Web3SendVaultTransaction(res, OperationType, data._id, data.editCard));
      })
      .catch((err) => {
        console.log(`Error in wrapperFunction:${err.message}`);
      });
  };
export default WrapperFunction;
