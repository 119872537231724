import GnosisSafeSol from "yieldster-abi/contracts/YieldsterVault.json";
import ERC20Token from "../../contractABI/ERC20.json";
import APSContract from "yieldster-abi/contracts/APContract.json";
import WhiteList from "yieldster-abi/contracts/Whitelist.json";
// import IStrategyContract from "yieldster-abi/contracts/IStrategy.json";
import ERC20Detailed from "yieldster-abi/contracts/ERC20Detailed.json";
import ERC20 from "yieldster-abi/contracts/ERC20.json";
import Web3 from "web3";
import axios from "axios";
import {
  backEndEnvFetch
} from "../api";
export const getBackendData = async () => {
  const data = await axios.get(backEndEnvFetch()).then((res) => {
    return res.data.data;
  });
  return data;
};

export const _getTokenBalance = (address, tokenAddress, web3, coinAddress) => {
  return new Promise(async (resolve, reject) => {
    var tokenBalance = 0;

    tokenAddress.methods
      .balanceOf(address)
      .call()
      .then(async function (result) {
        var tokensWei = result;
        // if(coinAddress == "0x3B96d491f067912D18563d56858Ba7d6EC67a6fa"){
        //   console.log("this is so true")
        // }
        // const weiValue = Web3.utils.fromWei(tokensWei, "ether");
        const Precision = await getTokenDecimalPrecision(coinAddress, web3);
        Precision.methods
          .decimals()
          .call()
          .then((res) => {
            switch (res) {
              case "1":
                return Web3.utils.fromWei(tokensWei, "wei");

                // case "2":
                //   return (tokensWei / 10 ** 2).toString();

              case "4":
                return Web3.utils.fromWei(tokensWei, "kwei");

              case "6":
                return Web3.utils.fromWei(tokensWei, "mwei");

              case "9":
                return Web3.utils.fromWei(tokensWei, "gwei");

              case "12":
                return Web3.utils.fromWei(tokensWei, "szabo");

              case "15":
                return Web3.utils.fromWei(tokensWei, "finney");

              case "18":
                return Web3.utils.fromWei(tokensWei, "ether");

              case "21":
                return Web3.utils.fromWei(tokensWei, "kether");

              case "24":
                return Web3.utils.fromWei(tokensWei, "mether");

              case "27":
                return Web3.utils.fromWei(tokensWei, "gether");

              case "30":
                return Web3.utils.fromWei(tokensWei, "tether");

              default:
                return (tokensWei / 10 ** parseInt(res)).toString();
            }
          })
          .then((updatedValue) => {
            resolve(updatedValue);
          });
      });
  });
};

export const getTokenDecimalPrecision = (tokenAddress, Web3) => {
  const web3 = Web3;
  return new web3.eth.Contract(ERC20Detailed.abi, tokenAddress);
};

export const getERC20InstanceAt = (tokenAddress, Web3) => {
  // console.log("emergency output",tokenAddress,Web3)
  const web3 = Web3;

  return new web3.eth.Contract(ERC20Token.abi, tokenAddress);
};

export const getGnosisSafeInstanceAt = (safeAddress, Web3) => {
  const web3 = Web3;
  return new web3.eth.Contract(GnosisSafeSol.abi, safeAddress);
};

export const getTokenPriceInUSD = async (Web3, networkId) => {
  const web3 = Web3;
  // const data = await getBackendData();
  // if (data) {
  return new web3.eth.Contract(APSContract.abi, process.env.REACT_APP_CONTRACT_ADDRESS);
  // }
};

export const getWhiteListInstanceAt = async (Web3, networkId) => {
  const web3 = Web3;
  const data = await getBackendData();
  if (data) {
    return new web3.eth.Contract(WhiteList.abi, data.WhitelistAddress);
  }
};

export const getWhiteListInstance = async (Web3) => {
  const web3 = Web3;

  return new web3.eth.Contract(
    WhiteList.abi,
    process.env.REACT_APP_NODE_WHITELIST_ADDRESS
  );
};

export const getGODAddress = async (Web3, networkId) => {
  const web3 = Web3;
  const data = await getBackendData();
  if (data) {
    return new web3.eth.Contract(APSContract.abi, data.APContractAddress);
  }
};

// export const IStrategyContractInstanceAt = (strategyAddress, Web3) => {
//   const web3 = Web3;
//   return new web3.eth.Contract(IStrategyContract.abi, strategyAddress);
// };

export const MyBalanceInstance = async (Web3, vaultAddress) => {
  const web3 = Web3;
  return new web3.eth.Contract(ERC20.abi, vaultAddress);
};