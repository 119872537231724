import React, { Component } from 'react';
import "./Styling/TableStyledButton.css"
import SuccessTick from '../../../assets/Transaction-Table/successmark.svg'
import FailureMark from '../../../assets/Transaction-Table/failuremark.svg'
import Tooltip from "@material-ui/core/Tooltip";


const SuccessOrFailureButton = (props) => {
    const {success_or_failure} = props;
    return(
    <>
     {success_or_failure == 1 ? (
        <Tooltip title="Success" placement="top">
          <img
            src={SuccessTick}
            style={{ height: "16px", width: "16px" }}
          ></img>
        </Tooltip>
      ) : (
        <Tooltip title="Corrupted" placement="top">
          <img
            src={FailureMark}
            style={{ height: "16px", width: "16px" }}
          ></img>
        </Tooltip>
      )}
    </>
    )

}

export default SuccessOrFailureButton