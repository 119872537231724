import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { safeName } from "../../redux/actions/SafeName";
import { tokenSymbol } from "../../redux/actions/tokenSymbol";
import { setVaultAddress } from "../../redux/actions/VaultAddress";
import { vaultIcon } from "../../redux/actions/vaultIcon";
import { Vaults } from "../../services/api";
import CustomLoader from "../Custom_Loader/customLoader";
import "./Style/index.css";
import { VaultCard } from ".";

function VaultGrid(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userAddress = useSelector((state) => state.get_user_address.address);
  const [paginatedData, setPaginatedData] = useState([]);
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const account = userAddress
    ? userAddress
    : "0x0000000000000000000000000000000000000000";
  const pageSize = 30;

  const fetchMoreData = () => {
    props.setPageNo((prev) => {
      if (
        paginatedData.length > 0 &&
        count !== 0 &&
        (prev + 1) * pageSize <= count
      ) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  const displayVaults = () => {
    let pass = {
      type: props.filterType,
      pageNumber: props.pageNo,
      pageSize: pageSize,
      data: props.searchContent,
      baseCurrency: props.baseCurrency,
      accountAddress: account,
    };
  
    if (pass.pageNumber !== undefined) {
      setLoader(true);
      Vaults.getFilteredVaults(pass)
        .then((response) => {
          if (response.data.data) {
            setCount(response.data.data.count);
            let vaults =
              props.handleResponse &&
              props.handleResponse(response.data.data?.vaults);
            if (vaults?.length) {
              setPaginatedData(
                paginatedData?.length === 0 || pass.pageNumber === 0
                  ? vaults
                  : paginatedData?.concat(vaults)
              );
            }
          }
        })
        .catch((e) => {
          setPaginatedData([]);
          setCount(0);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };  

  useEffect(() => {
    if (props?.data?.length >= count && props?.data?.length !== 0) {
      // if (props.pageNo === 0)
      setPaginatedData(props.data);
      setLoader(false);
    } else if (props.filterType && !isNaN(props.pageNo) && account) {
      displayVaults();
    }
    // else if (paginatedData?.length !== 0) setPaginatedData(paginatedData);
    // }
    // else setPaginatedData(paginatedData?.concat(props.data));
  }, [props.filterType, props.pageNo, account]);

  useEffect(() => {}, [paginatedData]);

  return (
    <div className="grid-data-format">
      {!paginatedData?.length && !loader ? (
        <div className="no-vaults">
          <span className="no-vaults-text">No Vaults to display</span>
        </div>
      ) : (
        <>
          {/* Remove data displayed while switching between tabs */}
          {loader && props.pageNo === 0 ? (
            <> </>
          ) : (
            <InfiniteScroll
              dataLength={paginatedData.length}
              next={fetchMoreData}
              hasMore={paginatedData.length < count}
              // height={"auto"}
              className="grid-row"
              scrollableTarget="content-body"
            >
              {paginatedData.map((data, index) => <VaultCard vault={data} key={index} /> )}
            </InfiniteScroll>
          )}
          {/* Infinity scrolling loader */}
          {loader ? <CustomLoader /> : <></>}
        </>
      )}
    </div>
  );
}

export default VaultGrid;
