import AxiosInstance from "../../utils/axios/axiosConfig";

export default class Faq {
    static allFaq() {
        return AxiosInstance({
            isHandlerEnabled: false,
            url: `faq/v2.0/yieldster/get`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}