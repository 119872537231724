export function DepositWithdrawInstance() {
  const randomNumber = Math.random() * 10000;
  return {
    type: "DEPOSIT_WITHDRAW_INSTANCE",
    payload: {
      randomNumber: randomNumber,
    },
  };
}

export function TransactionActionInstance(transactionHash) {
  const randomNumber = Math.random() * 10000;
  return {
    type: "TRANSACTION_INSTANCE",
    payload: {
      transactionInstance: randomNumber,
      transactionHash: transactionHash,
    },
  };
}
