const initState = {
    safeName:"",
    // tokenBalance:0
  
};

const safeName = (state=initState,action) => {
    switch(action.type) {
        case "SET_SAFE_NAME":
            return { 
                ...state,
                safeName: action.payload.safeName,
            }
            // case "SET_TOKEN_COUNT":
            // return { 
            //     ...state,
            //     tokenBalance: action.payload,
            // }

        default: 
            return state;
    }
}

export default safeName;