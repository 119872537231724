import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./Withdraw_Modal_Styling/withdraw.css";
import InputTextBox from "../../Deposit_Modal_Folder/Withdraw_Modal_Folder/Withdraw_Modal_Styling/Input_TextBox/inputBox";
import Web3 from "web3";
import { useSelector, useDispatch } from "react-redux";
import { getGnosisSafeInstanceAt } from "../../../../Asset-Details/TableFunctions";
import LoaderAnimation from "../../../../LoaderAnimation/LoaderModal";
import DropDown from "../Withdraw_Modal_Folder/Withdraw_Modal_Styling/Input_TextBox/DropDown/dropDown";
import GasPriceInput from "../../Deposit_Modal_Folder/Naked_Input/gasPriceInput";
import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import WrapperFunction from "../../../../Global-Functions/WrapperFunction";
import {
  GasRequired,
  GasPriceRequired,
  NonceRequired,
} from "../../../../../redux/actions/ParameterArray";
import {
  SmartContract,
  Vaults,
  WithdrawQueue,
} from "../../../../../services/api";
import { message } from "../../../../../utils/message";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const withdrawModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [assetAddress, setAssetAddress] = React.useState("");
  const [withdrawtype, setWithdrawType] = useState("Yes");
  const [amount, setAmount] = React.useState("");
  const [shareAmount, setShareAmount] = React.useState(0);
  const [valueSizeCheck, setValueSizeCheck] = React.useState(1);
  const vault_address = useSelector((state) => state.get_vault_address);
  const [gasPriceWithdraw, setGasPriceWithdraw] = React.useState(0);
  const [estGasWithdraw, setEstGasWithdraw] = React.useState(0);
  const [gasPrice, setGasPrice] = React.useState(0);
  const tokenBalance = useSelector(
    (state) => state.get_token_balance.tokenBalance
  );
  const user_address = useSelector((state) => state.get_user_address.address);
  const [encodedData, setEncodedData] = useStateWithCallback("");
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [gasRequired, setGasRequired] = React.useState(0);
  const [isDropDown, setIsDropDown] = React.useState(false);
  const [nonceValue, setNonceValueWithdraw] = React.useState(0);
  const [toggler, setToggler] = React.useState(0);
  const web3 = useSelector((state) => state.get_web3.web3);

  const handleClose = () => {
    setWithdrawType("");
    setOpen(false);
    setAmount("");
    setShareAmount(0);
  };

  useImperativeHandle(ref, () => ({
    handleOpen: () => {
      setOpen(true);
      setToggler(Math.random() * 1000);
    },

    handleClose: () => {
      // setOpen(false);
      handleClose();
    },
  }));

  useEffect(() => {
    dispatch(GasPriceRequired(gasPriceWithdraw));
  }, [gasPriceWithdraw]);

  useEffect(() => {
    dispatch(GasRequired(estGasWithdraw));
  }, [estGasWithdraw]);

  useEffect(() => {
    dispatch(NonceRequired(nonceValue));
  }, [nonceValue]);

  useEffect(() => {
    let str_amount = amount.split(".");
    let str_tokenBalance = tokenBalance?.split(".");
    if (parseFloat(amount) > parseFloat(tokenBalance)) {
      setValueSizeCheck(0);
    } else {
      if (str_amount[0] == str_tokenBalance[0]) {
        if (str_amount[1] > str_tokenBalance[1]) {
          setValueSizeCheck(0);
        } else {
          setValueSizeCheck(1);
        }
      } else {
        setValueSizeCheck(1);
      }
    }
  }, [amount]);

  useEffect(() => {
    handleWithdrawModeSelect("");
    handleWithdrawBalanceCheck();
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      var result = "";
      if (web3 && user_address) {
        web3.eth.getGasPrice().then((res) => {
          if (res != undefined && res != NaN) {
            setGasPrice(res);
            setGasPriceWithdraw(res);
          } else {
            setGasPrice(0);
            setGasPriceWithdraw(0);
          }
        });
        web3.eth.getTransactionCount(user_address, (err, nonce) => {
          setNonceValueWithdraw(nonce);
        });
        if (amount > 0) {
          const weiValue = Web3.utils.toWei(amount, "ether");
          const GnosisReturned = await getGnosisSafeInstanceAt(
            vault_address.vaultAddress,
            web3
          );
          if (withdrawtype == "Yes") {
            result = await GnosisReturned.methods
              .withdraw(assetAddress, weiValue)
              .encodeABI();
          } else if (withdrawtype == "No") {
            result = await GnosisReturned.methods
              .withdraw(weiValue)
              .encodeABI();
          }
          setEncodedData(result);
        }
      }
    };
  asyncFn();
  }, [web3, tokenBalance, assetAddress, amount, user_address, toggler]);

  useEffect(() => {
    const asyncFn = async () => {
      if (encodedData != "") {
        try {
          web3.eth
            .estimateGas({
              from: user_address,

              to: vault_address.vaultAddress,
              data: encodedData,
            })
            .then((res) => {
              if (res != undefined && res != NaN) {
                setGasRequired(res);
                setEstGasWithdraw(res);
              } else {
                setGasRequired(0);
                setEstGasWithdraw(0);
              }
            })
            .catch((err) => {
              setGasRequired(716071);
              setEstGasWithdraw(716071);
            });
        } catch (err) {
          setGasRequired(716071);
          setEstGasWithdraw(716071);
        }
      }
    };
    asyncFn();
  }, [encodedData]);

  const handleWithdrawModeSelect = (value) => {
    setWithdrawType(value);
  };

  const handleWithdrawBalanceCheck = async (amount) => {
    try {
      //Execute the withdraw api to check for the available asset
    } catch (error) {
      console.log(error);
    }
  };

  const handle_Withdraw_With_Asset = async () => {
    const weiValue = Web3.utils.toWei(amount, "ether");
    // let isWithdrawable = await handleWithdrawBalanceCheck(weiValue);
    try {
      const lockedWithdrawCheck = await Vaults.checkWithdrawPossibility(
        vault_address.vaultAddress,
        user_address,
        weiValue
      );

      //Check if the lock withdraw duration is pending
      if (lockedWithdrawCheck?.data?.data?.withdrawalStatus) {
        const withdrawQueueData = await WithdrawQueue.withdrawCheck(
          vault_address.vaultAddress,
          user_address,
          assetAddress,
          weiValue
        );
        if (withdrawQueueData?.data?.isWithdrawable) {
          const transactionData = {
            contractAddress: vault_address.vaultAddress,
            txType: "WITHDRAW",
            signer: user_address,
            txData: encodedData,
            nonce: 0,
            parameters: {
              _tokenAddress: assetAddress,
              _shares: weiValue,
            },
          };
          dispatch(WrapperFunction(transactionData, "POST"));
        } else {
          if (
            withdrawQueueData?.data?.status == "SUBMITTED_FOR_EXECUTION" &&
            withdrawQueueData?.data?.hash
          ) {
            message.warning(
              "Currently the required asset is not present in the vault.",
              20
            );
            message.warning(
              "Your request is under process ,we will notify you when the transaction is ready to be executed.",
              40
            );
            props.setWithdrawQueueHash(withdrawQueueData?.data?.hash);
          } else if (
            withdrawQueueData?.data?.status == "READY_FOR_WITHDRAWAL"
          ) {
            const GnosisReturned = await getGnosisSafeInstanceAt(
              withdrawQueueData?.data?.vaultAddress,
              web3
            );
            const encodeWithdraw = await GnosisReturned.methods
              .withdraw(
                withdrawQueueData?.data?.assetAddress,
                withdrawQueueData?.data?.amount
              )
              .encodeABI();
            const transactionData = {
              contractAddress: withdrawQueueData?.data?.vaultAddress,
              txType: "WITHDRAW",
              signer: withdrawQueueData?.data?.accountAddress,
              txData: encodeWithdraw,
              nonce: 0,
              parameters: {
                _tokenAddress: withdrawQueueData?.data?.assetAddress,
                _shares: withdrawQueueData?.data?.amount,
                hash: withdrawQueueData?.data?.hash,
              },
            };
            dispatch(WrapperFunction(transactionData, "POST"));
          } else {
            message.warning(
              "Your request is under process ,we will notify you when the transaction is ready to be executed.",
              40
            );
          }
        }
      } else {
        const withdrawTokenValue = Web3.utils.fromWei(
          String(lockedWithdrawCheck?.data?.data?.allowableAmount),
          "ether"
        );
        message.warning(
          `Unable to withdraw ${amount} vault tokens,the maximum amount that can be withdrawn at the moment is ${withdrawTokenValue} vault tokens`,
          20
        );
      }
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const handle_Withdraw_With_No_Asset = async () => {
    const weiValue = Web3.utils.toWei(amount, "ether");
    const transactionData = {
      contractAddress: vault_address.vaultAddress,
      txType: "WITHDRAW",
      signer: user_address,
      txData: encodedData,
      nonce: 0,
      parameters: {
        _shares: weiValue,
      },
    };
    dispatch(WrapperFunction(transactionData, "POST"));
    handleClose();
  };

  const handleWithdrawSubmit = () => {
    if (withdrawtype === "Yes") {
      handle_Withdraw_With_Asset();
    } else if (withdrawtype === "No") {
      handle_Withdraw_With_No_Asset();
    }
  };

  const dropDownSelect = async (res) => {
    if (res) {
      setAssetAddress(res?.assetAddress);
      setIsDropDown(true);
    }
  };

  const handleClick25 = () => {
    setShareAmount(tokenBalance * 0.25);
    setAmount((tokenBalance * 0.25).toString());
  };

  const handleClick50 = () => {
    setShareAmount(tokenBalance * 0.5);
    setAmount((tokenBalance * 0.5).toString());
  };

  const handleClick75 = () => {
    setShareAmount(tokenBalance * 0.75);
    setAmount((tokenBalance * 0.75).toString());
  };

  const handleClick100 = () => {
    setShareAmount(tokenBalance);
    setAmount(tokenBalance);
  };

  const inputChange = (value) => {
    setAmount(value);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="dashboard-withdraw-main-card">
            <div className="Withdraw-Heading">
              <p>WITHDRAW</p>
            </div>
            <div className="display-token-balance">
              <p style={{ fontSize: "14px" }}>YOUR VAULT TOKENS</p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1B3E69",
                }}
              >
                {tokenBalance}
              </p>
            </div>
            <div className="input-text-and-shares-withdraw">
              <div
                style={{ width: "210px", height: "150px", paddingTop: "25px" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "185px",
                  }}
                >
                  How much tokens would you like to withdraw
                </p>
                <div style={{ height: "20px" }}>
                  {valueSizeCheck == 0 ? (
                    <p id="less-balance-alert-text-withdraw">
                      Input token greater than balance.
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "100%",
                  paddingTop: "15px",
                }}
              >
                <InputTextBox
                  inputChange={inputChange}
                  shareAmount={shareAmount}
                  valueSizeCheck={valueSizeCheck}
                />

                <div className="withdraw-button-styling">
                  <button onClick={handleClick25}>
                    <p>25%</p>
                  </button>
                  <button onClick={handleClick50}>
                    <p>50%</p>
                  </button>
                  <button onClick={handleClick75}>
                    <p>75%</p>
                  </button>
                  <button onClick={handleClick100}>
                    <p>100%</p>
                  </button>
                </div>
              </div>
            </div>

            <div className="asset-select-dropdown-div-withdraw">
              <p
                style={{
                  marginBottom: "25px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Select Asset
              </p>
              <DropDown
                dropDownSelect={dropDownSelect}
                handleWithdrawModeSelect={handleWithdrawModeSelect}
              />
            </div>

            <div className="gas-and-vault-alloc-numeric-data-withdraw">
              <div style={{ width: "42%" }}></div>
              <div
                style={{
                  display: "flex",
                  paddingTop: "10px",
                  lineHeight: "180%",
                  width: "58%",
                }}
              >
                <div className="gas-and-vault-alloc-numeric-data-detailed-info-withdraw">
                  <p style={{ fontSize: "14px" }}>Est. Tokens</p>
                  <p style={{ fontSize: "14px" }}>Est. Gas Price</p>
                  {/* <p style={{ fontSize: "14px" }}>Override Gas Price</p> */}
                  <p style={{ fontSize: "14px" }}>Est. Gas Required</p>
                  {/* <p style={{ fontSize: "14px" }}>Override Gas Required</p> */}
                  <p style={{ fontSize: "14px" }}>Est. Nonce</p>
                  <p className="gas-vault-alloc-total-styling-withdraw">
                    TOTAL
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "47%",
                    alignItems: "flex-end",
                    gap: "3px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#1B3E69",
                      fontSize: "14px",
                    }}
                  >
                    ---
                  </p>
                  <GasPriceInput
                    id={1}
                    estGasPrice={gasPrice / 10 ** 9}
                    type="withdraw"
                    setGasPriceWithdraw={setGasPriceWithdraw}
                  />
                  <GasPriceInput
                    id={2}
                    estGasPrice={gasRequired}
                    type="withdraw"
                    setEstGasWithdraw={setEstGasWithdraw}
                  />
                  <GasPriceInput
                    id={3}
                    estGasPrice={nonceValue}
                    type="withdraw"
                    setNonceValueWithdraw={setNonceValueWithdraw}
                  />
                  <p className="gas-vault-alloc-total-styling-withdraw">
                    {Web3.utils.fromWei(
                      (estGasWithdraw * gasPriceWithdraw).toString(),
                      "ether"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px 40px 0px 0px",
              }}
            >
              <div className="submit-button-withdraw-modal">
                {amount > 0 && isDropDown == true && valueSizeCheck == 1 ? (
                  <button onClick={handleWithdrawSubmit}>
                    <p>WITHDRAW</p>
                  </button>
                ) : (
                  <button
                    onClick={handleWithdrawSubmit}
                    className="disabled"
                    disabled
                  >
                    <p>WITHDRAW</p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <LoaderAnimation loading={loading} />
    </div>
  );
});

export default withdrawModal;
