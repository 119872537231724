import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../Dashboard-Version2/DashBoard_Styling/dashboardstyle.css";
import MyInvestments from "../Dashboard-Version2/Cards/My_Investments/myInvestments";
import VaultStats from "../Dashboard-Version2/Cards/My_Investments/Vault_Stats/vaultStatistics";
import DepositWithdrawButton from "../Dashboard-Version2/Deposit-Withdraw-Button/despositWithdrawComp";
import { handleWalletSelection } from "../Blocknative/walletSelectionConfig";
import { message } from "../../utils/message";
import usePolling from "../../hooks/usePolling";
import { getGnosisSafeInstanceAt } from "../Asset-Details/TableFunctions";

const RightSideContainer = (props) => {
  // const {loadingSkeleton} = props;
  const [showButton, setShowButton] = useState(false);
  const [showTransactionButtons, setShowTransactionButtons] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [investment, setInvestment] = useState(false);
  const [loadingUniverse, handleSetLoadingUniverse] = useState(true);
  const web3 = useSelector((state) => state.get_web3.web3);
  const [vaultStats, setVaultStats] = useState(false);
  const userAddress = localStorage.getItem("accountAddress");
  const vault_address = useSelector((state) => state.get_vault_address);
  const vaultDetails = useSelector((state) => state.get_vault_details.vault);
  const [paused, setPaused] = useState(false);

  const childRef = useRef();

  useEffect(() => {
    // if (investment && vaultStats) {
    setLoadingSkeleton(false);
    // } else {
    //   setLoadingSkeleton(true);
    // }
  }, [investment, vaultStats]);

  useEffect(() => {
    if (!userAddress) {
      showDepositWithdrawButton(false);
    }
  }, [userAddress]);

  const getVaultPaused = async () => {
    try {
      if ((vault_address.vaultAddress, web3)) {
        const GnosisReturned = await getGnosisSafeInstanceAt(
          vault_address.vaultAddress,
          web3
        );
        const pause = await GnosisReturned.methods.paused().call();
        setPaused(pause);
      }
    } catch (error) {
      console.log(error);
    }
  };
  usePolling(() => {
    getVaultPaused();
  }, 10000);
  useEffect(() => {
    getVaultPaused();
  }, []);

  const handleDepositViaWallet = async () => {
    if (!paused) {
      if (!showButton) {
        childRef.current.handleDepositViaWallet();
      } else {
        if (userAddress) {
          const ready = await handleWalletSelection(userAddress);
          if (!ready) return;
        } else message.error("User not authorized to perform this operation.");
      }
    } else
      message.error(
        "The vault is currently paused,deposit can not be performed at the moment."
      );
  };

  const handleWithdrawViaWallet = async () => {
    if (!showButton) {
      childRef.current.handleWithdrawViaWallet();
    } else {
      if (userAddress) {
        const ready = await handleWalletSelection(userAddress);
        if (!ready) return;
      } else message.error("User not authorized to perform this operation.");
    }
  };

  const showDepositWithdrawButton = (bool) => {
    setShowButton(bool);
  };

  const handleInvestmentLoading = (value) => {
    setInvestment(value);
    // props.setInvestment(value);
  };

  const setLoadingUniverse = (response) => {
    handleSetLoadingUniverse(response);
  };

  const handleVaultStatLoading = (value) => {
    setVaultStats(value);
    // props.setVaultStats(value);
  };

  useEffect(() => {
    const { vault, whiteList } = vaultDetails;
    if (vault) {
      if (vault.vaultType === "PRIVATE") {
        whiteList?.map((list) => {
          const whitelistAddresses = list?.userList?.map((address) => {
            return address.toLowerCase();
          });
          if (
            whitelistAddresses?.includes(userAddress?.toLowerCase()) ||
            list?.admin?.toLowerCase() == userAddress?.toLowerCase()
          ) {
            setShowTransactionButtons(true);
          }
        });
      } else if (vault.vaultType === "PUBLIC") {
        setShowTransactionButtons(true);
      }
    }
  }, [vaultDetails, userAddress]);

  return (
    <div className="investments_and_vault_stats">
      {showTransactionButtons && (
        <DepositWithdrawButton
          handleDepositViaWallet={handleDepositViaWallet}
          handleWithdrawViaWallet={handleWithdrawViaWallet}
          showButton={showButton}
        />
      )}
      {userAddress ? (
        <MyInvestments
          isInvestmentLoading={handleInvestmentLoading}
          setLoadingUniverse={setLoadingUniverse}
          loadingUniverse={loadingUniverse}
          loadingSkeleton={loadingSkeleton}
          showDepositWithdrawButton={showDepositWithdrawButton}
          ref={childRef}
        />
      ) : (
        <div></div>
      )}

      <VaultStats
        isVaultStatLoading={handleVaultStatLoading}
        loadingUniverse={loadingUniverse}
        loadingSkeleton={loadingSkeleton}
      />
    </div>
  );
};

export default RightSideContainer;
