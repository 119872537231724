import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Collapse } from "antd";
import "./Style/index.css";

const { Panel } = Collapse;

function CollapseGrid(props) {
  const [selectedFields, setSelectedFields] = useState([]);
  const [i, drag, preview] = useDrag({
    type: "asset",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [j, drag2, preview2] = useDrag({
    type: "vault",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [k, drag3, preview3] = useDrag({
    type: "protocol",
    item: () => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [l, drag4, preview4] = useDrag({
    type: "deposit",
    item: (item, monitor) => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [m, drag5, preview5] = useDrag({
    type: "withdraw",
    item: () => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview2(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview3(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview4(getEmptyImage(), {
      captureDraggingState: true,
    });
    preview5(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  const clearItemSelection = () => {
    setSelectedFields([]);
  };

  const handleItemSelection = (index, type) => {
    let newSelectedFields;
    switch (type) {
      case "asset":
        let asset = props.fields.asset[index];
        asset["type"] = type;
        newSelectedFields = [asset];
        // newSelectedFields = [...selectedFields, asset];
        break;
      case "vault":
        let vault = props.fields.vault[index];
        vault["type"] = type;
        newSelectedFields = [vault];
        break;
      case "protocol":
        let protocol = props.fields.protocol[index];
        protocol["type"] = type;
        newSelectedFields = [protocol];
        break;
      case "deposit":
        let deposit = props.fields.deposit[index];
        deposit["type"] = type;
        newSelectedFields = [deposit];
        break;
      case "withdraw":
        let withdraw = props.fields.withdraw[index];
        withdraw["type"] = type;
        newSelectedFields = [withdraw];
        break;
      default:
        break;
    }
    setSelectedFields(newSelectedFields);
  };

  useEffect(() => {
    clearItemSelection();
  }, []);

  return (
    <Collapse collapsible="true" ghost className="collapse">
      <Panel header={<div id="head">Assets</div>} key="1" className="panel">
        <div className="contents" ref={drag}>
          {props.fields.asset.map((e, i) => (
            <div
              className="logo"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "asset")}
            >
              {e.assetImageURL ? (
                <img src={e.assetImageURL} alt={e.assetSymbol.slice(0, 3)} />
              ) : (
                <span>{e.assetSymbol.slice(0, 3)}</span>
              )}
            </div>
          ))}
        </div>
      </Panel>
      <Panel header={<div id="head">Protocols</div>} key="3" className="panel">
        <div className="contents" ref={drag3}>
          {props.fields.protocol.map((e, i) => (
            <div
              className="logo"
              key={i}
              onClick={() => handleItemSelection(i, "protocol")}
            >
              {e.protocolSymbol.slice(0, 3)}
            </div>
          ))}
        </div>
      </Panel>
      <Panel header={<div id="head">Vaults</div>} key="2" className="panel">
        <div className="contents" ref={drag2}>
          {props.fields.vault.map((e, i) => (
            <div
              className="logo"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "vault")}
            >
              {e.logoImg ? (
                <img src={`data:image/jpeg;base64,${e.logoImg.data}`} alt="" />
              ) : (
                <span>{e.vaultName.slice(0, 3)}</span>
              )}
            </div>
          ))}
        </div>
      </Panel>
      <Panel
        header={<div id="head">Deposit Strategy</div>}
        key="4"
        className="panel"
      >
        <div className="contents" ref={drag4}>
          {props.fields.deposit.map((e, i) => (
            <div
              className="logo"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "deposit")}
            >
              {e.strategyImgURL ? (
                <img src={e.strategyImgURL} alt={e.name} />
              ) : (
                <span>{e.name}</span>
              )}
            </div>
          ))}
        </div>
      </Panel>
      <Panel
        header={<div id="head">Withdraw Strategy</div>}
        key="5"
        className="panel"
      >
        <div className="contents" ref={drag5}>
          {props.fields.withdraw.map((e, i) => (
            <div
              className="logo"
              key={i}
              onMouseEnter={() => handleItemSelection(i, "withdraw")}
            >
              {e.strategyImgURL ? (
                <img src={e.strategyImgURL} alt={e.name} />
              ) : (
                <span>{e.name}</span>
              )}
            </div>
          ))}
        </div>
      </Panel>
    </Collapse>
  );
}

export default CollapseGrid;
