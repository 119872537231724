export function networkName(id){
    switch (id) {
        case 1:
          return 'main'
        case 3:
          return 'ropsten'
        case 4:
          return 'rinkeby'
        case 5:
          return 'goerli'
        case 42:
          return 'kovan'
        case 100:
          return 'xdai'
        case 1337:
          return 'localhost'
        default:
          return 'local'
      }
    }
