import React, { useState, useEffect } from "react";
import axios from "axios";
import { adminLogin } from "../../../api";
import PropTypes from "prop-types";
import "./Login.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "../../../../utils/message";

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginToken, setLoginToken] = useState();

  //Redux
  const userAddress = useSelector((state) => state.get_user_address.address);

  //Variables
  const history = useHistory();

  async function loginUser(username, password, userAddress) {
    const data = {
      email: username,
      password: password,
      accountAddress: userAddress,
    };
    axios
      .post(adminLogin(), data)
      .then((response) => {
        if (response.data.data.token) {
          setToken(response.data.data.token);
          setLoginToken(response.data.data.token);
        } else if (
          response.data.data == "User not found" &&
          response.data.status == 1
        ) {
          message.error("User not found");
        }
      })
      .catch((error) => {
        message.error("Incorrect Password");
        console.log(error);
      });
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    await loginUser(username, password, userAddress);
  };
  useEffect(() => {
    if (loginToken != undefined) history.push("/admin");
  }, [loginToken]);
  return (
    <form onSubmit={submitHandler}>
      <div className="login-wrap">
        <div className="login-card">
          <div className="login-heading">Login</div>
          <div className="content-wrap">
            <input
              className="useremail-heading"
              id="username-id"
              placeholder="Email"
              type="email"
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              className="password-heading"
              id="password-id"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-button-wrap">
            <button className="login-button">Login</button>
          </div>
        </div>
      </div>
    </form>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
