import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";
import { getVault } from "../../components/api";

export default class Vaults {
  static getAll() {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/get`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getAllVaults(no = 0, size = 0) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getAllVaults?pageNumber=${no}&pageSize=${size}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getMyVault(address, pageNo = 0, pageSize = 0) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getMyVaults/${address}?pageNo=${pageNo}&pageSize=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getVaultByAdmin(address) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getVaultByAdminId/${address}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getFeaturedVaults(no = 0, size = 0) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getFeaturedVaultDetailsByPagination?pageNumber=${no}&pageSize=${size}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static autoCompleteDetails(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getDetailsForAutoComplete?data=${data}`,
      method: "get",
      headers: {
        "Content-Type": "*/*",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getFilteredVaults(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getVaultDetailsByFilter`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: data,
    });
  }

  // service for data processing
  static getVaultYield(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getAprOrProfitOfVault`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: data,
    });
  }

  static getSortedVaults(params, data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getVaultsSorted`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: params,
      data: JSON.stringify(data),
    });
  }

  static addVault(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/addVault`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      body: data,
    });
  }

  static searchItems(data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getDetailsBySearch`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: { data: data },
    });
  }

  static getVaultByFinancialDetails(accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getFinancialDetails/${accountAddress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        accountAddress,
      },
    });
  }

  static getIcon(vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/sdk/v2.0/yieldster/getVaultIcon/${vaultAddress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
      },
    });
  }

  static getApr(vaultAddress, currentBlockNumber) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/sdk/v2.0/yieldster/apr`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        currentBlockNumber,
      },
    });
  }

  static stockChartData(
    filter,
    vaultAddress,
    accountAddress,
    currentBlockNumber
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/graphData`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        filter,
        vaultAddress,
        accountAddress:
          accountAddress || "0x0000000000000000000000000000000000000000",
        currentBlockNumber,
      },
    });
  }

  static getVaultDetails(vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/vaultByAddress`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
      },
    });
  }

  static userAndNavDetailsOfVaultToken(accountAddress, vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/UserAndNavDetailsOfVaultToken?accountAddress=${
        accountAddress || "0x0000000000000000000000000000000000000000"
      }&vaultAddress=${vaultAddress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getUserInvestmentDetails(vaultAddress, accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/user-investment-details`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
      },
    });
  }

  static getQueuedTransactions(vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/filterVaultTransactionbytxstatuspendingorreverted/${vaultAddress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
      },
    });
  }

  static getVaultAssets(id) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getAssetByVaultId/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static getGasEstimate(
    accountAddress,
    vaultAddress,
    tokenAddress,
    amount,
    transactionData
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/get-gas-estimate/`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        tokenAddress,
        amount,
        transactionData,
        accountAddress,
      },
    });
  }

  static addWithdrawRequest(
    accountAddress,
    vaultAddress,
    toToken,
    amount,
    instruction,
    value
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/withdraw`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        accountAddress,
        vaultAddress,
        toToken,
        amount,
        instruction,
        value,
      },
    });
  }

  static getSafeDetails(address) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/vault-details/${address}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static vaultAssetListwithHistoricalData(
    vaultAddress,
    timestamp,
    baseCurrency
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/vaultAssetListwithHistoricalData`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        timestamp,
        baseCurrency,
      },
    });
  }

  static getNavLiquidatedValues(vaultAddress, accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/NAV-LiquidatedValue`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
      },
    });
  }

  static updateAdvisorConfig(vaultAddress, advisorId, data) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/Vault/v2.0/yieldster/update-advisor-config/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: JSON.stringify(data),
      params: {
        advisorId,
      },
    });
  }

  static updateDepositStrategy(vaultAddress, depositStrategy) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/Vault/v2.0/yieldster/update-deposit-strategy`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        depositStrategy,
      },
    });
  }

  static updateWithdrawalStrategy(vaultAddress, withdrawalStrategy) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/Vault/v2.0/yieldster/update-withdrawal-strategy/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        withdrawalStrategy,
      },
    });
  }

  static updateFees(vaultAddress, feeAddress, beneficiaryAddress, percentage) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/Vault/v2.0/yieldster/update-ManagementFee-Percentage-And-Beneficiary`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        feeAddress,
        beneficiaryAddress,
        percentage,
      },
    });
  }

  static updateVaultName(vaultId, vaultName) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-vault-name/${vaultId}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: { vaultName },
    });
  }

  static updateVaultVisibility(vaultAddress, isVisible) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-vault-visibility`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        isVisible,
      },
    });
  }

  static updateEmergencyVault(vaultId, emergencyVaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-emergency-vault-address/${vaultId}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        emergencyVaultAddress,
      },
    });
  }

  static updateVaultAdmin(vaultId, currentAdminAddress, newAdminAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/updateVaultAdmin`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultId,
        currentAdminAddress,
        newAdminAddress,
      },
    });
  }
  static switchVaultToPrivate(
    vaultAddress,
    userList,
    admin,
    groupName,
    groupId,
    privateToggle
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/switchVaultToPrivate/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: {
        userList,
        admin,
        groupName,
        groupId,
        private: privateToggle,
      },
    });
  }

  static changeSupportedAsset(vaultAddress, supportedAsset) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-supported-assets/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: supportedAsset,
    });
  }
  static changeSupportedProtocol(vaultAddress, supportedProtocol) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-supported-protocols/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: supportedProtocol,
    });
  }
  static changeSupportedVaults(vaultAddress, supportedVaults) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/update-supported-vaults/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: supportedVaults,
    });
  }

  static switchVaultToPublic(vaultAddress, accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/switchVaultToPublic`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
      },
    });
  }

  static updateVaultAssets(vaultAddress, vaultAsset) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/updateVaultAsset`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        vaultAsset,
      },
    });
  }

  static updateBaseCurrency(vaultAddress, baseCurrency) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/updateBaseCurrency`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        baseCurrency,
      },
    });
  }

  static getVaultDepositWithdrawAssets(id) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/getDepositWithdrawAssetsByVaultId/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }

  static updateVaultDescription(vaultAddress, description, longDescription) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/updateVaultDescription/${vaultAddress}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: { description, longDescription },
    });
  }

  static checkWithdrawPossibility(vaultAddress, accountAddress, amount) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/checkWithdrawPossibility`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
        amount,
      },
    });
  }

  static updateVaultType(vaultAddress, profitType) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `Vault/v2.0/yieldster/updateVaultType`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: { vaultAddress, profitType },
    });
  }
}
