import React, { useEffect, useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InputBox from "./Input_Box/input";
import { createTheme } from "@material-ui/core/styles";
import "./Input_Box/Input_Styling/style.css";
import { getGnosisSafeInstanceAt } from "../../Asset-Details/TableFunctions";
import WrapperFunction from "../../Global-Functions/WrapperFunction";

const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  headingContent: {
    fontSize: "14px",
    textAlign: "justify",
    padding: "20px 20px 20px 20px",
    color: "#6D7681",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  button: {
    margin: "0px 20px 20px 20px",
    padding: "8px 20px",
    borderRadius: "8px",
    width: "440px",
    background: "#1B3E69",
    color: "white",
    fontFamily: "Catamaran",
    fontWeight: "bold",
    boxShadow: "none",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#1B3E69",
      cursor: "pointer",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
  },
  textField: {
    display: "flex",
    gap: "10px",
    margin: "0px 0px 10px 0px ",
    padding: "0px 20px",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "540px",
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
    mainContent: {
      fontSize: "14px",
    },
  },
});

export default function Modal(props) {
  const classes = useStyles();
  const { web3, vaultAddress, dispatch } = props;
  const dialog = useDialog();
  const [value, setValue] = useState();
  const [addAddress, setAddAddress] = useState();
  const [returnConditionAdd, setReturnConditionAdd] = useState(true);
  const [encodedData, setEncodedData] = useState("");

  function validateInputAddresses(address) {
    return (
      /^(0x){1}[0-9a-fA-F]{40}$/i.test(address) &&
      web3.utils.isAddress(address) &&
      address != "0x0000000000000000000000000000000000000000"
    );
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (addAddress && web3) {
        const networkId = await web3.eth.net.getId();
        var safeInstance = await getGnosisSafeInstanceAt(vaultAddress, web3);
        var result = safeInstance.methods
          .upgradeMasterCopy(addAddress)
          .encodeABI();
        setEncodedData(result); //Set encoded data here
      }
    };
    asyncFn();
  }, [addAddress, web3]);

  const handleUpgradeMasterCopy = async () => {
    const account = await web3.eth.getAccounts();
    try {
      const transactionData = {
        contractAddress: vaultAddress,
        txType: "UPGRADE_MASTERCOPY",
        signer: account[0],
        txData: encodedData,
        nonce: 0,
        parameters: { _mastercopy: addAddress },
      };
      dialog.close(value);
      dispatch(WrapperFunction(transactionData, "POST", vaultAddress));
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChangeAdd = (value) => {
    setAddAddress("");
    let conditionCheck = validateInputAddresses(value);

    setReturnConditionAdd(validateInputAddresses(value));
    if (conditionCheck) {
      setAddAddress(value);
    }
  };

  return (
    <React.Fragment>
      <div>
        <p className={classes.heading}>UPGRADE MASTER COPY</p>
        <p className={classes.headingContent}>
          lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          mollis lectus a urna dignissim,lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Praesent mollis lectus a urna dignissim,
        </p>

        <div className={classes.textField}>
          <InputBox
            handleInputChangeAdd={handleInputChangeAdd}
            returnCondition={returnConditionAdd}
            id="1"
          />
        </div>
        <div>
          {!returnConditionAdd ? (
            <p
              style={{
                color: "#C42121",
                marginLeft: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              Invalid Ethereum Address
            </p>
          ) : (
            <></>
          )}
        </div>
        {/* <SimpleSelect topic={props.name}/> */}
        <Button
          variant="contained"
          disabled={!addAddress}
          onClick={() => {
            handleUpgradeMasterCopy();
          }}
          className={classes.button}
        >
          UPGRADE MASTER COPY
        </Button>
      </div>
    </React.Fragment>
  );
}
