import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { loadCurrency } from "../../redux/actions/currency-details";
import { setCurrencyDetails } from "../../redux/actions/asset-details";
import { setBaseCurrency } from "../../redux/actions/asset-details";
import Currency from "../../services/api/currency";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      paddingBottom: "2px",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 125,
    marginTop: "-9px",
    "& .MuiFilledInput-input": {
      paddingBottom: "2px",
      color: "#1B3E69",
      fontSize: "14px",
      fontFamily: "Open Sans",
      fontWeight: "bold",
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  select: {
    "&:before": {
      borderColor: "#6D7681",
      marginLeft: "12px",
      width: "70px",
    },
    "&:after": {
      borderColor: "#6D7681",
      marginLeft: "12px",
      width: "70px",
    },
    backgroundColor: "white",
  },
  headingStyle: {
    color: "#6D7681",
    fontSize: "10px",
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  downArrow: {
    marginTop: "10px",
    marginRight: "2px",
  },
}));

var num = 1;
function DropDown(props) {
  const dispatch = useDispatch();
  const [currencyList, setCurrencyList] = useState([])
  useEffect(() => {
    Currency.getCurrency().then((res) => {
      setCurrencyList(res.data?.data)
    })
    dispatch(loadCurrency());
  }, []);

  const handleCurrencySelect = async (event) => {
    props.setCurrency(event.target.value)
  };

  const classes = useStyles();

  const handleChange = (event) => {
    props.setCurrency(event.target.value)
  };

  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel className={classes.headingStyle}>
          SELECT A CURRENCY
        </InputLabel>
        <Select
          // id="demo-simple-select-filled"
          value={props.currency}
          onChange={handleChange}
          native={false}
          className={classes.select}
          classes={{ iconFilled: classes.downArrow }}
        // className={classes.currencyStyle}
        >
          {currencyList.map((currency) => {
            return (
              <MenuItem
                key={currency?.id}
                value={currency?.currencySymbol}
                onClick={handleCurrencySelect}
              >
                {currency?.currencySymbol}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default DropDown;
