import React, { useEffect, useState } from "react";
import { Switch, Row, Col, Tooltip, Radio, Popover, Modal, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../Polling/useInterval";
import { message } from "../../utils/message";
import WhitelistRow from "../Whitelist";
import { TransactionDetails, Vaults } from "../../services/api";
import { TransactionActionInstance } from "../../redux/actions/DepositWithdrawAction";
import { Form, Input } from "antd";
import "./Style/index.css";
import { VaultTypeMap, VaultType } from "../../constants/vaultType";
import { profitTypeDisplay } from "../../utils/profitTypeDisplay";
import EditLongDescription from "../Editor/DescriptionEditor";
import { formatStrategyValues } from "rc-tree-select/lib/utils/strategyUtil";

function CardItem({ setField, text, data, formValues }) {
  const dispatch = useDispatch();
  const icon = localStorage.getItem("vaultIcon");
  const [showAdd, setShowAdd] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [groupName, setGroupName] = useState("");
  const [transactionHash, setTransactionHash] = useState();
  const transaction = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.transactionHash
  );
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const [adminData, setAdminData] = useState(data);
  const walletAddress = localStorage.getItem("accountAddress");
  const [description, setDescription] = useState(false);

  const handleTextChange = (e) => {
    let temp = { id: e.target.id, value: e.target.value };
    setField(temp);
  };

  const handleSwitch = (value, key) => {
    let temp = { id: key, value: value };
    setField(temp);
  };

  const profitTypeHandler = (event, id) => {
    let temp;
    switch (event.target.value) {
      case "Asset Vault":
        temp = { id: id, value: VaultType.ASSET_VAULT };
        break;
      case "Yield Vault":
        temp = { id: id, value: VaultType.YIELD_VAULT };
        break;
      default:
        temp = { id: id, value: VaultType.ASSET_VAULT };
        break;
    }
    setField(temp);
  };

  const getAdminCardDetails = async () => {
    try {
      const adminCardDetails = await Vaults.getVaultDetails(
        vaultAddress.vaultAddress
      );
      setAdminData(adminCardDetails.data.data.vault);
    } catch (error) {
      console.log(error);
    }
  };

  const pollingApi = () => {
    if (transactionHash) {
      TransactionDetails.getTransactionDetails(transactionHash)
        .then((res) => {
          if (res.data.data?.txnStatus === "SUCCESS") {
            setTransactionHash("");
            dispatch(TransactionActionInstance());
            getAdminCardDetails();
          } else if (res.data.data === "Error") {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    }
  };

  useEffect(() => {
    if (transaction?.admin) {
      setTransactionHash(transaction.admin);
    }
  }, [transaction]);

  useInterval(async () => {
    pollingApi();
  }, 5000);
  useEffect(() => {
    setAdminData(data);
  }, [data]);

  return (
    <div id="admin-edit">
      <table>
        <tr>
          <td>
            <div className="text">Token Name</div>
          </td>
          <td>
            <div className="value">
              {adminData?.tokenName ? adminData?.tokenName : "Not set"}
            </div>
          </td>
          <td>
            <div className="text">Token Symbol</div>
          </td>
          <td>
            <div className="value">
              {adminData?.tokenSymbol ? adminData?.tokenSymbol : "Not set"}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="text">Wallet Address</div>
          </td>
          <td>
            <div className="value">
              <Tooltip
                title={
                  adminData?.walletAddress
                    ? adminData?.walletAddress
                    : walletAddress
                }
              >
                <span>
                  {adminData?.walletAddress
                    ? adminData?.walletAddress
                    : walletAddress}
                </span>
              </Tooltip>
            </div>
          </td>
          <td>
            <div className="text">Vault Icon</div>
          </td>
          <td>
            <div className="value">
              {icon ? (
                <img src={`data:image/jpeg;base64,${icon}`} alt="icon" />
              ) : (
                <div>Not set</div>
              )}
            </div>
          </td>
        </tr>
      </table>
      <div className="input-text-box">
        <div className="input-box">
          <input
            className="input"
            id="vaultName"
            defaultValue={adminData?.vaultName}
            type={text}
            onChange={(e) => handleTextChange(e)}
          />
          <div className="text">Name</div>
        </div>
        <div className="input-box">
          <input
            className="input"
            id="emergencyVault"
            defaultValue={adminData?.emergencyVaultAddress}
            type={text}
            onChange={(e) => handleTextChange(e)}
          />
          <div className="text">Emergency Vault</div>
        </div>
        <div className="input-box">
          <input
            className="input"
            id="vaultAdmin"
            defaultValue={adminData?.vaultAdmin}
            type={text}
            onChange={(e) => handleTextChange(e)}
          />
          <div className="text">Vault Admin</div>
        </div>
      </div>
      <Row align="middle" style={{ padding: "10px 0px" }}>
        <Col xs={14}>
          <Radio.Group
            id="profitType"
            className="vault-type-group"
            onChange={(event) => profitTypeHandler(event, "profitType")}
            defaultValue={profitTypeDisplay(adminData?.profitType)}
            value={profitTypeDisplay(formValues?.profitType)}
          >
            {VaultTypeMap.map((type, index) => {
              return (
                <Radio className="vault-type-radio" key={index} value={type}>
                  {type}
                </Radio>
              );
            })}
          </Radio.Group>
          <div className="text">Vault Type </div>
        </Col>
      </Row>
      &nbsp;
      <div className="input-text-area">
        <textarea
          type="text"
          defaultValue={adminData?.description}
          className="input"
          placeholder="Short Description"
          id="description"
          onChange={(e) => handleTextChange(e)}
          required={true}
          maxLength="320"
        ></textarea>
        <div className="text">
          Short Description <span className="value"> (Max 320 characters)</span>
        </div>
      </div>
      <Row align="middle">
        <Col span={12}>
          <div className="text">Tell us more about this vault</div>
        </Col>
        <Col span={12} className="text">
          <Button type="text" onClick={() => setModalOpen(true)}>
            <div className="add-long-button">+ Change Detailed Description</div>
          </Button>

          {/* <Popover
            trigger={"click"}
            // placement="bottomLeft"
            autoAdjustOverflow={false}
            overlayClassName="description-popover"
            title={
              <div className="description-popover-title">
                <span>CHANGE DETAILED DESCRIPTION</span>
                <span
                  className="close-button"
                  onClick={() => setDescription(false)}
                >
                  X
                </span>
              </div>
            }
            // onVisibleChange={(visible) => {
            //   setDescription(visible);
            // }}
            visible={description}
            content={
              <EditLongDescription
                type={"edit"}
                short={adminData?.longDescription}
                description={adminData?.longDescription}
                formValues={formValues}
                handleInputChange={(d) => {
                  setField({ id: "longDescription", value: d.value });
                }}
                close={(v) => setDescription(v)}
              />
            }
          >
            <div
              className="add-long-button"
              onClick={() => {
                setDescription(true);
              }}
            >
              + Change Detailed Description
            </div>
          </Popover> */}
        </Col>
      </Row>
      <Row align="middle">
        <Col xs={6}>
          <span className="text">Private Vault</span>
          <Tooltip
            title="Only whitelisted users are allowed to deposit or withdraw assets from the vault. Public
vaults allow anyone to deposit and withdraw."
          >
            <QuestionCircleOutlined
              style={{
                marginLeft: "5px",
              }}
            />
          </Tooltip>
        </Col>
        <Col xs={6}>
          <Switch
            id="vaultType"
            defaultChecked={adminData?.vaultType == "PRIVATE"}
            disabled={true}
            onChange={(value) => {
              handleSwitch(value, "vaultType");
              setShowAdd(!showAdd);
            }}
          />
        </Col>
        <Col xs={6}>
          <span className="text">Visible</span>
          <Tooltip
            title="Choose visible if you want other people to be able to see your vault even if they are not
                                  whitelisted for a vault. If set to not visible only whitelisted users and the vault admin will be able to see
                                  the vault."
          >
            <QuestionCircleOutlined
              style={{
                marginLeft: "5px",
              }}
            />
          </Tooltip>
        </Col>
        <Col xs={6}>
          <Switch
            id="visible"
            defaultChecked={
              adminData?.isVisible ? adminData?.isVisible : adminData?.visible
            }
            onChange={(value) => handleSwitch(value, "visible")}
            checked={formValues?.isVisible}
          />
        </Col>
      </Row>
      <br />
      {/* {showAdd ? (
        <>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            labelAlign="left"
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Group Name"
              name="groupName"
              rules={[
                {
                  required: true,
                  message: "Please enter the group name",
                },
              ]}
            >
              <Input onChange={(e) => setGroupName(e.target.value)} />
            </Form.Item>
          </Form>
          <WhitelistRow />
        </>
      ) : (
        ""
      )} */}
      <Modal
        title={
          <div className="description-popover-title">
            <span>CHANGE DETAILED DESCRIPTION</span>
          </div>
        }
        centered
        visible={modalOpen}
        maskClosable={true}
        width="90vw"
        footer={null}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        className="modal-description"
      >
        <div>
          <EditLongDescription
            type={"edit"}
            short={adminData?.longDescription}
            description={adminData?.longDescription}
            formValues={formValues}
            handleInputChange={(d) => {
              setField({ id: "longDescription", value: d.value });
            }}
            close={(v) => setModalOpen(v)}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CardItem;
