import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import AddCustomStrategyButtom from "./Add-Custom-Strategy/addCustomStrategy";
import CustomStrategyLister from "./Custom-Strategies-Card/strategyCard";
import { getAllCustomStrategies, iconURL } from "../api";
import { useSelector } from "react-redux";
import NoData from "../No-Data-Component/noData";
import { getConfig } from "../api";
import style from "./Style/style.css";
const CustomStrategySection = () => {
  const [customStrategyData, setCustomStrategyData] = useState([]);
  const [validAddress, setValidAddress] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [noData, setNoData] = useState(true);
  const apiUpdationReflector = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const userAddress = useSelector((state) => state.get_user_address.address);
  useEffect(() => {
    const asyncFn = async () => {
      const data = await axios.get(getAllCustomStrategies());
      if (data.data.status == 1) {
        setCustomStrategyData(data.data.data);
      }
    };
    asyncFn();
  }, [apiUpdationReflector]);

  useEffect(() => {
    const asyncFn = async () => {
      const result = await axios.get(getConfig());
      if (result.data.status) {
        setValidAddress([]);
        const { GODAddress, apsManager, strategyManager } = result.data.data[0];
        const apsManagers = await apsManager.map((manager) => {
          return manager.toUpperCase();
        });
        const startegyManagers = await strategyManager.map((manager) => {
          return manager.toUpperCase();
        });
        Promise.all([
          GODAddress.toUpperCase(),
          ...apsManagers,
          ...startegyManagers,
        ]).then((s) => {
          setValidAddress([...validAddress, s]);
        });
      }
    };
    asyncFn();
  }, []);

  useEffect(() => {
    if (userAddress) {
      setShowAddButton(
        validAddress.some((address) => {
          return address === userAddress.toUpperCase();
        })
      );
      const validator2 = customStrategyData.length > 0;
      if (showAddButton || validator2) {
        setNoData(false);
      }
    }
  }, [validAddress, customStrategyData, userAddress]);

  return (
    <Fragment>
      <div className="custom-strategy-table-contents-placer">
        {noData ? (
          <NoData text="No Contents Available" />
        ) : (
          <>
            {userAddress ? (
              showAddButton ? (
                <AddCustomStrategyButtom />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {customStrategyData.map((res) => {
              const image = iconURL(res.icon);
              return (
                <CustomStrategyLister
                  image={image}
                  title={res.name}
                  description={res.description}
                  url={res.url}
                />
              );
            })}
          </>
        )}
      </div>
    </Fragment>
  );
};
export default CustomStrategySection;
