import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../src/assets/akkrue-main-white-logo.svg";
import SafeDetails from "./Safedetails/SafeDetails";
import { config } from "./sidebarConfig";
import theme from "./theme";

const useStyles = makeStyles((theme) => ({
  sidebarWrapper: {
    paddingTop: "15px",
    width: " 100%",
    boxShadow: "1.5px 0 3px -2px #888",
    color: "rgb(6, 6, 48)",
    position: "absolute",
    top: 60,
    [theme.breakpoints.down("1640")]: {
      paddingTop: "10px",
    },
  },
  logo: {
    width: "200px",
    height: "60px",
    backgroundColor: "#B47C39",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1200,
    position: "fixed",
  },
  listItemRootSidebar: {
    [theme.breakpoints.down("1640")]: {
      paddingBottom: "1px",
    },
  },
}));

//Write the API call for Safe details and account address and balance

function Sidebar() {
  //Variables
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  //states
  const [name, setName] = useState();
  const [found, setFound] = useState(null); //State which returns true if admin list is found from allowedAddress
  const [subMenuOpen, setsubMenuOpen] = useState(false);
  const [allowedAddress, setAllowedAddress] = useState([]);
  //redux
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const userAddress = useSelector((state) => state.get_user_address.address);
  const vaultData = useSelector((state) => state.get_vault_details.vault);
  const [adminData, setAdminData] = useState(vaultData?.vault);

  //Functions
  //Axios request:GET aps manager,strategy manager and GOD Address
  const getAllAdminPromise = () => {
    //To get GOD Address
    // const getConfigPromise = axios.get(getConfig());
    // const getWhitelistPromise = axios.get(getWhitelist());
    if (vaultAddress && vaultAddress != "" && vaultAddress != null) {
      //To get APS and Strategy Manager
      // const getAdminListPromise = axios.get(getVault(vaultAddress));
      // Promise.all([
      //   getConfigPromise,
      //   getAdminListPromise,
      //   getWhitelistPromise,
      // ]).then((response) => {
      var arrayAdmins = [
        adminData?.vaultAdmin?.toLowerCase(),
        process.env.REACT_APP_GOD_ADDRESS.toLocaleLowerCase(),
      ];
      // if (response[0].data && response[0].data.status == 1) {
      //   response[0].data.data.map((data) => {
      //     arrayAdmins.push(data.GODAddress.toLowerCase());
      //   });
      // }
      // if (response[1].data && response[1].data.status == 1) {
      //   if (
      //     response[1].data.data.apsManager &&
      //     response[1].data.data.apsManager != undefined
      //   ) {
      //     arrayAdmins.push(response[1].data.data.apsManager.toLowerCase());
      //   }
      //   // if (
      //   //   response[1].data.data.whitelistManager &&
      //   //   response[1].data.data.whitelistManager != undefined
      //   // ) {
      //   //   console.log("consoling ", response[1].data.data);
      //   //   response[1].data.data.whitelistManager.map((data) => {
      //   //     arrayAdmins.push(data.toLowerCase());
      //   //   });
      //   //   // arrayAdmins.push(
      //   //   //   response[1].data.data.whitelistManager.toLowerCase()
      //   //   // );
      //   // }
      //   if (
      //     response[1].data.data.strategyManager &&
      //     response[1].data.data.strategyManager != undefined
      //   ) {
      //     arrayAdmins.push(
      //       response[1].data.data.strategyManager[0].toLowerCase()
      //     );
      //   }
      // }
      // if (
      //   response[2].data &&
      //   response[2].data.data &&
      //   response[2].data.status == 1
      // ) {
      //   // console.log("Whitelist manager",response[2].data.data)
      //   response[2].data.data.map((data) => {
      //     data.admin.map((data) => {
      //       arrayAdmins.push(data.toLowerCase());
      //     });
      //   });
      // }
      setAllowedAddress(arrayAdmins);
      // });
    }
  };

  //Sidebar options:Onclick path handler
  const pathHandler = (path, sub, name) => {
    if (path.includes("manage")) {
      if (!location.pathname.includes(path)) {
        if (sub && path) {
          setsubMenuOpen(!subMenuOpen);
          setName(name);
        } else if (!sub && path) {
          var newPath = `/vault/${vaultAddress}`;
          path = newPath.concat(path);
          history.push(path);
          setName(name);
        }
      }
    } else {
      if (sub && path) {
        setsubMenuOpen(!subMenuOpen);
        setName(name);
      } else if (!sub && path) {
        if (path.includes("helpcenter")) {
          history.push(path);
        } else {
          var newPath = `/vault/${vaultAddress}`;
          path = newPath.concat(path);
          history.push(path);
          setName(name);
        }
      }
    }
  };
  //Function to highlight if clicked or not
  const sideBarIsClickedOrNot = (colour, item) => {
    return (
      <ThemeProvider theme={theme}>
        {name == item.name ? (
          <ListItemIcon style={{ color: colour }}>{item.icon}</ListItemIcon>
        ) : (
          <ListItemIcon style={{ color: "#6D7681" }}>{item.icon}</ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={
            <Typography type="body2" style={{ color: colour }}>
              {item.name}
            </Typography>
          }
        />
        <ListItemIcon style={{ color: colour }}>{item.dropdown}</ListItemIcon>
      </ThemeProvider>
    );
  };
  //Function to list options(Menu and SubMenu) in sidebar
  const sideBarList = (item) => {
    return (
      <React.Fragment>
        <ListItem
          button
          onClick={() => pathHandler(item.path, item.sub, item.name)}
          autoFocus={item.autoFocus}
          classes={{ root: classes.listItemRootSidebar }}
        >
          {name === item.name
            ? sideBarIsClickedOrNot("#22C55E", item)
            : sideBarIsClickedOrNot("#ffffff", item)}
        </ListItem>
      </React.Fragment>
    );
  };
  //Managers check function
  const managerCheck = (name, list) => {
    if (found) {
      return <React.Fragment key={list.name}>{sideBarList(list)}</React.Fragment>;
    } else if (!found && list.name != name)
      return <React.Fragment key={list.name}>{sideBarList(list)}</React.Fragment>;
  };

  //Function to display sidebar
  const SideBarListDisplay = () => {
    return config.map((item) => {
      return (
        <React.Fragment key={item.name}>
          {managerCheck("Apps", item)}
          {subMenuOpen && item.sub ? (
            item.sub.map((submenulist) => {
              return managerCheck("Manage", submenulist);
            })
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    });
  };
  useEffect(() => {
    let pathArray = location.pathname;
    for (var i = 0; i < config.length; i++) {
      var check = pathArray.includes(config[i].path);
      if (check && config[i].path != "/vault") {
        setName(config[i].name);
        break;
      } else if (check && config[i].path == "/vault") {
        for (var j = 0; j < config[i].sub.length; j++) {
          var subCheck = pathArray.includes(config[i].sub[j].path);
          if (subCheck) {
            setsubMenuOpen(true);
            setName(config[i].sub[j].name);
            break;
          } else {
            setName(config[i].name);
            setsubMenuOpen(false);
          }
        }
      }
    }
  }, []);
  useEffect(() => {
    let pathArray = location.pathname;
    for (var i = 0; i < config.length; i++) {
      var check = pathArray.includes(config[i].path);
      if (check && config[i].path != "/vault") {
        setName(config[i].name);
        setsubMenuOpen(false);
        break;
      } else if (check && config[i].path == "/vault") {
        for (var j = 0; j < config[i].sub.length; j++) {
          var subCheck = pathArray.includes(
            config[i].sub[j].name.toLowerCase()
          );
          if (subCheck) {
            setsubMenuOpen(true);
            setName(config[i].sub[j].name);
            break;
          } else {
            setName(config[i].name);
            setsubMenuOpen(false);
          }
        }
      }
    }
  }, [location.pathname]);
  useEffect(() => {
    getAllAdminPromise();
  }, [vaultAddress, userAddress]);
  useEffect(() => {
    if (userAddress) var userAddressLowerCase = userAddress?.toLowerCase();
    var bool = allowedAddress.includes(userAddressLowerCase);
    //Change setFound accordingly
    setFound(bool);
  }, [allowedAddress, userAddress]);

  return (
    <React.Fragment>
      <div className={classes.logo}>
        <Logo />
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.sidebarWrapper}
      >
        <Divider />
        <SideBarListDisplay />
      </List>
      <SafeDetails />
    </React.Fragment>
  );
}

export default Sidebar;
