export const state1 = {
  vaultAddress: "",
  strategyManager:"",
  whitelistManager:"",
  apsManager:""
};

const chainReducer = (state = state1, action) => {
  switch (action.type) {
    case "CHAIN_VAULT_ADDRESS":
      return {
        ...state,
        vaultAddress: action.payload,
      };
    case "CHAIN_STRATEGY_MANAGER":
      return {
        ...state,
        strategyManager: action.payload,
      };
    case "CHAIN_WHITELIST_MANAGER":
      return {
        ...state,
        whitelistManager: action.payload,
      };

    case "CHAIN_APS_MANAGER":
      return {
        ...state,
        apsManager: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chainReducer;
