export const VaultCreationStatus = {
  VAULT_PROXY_CREATION: "VAULT_PROXY_CREATION",
  WHITELIST_CREATION: "WHITELIST_CREATION",
  WHITELIST_GROUP_ADD: "WHITELIST_GROUP_ADD",
  REGISTER_WITH_APS: "REGISTER_WITH_APS",
  SET_VAULT_ASSETS: "SET_VAULT_ASSETS",
  SET_TOKEN_DETAIlS: "SET_TOKEN_DETAIlS",
  SET_VAULT_BENEFICIARY: "SET_VAULT_BENEFICIARY",
  SET_MANAGEMENT_FEE_BENEFICIARY_AND_PERCENTAGE: "SET_MANAGEMENT_FEE_BENEFICIARY_AND_PERCENTAGE",
  SET_LOCKED_WITHDRAW:"SET_LOCKED_WITHDRAW",
  COMPLETED: "COMPLETED",
};