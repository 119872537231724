import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import DepositModal from "../../Modals/Deposit_Modal_Folder/depositModal";
import WithdrawModal from "../../Modals/Deposit_Modal_Folder/Withdraw_Modal_Folder/withdrawModal";
import "./Card_Styling/MyInvestmentStyle.css";
import Web3 from "web3";
import { getGnosisSafeInstanceAt } from "../../../Asset-Details/TableFunctions";
import { tokenBalance } from "../../../../redux/actions/token-balance";
import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import CustomTable from "../../Custom_Table/customTable";
import { MyBalanceInstance } from "../../../Asset-Details/TableFunctions";
import { MyBalance } from "../../../../redux/actions/MyBalance";
import { useLocation } from "react-router-dom";
import { Vaults, WithdrawQueue } from "../../../../services/api";
import usePolling from "../../../../hooks/usePolling";
import { message } from "../../../../utils/message";
import WrapperFunction from "../../../Global-Functions/WrapperFunction";
import {
  GasRequired,
  GasPriceRequired,
  NonceRequired,
} from "../../../../redux/actions/ParameterArray";

const BasicTable = forwardRef((props, ref) => {
  // export default function BasicTable(props) {
  const childRef = useRef();
  const withDrawRef = useRef();
  const location = useLocation();
  const { loadingUniverse, loadingSkeleton } = props;
  const connectionCheck =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const userAddress = localStorage.getItem("accountAddress");
  // const userAddress = "0xe140e91e48b0fab5a36bda64870213eaf202e4e4";
  const isDepositWithdraw = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.randomNumber
  );
  const transactionInstance = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const web3 = useSelector((state) => state.get_web3.web3);
  const userTokenBalance = useSelector(
    (state) => state.get_token_balance.tokenBalance
  );
  const dispatch = useDispatch();
  const [token, setToken] = useState(0);
  const [tokeninUsd, setTokenInUsd] = useState(0);
  const [userNAVGain, setUserNAVGain] = useStateWithCallback(0);
  const [apr, setAPR] = useState(0);
  const [depositAndWithdraw, setDepositAndWithdraw] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useStateWithCallback("");
  const [emergencyBreak, setEmergencyBreak] = useState(false);
  const [emergencyExit, setEmergencyExit] = useState(false);
  const [firstAPI, setFirstAPI] = useState(false);
  const [secondAPI, setSecondAPI] = useState(false);
  const [encodedData, setEncodedData] = useState(null);
  const [investmentsDetails, setInvestmentsDetails] = useState({
    profit: 0,
    profitPercentage: 0,
    currentValue: 0,
    baseCurrency: "USD",
  });
  const [userNavDetails, setUserNavDetails] = useState();
  const [withdrawQueueHash, setWithdrawQueueHash] = React.useState(null);
  // const [withdrawQueueData, setWithdrawQueueData] = React.useState();
  const myBalanceRedux = useSelector((state) => state.get_mybalance.myBalance);
  const nav_of_vault = useSelector((state) => state.get_token_value);
  const vaultAddress = location.pathname.split("/")[2];

  const executeDepositStatement = () => {
    childRef.current.handleOpen();
  };

  const executeWithdrawStatement = () => {
    withDrawRef.current.handleOpen();
  };

  useImperativeHandle(ref, () => ({
    handleDepositViaWallet: () => {
      if (connectionCheck !== "" && connectionCheck !== null) {
        executeDepositStatement();
      }
    },
    handleWithdrawViaWallet: () => {
      if (connectionCheck !== "" && connectionCheck !== null) {
        executeWithdrawStatement();
      }
    },
  }));

  const setLoadingUniverse = (response) => {
    props.setLoadingUniverse(response);
  };

  const getBalanceStats = () => {
    if (vaultAddress && userAddress) {
      Vaults.getUserInvestmentDetails(vaultAddress, userAddress)
        .then((res) => {
          setInvestmentsDetails((prev) => ({ ...prev, ...res.data?.data }));
        })
        .catch((err) => {
          console.log({ err });
        });
      Vaults.userAndNavDetailsOfVaultToken(userAddress, vaultAddress).then(
        (res) => {
          setUserNavDetails(res.data?.data);
        }
      );
    }
  };

  // useInterval(async () => {
  //   if (userAddress && connectionCheck) {
  //     await axios
  //       .get(VaultNAVandGain(connectionCheck, userAddress))
  //       .then((res) => {})
  //       .catch((err) => {});
  //   }
  // }, 30000);

  useEffect(() => {
    if (!emergencyExit && !emergencyBreak && depositAndWithdraw) {
      props.showDepositWithdrawButton(true);
    } else {
      props.showDepositWithdrawButton(false);
    }
  }, [emergencyExit, emergencyBreak, depositAndWithdraw]);

  useEffect(() => {
    const asyncFn = async () => {
      if (web3 && connectionCheck && userAddress) {
        const ERC20Instance = await MyBalanceInstance(web3, connectionCheck);
        const myBalance = await ERC20Instance.methods
          .balanceOf(userAddress)
          .call();
        if (nav_of_vault.tokenValue) {
          dispatch(
            MyBalance(
              parseFloat(web3.utils.fromWei(myBalance, "ether")) *
                parseFloat(nav_of_vault.tokenValue)
            )
          );
        } else {
          dispatch(
            MyBalance(parseFloat(web3.utils.fromWei(myBalance, "ether")))
          );
        }

        // setMyBalance(web3.utils.fromWei(myBalance,'ether'))
      }
    };
    asyncFn();
  }, [isDepositWithdraw, web3, connectionCheck, userAddress, nav_of_vault]);

  // useInterval(async () => {
  //   await axios
  //     .get(VaultNAVandGain(connectionCheck, userAddress))
  //     .then((res) => {
  //       setTokenInUsd(res.data.data.currentValue / 10 ** 18);
  //       setUserNAVGain(res.data.data.profit);
  //       setAPR(res.data.data.profitPercentage);
  //     })
  //     .catch((err) => {});
  // }, 5000);

  useEffect(() => {
    if (firstAPI && secondAPI) {
      props.isInvestmentLoading(true);
    }
  }, [firstAPI, secondAPI]);

  useEffect(() => {
    const asyncFn = async () => {
      setFirstAPI(false);
      setSecondAPI(false);
      props.isInvestmentLoading(false);
      if (userAddress && connectionCheck && web3) {
        const GnosisReturned = await getGnosisSafeInstanceAt(
          connectionCheck,
          web3
        );

        const balance = await GnosisReturned.methods
          .balanceOf(userAddress)
          .call({ from: userAddress });

        setToken(balance);
        dispatch({
          type: "SET_TOKEN_COUNT",
          payload: balance,
        });

        // await axios
        //   .get(VaultNAVandGain(connectionCheck, userAddress))
        //   .then((res) => {
        //     setTokenInUsd(res.data.data.currentValue / 10 ** 18);
        //     setUserNAVGain(res.data.data.profit, () => {
        //       setFirstAPI(true);
        //     });
        //     setAPR(res.data.data.profitPercentage);
        //   })
        //   .catch((err) => {});
      }
    };
    asyncFn();
  }, [userAddress, connectionCheck, isDepositWithdraw]);

  useEffect(() => {
    const asyncFn = async () => {
      if (
        encodedData != "" &&
        encodedData &&
        web3 &&
        userAddress &&
        vaultAddress
      ) {
        web3.eth
          .estimateGas({
            from: userAddress,
            to: vaultAddress,
            data: encodedData,
          })
          .then((res) => {
            if (res != undefined && res != NaN) {
              // setGasRequired(res);
              dispatch(GasRequired(res));
            } else {
              // setGasRequired(0);
              dispatch(GasRequired(0));
            }
          })
          .catch((err) => {
            // setGasRequired(716071);
            dispatch(GasRequired(716071));
          });

        web3.eth.getGasPrice().then((res) => {
          if (res != undefined && res != NaN) {
            // setGasPrice(res);
            dispatch(GasPriceRequired(res));
          } else {
            // setGasPrice(0);
            dispatch(GasPriceRequired(0));
          }
        });
        web3.eth.getTransactionCount(userAddress, (err, nonce) => {
          dispatch(NonceRequired(nonce));
        });
      }
    };
    asyncFn();
  }, [encodedData]);

  const getWithdrawQueueStatus = async () => {
    try {
      const withdrawQueueStatus = await WithdrawQueue.getWithdrawQueueById(
        withdrawQueueHash
      );
      if (withdrawQueueStatus?.data?.data?.status == "READY_FOR_WITHDRAWAL") {
        message.warning(
          "Your withdraw request is ready to be executed .Please confirm the transaction in your wallet.",
          50
        );

        const GnosisReturned = await getGnosisSafeInstanceAt(
          withdrawQueueStatus?.data?.data?.vaultAddress,
          web3
        );
        const withdrawEncodedData = await GnosisReturned.methods
          .withdraw(
            withdrawQueueStatus?.data?.data?.toTokenAddress,
            withdrawQueueStatus?.data?.data?.requiredAmount
          )
          .encodeABI();
        setEncodedData(withdrawEncodedData);
        const transactionData = {
          contractAddress: withdrawQueueStatus?.data?.data?.vaultAddress,
          txType: "WITHDRAW",
          signer: withdrawQueueStatus?.data?.data?.userAddress,
          txData: withdrawEncodedData,
          nonce: 0,
          parameters: {
            _tokenAddress: withdrawQueueStatus?.data?.data?.toTokenAddress,
            _shares: withdrawQueueStatus?.data?.data?.requiredAmount,
            hash: withdrawQueueHash,
          },
        };
        dispatch(WrapperFunction(transactionData, "POST"));

        setWithdrawQueueHash(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // /showDepositWithdrawButton={showDepositWithdrawButton}
  let withdrawQueueHashResponse;
  usePolling(async () => {
    if (!withdrawQueueHash) {
      withdrawQueueHashResponse = await getWithdrawStatusByUserAddress();
    }
    if (withdrawQueueHash) getWithdrawQueueStatus();
  }, 120000);
  //Function to get the withdraw queue status by user address
  const getWithdrawStatusByUserAddress = async () => {
    try {
      const withdrawStatusByUser = await WithdrawQueue.getWithdrawQueueByUser(
        userAddress,
        vaultAddress
      );
      if (
        withdrawStatusByUser.data?.data?.requestId &&
        withdrawStatusByUser.data?.data != null
      ) {
        setWithdrawQueueHash(withdrawStatusByUser.data?.data?.requestId);
        return true;
      } else {
        setWithdrawQueueHash(null);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const weiValue = Web3.utils.fromWei(token.toString(), "ether");
    dispatch(tokenBalance(weiValue));
  }, [token]);

  useEffect(() => {
    getBalanceStats();
    getWithdrawStatusByUserAddress();
  }, []);

  useEffect(() => {
    if (isDepositWithdraw || transactionInstance) getBalanceStats();
  }, [isDepositWithdraw, transactionInstance]);

  return (
    <>
      <div className="investment-button-and-table-gap">
        <CustomTable
          tableId={1}
          userTokenBalance={userNavDetails?.userBalance}
          tokenSymbol={tokenSymbol}
          value_1={investmentsDetails?.currentValue}
          value_2={investmentsDetails?.profit}
          value_3={investmentsDetails?.profitPercentage}
          value_4={0}
          baseCurrency={investmentsDetails?.baseCurrency}
          loadingSkeleton={loadingSkeleton}
        />
      </div>
      <DepositModal ref={childRef} setLoadingUniverse={setLoadingUniverse} />
      <WithdrawModal
        ref={withDrawRef}
        setLoadingUniverse={setLoadingUniverse}
        setWithdrawQueueHash={setWithdrawQueueHash}
      />
    </>
  );
});

export default BasicTable;
