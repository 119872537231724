const initState = {
    value: null,
  
};

const setVaultCreationStatus = (state=initState,action) => {
    switch(action.type) {
        case "SET_VAULT_CREATION_STATUS":
            return { 
                ...state,
                value: action.payload.value,
            }

        default: 
            return state;
    }
}

export default setVaultCreationStatus;