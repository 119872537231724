export const totalSupply = (val) => async(dispatch) => {
      
    dispatch({
      type: "SET_TOTAL_SUPPLY",
      payload: {
        totalSupply: val
      }
    })
  }

