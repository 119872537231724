const initState = {
  randomNumber: 0,
  transactionInstance: 0,
};
const DepositWithdrawInstance = (state = initState, action) => {
  switch (action.type) {
    case "DEPOSIT_WITHDRAW_INSTANCE":
      return {
        ...state,
        randomNumber: action.payload.randomNumber,
      };

    case "TRANSACTION_INSTANCE":
      return {
        ...state,
        transactionInstance: action.payload.transactionInstance,
        transactionHash: action.payload.transactionHash,
      };

    default:
      return state;
  }
};

export default DepositWithdrawInstance;
