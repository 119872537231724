import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDrop } from "react-dnd";
import TagContainer from "../TagContainer/TagContainer";
import "./Style/index.css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

function CardItem(props) {
  const [i, drop] = useDrop({
    accept: props.accept,
    drop: () => ({ target: props.id }),
    canDrop: (item) => {
      return item.source !== props.id;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });

  const displayData = (value) => {
    switch (value[0]?.type) {
      case "deposit":
        return value[0]?.name?.slice(0, 5);
      case "withdraw":
        return value[0]?.name?.slice(0, 5);
      default:
        return "";
    }
  };

  const handleTextChange = (e) => {
    let temp = { id: e.target.id, value: e.target.value };
    props.setField(temp);
  };

  const handleNumberChange = (e) => {
    if (e.key === "." || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    } else {
      let value = e.target.value == 0 ? "" : e.target.value;
      let temp = { id: e.target.id, value: value };
      props.setField(temp);
    }
  };

  const removeItem = (address, type) => {
    props.removeItemsFromCart(address, type);
  };

  return (
    <div className="content-items" ref={drop} id={props.id}>
      {props.type ? (
        <>
          <div className="input-add">
            <input
              value={props.fields}
              type={props.type}
              id={props.id}
              min={
                props.id === "noOfDaysForLockedWithdraw" ||
                props.id == "noOfDays"
                  ? 1
                  : 0
              }
              disabled={props.disabled}
              onChange={(e) => {
                props.id === "noOfDaysForLockedWithdraw" ||
                props.id == "noOfDays"
                  ? handleNumberChange(e)
                  : handleTextChange(e);
              }}
              onKeyDown={(e) => {
                if (
                  props.id == "noOfDaysForLockedWithdraw" ||
                  props.id == "noOfDays"
                )
                  handleNumberChange(e);
              }}
              onBlur={handleTextChange}
              placeholder={props.placeholder}
              {...(props.type === "number"
                ? {
                    min:
                      props.id === "noOfDaysForLockedWithdraw" ||
                      props.id == "noOfDays"
                        ? 1
                        : 0,
                  }
                : {})}
            />
          </div>
          {props.isValidated.supportCard[props.id] === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          {props.isValidated.supportCard[props.id] === "invalid-address" && (
            <span className="validation-required">*Invalid address</span>
          )}
          {props.tooltipText ? (
            <div className="text">
              {props.text}
              <Tooltip title={props.tooltipText}>
                <QuestionCircleOutlined
                  style={{
                    marginLeft: "5px",
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="add">
            {props.fields && props.fields.length ? (
              props.fields.map((field, index) => (
                <TagContainer
                  data={displayData(field)}
                  address={field[0]?.strategyAddress}
                  type={field[0]?.type}
                  removeItems={removeItem}
                  tagIcon={field[0]?.strategyImgURL}
                />
              ))
            ) : (
              <p className="tag-placeholder">{`Drag a ${props.text} Strategy here`}</p>
            )}
          </div>
          {props.isValidated.supportCard[props.id] === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          <div className="text">{props.text}</div>
        </>
      )}
    </div>
  );
}

export default CardItem;
