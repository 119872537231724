const initState = {
    currentRoute: ""
};

const CurrentRouteReducer = (state=initState,action) => {
    switch(action.type) {
        case "CURRENT_ROUTE_STATE":
            return { 
                ...state,
                currentRoute: action.payload.currentRoute,
            }

        default: 
            return state;
    }
}

export default CurrentRouteReducer;