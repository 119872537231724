import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {  makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AlertDialog from "./AlertModal";
import FormDialog from "./ChangeModal";
import { CustomDialog } from "react-st-modal";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { useSelector,useDispatch } from "react-redux";
import { getConfig, getVault } from "../../api";
import axios from "axios";
import { Divider } from "@material-ui/core";
import addIcon from '../../../assets/Manage/Whitelist/AddButton.svg'
import '../SafeDetails/WhiteList_Manager/addButton'
import CustomLoader from '../../Custom_Loader/customLoader'
import {getBackendData} from '../../Asset-Details/TableFunctions'
import SDKMOdal from '../../SDKModal'

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: " 24px",
    color: "#1B3E69",
    fontWeight: "bold",
    marginTop:"0px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"20px",
    },
  },
  contentSmall: {
    fontSize: "14px",
    textAlign: "justify",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  smallHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginTop: "40px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"13px",
    },
  },
  godContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    paddingLeft: "20px",
    fontSize: "11px",
    lineHeight: "20px",
    fontWeight: "bold",
    marginTop: "10px",
  },
  contentAddress: {
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "5px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  godAddress: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  address: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('1641')]:{
      height: "32px",
   },
  },
  break: {
    justifyContent: "space-between",
  },
  containerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop:"10px"
  },
  innerContent: {
    width: "80%",
    textAlign: "justify",
    fontSize:"14px",
    color:"#6D7681",
    marginBottom: "20px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  buttonContainer: {
    width: "20%",

    [theme.breakpoints.up(900)]: {
      width: "30%",
    },
    [theme.breakpoints.up(1165)]: {
      width: "20%",
    },
  },
  button: {
    marginBottom: "20px",
    float: "right",
    backgroundColor: "#C42121",
    color:"white",
    boxShadow:"none",
    fontSize:"14px",
    "&:hover":{
      backgroundColor: "#C42121",
    color:"white",
    boxShadow:"none",
    transform:"scale(1.05)"
    },
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  buttonNew: {
    marginRight: "-15px",
    background: "transparent",
    textDecoration: "underline",
    textTransform: "capitalize",
    boxShadow: "none",
    color: "#1B3E69",
    fontSize:"14px",fontWeight:"bold",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      transform: "scale(1.01)",
      background: "transparent",
      textDecoration: "underline",
      textTransform: "capitalize",
      boxShadow: "none",
      color: "#1B3E69",
      fontSize:"14px",fontWeight:"bold",
      fontFamily: "'Open Sans', sans-serif",
    },
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  modalTop:{
    bcakground:"#1B3E69",
    color:"white"
  },
  addressFont: {
    fontSize: "14px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
}));

export default function God(props) {
  const classes = useStyles();
  const defaultValue = useSelector((state) => state.get_vault_address);
  const [godAddress, setGodAddress] = React.useState();
  const [emrgencyBreak, setEmergencyBreak] = React.useState(false);
  const [change, setChange] = React.useState(true);
  const web3 = useSelector((state) => state.get_web3.web3);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const history = useHistory();
  const [loader, setLoader] = React.useState(true);
  const dispatch = useDispatch()
  const [abiAddress,setAbiAddress]=React.useState()

  useEffect(() => {
    const asyncFn = async () => {
      setAbiAddress(await getBackendData());
    };
    asyncFn();
  }, []);

  useEffect(() => {
    axios.get(getConfig()).then((data) => {
      if (data.data.data.length) {
        setGodAddress(data.data.data[0].GODAddress);
      }
    });
    if (defaultValue.vaultAddress != "")
      axios.get(getVault(defaultValue.vaultAddress)).then((data) => {
        if (data.data.data)
          setEmergencyBreak(data.data.data.emergencyBreak);
      });
  }, [change, defaultValue.vaultAddress, userAddress]);

  useEffect(() => {
    if (godAddress != undefined && userAddress != null) {
      if (godAddress.toUpperCase() != userAddress.toUpperCase())
        history.push("/not-authorized");
    }
    if (godAddress != undefined && godAddress != "" && abiAddress) setLoader(false);
  }, [defaultValue.vaultAddress, godAddress, userAddress,abiAddress]);

  return (
    <>
      {loader ? (
        <div style={{ padding: "10px" }}>
        <h1 className={classes.heading}>God Role</h1>
        <div style={{marginTop:"20vh"}}><CustomLoader /></div>
        </div>
      ) : (
        <div style={{ padding: "10px" }}>
          {godAddress != undefined && userAddress != null ? (
            godAddress.toUpperCase() == userAddress.toUpperCase() ? (
              <>
                <h1 className={classes.heading}>God Role</h1>
                <p className={classes.contentSmall}>
                Serving as an additional line of defense, the god role is responsible for monitoring the vault and taking action in case of an emergency.
                </p>
                <p className={classes.smallHeading}>GOD</p>
                <div className={classes.godContainer}>
                  <div>
                    <div className={classes.godAddress}>
                      <p className={classes.contentAddress}>Address</p>
                      { godAddress.toUpperCase() == userAddress.toUpperCase()?
                      <p className={classes.contentAddress}>Action</p>:""}
                    </div>
                    <Divider />
                    <div className={classes.address}>
                      <p className={classes.addressFont}>{godAddress}</p>
                      { godAddress.toUpperCase() == userAddress.toUpperCase() ?
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonNew}
                        disabled={
                          godAddress.toUpperCase() != userAddress.toUpperCase()
                        }
                        onClick={async () => {
                          const result = await CustomDialog(
                            <MuiThemeProvider theme={theme}>
                              <FormDialog
                                change={change}
                                setChange={setChange}
                                vaultAddress={defaultValue.vaultAddress}
                                web3={web3}
                                heading="Change God"
                                dispatch={dispatch}
                                abiAddress={abiAddress}
                              />
                            </MuiThemeProvider>,
                            {}
                          );
                        }}
                      >
                        Edit
                      </Button>:""}
                    </div>
                    <Divider />
                    <div style={{display:"flex",justifyContent:"flex-end",marginTop:"10px"}}>
                    {/* <button className="custom-add-button-styling"><img src={addIcon}></img><p>ADD</p></button> */}

                    </div>


                  </div>

                  {emrgencyBreak == false ? (
                    <div className={classes.break}>
                      <p className={classes.smallHeading}>EMERGENCY BREAK</p>
                      <div className={classes.containerContent}>
                        <div className={classes.innerContent}>
                        In the case of an ongoing attack the emergency break function halts all withdrawal and deposit of funds from the vault.{" "}
                        </div>
                        <div className={classes.buttonContainer}>
                          {godAddress.toUpperCase() ==
                              userAddress.toUpperCase()?
                          <Button
                            variant="contained"
                            className={classes.button}
                            disabled={
                              godAddress.toUpperCase() !=
                              userAddress.toUpperCase()
                            }
                            onClick={async () => {
                              const result = await CustomDialog(
                                <MuiThemeProvider theme={theme}>
                                  <AlertDialog
                                    type="break"
                                    heading="Emergency Break"
                                    description="This process will disable all interactions with the contract for everyone except god. Are you sure ?"
                                    vaultAddress={defaultValue.vaultAddress}
                                    change={change}
                                    setChange={setChange}
                                    web3={web3}
                                    breakChange={props.breakChange} 
                                    setBreakChange={props.setBreakChange}
                                    dispatch={dispatch}
                                  />{" "}
                                </MuiThemeProvider>,
                                {}
                              );
                            }}
                          >
                            {" "}
                            BREAK
                          </Button>:""}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.break}>
                      <p className={classes.smallHeading}>EMERGENCY BREAK</p>
                      <div className={classes.containerContent}>
                        <div className={classes.innerContent}>
                        In the case of an ongoing attack the emergency break function halts all withdrawal and deposit of funds from the vault. {" "}
                        </div>
                        <div className={classes.buttonContainer}>
                        {godAddress.toUpperCase() ==
                              userAddress.toUpperCase()?
                          <Button
                            variant="contained"
                            className={classes.button}
                            disabled={
                              godAddress.toUpperCase() !=
                              userAddress.toUpperCase()
                            }
                            onClick={async () => {
                              const result = await CustomDialog(
                                <MuiThemeProvider theme={theme}>
                                  <AlertDialog
                                    type="start"
                                    heading="Emergency Break"
                                    description="This process will enable all interactions with the contract for everyone . Are you sure ?"
                                    vaultAddress={defaultValue.vaultAddress}
                                    change={change}
                                    setChange={setChange}
                                    web3={web3}
                                    breakChange={props.breakChange} 
                                    setBreakChange={props.setBreakChange}
                                    dispatch={dispatch}
                                  />{" "}
                                </MuiThemeProvider>,
                                {}
                              );
                            }}
                          >
                            {" "}
                            START
                          </Button>:""}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={classes.break}>
                    <p className={classes.smallHeading}>EMERGENCY EXIT</p>
                    <div className={classes.containerContent}>
                      <div className={classes.innerContent}>
                      In the case of an ongoing attack the emergency exit function withdraws all funds into a gnosis safe controlled by the strategy manager. {" "}
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          disabled={
                            godAddress.toUpperCase() !=
                            userAddress.toUpperCase()
                          }
                          onClick={async () => {
                            const result = await CustomDialog(
                              <MuiThemeProvider theme={theme}>
                                <AlertDialog
                                  type="exit"
                                  heading="Emergency Exit"
                                  description=" This process will disable all interactions with the contract for everyone and 
                        immediately move all safe assets to Yieldcube emregency vault
                     . Once enabled you cannot change it. Are you sure ?
                     "
                                  vaultAddress={defaultValue.vaultAddress}
                                  web3={web3}
                                  dispatch={dispatch}
                                />{" "}
                              </MuiThemeProvider>,
                              {}
                            );
                          }}
                        >
                          {" "}
                          EXIT
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      )}     
    </>
  );
}
