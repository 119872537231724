const initState = {
    whitelistDetails: "",
};

const setWhitelistDetails = (state=initState,action) => {
    switch(action.type) {
        case "GET_WHITELIST_DETAILS":
            return { 
                ...state,
                whitelistDetails: action.payload.whitelistDetails
            }

        default: 
            return state;
    }
}

export default setWhitelistDetails;