const initState = {
    web3:"",
  
};

const getWeb3Reducer = (state=initState,action) => {
    switch(action.type) {
        case "SET_WEB3":
            return { 
                ...state,
                web3: action.payload.web3,
            }
          

        default: 
            return state;
    }
}

export default getWeb3Reducer;