import React from "react";
import "./index.css";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import OfficialLogo from "../../../assets/Logos/OfficialLogo.svg";
import { Divider } from "@material-ui/core";

function RegistrationLogin({ setToken }) {
  return (
    <div className="registration-login-wrap">
      <img className="logo" src={OfficialLogo} />
      <div className="registration-login-wrap-content-wrap">
        <SignUp />
        <Divider orientation="vertical" light={true}  />
        <Login setToken={setToken} />
      </div>
    </div>
  );
}

export default RegistrationLogin;
