import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./Deposit_Styling/deposit.css";
import InputTextBox from "../Deposit_Modal_Folder/Naked_Input/inputBox";
import Web3 from "web3";
import ERC20Token from "../../../../contractABI/ERC20.json";
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useSendTransaction,
  erc20ABI,
  useContractRead,
} from "wagmi";
import {
  getContract,
  getWalletClient,
  prepareWriteContract,
  readContract,
  writeContract,
} from "@wagmi/core";
import {
  getERC20InstanceAt,
  _getTokenBalance,
  getGnosisSafeInstanceAt,
  getTokenDecimalPrecision,
} from "../../../Asset-Details/TableFunctions";
import { useSelector, useDispatch } from "react-redux";
import LoaderAnimation from "../../../LoaderAnimation/LoaderModal";
import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import DropDown from "./DropDown/dropDown";
import GasPriceInput from "./Naked_Input/gasPriceInput";
import { getTokenPriceInUSD } from "../../../Asset-Details/TableFunctions";
import { TransactionInProgress } from "../../../../redux/actions/reload-state";
import WrapperFunction from "../../../Global-Functions/WrapperFunction";
import {
  GasRequired,
  GasPriceRequired,
  NonceRequired,
} from "../../../../redux/actions/ParameterArray";
import { RPC_URL } from "../../../../utils/consts";
import { wagmiConfig, walletClient } from "../../../../utils/web3/config";
import { convertToWei } from "../../../../utils/helperFunctions";
import usePolling from "../../../../hooks/usePolling";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const depositModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [assetBalance, setAssetBalance] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [assetName, setAssetName] = React.useState("");
  const [assetAddress, setAssetAddress] = useStateWithCallback("");
  const [shareAmount, setShareAmount] = React.useState(0);
  const [isDropDown, setIsDropDown] = React.useState(false);
  const [valueSizeCheck, setValueSizeCheck] = React.useState(1);
  const [precisionValue, setPrecisionValue] = useStateWithCallback(18);
  const web3 = useSelector((state) => state.get_web3.web3);
  const user_address = useSelector((state) => state.get_user_address.address);
  const vault_address = useSelector((state) => state.get_vault_address);
  const [usdPrice, setUSDPrice] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [approveConfig, setApproveConfig] = React.useState();
  const [weiAmount, setWeiAmount] = useStateWithCallback(0);
  const [approveOrDeposit, setApproveOrDeposit] = React.useState("approve");
  const total_supply = useSelector(
    (state) => state.get_total_supply.totalSupply
  );
  const vaultNAV = useSelector((state) => state.get_token_value.navValue);
  const wallet = useSelector((state) => state.get_wallet_state.wallet);

  const [gasPrice, setGasPrice] = React.useState(0);
  const [vaultAlloc, setVaultAlloc] = React.useState(0);
  const [encodedData, setEncodedData] = useStateWithCallback("");
  const [gasRequired, setGasRequired] = React.useState(0);
  const [inputGasPrice, setInputGasPrice] = React.useState(0);
  const [inputGasEstimate, setInputGasEstimate] = React.useState(0);
  const [nonceValue, setNonce] = React.useState(0);
  const [toggler, setToggler] = React.useState(0);
  let networkId;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GasPriceRequired(parseInt(inputGasPrice)));
  }, [inputGasPrice]);

  useEffect(() => {
    dispatch(GasRequired(inputGasEstimate));
  }, [inputGasEstimate]);

  useEffect(() => {
    dispatch(NonceRequired(nonceValue));
  }, [nonceValue]);

  const handleClose = () => {
    setAmount("");
    setShareAmount(0);
    setApproveOrDeposit("approve");
    setWeiAmount(0);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen: () => {
      setOpen(true);
      setToggler(Math.random() * 1000);
    },

    handleClose: () => {
      handleClose();
    },
  }));

  const dropDownSelect = async (res) => {
    networkId = await web3.eth.net.getId();
    if (res) {
      setAssetName(res.assetName);
      setAssetAddress(res.assetAddress, () => {
        getDepositNAV(res.assetAddress).then((res) => {
          setUSDPrice(Web3.utils.fromWei(res, "ether"));
        });
      });

      const ERC20Instance = await getERC20InstanceAt(res.assetAddress, web3);
      const Precision = await getTokenDecimalPrecision(res.assetAddress, web3);
      if (wallet?.name == "WalletConnect") {
        readContract({
          abi: erc20ABI,
          functionName: "decimals",
          address: res.assetAddress,
          account: user_address,
        })
          .then((decimals) => {
            setPrecisionValue(decimals, () => {
              ERC20Instance.methods
                .balanceOf(user_address)
                .call()
                .then((result) => {
                  displayBalanceCheck(result, decimals);
                })
                .catch(console.log);
            });
          })
          .catch((err) => {
            console.error("failed to load token decimal places", err);
            setPrecisionValue(18);
            setAssetBalance("");
          });
      } else {
        Precision.methods
          .decimals()
          .call()
          .then((res) => {
            setPrecisionValue(res, () => {
              ERC20Instance.methods
                .balanceOf(user_address)
                .call()
                .then((result) => {
                  displayBalanceCheck(result, res);
                })
                .catch(console.log);
            });
          });
      }
      setIsDropDown(true);
    }
  };

  const displayBalanceCheck = (real_balance, precisionValue) => {
    let weiValue;
    switch (precisionValue) {
      case "1":
        weiValue = Web3.utils.fromWei(real_balance, "wei");
        setAssetBalance(weiValue);
        break;

      // case "2":
      //   weiValue = (parseFloat(real_balance)/10**2).toString();
      //   setAssetBalance(weiValue);
      //   break;

      case "4":
        weiValue = Web3.utils.fromWei(real_balance, "kwei");
        setAssetBalance(weiValue);
        break;

      case "6":
        weiValue = Web3.utils.fromWei(real_balance, "mwei");
        setAssetBalance(weiValue);
        break;

      case "9":
        weiValue = Web3.utils.fromWei(real_balance, "gwei");
        setAssetBalance(weiValue);
        break;

      case "12":
        weiValue = Web3.utils.fromWei(real_balance, "szabo");
        setAssetBalance(weiValue);
        break;

      case "15":
        weiValue = Web3.utils.fromWei(real_balance, "finney");
        setAssetBalance(weiValue);
        break;

      case "18":
        weiValue = Web3.utils.fromWei(real_balance, "ether");
        setAssetBalance(weiValue);
        break;

      case "21":
        weiValue = Web3.utils.fromWei(real_balance, "kether");
        setAssetBalance(weiValue);
        break;

      case "24":
        weiValue = Web3.utils.fromWei(real_balance, "mether");
        setAssetBalance(weiValue);
        break;

      case "27":
        weiValue = Web3.utils.fromWei(real_balance, "gether");
        setAssetBalance(weiValue);
        break;

      case "30":
        weiValue = Web3.utils.fromWei(real_balance, "tether");
        setAssetBalance(weiValue);
        break;

      default:
        weiValue = (
          parseFloat(real_balance) /
          10 ** parseInt(precisionValue)
        ).toString();
        setAssetBalance(weiValue);
        break;
    }
  };

  useEffect(() => {
    if (usdPrice != "") {
      if (total_supply > 0) {
        setVaultAlloc((usdPrice * amount * total_supply) / vaultNAV);
      } else if (total_supply == 0) {
        if (amount > 0) {
          setVaultAlloc(parseFloat(amount));
        } else {
          setVaultAlloc(0);
        }
      }
    }
  }, [usdPrice, amount, total_supply]);

  useEffect(() => {
    let str_amount = amount.split(".");
    let str_assetBalance = assetBalance.split(".");
    if (parseFloat(amount) > parseFloat(assetBalance)) {
      setValueSizeCheck(0);
    } else {
      if (str_amount[0] == str_assetBalance[0]) {
        if (str_amount[1] > str_assetBalance[1]) {
          setValueSizeCheck(0);
        } else {
          setValueSizeCheck(1);
        }
      } else {
        setValueSizeCheck(1);
      }
    }
  }, [amount, assetBalance]);

  const fetchApprovedData = (shareAmount) => {
    readContract({
      abi: erc20ABI,
      functionName: "decimals",
      address: assetAddress,
      account: user_address,
    }).then((decimals) => {
      if (decimals) {
        let weiValue = convertToWei(decimals + "", shareAmount);
        setWeiAmount(weiValue, () => {
          readContract({
            abi: erc20ABI,
            functionName: "allowance",
            address: assetAddress,
            account: user_address,
            args: [user_address, vault_address.vaultAddress],
          }).then((allowanceAmount) => {
            if (parseFloat(allowanceAmount) >= parseFloat(weiValue)) {
              setApproveOrDeposit("deposit");
            } else {
              setApproveOrDeposit("approve");
            }
          });
        });
      }
    });
  };

  usePolling(async () => {
    if (
      wallet?.name === "WalletConnect" &&
      assetAddress &&
      user_address &&
      shareAmount &&
      approveOrDeposit === "approve"
    ) {
      fetchApprovedData(shareAmount);
    }
  }, 5000);

  useEffect(() => {
    const asyncFn = async () => {
      if (web3 && user_address) {
        web3.eth.getGasPrice().then((res) => {
          if (res != undefined && res != NaN) {
            setGasPrice(res);
            setInputGasPrice(res);
          } else {
            setGasPrice(0);
            setInputGasPrice(0);
          }
        });
        web3.eth.getTransactionCount(user_address, (err, nonce) => {
          setNonce(nonce);
        });
      }
      if (web3 && weiAmount != "" && weiAmount != 0) {
        const GnosisReturned = await getGnosisSafeInstanceAt(
          vault_address.vaultAddress,
          web3
        );
        var result = await GnosisReturned.methods
          .deposit(assetAddress, weiAmount)
          .encodeABI();
        setEncodedData(result);
      }
    };
    asyncFn();
  }, [web3, weiAmount, assetAddress, user_address, toggler]);

  useEffect(() => {
    const asyncFn = async () => {
      if (encodedData != "") {
        try {
          web3.eth
            .estimateGas({
              from: user_address,

              to: vault_address.vaultAddress,
              data: encodedData,
            })
            .then((res) => {
              if (res != undefined && res != NaN) {
                setGasRequired(res);
                setInputGasEstimate(res);
              } else {
                setGasRequired(0);
                setInputGasEstimate(0);
              }
            })
            .catch((err) => {
              setGasRequired(1400000);
              setInputGasEstimate(1400000);
            });
        } catch (err) {
          setGasRequired(1400000);
          setInputGasEstimate(1400000);
        }
      }
    };
    asyncFn();
  }, [encodedData]);

  const inputChange = async (value) => {
    setAmount(value);
    setShareAmount(value);
    if (assetAddress && value != "" && value > 0)
      if (wallet?.name == "WalletConnect") {
        fetchApprovedData(value);
      } else {
        const Precision = await getTokenDecimalPrecision(assetAddress, web3);
        Precision.methods
          .decimals()
          .call()
          .then((res) => {
            return convertToWei(res, value);
          })
          .then((weiValue) => {
            setWeiAmount(weiValue, () => {
              Precision.methods
                .allowance(user_address, vault_address.vaultAddress)
                .call()
                .then(async (allowanceAmount) => {
                  if (parseFloat(allowanceAmount) >= parseFloat(weiValue)) {
                    setApproveOrDeposit("deposit");
                  } else {
                    setApproveOrDeposit("approve");
                  }
                });
            });
          })
          .catch(console.log);
      }
    else if (value == "") {
      setWeiAmount(0);
    }
  };

  const handleClick25 = () => {
    setShareAmount(assetBalance * 0.25);
    inputChange((assetBalance * 0.25).toString());
    setAmount((assetBalance * 0.25).toString());
  };

  const handleClick50 = () => {
    setShareAmount(assetBalance * 0.5);
    inputChange((assetBalance * 0.5).toString());
    setAmount((assetBalance * 0.5).toString());
  };

  const handleClick75 = () => {
    setShareAmount(assetBalance * 0.75);
    inputChange((assetBalance * 0.75).toString());
    setAmount((assetBalance * 0.75).toString());
  };

  const handleClick100 = () => {
    setShareAmount(assetBalance);
    inputChange(assetBalance.toString());
    setAmount(assetBalance);
  };

  const handleDeposit = async () => {
    if (approveOrDeposit == "deposit") {
      depositFunction();
    } else if (approveOrDeposit == "approve") {
      approveFunction();
    }
  };

  const depositFunction = async () => {
    const transactionData = {
      contractAddress: vault_address.vaultAddress,
      txType: "DEPOSIT",
      signer: user_address,
      txData: encodedData,
      nonce: 0,
      parameters: {
        _tokenAddress: assetAddress,
        _amount: weiAmount,
      },
    };
    dispatch(WrapperFunction(transactionData, "POST"));
    handleClose();
  };

  const approveFunction = async () => {
    dispatch(TransactionInProgress(true));
    if (wallet?.name == "WalletConnect") {
      try {
        const prepareWriteContractConfig = prepareWriteContract({
          address: assetAddress,
          abi: erc20ABI,
          functionName: "approve",
          args: [vault_address.vaultAddress, weiAmount],
          account: user_address,
        })
          .then((prepareWriteContractConfig) => {
            setApproveConfig(prepareWriteContractConfig);
            if (prepareWriteContractConfig) {
              writeContract(prepareWriteContractConfig)
                .then((hash) => {
                  console.log({ hash });
                })
                .catch((err) => {
                  console.log({ err });
                });
            }
          })
          .catch(console.log);
      } catch (error) {
        console.log({ error });
      }
    } else {
      const ERC20Returned = await getERC20InstanceAt(assetAddress, web3);
      const Precision = await getTokenDecimalPrecision(assetAddress, web3);
      ERC20Returned.methods
        .approve(vault_address.vaultAddress, weiAmount)
        .send({
          from: user_address,
        })
        .on("transactionHash", async (hash) => {
          console.log({ hash });
        })
        .on("receipt", (res) => {
          console.log("receipt", res);
          setApproveOrDeposit("deposit");
          web3.eth.getTransactionCount(user_address, (err, nonce) => {
            setNonce(nonce);
          });
        })
        .on("error", (err) => {
          console.log({ err });
          setLoading(false);
          dispatch(TransactionInProgress(false));
        });
    }
  };

  const getDepositNAV = async (tokenAddress) => {
    const assetDataReturned = await getTokenPriceInUSD(web3, networkId);
    try {
      let val = await assetDataReturned.methods
        .getUSDPrice(tokenAddress)
        .call();
      return val;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="dashboard-deposit-main-card">
            <div className="Deposit-Heading">
              <p>DEPOSIT</p>
            </div>
            <div className="assets-and-interest-apr">
              <div className="deposit-modal-interest-apr-flexbox">
                <div className="deposit-modal-text">
                  <p>INTEREST APR</p>
                </div>
                <div className="deposit-modal-text">
                  <p style={{ fontWeight: "bold", color: "#1B3E69" }}>NIL</p>
                </div>
              </div>
              <div className="deposit-modal-asset-flexbox">
                <div className="deposit-modal-text">
                  <p>ASSET</p>
                </div>
                <div className="deposit-modal-text">
                  <p style={{ fontWeight: "bold", color: "#1B3E69" }}>
                    {assetName === "" ? "None" : assetName}
                  </p>
                </div>
              </div>
            </div>

            <div className="asset-select-dropdown-div">
              <p
                style={{
                  marginBottom: "25px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Select Asset
              </p>
              <DropDown dropDownSelect={dropDownSelect} />
            </div>
            <div className="input-text-and-shares">
              <div
                style={{ width: "210px", height: "150px", paddingTop: "12px" }}
              >
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Enter Amount
                </p>
                <div style={{ height: "20px" }}>
                  {valueSizeCheck == 0 ? (
                    <p id="less-balance-alert-text-deposit">
                      Input amount greater than balance.
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <InputTextBox
                  dropDownSelect={dropDownSelect}
                  inputChange={inputChange}
                  shareAmount={shareAmount}
                  valueSizeCheck={valueSizeCheck}
                />

                <div className="deposit-button-styling">
                  <button onClick={handleClick25}>
                    <p>25%</p>
                  </button>
                  <button onClick={handleClick50}>
                    <p>50%</p>
                  </button>
                  <button onClick={handleClick75}>
                    <p>75%</p>
                  </button>
                  <button onClick={handleClick100}>
                    <p>100%</p>
                  </button>
                </div>
                <div className="balance-held-by-user">
                  <p>{assetBalance.toString()}</p>
                  <p>{assetName}</p>
                </div>
              </div>
            </div>

            <div className="gas-and-vault-alloc-numeric-data">
              <div style={{ width: "42%" }}></div>
              <div
                style={{
                  display: "flex",
                  paddingTop: "10px",
                  lineHeight: "180%",
                  width: "58%",
                }}
              >
                <div className="gas-and-vault-alloc-numeric-data-detailed-info">
                  <p style={{ fontSize: "14px" }}>Est. Vault Allocation</p>
                  <p style={{ fontSize: "14px" }}>Est. Gas Price</p>
                  {/* <p style={{ fontSize: "14px" }}>Override Gas Price</p> */}
                  <p style={{ fontSize: "14px" }}>Est. Gas Required</p>
                  <p style={{ fontSize: "14px" }}>Est. Nonce</p>
                  {/* <p style={{ fontSize: "14px" }}>Override Gas Required</p> */}
                  <p className="gas-vault-alloc-total-styling">TOTAL</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "47%",
                    alignItems: "flex-end",
                    gap: "3px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#1B3E69",
                      fontSize: "14px",
                    }}
                  >
                    {vaultAlloc.toFixed(2)}
                  </p>
                  <GasPriceInput
                    id={1}
                    estGasPrice={gasPrice / 10 ** 9}
                    setInputGasPrice={setInputGasPrice}
                    type="deposit"
                  />
                  <GasPriceInput
                    id={2}
                    estGasPrice={gasRequired}
                    setInputGasEstimate={setInputGasEstimate}
                    type="deposit"
                  />
                  <GasPriceInput
                    id={3}
                    estGasPrice={nonceValue}
                    setNonce={setNonce}
                    type="deposit"
                  />
                  <p className="gas-vault-alloc-total-styling">
                    {Web3.utils.fromWei(
                      (inputGasPrice * inputGasEstimate).toString(),
                      "ether"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="submit-button-deposit-modal">
              {amount > 0 && isDropDown == true && valueSizeCheck == 1 ? (
                <button onClick={handleDeposit}>
                  <p>{approveOrDeposit == "deposit" ? "DEPOSIT" : "APPROVE"}</p>
                </button>
              ) : (
                <button onClick={handleDeposit} className="disabled" disabled>
                  <p>{approveOrDeposit == "deposit" ? "DEPOSIT" : "APPROVE"}</p>
                </button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <LoaderAnimation loading={loading} />
    </div>
  );
});

export default depositModal;
