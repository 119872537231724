import React from 'react'
import './styling.css'
import GetAppIcon from '@material-ui/icons/GetApp';

function ExportButton(props) {
    return (
        <button className='outerbox' onClick={props.onClick}>
            <div style={{height:'100%',width:'20%',padding:'0px'}}>
                <GetAppIcon style={{margin:'20% 0'}} fontSize='inherit' color='primary'/>
            </div>
            <div style={{height:'100%',width:'80%',padding:'0px'}}>
                <p style={{margin:'2% 0',fontSize:'inherit'}} className='button-content' >{props.content}</p>
            </div>

        </button>
    )
}

export default ExportButton