const initState = {
  sinceInceptionAPR: 0,
};

const SinceInceptionAPRReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SINCE_INCEPTION__APR":
      return {
        ...state,
        sinceInceptionAPR: action.payload.sinceInceptionAPR,
      };

    default:
      return state;
  }
};

export default SinceInceptionAPRReducer;
