const initState = {
    assets : [],
    //protocols: [],
    strategy: [],
    currency: '',
    previous_currency: ''
};

const assetsReducer = (state=initState,action) => {
    switch(action.type) {
        case "FETCH_ASSETS":
            return { 
                ...state,
                assets: action.payload.assets,
                protocols: action.payload.protocol,
                strategy: action.payload.strategyArray
            }

        case "LOAD_CURRENCY":
                return { 
                    ...state,
                    currency: action.payload.selectedCurrency
                }

        case "SET_BASE_CURRENCY":
            return { 
                    ...state,
                    previous_currency:action.payload.previousCurrency
            }

        default: 
            return state;
    }
}

export default assetsReducer;