import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import "./Style/index.css";

function Search(props) {
  const [selectedFields, setSelectedFields] = useState([]);
  const [i, drag, preview] = useDrag({
    type: props.fields.type ? props.fields.type : "text",
    item: () => {
      return { fields: selectedFields };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        props.addItemsToCart(item.fields, dropResult);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const clearItemSelection = () => {
    setSelectedFields([]);
  };

  const handleItemSelection = (type) => {
    let newSelectedFields;
    let temp = props.fields.filter;
    switch (type) {
      case "asset":
        temp["type"] = type;
        newSelectedFields = [temp];
        break;
      case "vault":
        temp["type"] = type;
        newSelectedFields = [ temp];
        break;
      case "protocol":
        temp["type"] = type;
        newSelectedFields = [temp];
        break;
      default:
        console.log("default");
        break;
    }
    setSelectedFields(newSelectedFields);
  };

  const displayData = (value) => {
    switch (value.type) {
      case "asset":
        return value.filter.assetName;
      case "vault":
        return value.filter.vaultName;
      case "protocol":
        return value.filter.protocolName;
      default:
        return " ";
    }
  };

  const displayImage = (value) => {
    switch (value.type) {
      case "asset":
        return <img src={value.filter.assetImageURL} />;
      case "vault":
        return (
          <img src={`data:image/jpeg;base64,${value.filter.logoImg.data}`} />
        );
      case "protocol":
        return value.filter.protocolIcon !== null ? (
          <img src={value.filter.protocolIcon} alt="" />
        ) : (
          <span>{value.filter.protocolSymbol.slice(0, 4)}</span>
        );
      default:
        return " ";
    }
  };

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
    clearItemSelection();
  }, []);

  return (
    <div className="search-result">
      <div className="title">Search Result :</div>
      <div
        className="contents"
        ref={drag}
        onMouseEnter={() => handleItemSelection(props.fields.type)}
      >
        <p className="type">{props.fields.type}</p>
        <span className="logo">{displayImage(props.fields)}</span>
        <span>{displayData(props.fields)}</span>
      </div>
    </div>
  );
}

export default Search;
