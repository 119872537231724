import React, { useState, useEffect } from "react";
import axios from "axios";
import { adminReg } from "../../../api";
import "./SignUp.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { message } from "../../../../utils/message";

export default function SignUp() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [confirm, setConfirmPassword] = useState();
  const [passwordEqual, setPasswordEqual] = useState(true);
  const [reload, setReload] = useState(false);
  const [adminExist, setAdminExist] = useState(false);

  const userAddress = useSelector((state) => state.get_user_address.address);

  async function signUpUser(username, password, userAddress) {
    const data = {
      email: username,
      password: password,
      accountAddress: userAddress,
    };
    axios
      .post(adminReg(), data)
      .then((data) => {
        console.log("Data", data);
        if (data.data.status == 1) {
          console.log("success");

          setReload(!reload);
          message.success("Registration successful. Please sign in to continue");
        }
      })
      .catch((error) => {
        console.log(error);
        setAdminExist(true);
      });
  }

  const history = useHistory();
  //Submit handler for sign up
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("User details", username, password, confirm);
    if (confirm == password) {
      await signUpUser(username, password, userAddress);
    }
    //Write code for response
  };
  useEffect(() => {
    document.getElementById("signup-username-id").value = "";
    document.getElementById("signup-password-id").value = "";
    document.getElementById("signup-confirm-password-id").value = "";

    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setAdminExist(false);
    setPasswordEqual(true);
  }, [reload]);
  useEffect(() => {
    if (confirm != password) setPasswordEqual(false);
    else setPasswordEqual(true);
  }, [confirm, password]);
  useEffect(() => {
    if (adminExist == true)
      message.error(
        "Error: Either the admin already exists or you must be a Yieldcube DAO to register."
      );
    setReload(!reload);
  }, [adminExist]);

  return (
    <form onSubmit={submitHandler}>
      <div className="signup-wrap">
        <div className="signup-card">
          <div className="signup-heading">Sign Up</div>
          <div className="signup-content-wrap">
            <input
              className="signup-useremail-heading"
              id="signup-username-id"
              placeholder="Email"
              type="email"
              onChange={(e) => setUserName(e.target.value)}
            />

            <input
              className="signup-password-heading"
              id="signup-password-id"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              className="signup-password-heading"
              id="signup-confirm-password-id"
              placeholder="Confirm Password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              className={
                passwordEqual ? "password-incorrect-none" : "password-incorrect"
              }
            >
              *Passwords does not match
            </span>
          </div>
          <div className="signup-button-wrap">
            <button className="signup-button">Sign Up</button>
          </div>
        </div>
      </div>
    </form>
  );
}
