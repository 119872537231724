import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import "./Style/index.css";

const Toaster = (props) => {
  const handleClose = () => {
    props.handleViewToaster();
  };
  return props.viewToaster ? (
    <div className="toaster-item">
      <Row span={24}>
        <Col span={2}>
          <div className="toaster-icon">
            <WarningOutlined style={{ fontSize: "25px", color: "#FF7A00" }} />
          </div>
        </Col>
        <Col span={20}>
          <div className="toaster-message">{props.message}</div>
        </Col>
        <Col span={2} className="toaster-close-col">
          <div className="toaster-close">
            <CloseOutlined
              style={{ fontSize: "15px", color: "#6D768170" }}
              onClick={handleClose}
            />
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );
};

export default Toaster;
