const initState = {
    stats : [],
    apr : [],
    nav : [],
    assetSum : 0
    
};

const tableDataReducer = (state=initState,action) => {
    switch(action.type) {
        // case "FETCH_SAFE_DETAILED_STATISTICS":
        //     return { 
        //         ...state,
        //         stats: action.payload.stats,
        //         apr: action.payload.apr,
        //         nav: action.payload.nav,
        //         assetSum : action.payload.assetSum
        //     }

            case "FETCH_SAFE_DETAILS":
                return { 
                    ...state,
                    stats: action.payload.stats
                }

                
                case "FETCH_NAV_DETAILS":
                    return { 
                        ...state,
                        nav: action.payload.nav
                    }

                    
                    case "FETCH_APR_DETAILS":
                        return { 
                            ...state,
                            apr: action.payload.apr
                        }
            

        default: 
            return state;
    }
}

export default tableDataReducer;