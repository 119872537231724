import AxiosInstance from "../../utils/axios/axiosConfig";
import { Bearer_Token } from "../../constants/serverAddress";

export default class TransactionDetails {
  static overviewTransaction(vaultAddress, accountAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `transaction-details/v2.0/yieldster/filter-transaction-by-status`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
        accountAddress,
      },
    });
  }

  static getTransactionByType(vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/get-transaction-by-type`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
      },
    });
  }

  static updateTransactionStatus(txnHash, newTxnStatus) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/update-transaction-status`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        txnHash,
        newTxnStatus,
      },
    });
  }
  static postTransactionDetails(
    blockNumer,
    txnHash,
    txnType,
    txnChain,
    txnData,
    wallet,
    fromAddress,
    toAddress,
    signerAddress,
    tag,
    parameter,
    transactionDetails
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/save-transaction-details`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        blockNumer,
        txnHash,
        txnType,
        txnChain,
        txnData,
        wallet,
        fromAddress,
        toAddress,
        signerAddress,
        tag,
        parameter,
        transactionDetails,
      },
    });
  }

  static addTransaction(
    blockNumber,
    txnHash,
    txnType,
    txnChain,
    txnData,
    wallet,
    fromAddress,
    toAddress,
    signerAddress,
    txnSource,
    accountAddress,
    parameter,
    txnStatus
  ) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/add-transaction`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      data: {
        blockNumber,
        txnHash,
        txnType,
        txnChain,
        txnData,
        wallet,
        fromAddress,
        toAddress,
        signerAddress,
        txnSource,
        accountAddress,
        parameter,
        ...(txnStatus ? { txnStatus } : {}),
      },
    });
  }
  static getAdvisorTransactions(vaultAddress) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/get-advisor-transactions`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
      params: {
        vaultAddress,
      },
    });
  }

  static getTransactionDetails(txnHash) {
    return AxiosInstance({
      isHandlerEnabled: false,
      url: `/transaction-details/v2.0/yieldster/get-transaction-by-hash?txnHash=${txnHash}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: { Bearer_Token },
      },
    });
  }
}
