import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "./style/style.css";
import { registerSDK } from "../api";
import axios from "axios";
import { TransactionActionInstance } from "../../redux/actions/DepositWithdrawAction";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "8px 20px",
    borderRadius: "8px",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
      transform: "scale(1.05)",
    },
  },
  smallHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    // color: "#1B3E69",
    marginTop: "20px",
    marginBottom: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "100%",
      [theme.breakpoints.down("1641")]: {
        width: "100%",
        height: "40px",
      },
      [theme.breakpoints.down("1241")]: {
        width: "100%",
        height: "40px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
  rootNew: {
    "& .MuiOutlinedInput-root": {
      width: "420px",
      [theme.breakpoints.down("1641")]: {
        width: "420px",
        height: "40px",
      },
      [theme.breakpoints.down("1241")]: {
        width: "400px",
        height: "40px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
  textArea: {
    border: "1px solid rgb(201,196,196)",
    fontFamily: "'Open sans', sans-serif",
    fontSize: "13px",
    background: "white",
    outline: "#1B3E69 !important",
    padding: "15px",
    borderRadius: "5px",
    textAlign: "justify",
    width: "100%",
    boxSizing: "border-box",
    "&:hover": {
      borderColor: "#1B3E69",
      borderWidth: 1,
    },
    "&:focus": {
      borderColor: "#1B3E69",
      borderWidth: 2,
    },
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  buttonContainer: {
    float: "right",
    padding: "20px 0px 30px 0px",
  },
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  imageContainer: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid rgb(201,196,196)",
    backgroundColor: "white",
  },
  input: {
    width: "16vw",
    outline: "none",
    marginTop: "10px",
  },
  upload: {
    fontSize: "14px",
    fontWeight: "bold",
    padding: "8px 20px",
    cursor: "pointer",
    background: "#DEE5EF",
    borderRadius: "8px",
    marginTop: "10.4px",
    marginLeft: "10px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "11px",
    },
  },
  imageHeading: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default function SDKMOdal(props) {
  const { dispatch } = props;
  const classes = useStyles();
  const dialog = useDialog();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [name, setName] = useState();
  const [url, setUrl] = useState();
  const [description, setDescription] = useState();

  const [invalid, setInvalid] = React.useState(false);
  let data;

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleUrl = (event) => {
    setUrl(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSave = (event) => {
    event.preventDefault();
    data = new FormData();
    data.append("file", preview);
    data.append("name", name);
    data.append("url", url);
    data.append("description", description);

    axios
      .post(registerSDK(), data)
      .then((res) => {
        if (res.data.status) {
          dispatch(TransactionActionInstance());
        }
      })
      .catch((err) => {});
    dialog.close();
  };

  const handleClose = (event) => {
    dialog.close();
  };

  const onSelectFile = (e, b, s, ew) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    e.preventDefault();
    const { files } = e.target;
    const localImageUrl = window.URL.createObjectURL(files[0]);
    setPreview(files[0]);
    e.target.files = null;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#1B3E69",
        }}
      >
        <p className={classes.heading}>ADD CUSTOM APP</p>
        <p
          className={classes.heading}
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          X
        </p>
      </div>
      <p className={classes.content}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in
        tincidunt orci. Nullam pulvinar porttitor condimentum. Ut et mi et
        sapien luctus viverra vel porttitor purus. Suspendisse potenti. In magna
        velit, dapibus sed urna euismod, rutrum efficitur ipsum.
      </p>

      <form
        onSubmit={handleSave}
        style={{ margin: "-30px 30px 30px 30px" }}
        className="form-container-sdk"
      >
        {/* <div> */}
        <div className={classes.topContainer}>
          <div>
            <p className={classes.smallHeading}>App Name</p>
            <TextField
              size="small"
              className={classes.root}
              InputProps={{
                style: {
                  fontSize: 12,
                  fontFamily: "'Open sans', sans-serif",
                },
              }}
              id="outlined-basic"
              placeholder="Enter the app name"
              onChange={handleName}
              variant="outlined"
              required={true}
            />
          </div>
          <div>
            <p className={classes.smallHeading}>App Icon</p>
            <div className={classes.imageHeading}>
              {preview ? (
                <img
                  className={classes.imageContainer}
                  src={
                    preview ? window.URL.createObjectURL(preview) : undefined
                  }
                />
              ) : (
                <div
                  className={classes.imageContainer}
                  style={{
                    border: invalid
                      ? "1px solid #C42121"
                      : "1px solid rgb(201,196,196)",
                  }}
                ></div>
              )}

              <label class="custom-file-upload">
                <input
                  style={{ color: "transparent" }}
                  type="file"
                  onChange={onSelectFile}
                  className={classes.input}
                  onInvalid={() => {
                    setInvalid(true);
                  }}
                  id="inputId"
                  placeholder=""
                  required={!preview}
                />
                <p className={classes.upload}>UPLOAD FILE</p>
              </label>
            </div>
          </div>
        </div>
        <div>
          <p className={classes.smallHeading}>App url</p>
          <TextField
            size="small"
            className={classes.rootNew}
            InputProps={{
              style: { fontSize: 12, fontFamily: "'Open sans', sans-serif" },
            }}
            id="outlined-basic"
            placeholder="Enter the app url"
            onChange={handleUrl}
            variant="outlined"
            //   type="email"
            required={true}
          />

          <p className={classes.smallHeading}>App Description</p>
          <TextareaAutosize
            className={classes.textArea}
            rowsMin={2}
            rowsMax={2}
            placeholder="Please describe your app"
            aria-label="maximum height"
            //   defaultValue={defaultValue.formvaultDescription}
            onChange={handleDescription}
            required={true}
            InputProps={{ style: { fontSize: 12 } }}
          />
        </div>
        {/* </div> */}
        <div
          style={{ float: "right", marginTop: "25px", marginBottom: "25px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
}
