import React from "react";
import "./Style/style.css";
const DepositWithdrawComponent = (props) => {
  const { handleDepositViaWallet, handleWithdrawViaWallet, showButton } = props;
  return (
    <div className="deposit-withdraw-button-component">
      <button
        className="new-deposit-withdraw-button-style"
        id="new-deposit"
        onClick={handleDepositViaWallet}
        // disabled={!showButton}
      >
        <p>Deposit</p>
      </button>
      <button
        className="new-deposit-withdraw-button-style"
        id="new-withdraw"
        onClick={handleWithdrawViaWallet}
        // disabled={!showButton}
      >
        <p>Withdraw</p>
      </button>
    </div>
  );
};

export default DepositWithdrawComponent;
