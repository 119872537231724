export const Provider = (val) => async(dispatch) => {
  dispatch({
    type: "SET_PROVIDER",
    payload: {
      setprovider: val
    }
  })
}

// export const UpdateWeb3 = (val) => async(dispatch) => {
//   dispatch({
//     type: "SET_WEB3",
//     payload:val
// })}
