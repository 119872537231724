import React, { useState, Component } from "react";
import "./Percentage-Card-Styler/NewPercentageCardStyler.css";
import up_arrow from "../../../assets/home-dashboard/diagonal-arrow-growth.svg";
import down_arrow from "../../../assets/home-dashboard/diagonal-arrow-decline.svg";
import Skeleton from "@material-ui/lab/Skeleton";
// import downgraph from '../../../assets/home-dashboard/Decline.png'
// import upgraph from '../../../assets/home-dashboard/Grow.png'
import upgraph from '../../../assets/home-dashboard/PriceCardAssets/Grow.svg'
import downgraph from '../../../assets/home-dashboard/PriceCardAssets/Dip.svg'
import nullgraph from '../../../assets/home-dashboard/PriceCardAssets/Zero.svg'
// import new
function PercentageCard(props) {
  const { toggle, value, isChartLoading, percentvalue, image } = props;
  return (
    <>
      {isChartLoading ? (
        <Skeleton
          animation="wave"
          height={20}
          width="50px"
          style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", marginLeft: 2, marginBottom: "17px" }}
        />
      ) : (
        <div style={{ marginBottom: 0 }}>
          {toggle === "grow" ? (

            <div id="newgrow" className="new-percentage-card">
              <div style={{ display: "flex", gap: "3px" }}>
                <img src={up_arrow} ></img>
                <p style={{ color: "#188038", fontSize: "0.75rem", fontWeight: "bold" }}>{value == undefined ? 0 : parseFloat(value).toFixed(2)} %</p>
              </div>
            </div>
          ) : toggle === "drop" ? (
            <div id="newdrop" className="new-percentage-card">
              {/* <img src={downgraph} className="small-info-graph"></img> */}
              <div style={{ display: "flex", gap: "3px" }}>
                <img src={down_arrow} ></img>
                <p style={{ color: "#C42121", fontSize: "0.75rem", fontWeight: "bold" }}>
                  {value == undefined ? 0 : parseFloat(value * -1).toFixed(2)}%
                </p>
              </div>
            </div>
          ) : (
            <div id="newnull" className="new-percentage-card">
              <p style={{ fontSize: "0.75rem", fontWeight: "bold" }}>0%</p>
            </div>
          )}
        </div>
      )}

    </>
  );
}

export default PercentageCard;
