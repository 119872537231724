import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { TINY_API_KEY } from "../../utils/consts";
import "./Style/index.css";

function RichTextEditor(props) {
  const plugins = [
    "lists",
    "link",
    "image",
    "preview",
    "code",
    "fullscreen",
    "table",
    "wordcount",
    "insertdatetime",
    "advlist",
    "emoticons",
  ];
  const options =
    "bold italic underline | " +
    "backcolor forecolor| " +
    "fontname fontsize blocks |" +
    "alignleft aligncenter alignright alignjustify | " +
    "bullist numlist table| " +
    "outdent indent |" +
    "removeformat link code emoticons ";
  return (
    <div className="description-editor-box">
      {/* {props.type == "edit" ? ( */}
      <Editor
        apiKey={TINY_API_KEY}
        value={props.initialValue ? props.initialValue : ""}
        init={{
          toolbar_mode: "wrap",
          menubar: false,
          plugins: plugins,
          toolbar: options,
          content_style: "body { font-size:14px }",
          
        }}
        onEditorChange={(a, v) => {
          props.setLongDescription(a);
        }}
      />
      {/* ) : (
        <Editor
          apiKey={TINY_API_KEY}
          value={props.initialValue}
          init={{
            toolbar_mode: "wrap",
            menubar: false,
            plugins: plugins,
            toolbar: options,
            content_style: "body { font-size:14px }",
          }}
          onEditorChange={(a, v) => {
            props.setLongDescription(a);
          }}
        />
      )} */}
    </div>
  );
}

export default RichTextEditor;
