// export const base_url = "https://api.yieldster.finance/";
export const base_url = process.env.REACT_APP_NODE_BASE_URL;
// export const base_url = "http://localhost:8050/";

const qrcodeUrl = "https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=";
const assets = `asset/`;
const price = `price/`;
const vault = `vault/`;
const protocol = `protocol/`;
const data = `data/`;
const config = `config/`;
const apsmanager = `apsmanager/`;
const feecharginginterval = `feecharginginterval/`;
const strategymanager = `strategymanager/`;
const whitelist = `whitelist/`;
const strategy = `strategy/`;
const sdk = `sdk/`;
const disabledstrategy = "disabledstrategy/";
const strategymap = "strategymap/";
const addstrategy = "addstrategy/";
const user = "user/";
const navhistory = `navhistory/`;
const top = "top/";
const activatestrategy = "activatestrategy/";
const deactivatestrategy = "deactivatestrategy/";
const address = "address/";
const deposit = "deposit/";
const historical = "historical/";
const investment = "investment";
const getAllVaultsapy = "getVaultapy/";
const add = "adduser/";
const remove = "removeuser/";
const group = "group/";
const featuredvault = "featuredvault/";
const filter = "filter/";
const changeadmin = "changeadmin/";
const sendmail = "sendmail/";
const addasset = "addasset/";
const removeasset = "removeasset/";
const depositasset = "depositasset/";
const withdrawasset = "withdrawasset/";
const strategyasset = "strategyasset/";
const god = "god/";
const enablebreak = "enablebreak/";
const disablebreak = "disablebreak/";
const exit = "exit/";
const transaction = "transaction/";
const addData = "add/";
const strategynav = "strategynav/";
const pending = "pending/";
const pause = "pause/";
const toggle = "toggle/";
const complete = "complete/";
const details = "details/";
const exchangeprice = "exchange-price/";
const web3 = "web3/";
const portfolio = "portfolio/";
const strategyPortfolio = "strategyPortfolio/";
const vaultPortfolio = "vaultPortfolio/";
const strategyShare = "strategyShare/";
const vaultAsset = "vaultAsset/";
const userstats = "userstats/";
const contractAddress = "contractAddress/";
const admin = "admin/";
const tx = "tx/";
const faq = "faq/";
const login = "login/";
const register = "register/";
const subscribe = "subscribe";
export const getBaseURL = () => `${base_url}`;
export const getAssetsURL = () => `${base_url}${assets}${vaultAsset}`;
export const getProtocolURL = () => `${base_url}${protocol}`;
export const getStrategyURL = () => `${base_url}${strategy}`;
export const getAssetPrice = (assetSymbol) =>
  `${base_url}${assets}${price}${assetSymbol}`;
export const setVault = () => `${base_url}${vault}`;
export const getapsmanager = () => `${base_url}${config}${apsmanager}`;
export const getfeecharginginterval = () =>
  `${base_url}${config}${feecharginginterval}`;
export const getstrategymanager = () =>
  `${base_url}${config}${strategymanager}`;
export const getAsset = () => `${base_url}${assets}`;
export const getWhitelist = () => `${base_url}${whitelist}`;
export const getStrategy = () => `${base_url}${strategy}`;
export const getStrategyMap = () => `${base_url}${vault}${strategymap}`;
export const getAddstrategy = () => `${base_url}${vault}${addstrategy}`;
export const getTopVaults = () => `${base_url}${vault}${top}`;
export const activeStrategy = () => `${base_url}${vault}${activatestrategy}`;
export const deactivateStrategy = () =>
  `${base_url}${vault}${deactivatestrategy}`;
// export const getFeaturedVault = () => `${base_url}${config}${featuredvault}`;
export const filterVaults = () => `${base_url}${vault}${filter}`;
export const updateDepositAsset = () => `${base_url}${vault}${depositasset}`;
export const updateWithdrawAsset = () => `${base_url}${vault}${withdrawasset}`;
export const getStrategyAsset = () => `${base_url}${vault}${strategyasset}`;
export const changeGod = () => `${base_url}${config}${god}`;
export const getConfig = () => `${base_url}${config}`;
export const enableBreak = () => `${base_url}${vault}${enablebreak}`;
export const disableBreak = () => `${base_url}${vault}${disablebreak}`;
export const exits = () => `${base_url}${vault}${exit}`;
export const sendMail = () => `${base_url}${whitelist}${sendmail}`;
export const setStrategyManager = () => `${base_url}${vault}${strategymanager}`;
export const setAPSManager = () => `${base_url}${vault}${apsmanager}`;
export const getStrategyDetails = () => `${base_url}${strategy}${strategynav}`;
export const getVaultPortfolio = () => `${base_url}${vault}${vaultPortfolio}`;
export const getStrategyShare = () => `${base_url}${vault}${strategyShare}`;
export const addPortfolio = () => `${base_url}${vault}${portfolio}`;
export const getWhitelistAdmins = () => `${base_url}${whitelist}${admin}`;
export const getFaq = () => `${base_url}${config}${faq}`;
export const adminLogin = () => `${base_url}${user}${login}`;
export const adminReg = () => `${base_url}${user}${register}`;
export const registerSDK = () => `${base_url}${strategy}${sdk}`;
export const filterMyVaults = () => `${base_url}${user}${filter}`;
export const subscribeUser = () => `${base_url}${subscribe}`;

export const getAssetsByAddress = (address) => {
  return `${base_url}${assets}${address}`;
};

export const getFile = (file) => {
  return `${base_url}${file}`;
};

export const getVault = (vaultAddress) => {
  return `${base_url}${vault}${vaultAddress}`;
};

export const getUser = (userAddress) => {
  return `${base_url}${user}${userAddress}`;
};

export const stockChartData = () => {
  return `${base_url}${vault}${navhistory}`;
};

export const patchDefaultVaultAddress = () => {
  return `${base_url}${user}`;
};

export const addAssets = () => {
  return `${base_url}${vault}${addasset}`;
};

export const removeAssets = () => {
  return `${base_url}${vault}${removeasset}`;
};

export const iconURL = (rowIcon) => {
  return `${base_url}${rowIcon}`;
};

export const getDisabledStrategies = (vaultAddress) => {
  return `${base_url}${vault}${disabledstrategy}${vaultAddress}`;
};

export const getStrategyByAddress = (vaultAddress) => {
  return `${base_url}${strategy}${vaultAddress}`;
};

export const getAssetsPerVault = (vaultAddress) => {
  return `${base_url}${vault}${vaultAddress}`;
};

export const qrCodeGenerator = (val) => {
  return `${qrcodeUrl}${val}`;
};

export const getImage = (image) => {
  return `${base_url}${image}`;
};

export const postUserDetails = () => {
  return `${base_url}${user}${deposit}`;
};

export const historicalDateTime = (datetime) => {
  return `${base_url}${assets}${historical}${datetime}`;
};

export const myInvestmentDetails = () => {
  return `${base_url}${vault}${investment}`;
};

export const currencyExchangeAPI = (from_currency, to_currency) => {
  return `https://api.exchangeratesapi.io/latest?base=${from_currency}&symbols=${to_currency}`;
};

export const currencyConvertionAPI_Temporary = (from_currency, to_currency) => {
  return `https://free.currconv.com/api/v7/convert?q=${from_currency}_${to_currency}&compact=ultra&apiKey=8ec8aeabf05f6b0fd71c`;
};

export const exchangeAPI_Re_Route = (from_currency, to_currency) => {
  currencyConvertionAPI_Temporary(from_currency, to_currency);
};

export const VaultNAVandGain = (vaultAddress, accountAddress) => {
  return `${base_url}${vault}${investment}?accountAddress=${accountAddress}&vaultAddress=${vaultAddress}`;
};
export const getFeaturedVault = (accountAddress) => {
  return `${base_url}${config}${featuredvault}?accountAddress=${accountAddress}`;
};

export const getVaultAPYforEachVault = (vaultAddress) => {
  return `${base_url}${vault}${getAllVaultsapy}${vaultAddress}`;
};

export const addWhiteListUser = () => {
  return `${base_url}${whitelist}${add}`;
};

export const removeWhiteListUser = () => {
  return `${base_url}${whitelist}${remove}`;
};

export const getWhitelistAddress = (vaultAddress) => {
  return `${base_url}${whitelist}${group}?vaultAddress=${vaultAddress}`;
};

export const changeWhitelistManager = () => {
  return `${base_url}${whitelist}${changeadmin}`;
};

export const vaultTransactions = (vaultAddress) => {
  return `${base_url}${vault}${transaction}${vaultAddress}`;
};

export const vaultAdd = () => {
  return `${base_url}${vault}${addData}`;
};

export const PauseResumeToggler = (vaultID) => {
  return `${base_url}${vault}${pending}${pause}${toggle}${vaultID}`;
};

//${base_url}/vault/pending/${current_status.get_pending_vault_status.vaultID}

export const PendingVaultStatus = (vaultID) => {
  return `${base_url}${vault}${pending}${vaultID}`;
};

// ${base_url}/vault/pending/details/${current_status.get_pending_vault_status.vaultID}

export const MainVaultStatus = (vaultID) => {
  return `${base_url}${vault}${pending}${details}${vaultID}`;
};

// ${base_url}/vault/transaction/add`

export const VaultTransactionAdd = () => {
  return `${base_url}${vault}${transaction}${addData}`;
};

// ${base_url}/vault/transaction/complete/${transactionHash}

export const VaultTransactionComplete = (transactionHash) => {
  return `${base_url}${tx}${complete}${transactionHash}`;
};

export const VaultAddForAddition = () => {
  return `${base_url}${vault}${addData}`;
};

export const ExchangePrice = (to) => {
  return `${base_url}${exchangeprice}?currency=${to}`;
};

export const OverallStats = (accountAddress) => {
  return `${base_url}${user}${userstats}?accountAddress=${accountAddress}`;
};

// export const getHistoryAssetPrice = (assetSymbol) => {
//   return `${base_url}asset/assetprice?assetSymbol=${assetSymbol}&date=20-05-2020`
// }

export const getHistoryAssetPrice = (currency, date) => {
  return `${base_url}${exchangeprice}history?currency=${currency}&date=${date}`;
};

export const strategyHistory = (timeStamp) => {
  return `${base_url}${strategy}strategyhistory/${timeStamp}`;
};

export const backEndEnvFetch = () => {
  return `${base_url}${web3}${contractAddress}`;
};

export const DepositWithdrawPendingTransaction = () => {
  return `${base_url}tx/pending`;
};

export const SmartStrategyOfVault = (vaultAddress) => {
  return `${base_url}vault/smart-strategy?vaultAddress=${vaultAddress}`;
};

export const removeWhitelistManager = () => {
  return `${base_url}${whitelist}removemanager/`;
};

export const addWhitelistManager = () => {
  return `${base_url}${whitelist}addmanager/`;
};

export const vaultTransactionComplete = (txHash) => {
  return `${base_url}tx/complete/${txHash}`;
};

export const allPendingTransactions = (vaultAddress) => {
  return `${base_url}tx/pending?vaultAddress=${vaultAddress}`;
};

export const SaveTransactionHash = (id) => {
  return `${base_url}tx/save-transaction-hash/${id}`;
};

export const UpdateHashandNonce = () => {
  return `${base_url}tx/update-hash`;
};

export const SetTxStatusCorrupted = (id) => {
  return `${base_url}tx/update-tx-status/${id}`;
};

export const TransactionDeletionAPI = (id) => {
  return `${base_url}tx/terminate-transaction/${id}`;
};

export const getAllTransactionData = (vaultAddress, limit, pageNo) => {
  return `${base_url}tx/all-transaction?vaultAddress=${vaultAddress}&limit=${limit}&pageNo=${pageNo}`;
};

export const getAllCustomStrategies = () => {
  return `${base_url}strategy/sdk`;
};

export const hasUserDeposited = (vaultAddress, userAddress) => {
  return `${base_url}${user}is-deposited?accountAddress=${userAddress}&vaultAddress=${vaultAddress}`;
};

export const toggleVaultVisibility = (vaultAddress) => {
  return `${base_url}${vault}updateVisibility?vaultAddress=${vaultAddress}`;
};

export const getManagementFee = (vaultAddress) => {
  return `${base_url}${vault}/management-fee/${vaultAddress}`;
};

export const getTransactionfromSafeHash = (safeHash) => {
  return `https://safe-transaction-mainnet.safe.global/api/v1/multisig-transactions/${safeHash}`;
};
