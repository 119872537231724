import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-root": {
      fontSize: "10px",
    },
    "&  .MuiInputLabel-formControl": {
      fontWeight: "bold",
      fontFamily: "Open Sans",
    },
  },
  dateSelect: {
    width: "200px",
    "& .MuiInputLabel-root": {
      fontWeight: "bold",
      color: "#6D7681",
      fontSize: "10px",
      fontFamily: "Open Sans",
    },
    "& .MuiInputBase-input": {
      color: "#1B3E69",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "Open Sans",
      padding: "0px 0px 6px 0px",
    },
    "& .MuiInput-underline::before": {
      borderColor: "#6D7681",
      fontWeight: "bold",
      width: "80%",
    },
    "& .MuiInput-underline::after": {
      borderColor: "#6D7681",
      width: "0rem",
    },
    "& .MuiIconButton-root": {
      paddingLeft: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  timeSelect: {
    width: "140px",
    "& .MuiInputLabel-root": {
      fontWeight: "bold",
      color: "#6D7681",
      fontSize: "10px",
      fontFamily: "Open Sans",
    },
    "& .MuiInputBase-input": {
      color: "#1B3E69",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "Open Sans",
      padding: "0px 0px 6px 0px",
    },
    "& .MuiInput-underline::before": {
      borderColor: "#6D7681",
      fontWeight: "bold",
      width: "70%",
    },
    "& .MuiInput-underline::after": {
      borderColor: "#6D7681",
      width: "0%",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
}));

export default function MaterialUIPickers(props) {
  const { minTime } = props;
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const classes = useStyles();
  // props.handleDateSubmit(selectedDate);
  const handleTimeChange = (event) => {
    setSelectedDate(event.toISOString(event));
    props.handleDateSubmit(event.toISOString(event));
  };

  const handleDateChange = (event) => {
    handleTimeChange(event);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="none"
        id="date-picker-dialog"
        label="SELECT A DATE"
        format="MMM dd'th' yyyy"
        value={selectedDate}
        minDate={minTime}
        maxDate={new Date()}
        onChange={handleDateChange}
        className={classes.dateSelect}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <KeyboardTimePicker
        margin="none"
        id="time-picker"
        label="SELECT A TIME"
        value={selectedDate}
        className={classes.timeSelect}
        onChange={handleTimeChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
      {/* </Grid> */}
    </MuiPickersUtilsProvider>
  );
}
