import { store } from "..";
import { currentBlockNumber } from "../redux/actions/CurrentBlockNumber";

export const getBlockNumber = async (web3) => {
  if (web3)
    try {
      const blockNumber = await web3.eth.getBlockNumber();
      store.dispatch(currentBlockNumber(blockNumber));
    } catch (error) {
      console.log(error);
    }
};
