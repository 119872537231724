import React from "react";
import "./Style/style.css";

const CustomLoader = (props) => {
  const { marginTop, marginBottom, loaderHeight } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "70%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: `${marginTop}rem`,
        minHeight: loaderHeight || 0
      }}
    >
      <p id="loading-text">LOADING</p>
      <div className="custom-loader-animation-main-div">
        <div className="custom-loader-animation-main-sub-div"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
