const initState = {
    tokenBalance:"",
  
};

const IsConnectedReducer = (state=initState,action) => {
    switch(action.type) {
        case "GET_TOKEN_BALANCE":
            return { 
                ...state,
                tokenBalance: action.payload.tokenBalance,
            }

        default: 
            return state;
    }
}

export default IsConnectedReducer;