import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TransactionDeletionAPI } from "../../../api";
import axios from "axios";
import { message } from "../../../../utils/message";
const useStyles = makeStyles((theme) => ({
  heading: {
    background: "#1B3E69",
    color: "white",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "10px 30px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "14px",
    },
  },
  button: {
    margin: "0px 30px 15px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#DEE5EF",
    color: "#1B3E69",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      background: "#DEE5EF",
      color: "#1B3E69",
      boxShadow: "none",
    },
  },
  content: {
    padding: "30px 40px 30px 30px",
    textAlign: "justify",
    fontSize: "14px",
  },
  button1: {
    margin: "0px 23px 15px 10px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#C42121",
    color: "white",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#C42121",
    },
  },
  textField1: {
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    margin: "0px 30px 10px 30px ",
  },
  textField: {
    margin: "0px 30px 10px 30px ",
  },
  contentAddress: {
    fontSize: "14px",
    // fontWeight: "bold",
    marginTop: "10px",
    // color: "#1B3E69",
    marginBottom: "5px",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      width: "420px",
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
}));

export default function TerminateTransaction(props) {
  const { id } = props;
  const classes = useStyles();
  const dialog = useDialog();

  const handleTerminateTransaction = async (id) => {
    try {
      const data = await axios.delete(TransactionDeletionAPI(id));
      if (data.data.status) {
        message.success("Transaction terminated");
      }
    } catch (err) {
      message.error("Error occured while attempting to terminate transaction");
    }
  };

  return (
    <React.Fragment>
      <div style={{ height: "0px" }}>
        <p className={classes.heading}>TERMINATE TRANSACTION</p>
        <div className={classes.textField1}>
          <p className={classes.contentAddress}>
            Confirm to terminate the current transaction
          </p>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            dialog.close();
          }}
          className={classes.button}
        >
          EXIT
        </Button>
        {/* <SimpleSelect topic={props.name}/> */}
        <Button
          variant="contained"
          // disabled={!(whitelistId && changeManagerAddress)}
          onClick={() => {
            // Сlose the dialog and return the value
            handleTerminateTransaction(id);
            dialog.close();
          }}
          className={classes.button1}
        >
          CONFIRM
        </Button>
      </div>
      {/* <LoaderAnimation loading={progress} /> */}
    </React.Fragment>
  );
}
