import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { totalSupply } from "../../../../../redux/actions/Total_Supply";
import { Vaults } from "../../../../../services/api";
import { getERC20InstanceAt } from "../../../../Asset-Details/TableFunctions";
import Customtable from "../../../Custom_Table/customTable";
import "./vault_stats_styling/vaultStatsStyling.css";
import { SinceInceptionAPR } from "../../../../../redux/actions/SinceInceptionAPR";

export default function VaultStatistics(props) {
  const { loadingUniverse, loadingSkeleton } = props;
  const vaultNAV = useSelector((state) => state.get_token_value.navValue);
  const location = useLocation();
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const blockNumber = useSelector(
    (state) => state.get_current_block_number.blockNumber
  );
  const isDepositWithdraw = useSelector(
    (state) => state.get_deposit_withdraw_state_change_reflector.randomNumber
  );
  const stateChangeReflector = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const graphFilterValue = useSelector(
    (state) => state.get_graph_filter.filter
  );
  const web3 = useSelector((state) => state.get_web3.web3);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const currentVault = useSelector((state) => state.get_vault_details.vault);

  const [apr, setAPRSinceInception] = useStateWithCallback(0);
  const [apr24hours, setAPRSince24] = useState(0);
  const [apr7days, setAPRSince7Days] = useState(0);
  const [outstandingTokens, setOutstandingTokens] = useState(0);
  const dispatch = useDispatch();

  const statisticsAPI = async () => {
    if (vaultAddress && blockNumber) {
      props.isVaultStatLoading(false);
      Vaults.getApr(vaultAddress, blockNumber)
        .then((res) => {
          setAPRSinceInception(res.data.data.sinceInceptionAPY, () => {
            props.isVaultStatLoading(true);
          });
          dispatch(SinceInceptionAPR(res.data.data.sinceInceptionAPY));
          setAPRSince24(res.data.data.oneDayAPY);
          setAPRSince7Days(res.data.data.oneWeekAPY);
        })
        .catch((err) => {
          props.isVaultStatLoading(true);
          dispatch(SinceInceptionAPR(0));
          console.log("Error occured", err);
        });
      if (web3 && vaultAddress) {
        const erc20 = await getERC20InstanceAt(vaultAddress, web3);
        erc20.methods
          .totalSupply()
          .call()
          .then((res) => {
            dispatch(totalSupply(parseFloat(web3.utils.fromWei(res, "ether"))));
            setOutstandingTokens(
              parseFloat(web3.utils.fromWei(res, "ether")).toFixed(2)
            );
          });
      }
    }
  };

  useEffect(() => {
    statisticsAPI();
  }, []);

  useEffect(() => {
    statisticsAPI();
  }, [
    vaultAddress,
    userAddress,
    web3,
    isDepositWithdraw,
    blockNumber,
    stateChangeReflector,
    graphFilterValue,
    currentVault?.vault?.profitType,
  ]);

  return (
    <>
      <Customtable
        tableId={2}
        value_1={vaultNAV}
        value_2={apr}
        value_3={apr24hours}
        value_4={apr7days}
        loadingSkeleton={loadingSkeleton}
        tokensOutstanding={outstandingTokens}
      />
    </>
  );
}
