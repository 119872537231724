import React, { useState } from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import AvatarCharacter from "../../SidebarSettings/APS/Avatar/index";
import { getImage } from "../../api";
import { useSelector, useDispatch } from "react-redux";
import { CustomDialog } from "react-st-modal";
import { MuiThemeProvider } from "@material-ui/core/styles";
import PopUpModal from "../Popup-Modal/Modal";
import EnableDisableVault from "../EnableDisableModal";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { handleWalletSelection } from "../../Blocknative/walletSelectionConfig";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  SingleCard: {
    boxSizing: "border-box !important",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: " 4px 4px 30px rgba(27, 62, 105, 0.10)",
    padding: "24px",
    width: "404.5px !important",
    // cursor: "pointer",
  },
  subContainerInCardHeading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "16px",
    alignItems: "center",
    marginBottom: "5px",
  },
  subContainerInCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
  },
  valueAndGainBox: {
    display: "flex",
    flexDirection: "column",
  },
  headingName: {
    fontFamily: "'Catamaran', sans-serif",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1B3E69",
  },
  value: {
    color: "#1B3E69",
  },
  valueNumber: {
    color: "#1B3E69",
    fontWeight: "bold",
    fontSize: "36px",
  },
  positive: {
    color: "#188038",
    display: "flex",
    alignItems: "center",
    marginLeft: "-5px",
  },
  bold: {
    fontWeight: "bold",
  },
  negative: {
    color: " #C42121",
    display: "flex",
    alignItems: "center",
    marginLeft: "-5px",
  },
  upgradeWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  upgradeHeading: {
    height: "35px",
    width: "165px",
    backgroundColor: "#1B3E69",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    fontFamily: "Catamaran",
    fontSize: "16px",
    fontWeight: "700",
    cursor: "pointer",
  },
  // C42121
  // 168037
  enableDisableButtonStyling: {
    height: "35px",
    width: "165px",
    backgroundColor: (props) => (!props.visibility ? "#168037" : "#C42121"),
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    fontFamily: "Catamaran",
    fontSize: "16px",
    fontWeight: "700",
    cursor: "pointer",
  },
  customBadge: {
    backgroundColor: (props) => (props.visibility ? "#168037" : "#C42121"),
    color: "#ffffff",
    "&:hover": {
      cursor: "pointer",
    },
  },
  customTooltip: {
    backgroundColor: (props) => (props.visibility ? "#168037" : "#C42121"),
    color: "#ffffff",
    marginLeft: "25px",
    marginTop: "15px",
  },
}));

export default function Card(props) {
  const { visibility, changeVaultVisibility } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.get_web3.web3);
  const userAddress = useSelector((state) => state.get_user_address.address);

  const upgradeHandler = async () => {
    const ready = await handleWalletSelection(userAddress);
    if (ready) {
      const result = await CustomDialog(
        <MuiThemeProvider>
          <PopUpModal
            web3={web3}
            vaultAddress={props.props.vaultAddress}
            dispatch={dispatch}
          />
        </MuiThemeProvider>,

        {
          showCloseIcon: false,
        }
      );
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.SingleCard}>
        <div className={classes.subContainerInCardHeading}>
          <p className={classes.headingName}>{props.props.vaultName}</p>
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
            }}
            title={visibility ? "Visible" : "Hidden"}
            placement="top-start"
            // disableHoverListener
          >
            <Badge
              badgeContent=" "
              variant="dot"
              classes={{ badge: classes.customBadge }}
            >
              <AvatarCharacter
                image={getImage(props.props.vaultIcon)}
                class="medium"
              />
            </Badge>
          </Tooltip>
        </div>
        <Divider style={{ margin: "5px -24px" }} />
        <div className={classes.subContainerInCard}>
          <div className={classes.valueAndGainBox}>
            <p className={classes.value}>1 Day</p>
            {props.props.apr && props.props.apr.oneDayAPY != 0 ? (
              <p
                className={
                  props.props.apr.oneDayAPY < 0
                    ? classes.negative
                    : classes.positive
                }
              >
                <span class="material-icons" style={{ fontSize: "18px" }}>
                  {props.props.apr.oneDayAPY < 0 ? "south" : "north"}
                </span>
                <span className={classes.bold}>
                  {parseFloat(props.props.apr)
                    ? props.props.apr.oneDayAPY.toFixed(2)
                    : 0.00}{" "}
                  %
                </span>
              </p>
            ) : (
              <p>
                <span className={classes.bold}>0.00 %</span>
              </p>
            )}{" "}
          </div>
          <div className={classes.valueAndGainBox}>
            <p className={classes.value}>1 Week</p>
            {props.props.apr && props.props.apr.oneWeekAPY != 0 ? (
              <p
                className={
                  props.props.apr.oneWeekAPY < 0
                    ? classes.negative
                    : classes.positive
                }
              >
                <span class="material-icons" style={{ fontSize: "18px" }}>
                  {props.props.apr.oneWeekAPY < 0 ? "south" : "north"}
                </span>{" "}
                <span className={classes.bold}>
                  {parseFloat(props.props.apr)
                    ? props.props.apr.oneWeekAPY.toFixed(2)
                    : 0.00}{" "}
                  %
                </span>
              </p>
            ) : (
              <p>
                <span className={classes.bold}>0.00 %</span>
              </p>
            )}
          </div>
          <div className={classes.valueAndGainBox}>
            <p className={classes.value}>Total</p>
            {props.props.apr && props.props.apr.oneWeekAPY != 0 ? (
              <p
                className={
                  props.props.apr.sinceInceptionAPY < 0
                    ? classes.negative
                    : classes.positive
                }
              >
                <span class="material-icons" style={{ fontSize: "18px" }}>
                  {props.props.apr.sinceInceptionAPY < 0 ? "south" : "north"}
                </span>
                <span className={classes.bold}>
                  {parseFloat(props.props.apr)
                    ? props.props.apr.sinceInceptionAPY.toFixed(2)
                    : 0.00}{" "}
                  %
                </span>
              </p>
            ) : (
              <p>
                <span className={classes.bold}>0.00 %</span>
              </p>
            )}
          </div>
        </div>
        <Divider style={{ margin: "5px -24px 15px -24px" }} />
        <span className={classes.upgradeWrap}>
          <span
            className={classes.enableDisableButtonStyling}
            onClick={async () => {
              const result = await CustomDialog(
                <MuiThemeProvider>
                  <EnableDisableVault
                    web3={web3}
                    vaultAddress={props.props.vaultAddress}
                    vaultVisibility={visibility}
                    changeVaultVisibility={changeVaultVisibility}
                    dispatch={dispatch}
                  />
                </MuiThemeProvider>,

                {
                  showCloseIcon: false,
                }
              );
            }}
          >
            {visibility ? "Hide Vault" : "Show Vault"}
          </span>
          <span className={classes.upgradeHeading} onClick={upgradeHandler}>
            Upgrade
          </span>
        </span>
      </div>
    </div>
  );
}
