import "./style.css";

const CustomTooltip = ({ active, payload, label, isUserDeposited }) => {
  const formattedDate = label;
  if (payload === null) return null;
  if (active && payload.length != 0) {
    return (
      <div className="custom-tooltip">
        <p className="label-tooltip">{`${formattedDate}`}</p>
        <p className="desc-tooltip">
          <span className="value-tooltip">
            NAV per Token: {parseFloat(payload[0].value)}
          </span>
          <br />
          {isUserDeposited && payload[1] != undefined ? (
            <span className="value-tooltip">
              My Balance: {parseFloat(payload[1].value)}
            </span>
          ) : null}
          <br />
        </p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
