const initState = {
    queueState: false
};

const AddtoQueueReducer = (state=initState,action) => {
    switch(action.type) {
        case "ADD_TO_QUEUE":
            return { 
                ...state,
                queueState: action.payload.addtoQueue,
            }

        default: 
            return state;
    }
}

export default AddtoQueueReducer;