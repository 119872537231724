import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Editor as EditorComponent } from ".";
import Preview from "./Preview";
import "./Style/index.css";

function EditLongDescription(props) {
  const description = props.description?.length ? props.description : "";
  const [preview, setPreview] = useState(false);
  const [data, setData] = useState(description);
  const adminData = useSelector(
    (state) => state.get_vault_details?.vault?.vault
  );
  const handleSubmit = () => {
    props.handleInputChange({
      id: "longDescription",
      value: data,
    });
    props.close(false);
  };
  const handleDiscard = () => {
    const discard = props.type == "create" ? "" : description;
    props.handleInputChange({
      id: "longDescription",
      value: discard,
    });
    setData(discard);
  };
  const handlePreview = () => {
    setPreview(true);
    setData(data);
  };

  useEffect(() => {
    if (
      props.type == "edit" &&
      adminData?.longDescription == props.formValues?.longDescription
    )
      handleDiscard();
  }, [props?.formValues?.longDescription]);

  return (
    <div className="description-container">
      <div className="right-block-wrap">
        <div className="heading">
          {/* <h2>
              Tell us more about this vault by adding a detailed description
            </h2> */}
          <p>Tell us more about this vault by adding a detailed description</p>
          <div className="break-line" />
        </div>
        {preview ? (
          <>
            <Preview
              value={data}
              handleSubmit={handleSubmit}
              back={(v) => setPreview(v)}
            />
          </>
        ) : (
          <>
            <div className="editor-wrap">
              <EditorComponent
                id="longDescription"
                type={props.type}
                initialValue={data}
                setLongDescription={(item) => {
                  // props.handleInputChange({
                  //   id: "longDescription",
                  //   value: item,
                  // });
                  setData(item);
                }}
              />
            </div>
            <div className="button-wrap">
              <button
                className="button-content-invert"
                onClick={() => {
                  handleDiscard();
                  props.close(false);
                }}
              >
                DISCARD AND GO BACK
              </button>
              <button className="button-content-invert" onClick={handlePreview}>
                PREVIEW DESCRIPTION
              </button>
              <button className="button-content" onClick={handleSubmit}>
                SAVE DESCRIPTION
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EditLongDescription;
