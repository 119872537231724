import React from "react";
import "./Style/index.css";

function ComingSoon() {
  return (
    <div className="container">
      <div className="coming-soon-text">Coming Soon</div>
    </div>
  );
}

export default ComingSoon;
