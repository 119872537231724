import React from "react";
import "./index.css";
import { footerNameConfig } from "./footerConfig";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footerWrap">
      {footerNameConfig.map((link) => {
        return (
          <React.Fragment key={link.name}>
            {link.path ? (
              <Link to={link.path} className="footerLinks">
                {link.name}
              </Link>
            ) : link.path === "" ? (
              <div className="footerLinks">{link.name}</div>
            ) : (
              <div>{link.name}</div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Footer;
