import React, { useEffect, useState } from "react";
import DropDown from "./DropDown";
import "../component-css/asset-details-flex.css";
import { useDispatch, useSelector } from "react-redux";
import DateTimePicker from "./Time_Picker/timePicker";
import SearchBasedTable from "./HistoryTable";
import NewPriceCard from "../Dashboard-Version2/Price_Card/NewPriceCard";
import { useStateWithCallback } from "@kingerez/usestatewithcallback";
import Vaults from "../../services/api/vaults";
import { useLocation } from "react-router-dom";
import { setCurrencyDetails } from "../../redux/actions/asset-details";

function AssetDetailsMain() {
  const location = useLocation();
  const dispatch = useDispatch();
  const assetList = useSelector((state) => state.get_assets);
  const [dataArray, setDataArray] = useState([]);
  let trial,
    shouldSubmitWorkVariable = 0;
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const accountAddress = localStorage.getItem("accountAddress");
  const [dateTime, setDateTime] = useStateWithCallback(new Date());
  const [minTime, setMinTime] = useState(new Date());
  const [tableSelect, setTableSelect] = useState(true);
  const [filterUpdated, setFilterUpdated] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [cardCurrency, setCardCurrency] = useState("USD");
  const [totalAsset, setTotalAsset] = useState(0);
  const [vaultNetValue, setVaultNetValue] = useState(0);
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);

  const handleDateSubmit = (datetime) => {
    setDateTime(datetime);
    setFilterUpdated(true);
  };

  const handleReset = () => {
    setTableSelect(true);
  };

  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress != "") {
        try {
          const res = await Vaults.getVaultDetails(vaultAddress);
          let creationTime =
            res?.data?.data?.createdAt ?? res?.data?.data?.vault?.createdAt;
          setMinTime(new Date(creationTime));
        } catch (err) {
          console.log({ err });
        }
        handleDateTimeSearch("initial");
      }
    };
    asyncFn();
  }, [vaultAddress]);

  useEffect(() => {
    if (currency !== "USD") setFilterUpdated(true);
  }, [currency]);

  const handleDateTimeSearch = async (initial) => {
    setDataArray([]);
    trial = [];
    let dts, safeAssets;
    let response = await Vaults.vaultAssetListwithHistoricalData(
      vaultAddress,
      dateTime,
      currency
    );
    dispatch(setCurrencyDetails(currency));
    setTableLoader(false);
    if (response?.data?.data) setDataArray(response?.data?.data);
    else setDataArray([]);
    initial !== "initial" && setTableSelect(false);
  };

  useEffect(() => {
    Vaults.getNavLiquidatedValues(vaultAddress, accountAddress).then((res) => {
      setVaultNetValue(res.data.data.NAV);
      setTotalAsset(res.data.data.LiquidatedValue);
      setCardCurrency(res.data.data.baseCurrency);
      setLoader(false);
    });
  }, [accountAddress, vaultAddress]);

  return (
    <React.Fragment>
      <div className="asset-page-aligner">
        <div className="asset-debts-table-first-flex">
          <p className="coins">Assets & Debts</p>

          <div className="assets-debts-row-two-display-flexer">
            <NewPriceCard
              title="Net Asset Value"
              isChartLoading={loader}
              value={(vaultNetValue / 10 ** 18).toFixed(2)}
              baseCurrency={cardCurrency}
            />
            <NewPriceCard
              title="Liquidation Value"
              isChartLoading={loader}
              value={totalAsset}
              baseCurrency={cardCurrency}
            />
          </div>
        </div>
        <div className="assets-debts-table-styler">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="asset-details-search-elements">
              <DateTimePicker
                handleDateSubmit={handleDateSubmit}
                shouldSubmitWorkVariable={shouldSubmitWorkVariable}
                minTime={minTime}
              />
              <div style={{ display: "flex", gap: "15px" }}>
                <button
                  disabled={!filterUpdated}
                  className={
                    filterUpdated
                      ? "asset-details-table-button-styling"
                      : "asset-details-table-button-styling disabled-button"
                  }
                  onClick={() => {
                    setFilterUpdated(false);
                    setTableLoader(true);
                    handleDateTimeSearch();
                  }}
                >
                  <p>APPLY</p>
                </button>
                {tableSelect == false ? (
                  <button
                    className="asset-details-table-button-styling"
                    id="reset-button"
                    onClick={handleReset}
                  >
                    <p>RESET</p>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <DropDown
              tableSelect={tableSelect}
              setCurrency={setCurrency}
              currency={currency}
            />
          </div>
          <SearchBasedTable
            loader={tableLoader}
            dataArray={dataArray}
            id={1}
            dateTime={dateTime}
            setTotalAsset={setTotalAsset}
            setVaultNetValue={setVaultNetValue}
            setLoaderToggle={setLoaderToggle}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AssetDetailsMain;
