import React, { useDebugValue, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CustomDialog } from "react-st-modal";
import "./Manager_Styling/style.css";
import ChangeManagerModal from "./Change_manager_modal/index";
import { useSelector } from "react-redux";
import addIcon from '../../../../assets/Manage/Whitelist/AddButton.svg'
import Tooltip from "@material-ui/core/Tooltip";
import "../WhiteList_Manager/addButton.css"
import WhiteListModal from "../WhiteList_Modal/index";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import addressDisplayConfig from "../../../Sidebar/Safedetails/addressDisplayConfig";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 500,
    boxShadow: 0,
    width: 500,
    // height: 10,
  },
  whitelistHeading:{
    fontSize: "16px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"13px",
    },
  },

  rowGroupName: {
    paddingRight: "0px",
    paddingLeft: "10px",
  },
  rowManagerWidth: {
    [theme.breakpoints.down(1111)]: {
      // fontSize: "15px",
      whiteSpace: "nowrap",

      width: "10px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    fontSize: "14px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
  },
  height: {
    height: "10px",
  },
  width: {
    width: "55%",
  },
  groupWidth: {
    width: "30%",
    height: "10px",
  },
  padding: {
    height: "10px",
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down('1641')]:{
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  whitelistAddress: {
    fontSize: "14px",
    display: "inline",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "none",
    },
  },
  whitelistAddressSmall: {
    fontSize: "14px",
    display: "none",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "inline",
    },
  },
  smallFontSize:{
    fontSize:"14px",
    [theme.breakpoints.down('1641')]:{
      fontSize:"12px",
    },

  }
}));


const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3E69",
    },
    secondary: {
      main: "#00BFF5",
    },
    tertiary: {
      main: "#2BB160",
    },
    mainContent: {
      fontSize: "14px",
    },
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: "rgb(74, 72, 72)",
    color: "rgb(237, 230, 230)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginBottom: "5px",
  },
}))(Tooltip);

export default function Manager(props) {
  const classes = useStyles();
  let finalRow = [];
  let whitelistManagerArray = [];
  const [arrayData, setArrayData] = useState([]);
  const { dataValue, toggle, showAddandRemoveButtons, web3, userAddress,displayMode } =
    props;
  const vaultAddress = useSelector(
    (state) => state.get_vault_address.vaultAddress
  );
  const [managerArray,setManagerArray] = useState([])

  const toggleState = (value) => {
    props.toggleState(value);
  };

  useEffect(() => {
    const asyncFn = async () => {
      if (vaultAddress) {
        finalRow = [];
        whitelistManagerArray = [];
        dataValue.map((res) => {
          res.admin.map((admin) => {
            whitelistManagerArray.push(admin.toUpperCase());
            finalRow.push({
              manager: admin,
              id: res.whiteListId,
              name: res.groupName,
              owner: res.owner,
            });
          });
        });

        await Promise.all(finalRow, whitelistManagerArray).then((s) => {
          setArrayData(finalRow);
          setManagerArray(whitelistManagerArray);
        });
      }
    };
    asyncFn();
  }, [vaultAddress, toggle,dataValue]);

  return (
    <div className="safedetails-whitelist-manager-styling">
      <p className={classes.whitelistHeading} style={{ color: "#1B3E69", fontWeight: "bold" }}>WHITELIST MANAGER</p>
      <div style={{ marginTop: "10px" }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead
              classes={{ root: classes.height }}
              style={{ borderBottom: "1px solid #DEE5EF" }}
            >
              <TableRow>
                <TableCell padding="none" classes={{ root: classes.width }}>
                  <p className={classes.smallFontSize} style={{  fontWeight: "bold" }}>
                    Address
                  </p>
                </TableCell>
                <TableCell padding="none" align="left">
                  <p className={classes.smallFontSize} style={{  fontWeight: "bold" }}>Group</p>
                </TableCell>
                <TableCell padding="none" align="right">
                  <p style={{  fontWeight: "bold" }} className={classes.smallFontSize}>Action</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayData.length != 0 ? (
                arrayData.map((row) => (<>
      
                  <TableRow
                    key={row.name}
                    style={{ borderBottom: "1px solid #DEE5EF" }}
                  >
                    <TableCell
                      className={classes.padding}
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      <p className={classes.whitelistAddress}>
                        <span>{row.manager}</span>
                      </p>
                      <p
                        className={classes.whitelistAddressSmall}
                        // style={{ fontSize: "14px" }}
                      >
                        <Tooltip title={row.manager} placement="top-start">
                          <span>{addressDisplayConfig(row.manager)}</span>
                        </Tooltip>
                      </p>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.groupWidth}
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      <p className={classes.smallFontSize}>{row.name}</p>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.height}
                      component="th"
                      scope="row"
                      padding="none"
                      align="right"
                    >
                      {/* {} */}
                      {managerArray.includes(userAddress.toUpperCase()) ? (
                        <button
                          id="safedetails-manager-button-styling"
                          onClick={async () => {
                            const result = await CustomDialog(
                              <ChangeManagerModal
                                dataValue={dataValue}
                                toggleState={toggleState}
                                web3={web3}
                                whitelistId={row.id}
                                WhitelistAdminAddress={row.manager}
                                userAddress={userAddress}
                                dispatch={props.dispatch}
                                abi={props.abi}
                                // progress={progress}
                              />,
                              {
                                showCloseIcon: false,
                              }
                            );
                          }}
                        >
                          <p>Delete</p>
                        </button>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                  </>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
      {(displayMode == true)?
      <button className="custom-add-button-styling" onClick={async() =>{
        const result = await CustomDialog(   <MuiThemeProvider theme={theme}>
         <WhiteListModal
           web3={web3}
           dataValue={dataValue}
           type="add_multiple_manager"
           toggleState={toggleState}
           userAddress={userAddress}
           dispatch={props.dispatch}
           abi={props.abi}
          //  progressData={progress}
         />
         </MuiThemeProvider>
       
         ,
         {
           showCloseIcon: false,
         }
       );
   }}><img src={addIcon}></img><p>ADD</p></button>:<></>
    }
        </div>
      </div>
    </div>
  );
}
