import { Select } from "antd";
import React from "react";
import { useDrop } from "react-dnd";
import { AdvisorType } from "../../constants/advisorType";
import "./Style/index.css";

function CardItem(props) {
  const [i, drop] = useDrop({
    accept: props.accept,
    drop: () => ({ target: props.id }),
    canDrop: (item) => {
      return item.source !== props.id;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      drop: monitor.getHandlerId(),
    }),
  });
  const { Option } = Select;

  const displayData = (value) => {
    switch (value[0].type) {
      case "protocol":
        return value[0].protocolSymbol.slice(0, 3);
      default:
        return " ";
    }
  };
  const handleChange = (value) => {
    props.setField({ id: "advisor", value: value });
    props.setField({ id: "settings", value: undefined });
    props.setEdited && props.setEdited(true);
  };

  return (
    <>
      <div className="content-items" ref={drop} id={props.id}>
        <div className="add">
          {props.id === "advisor" ? (
            <div className="select">
              <Select
                size="large"
                disabled={props.enabled}
                onChange={handleChange}
                onClear={() => props.setField({ id: "advisor", value: "" })}
                allowClear
                value={props?.fields?.value ?? ""}
                style={{
                  backgroundColor: "transparent",
                  gap: 0,
                  display: "flex",
                }}
              >
                {AdvisorType.map((type) => {
                  return <Option value={type}>{type}</Option>;
                })}
              </Select>
            </div>
          ) : props.fields ? (
            <>
              {props.fields.map((field, index) => (
                <span key={index}>{displayData(field)}</span>
              ))}
              <button>+</button>
            </>
          ) : (
            ""
          )}
          {props.isValidated.coreCard.advisor === "invalid" && (
            <span className="validation-required">
              *Fill the advisory settings
            </span>
          )}
          <div className="text">{props.text}</div>
        </div>
      </div>
    </>
  );
}

export default CardItem;
