import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import {  Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import AvatarCharacter from "../../../SidebarSettings/APS/Avatar";
import { getImage } from "../../../api";

const useStyles = makeStyles((theme) => ({
  table: {},
  mainContent: {
    fontSize: "16px",
    textAlign: "justify",
    marginBottom: "40px",
    [theme.breakpoints.down('1641')]:{
      fontSize: "13px",
   },
  },
  rowStart: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down('1641')]:{
      fontSize: "12px",
   },
  },
  rowEnd: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#6D7681",
    [theme.breakpoints.down('1641')]:{
      fontSize: "12px",
   },
  },
  confirm: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1B3E69",
    marginTop: "40px",
    marginBottom: "10px",
    [theme.breakpoints.down('1641')]:{
      fontSize: "12px",
   },
  },
  mainHeading: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#1B3E69",
    [theme.breakpoints.down('1641')]:{
      fontSize: "20px",
   },
  },
  dotGreen: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    background: "#188038",
    float: "right",
    marginRight: "22px",
    [theme.breakpoints.down('1641')]:{
      height: "10px",
      width: "10px",
   },
  
  },
  dotRed: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    background: "#C42121",
    float: "right",
    marginRight: "22px",
    [theme.breakpoints.down('1641')]:{
      height: "10px",
      width: "10px",
   },
  
  },
  buttonNew: {
    marginRight: "5px",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1B3E69",
    textDecoration: "underline",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.01)",
    },
    [theme.breakpoints.down('1641')]:{
      fontSize: "12px",
   },
  },
 paddingColumn:{
  paddingTop: "-10px",
  paddingBottom: "5px",
  paddingRight: "0px",
   [theme.breakpoints.down('1641')]:{
     padding:"6px 0px",
   }
 }
}));

export default function BasicTable(props) {
  const fields = useSelector((state) => state.get_formdetails);
  var members = [];
  if (fields.formUserGroups.length > 0) {
    fields.formUserGroups.forEach((user) => {
      members.push(user.groupName);
    });
  }

  const classes = useStyles();

  return (
    <div>
      {props.data.length > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
            
              <Grid item xs={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "2px solid rgba(109, 118, 129,0.16)",
                        }}
                      >
                        <TableCell
                          className={classes.rowEnd}
                          style={{
                            paddingLeft: "0px",
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                          }}
                        >
                          Strategy
                        </TableCell>
                        <TableCell
                          className={classes.rowEnd}
                          align="right"
                          style={{ paddingTop: "-10px", paddingBottom: "5px" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className={classes.rowEnd}
                          align="right"
                          style={{
                            paddingTop: "-10px",
                            paddingBottom: "5px",
                            paddingRight: "0px",
                          }}
                        >
                          Share
                        </TableCell>
                        {props.manager &&!props.break? (
                          <TableCell
                            className={classes.rowEnd}
                            align="right"
                            style={{
                              paddingTop: "-10px",
                              paddingBottom: "5px",
                              paddingRight: "0px",
                            }}
                          >
                            Action
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.data.map((row, index) => (
                        <>
                          <TableRow
                            style={{
                              borderBottom:
                                "2px solid rgba(109, 118, 129,0.16)",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.paddingColumn}
                            >
                              <p className={classes.rowStart}>
                                <AvatarCharacter
                                  image={getImage(row.strategyIcon)}
                                  class="small20"
                                />
                                <span>{row.strategyName}</span>
                              </p>
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.paddingColumn}
                            >
                              {row.status ? (
                                <p className={classes.dotGreen}>{row.status}</p>
                              ) : (
                                <p className={classes.dotRed}>{row.status}</p>
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.paddingColumn}
                            >
                              {row.share != "" ? (
                                <p className={classes.rowEnd}>{row.share} %</p>
                              ) : row.status ? (
                                <p className={classes.rowEnd}>0 %</p>
                              ) : (
                                <p></p>
                              )}
                            </TableCell>
                            {props.manager && !props.break ? (
                              <TableCell
                                align="right"
                                className={classes.paddingColumn}
                              >
                                {row.status ? (
                                  <p
                                    className={classes.buttonNew}
                                    onClick={() => {
                                      props.handleDeactivate(
                                        row.strategyAddress
                                      );
                                    }}
                                  >
                                    De-Activate
                                  </p>
                                ) : (
                                  <p
                                    className={classes.buttonNew}
                                    onClick={() => {
                                      props.handleActivate(row.strategyAddress);
                                    }}
                                  >
                                    Activate
                                  </p>
                                )}
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
}