import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../Transaction-Styling/Info-Table-Styling.css";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import copy from "copy-to-clipboard";
import DownArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-arrow.svg";
import UpArrow from "../../../../src/assets/Transaction-Table/transaction-table-dropdown-uparrow.svg";
import Copy from "../../../../src/assets/Transaction-Table/copy.svg";
import ReRoute from "../../../../src/assets/Transaction-Table/reroute.svg";
import EditIcon from "../../../../src/assets/Transaction-Table/EditButton.svg";
import "../Pending-Transaction/Pending-Transaction-Styler/PendingTransactionStyler.css";
import { makeStyles } from "@material-ui/core/styles";
import TerminationPermissionModal from "./Modal/terminationModal";
import { SaveTransactionHash } from "../../api";
import TxTypes from "./Enum/txTypes";
import useInterval from "../../Polling/useInterval";
import addressDisplayConfig from "../../Sidebar/Safedetails/addressDisplayConfig";
import hashDisplayConfig from "../../Sidebar/Safedetails/hashDisplayConfig";
import NoData from "../../No-Data-Component/noData";
import CustomLoader from "../../Custom_Loader/customLoader";
import WrapperFunction from "../../Global-Functions/WrapperFunction";
import validateTxHash from "../../Global-Functions/TransactionHashValidator";
import { message } from "../../../utils/message";
import Skeleton from "@material-ui/lab/Skeleton";
import { CustomDialog } from "react-st-modal";
import { Vaults } from "../../../services/api";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  input2: {
    fontSize: 12,
    fontFamily: "'Open sans', sans-serif",
    ".MuiOutlinedInput-input:-webkit-autofill": {
      height: "0px",
      backgroundColor: "white",
    },
  },

  root: {
    marginTop: "3px",
    marginBottom: "3px",
    "& .MuiOutlinedInput-root": {
      width: "350px",
      background: "white",
      // [theme.breakpoints.down("1641")]: {
      height: "26px",
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#1B3E69",
      },
      "&:hover fieldset": {
        borderColor: "#1B3E69",
      },
    },
  },
  button: {
    background: "#1B3E69",
    color: "white",
    borderRadius: "8px",
    width: "60px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: 12,
    fontFamily: "'Open sans', sans-serif",
    height: "26px",
    fontWeight: "bold",
    marginLeft: "5px",
    padding: "4px",
  },
  cancelbutton: {
    background: "#C42121",
    color: "white",
    borderRadius: "8px",
    width: "60px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: 12,
    fontFamily: "'Open sans', sans-serif",
    height: "26px",
    fontWeight: "bold",
    marginLeft: "5px",
    padding: "4px",
  },
  re_initiate_button: {
    margin: "0px 5px 10px 0px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#FFFFFF",
    color: "#1B3E69",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#FFFFFF",
    },
    [theme.breakpoints.down("1640")]: {
      padding: "4px 10px",
      fontSize: "12px",
    },
  },
  terminate_button: {
    margin: "0px 0px 10px 5px",
    padding: "8px 20px",
    borderRadius: "8px",
    background: "#C42121",
    color: "white",
    boxShadow: "none",
    float: "right",
    transition: "0.3s all ease",
    "&:hover": {
      boxShadow: "none",
      background: "#C42121",
    },
    [theme.breakpoints.down("1640")]: {
      padding: "4px 10px",
      fontSize: "12px",
    },
  },
  smallWrapper: {
    display: "flex",
    alignItems: "center",
  },
  hashContainer: {
    display: "flex",
    alignItems: "center",
  },
  txAddress: {
    fontSize: "14px",
    display: "inline",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "none",
    },
  },
  txAddressSmall: {
    fontSize: "14px",
    display: "none",
    [theme.breakpoints.down("1641")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("1620")]: {
      display: "inline",
    },
  },
}));

const EpochConvertor = (value, condition) => {
  var d = new Date(value);
  // d.setUTCSeconds(utcSeconds);
  var date = d.getDate();
  date = date < 10 ? "0" + date : date;
  var month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var year = d.getFullYear();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var final = hours + ":" + minutes + ":" + seconds + " " + ampm;
  var date_and_time =
    date +
    "/" +
    month +
    "/" +
    year +
    "    " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    ampm;
  if (condition === 1) {
    return final;
  } else if (condition === 0) {
    return date_and_time;
  }
};

function Row(props) {
  const [open, setOpen] = React.useState(false);
  const [txStatus, setTxStatus] = useState(true);
  const [newTxHash, setNewTxHash] = useState("");
  const [signerStatus, setSignerStatus] = useState(false);
  const [invalidHash, setInvalidHash] = useState(false);
  const userAddress = useSelector((state) => state.get_user_address.address);
  const { row } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showBox, setShowBox] = React.useState(false);

  useEffect(() => {
    if (row) {
      if (row.signerAddress?.toUpperCase() === userAddress?.toUpperCase()) {
        setSignerStatus(true);
      } else {
        setSignerStatus(false);
      }
    }
  }, [row]);

  useEffect(() => {
    if (row) {
      if (row.signerAddress === "PENDING") {
        setTxStatus(true);
      } else if (row.txnStatus === "REVERT" || row.txnStatus === "CORRUPTED") {
        setTxStatus(false);
      }
    }
  }, [row]);

  const handleSaveHash = (id) => {
    if (validateTxHash(newTxHash)) {
      setInvalidHash(false);
      axios
        .patch(SaveTransactionHash(id), {
          txHash: newTxHash,
        })
        .then((res) => {
          message.success("Hash updated successfully");
          setShowBox(false);
        })
        .catch((err) => {
          setShowBox(true);
          console.log(err);
        });
    } else {
      setInvalidHash(true);
      message.error("Error occured while attempting to update hash");
    }
  };

  const copytextHandler = (value) => {
    copy(value);
  };

  const linkHandler = (address) => {
    window.open(`https://etherscan.io/address/${address}`);
  };

  const TxHashlinkHandler = (address) => {
    window.open(`https://etherscan.io/tx/${address}`);
  };

  const handleReInitiation = (data) => {
    dispatch(WrapperFunction(data, "PATCH"));
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
    setNewTxHash(input);
  };

  return (
    <>
      <TableRow
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #DEE5EF",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ display: "flex", gap: "15px" }}
        >
          {txStatus ? (
            <div className="glowing-div-box"></div>
          ) : (
            <div className="error-red-div-box"></div>
          )}
          <p className="pending-transaction-table-content-size">
            {TxTypes(row.txnType)}
          </p>
        </TableCell>
        <TableCell>
          <p className="pending-transaction-table-content-size">
            {EpochConvertor(row.dateOfTransaction, 0)}
          </p>
        </TableCell>
        <TableCell>
          <p className="pending-transaction-table-content-size">{row.nonce}</p>
        </TableCell>
        <TableCell>
          <p
            className="pending-transaction-table-content-size"
            style={{ fontWeight: "bold" }}
          >
            {row.txnStatus}
          </p>
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <img
                src={UpArrow}
                style={{ height: "24px", width: "24px" }}
                alt=""
              ></img>
            ) : (
              <img
                src={DownArrow}
                style={{ height: "24px", width: "24px" }}
                alt=""
              ></img>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="collapse-table-style-pending-transaction">
              <div style={{ display: "flex", gap: "8vw", marginTop: "10px" }}>
                <div>
                  <p className="pending-transaction-dropdown-table-content">
                    From
                  </p>

                  <p className="pending-transaction-dropdown-table-content">
                    Executed
                  </p>
                  <p className="pending-transaction-dropdown-table-content">
                    Nonce
                  </p>
                  <p className="pending-transaction-dropdown-table-content">
                    Hash
                  </p>
                </div>
                <div>
                  <div>
                    <p className={classes.txAddress}>
                      <span>{row.signerAddress}</span>
                    </p>
                    <p
                      className={classes.txAddressSmall}
                    >
                      <Tooltip title={row.signerAddress} placement="top-start">
                        <span>{addressDisplayConfig(row.signerAddress)}</span>
                      </Tooltip>
                    </p>
                    <Tooltip title="Copy to clipboard" placement="top">
                      <span
                        onClick={() => copytextHandler(row.signerAddress)}
                        style={{ marginLeft: "5px" }}
                      >
                        <img src={Copy} alt=""></img>
                      </span>
                    </Tooltip>

                    <Tooltip title="Etherscan" placement="top">
                      <span
                        onClick={() => linkHandler(row.signerAddress)}
                        style={{ marginLeft: "5px" }}
                      >
                        <img src={ReRoute} alt=""></img>
                      </span>
                    </Tooltip>
                  </div>
                  {/* <p className="pending-transaction-dropdown-table-content-only">
                   {row.signer}
                  </p> */}

                  <p className="pending-transaction-dropdown-table-content-only">
                    {EpochConvertor(row.dateOfTransaction, 0)}
                  </p>
                  <p className="pending-transaction-dropdown-table-content-only">
                    {row.nonce}
                  </p>
                  {!showBox ? (
                    !row.txnHash ? (
                      <div style={{ display: "flex" }}>
                        <Skeleton animation="wave" height={22} width="100%" />
                        {userAddress?.toUpperCase() ===
                        row?.signerAddress?.toUpperCase() ? (
                          <Tooltip title="Edit Hash" placement="top">
                            <span
                              onClick={() => setShowBox(true)}
                              style={{
                                marginLeft: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img src={EditIcon} alt=""></img>
                            </span>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className={classes.hashContainer}>
                        <p className={classes.txAddress}>
                          <span>{row.txnHash}</span>
                        </p>
                        <p
                          className={classes.txAddressSmall}
                        >
                          <Tooltip title={row.txnHash} placement="top-start">
                            <span>{hashDisplayConfig(row.txnHash)}</span>
                          </Tooltip>
                        </p>
                        <Tooltip title="Copy to clipboard" placement="top">
                          <span
                            onClick={() => copytextHandler(row.txnHash)}
                            style={{
                              marginLeft: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={Copy} alt=""></img>
                          </span>
                        </Tooltip>

                        <Tooltip title="Etherscan" placement="top">
                          <span
                            onClick={() => TxHashlinkHandler(row.txnHash)}
                            style={{
                              marginLeft: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={ReRoute} alt=""></img>
                          </span>
                        </Tooltip>

                        {userAddress?.toUpperCase() ===
                        row.signerAddress?.toUpperCase() ? (
                          <Tooltip title="Edit Hash" placement="top">
                            <span
                              onClick={() => setShowBox(true)}
                              style={{
                                marginLeft: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img src={EditIcon} alt=""></img>
                            </span>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  ) : (
                    <>
                      <div className={classes.smallWrapper}>
                        <TextField
                          className={classes.root}
                          id="outlined-basic"
                          placeholder="Enter new hash"
                          onChange={handleInputChange}
                          variant="outlined"
                          InputProps={{ classes: { input: classes.input2 } }}
                        />
                        <div
                          className={classes.button}
                          onClick={() => handleSaveHash(row.txnId)}
                        >
                          SAVE
                        </div>
                        <div
                          className={classes.cancelbutton}
                          onClick={() => {
                            setShowBox(false);
                            setInvalidHash(false);
                          }}
                        >
                          CANCEL
                        </div>
                      </div>
                      {invalidHash ? (
                        <p style={{ color: "#c42121", fontSize: "12px" }}>
                          Invalid Transaction Hash
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* {!txStatus && signerStatus ? (
                <div style={{ marginTop: "15px" }}>
                  {!row.txnHash ? (
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const result = await CustomDialog(
                          <TerminationPermissionModal id={row.txnId} />,
                          {
                            showCloseIcon: false,
                          }
                        );
                      }}
                      className={classes.terminate_button}
                    >
                      TERMINATE
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    variant="contained"
                    className={classes.re_initiate_button}
                    onClick={() => handleReInitiation(row)}
                  >
                    RE-INITIATE
                  </Button>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function App() {
  const location = useLocation();
  const [tableContents, setTableContents] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const vaultAddress =
    location.pathname.split("/")[1] === "vault"
      ? location.pathname.split("/")[2]
      : undefined;
  const TransactionInitiated = useSelector(
    (state) =>
      state.get_deposit_withdraw_state_change_reflector.transactionInstance
  );
  const fetchPendingData = async () => {
    const result = await Vaults.getQueuedTransactions(vaultAddress);
    if (result.data) {
      const rowData =
        result.data.data &&
        result.data.data.filter((res) => {
          return res.txnStatus !== "SUCCESS" && res.txnStatus !== "CORRUPTED";
        });
      if (rowData) {
        Promise.all(rowData).then((res) => {
          setTableContents(res);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
      } else {
        setTableContents([]);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
  };

  useEffect(() => {
    fetchPendingData();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (TransactionInitiated) {
      fetchPendingData();
    }
  }, [TransactionInitiated]);

  useInterval(() => {
    fetchPendingData();
  }, 10000);

  return (
    <>
      <p className="queue-heading-styler">Pending Transactions </p>
      <p className="whitelist-descriptor">
        Transactions made by the users or strategies which have been initiated
        but have not yet been executed by the blockchain are listed below.
      </p>
      {loading ? (
        <CustomLoader />
      ) : tableContents.length === 0 ? (
        <NoData text="No Pending Transactions" />
      ) : (
        <TableContainer
          component={Paper}
          className="history-table-custom-scroll"
        >
          <Table>
            <TableHead
              style={{
                paddingBottom: "0px",
                borderBottom: "1px solid #DEE5EF",
              }}
            >
              <TableRow>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Type</p>
                </TableCell>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Date</p>
                </TableCell>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Nonce</p>
                </TableCell>
                <TableCell style={{ paddingBottom: "0px" }}>
                  <p className="pending-screen-table-heading">Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContents.map((row) => {
                return <Row key={row.txnId} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
