import axios from 'axios';
import {currencyList} from '../../assets/currency-list/currencies'

//Action creator
export const loadCurrency = () => async (dispatch) => {
    //FETCH AXIOS
    const keys = [];
     const currencyData = currencyList()

    // await axios.get('https://gist.githubusercontent.com/joseluisq/59adf057a8e77f625e44e8328767a2a5/raw/e26aa3a0a540a88049a69b9a50d8010004deb34d/currencies.json')
    // .then((data)=>{
     
    //     for(var k in data.data){
    //         keys.push(k);
    //     }
    // })
    
    dispatch({
      type: "FETCH_CURRENCY",
      payload: {
       currencies : currencyData,
        
      },
    });
  };

  //'https://gist.githubusercontent.com/joseluisq/59adf057a8e77f625e44e8328767a2a5/raw/e26aa3a0a540a88049a69b9a50d8010004deb34d/currencies.json'

