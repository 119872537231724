import { UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import "./Style/index.css";

function CardItem({
  id,
  accept,
  setField,
  text,
  isValidated,
  handleValidation,
}) {
  const handleTextChange = (e) => {
    let temp = { id: e.target.id, value: e.target.value };
    setField(temp);
  };

  switch (accept) {
    case "text":
      return (
        <div className="content-items" id={id}>
          <div className="add">
            <input
              type="text"
              placeholder={text}
              id={id}
              onChange={(e) => handleTextChange(e)}
              required={true}
            />
          </div>
          {isValidated.adminCard[id] === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          {isValidated.adminCard[id] === "invalid-address" && (
            <span className="validation-required">*Invalid address</span>
          )}
          <div className="text">{text}</div>
        </div>
      );
    case "upload":
      return (
        <div className="content-items" id={id}>
          <div className="add" style={{ maxWidth: "61%", minWidth: "100%" }}>
            <Dragger
              name="file"
              action=""
              className="admin-upload"
              maxCount={1}
              listType="picture"
              accept="image/png, image/jpeg, image/jpg,"
              beforeUpload={(file) => {
                // if (file.size < 1048576) {
                let data = new FormData();
                data.append("imageUpload", file);
                setField({ id: "vaultIcon", value: data });
                // dispatch(setVaultIcon(data));
                return false;
              }}
              onChange={(e) => {
                e.file.status === "removed"
                  ? handleValidation({
                      ...isValidated,
                      adminCard: {
                        ...isValidated.adminCard,
                        vaultIcon: "invalid",
                      },
                    })
                  : handleValidation({
                      ...isValidated,
                      adminCard: {
                        ...isValidated.adminCard,
                        vaultIcon: "valid",
                      },
                    });
              }}
            >
              <p className="ant-upload-hint">
                <UploadOutlined /> &nbsp; Drag and Drop to Upload Browse
              </p>
            </Dragger>
          </div>
          {isValidated.adminCard[id] === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          <div className="text">{text}</div>
        </div>
      );
    case "textarea":
      return (
        <div className="content-items" id={id}>
          <textarea
            className="add"
            type="text"
            placeholder={text}
            id={id}
            onChange={(e) => handleTextChange(e)}
            required={true}
            maxLength="320"
          ></textarea>

          {isValidated.adminCard[id] === "invalid" && (
            <span className="validation-required">*This field is required</span>
          )}
          <div className="text">
            {text} <span className="brown-text"> (Max 320 characters)</span>
          </div>
        </div>
      );
    default:
      break;
  }
}

export default CardItem;
