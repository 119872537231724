import { combineReducers } from "redux";
import AddtoQueue from "./AddtoQueueReducer";
import getDepositAssets from "./assets-In-DropDown";
import networkFetch from "./ConnectedNetworkReducer";
import PendingVaultCreation from "./Creation-Progress-Reducer";
import getBlockNumberReducer from "./CurrentBlockNumberReducer";
import CurrentRouteReducer from "./CurrentRouteReducer";
import DashboardLoader from "./Dashboard-Loader-Reducer";
import defaultVaultAddress from "./DefaultVaultAddressReducer";
import DepositWithdrawInstance from "./DepositWithdrawReducer";
import setFilterValue from "./FilterReducer";
import getAssets from "./get-assets";
import chainReducer from "./get-chain-details";
import getCurrencies from "./get-currency-listing";
import ReturnedHashReducer from "./get-return-hash";
import protocolReducer from "./get-strategy-protocol";
import { formReducer } from "./getFormDetails";
import Onboard from "./getOnboardReducer";
import { StrategyShare } from "./getStrategyShare";
import Toggle from "./getToggle";
import getWeb3 from "./getWeb3Reducer";
import isConnected from "./IsConnectedReducer";
import getLoader from "./LoaderAnimation";
import MyBalanceReducer from "./MyBalanceReducer";
import ParameterArrayReducer from "./ParameterArrayReducer";
import setProvider from "./ProviderReducer";
import TransactionProgressReducer from "./reload-state";
import safeName from "./SafeNameReducer";
import vaultType from "./SetVaultTypeReducer";
import DashboardCardsData from "./TableCardsData";
import ToggleState from "./ToggleStateReducer";
import getTokenBalance from "./token-balance-reducer";
import TokenSymbol from "./tokenSymbolReducer";
import TokenValue from "./TokenValue";
import Total_Supply from "./Total_Supply";
import userAddressFetch from "./UserAddressReducer";
import setVaultAddress from "./VaultAddress";
import setVaultCreationStatus from "./vaultCreation";
import vaultDetailsReducer from "./vaultDetails";
import VaultIcon from "./vaultIconReducer";
import WalletStateReducer from "./walletStateReducer";
import WhitelistDetails from "./whitelist-reducer";
import SinceInceptionAPRReducer from "./SinceInceptionAPRReducer";

const allReducers = combineReducers({
  get_assets: getAssets,
  get_currencies: getCurrencies,
  get_formdetails: formReducer,
  get_assets_deposit_via_wallet: getDepositAssets,
  get_loader_animation: getLoader,
  get_chain_details: chainReducer,
  get_strategy_protocol: protocolReducer,
  get_user_address: userAddressFetch,
  get_network_type: networkFetch,
  get_is_connected: isConnected,
  get_default_vault_address: defaultVaultAddress,
  get_vault_address: setVaultAddress,
  get_provider: setProvider,
  get_token_balance: getTokenBalance,
  get_safe_name: safeName,
  get_dashboard_card_data: DashboardCardsData,
  get_token_value: TokenValue,
  get_whitelist_details: WhitelistDetails,
  get_toggle_state: ToggleState,
  get_toggle: Toggle,
  get_vaultIcon: VaultIcon,
  get_token_symbol: TokenSymbol,
  get_dashboard_loading_state: DashboardLoader,
  get_pending_vault_status: PendingVaultCreation,
  get_web3: getWeb3,
  get_Share: StrategyShare,
  get_total_supply: Total_Supply,
  get_transaction_in_progress: TransactionProgressReducer,
  get_deposit_withdraw_state_change_reflector: DepositWithdrawInstance,
  get_mybalance: MyBalanceReducer,
  get_queue_status: AddtoQueue,
  get_return_hash: ReturnedHashReducer,
  get_current_route: CurrentRouteReducer,
  get_onboard: Onboard,
  get_vault_type: vaultType,

  get_deposit_withdraw_parameters: ParameterArrayReducer,
  get_wallet_state: WalletStateReducer,

  get_vault_creation_status: setVaultCreationStatus,
  get_vault_details: vaultDetailsReducer,
  get_current_block_number: getBlockNumberReducer,
  get_graph_filter: setFilterValue,
  get_since_inception_apr: SinceInceptionAPRReducer,
});

export default allReducers;
